import { Component, OnInit } from '@angular/core';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { GlobalService } from "../../services/global.service"
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss']
})
export class ProvisioningComponent implements OnInit {

  public syncUrl = "";
  public tabs = [];
  public activeTab = "";
  constructor(
    public ndvs: NetDocumentViewerService,
    public global: GlobalService,
    private activatedRoute: ActivatedRoute, private router: Router,
  ) {


  }


  ngOnInit(): void {
    if (this.ndvs.currentUser.role == "globalAdmin" || this.ndvs.currentUser.role == "syncAdmin") { this.global.isGlobalAdmin=true;}
    this.tabs = [
      { title: "Teams", value: "teams", isVisible: this.global.isGlobalAdmin },
      { title: "Requests", value: "requests", isVisible: this.global.isGlobalAdmin },
      // { title: "Create Request", value: "createRequest", isVisible: this.global.isGlobalAdmin },
      { title: "Create Request", value: "createRequest", isVisible: !this.global.isGlobalAdmin },
      { title: "My Requests", value: "myRequests", isVisible: !this.global.isGlobalAdmin }
    ];    
    if (this.global.isGlobalAdmin) {this.activeTab = "teams" } else { this.activeTab = "createRequest" };
    this.activatedRoute.params.subscribe((param) => {
      if (param.activetab) {
        const validActiveTab = this.tabs.find(tab => tab.value?.toLowerCase() == param.activetab?.toLowerCase() && tab.isVisible);
        if (validActiveTab) {
          this.activeTab = validActiveTab.value;
        }
      }
    });
    //this.activeTab = this.activatedRoute.snapshot.params.activeTab;

  }
  onTabChange(tab) {
    this.router.navigateByUrl(`/provisioning/${tab.nextId}`);
  }


  public onCloseNDSection() {
    this.ndvs.showSyncPopup = false;
    this.global.syncFrom = ""
  }

}
