import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NetDocLoginComponent } from './pages/net-doc-login/net-doc-login.component';
import { ProvisioningComponent } from './pages/provisioning/provisioning.component';
import { BulkUploadComponent } from './pages/bulk-upload/bulk-upload.component';
import { BulkTeamCreationComponent } from './pages/bulk-team-creation/bulk-team-creation.component';
import { BulkConfigUpdateComponent } from './pages/bulk-config-update/bulk-config-update.component';
import { TeamsSettingsComponent } from './pages/teams-settings/teams-settings.component';
import { AuthGuard } from './auth/auth.guard';
import { CreateTeamsComponent } from './pages/create-teams/create-teams.component';
import { CreateTeamsFTComponent } from './pages/create-teams-template-from-existing/create-teams-template-from-existing.component';
import { RequestsComponent } from './components/requests/requests.component';
import { CreateRequestComponent } from './components/create-request/create-request.component';
import { BulkTeamEditComponent } from './components/bulk-team-edit/bulk-team-edit.component';


let routes: Routes = [
    { path: '', component: LoginComponent, pathMatch: 'prefix' },
    { path: 'login', component: LoginComponent, pathMatch: 'prefix' },
    { path: 'netdocuments', component: NetDocLoginComponent, pathMatch: 'prefix' },
    { path: 'provisioning', component: ProvisioningComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'provisioning/:activetab', component: ProvisioningComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },  
    { path: 'bulk-upload', component: BulkUploadComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    // { path: 'bulk-team-creation', component: BulkTeamCreationComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'bulk-config-update', component: BulkConfigUpdateComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'teams-connections', component: TeamsSettingsComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'createteam', component: CreateTeamsComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'createfromtemplate', component: CreateTeamsFTComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'editrequest/:reqId', component: CreateRequestComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
    { path: 'editrequest', component: CreateRequestComponent, pathMatch: 'prefix' },
    { path: 'bulk-team-creation', component: BulkTeamEditComponent, pathMatch: 'prefix', canActivate: [AuthGuard] },
];

routes.push({ path: '**', redirectTo: '', pathMatch: 'prefix' });


@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class RouteConfigModule { }
