<app-container-top style="flex:0"></app-container-top>
<div class="container-holder" style="flex:1">
  <div class="nds-outer-box" style="max-width: 900px;">
    <div class="border-bottom d-flex justify-content-between pb-2 mb-3 align-items-center">
      <div class="pg-heading">
        Teams Connections
        <span>({{JobSettings.length}})</span>
      </div>
      <button type="button" class="btn" placement="left" [disabled]="defaultSelectionProcess"
        (click)="onCloseTeamsSetting()">
        <span class="material-icons-sharp md-home"></span>
      </button>
    </div>
    <div class="table-responsive border-bottom">
      <div class="note-content" *ngIf="JobSettings.length>0">
        Note: Connection Delete button is disabled if any Sync job contains the respective connection setting.
      </div>
      <div class="text-right mb-3" *ngIf="isAdmin">
        <!-- && !isProduction) -->
        <button type="button" class="btn im-primary-btn" placement="right" (click)="onDownloadSample()"
          [disabled]="(ndvs.currentUser.role==undefined || ndvs.currentUser.role!='globalAdmin')">
          <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
            <path
              d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
          </svg>
          Download sample
        </button>
        <button type="button" class="btn im-primary-btn" placement="right" (click)="onChooseFile(modalUpload)"
          [disabled]="(ndvs.currentUser.role==undefined || ndvs.currentUser.role!='globalAdmin')">
          <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
            <path
              d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
          </svg>
          Upload settings
        </button>
        <button type="button" class="btn im-primary-btn" placement="left"
          (click)="onCreateOneDriveJobSetting(modalOneDriveJobSetting)"
          [disabled]="(ndvs.currentUser.role==undefined || ndvs.currentUser.role!='globalAdmin')">
          <div class="d-flex align-items-center position-relative" style="left: -3px;">
            <span class="material-icons-sharp md-add d-flex align-items-center justify-content-center"></span>
            Add
          </div>
        </button>
      </div>
      <div *ngIf="(!showLoader && JobSettings.length==0)" class="no-data">
        Setting records not found
      </div>
      <table class="table mb-0" *ngIf="JobSettings.length>0">
        <thead class="thead-light">
          <tr>
            <th>Teams Tenant</th>
            <th class="mw-120">Actions</th>
            <th *ngIf="(ndvs.currentUser.role=='globalAdmin')" class="mw-120">
              Default
            </th>
          </tr>

        </thead>
        <tbody class="fs-14">
          <tr *ngFor="let jobSet of JobSettings; let settingIndex=index">
            <td class="text-left mw-400 align-middle">
              <div class="d-table-row site-links">
                <span class="d-table-cell fw-600"><a class="selected-url" title="{{jobSet.tenantName}}"
                    (click)="onCreateOneDriveJobSetting(modalOneDriveJobSetting,jobSet,settingIndex )">
                    {{jobSet.tenantName}}
                  </a></span>
              </div>
            </td>
            <td class="mw-120 align-middle">
              <button type="button" class="btn im-primary-btn btn-sm" data-toggle="modal" data-target="#editmodal"
                (click)="onCreateOneDriveJobSetting(modalOneDriveJobSetting,jobSet,settingIndex )"
                [disabled]="(ndvs.currentUser.role==undefined || ndvs.currentUser.role!='globalAdmin')">
                <span class="material-icons-sharp md-edit"></span>
              </button>
              <button type="button" class="btn btn-secondary btn-sm" (click)="showDeleteConfirm(modalDelete,jobSet)"
                [disabled]="((jobSet.jobAssigned!=undefined && jobSet.jobAssigned) || (ndvs.currentUser.role==undefined || ndvs.currentUser.role!='globalAdmin'))">
                <span class="material-icons-sharp md-delete"></span>
              </button>
            </td>
            <td *ngIf="(ndvs.currentUser.role=='globalAdmin')" class="mw-40 text-left align-middle">
              <input type="radio" [name]="'defaultRadioGroup'" [disabled]="defaultSelectionProcess"
                [checked]="jobSet.isDefault" (click)="showDefaultChangeConfirm(modalDefault,jobSet)"
                class="radio-class">
            </td>
          </tr>

        </tbody>
      </table>

    </div>
  </div>
  <!-- PAGE LOADER -->
  <div class="loader-container" *ngIf="showLoader">
    <div class="loader"></div>
  </div>

  <!-- DOWNLOADER -->
  <div *ngIf="(downloadFileUrl!='')" class="file-downloader" hidden>
    <iframe [src]="downloadFileUrl"></iframe>
  </div>
</div>



<app-footer style="flex:0"></app-footer>

<!-- Modal -->

<ng-template #modalDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button> -->
  </div>
  <div class="modal-body">
    Are you sure to delete this connection setting?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
      No
    </button>
    <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
      Yes
    </button>
  </div>
</ng-template>

<ng-template #modalDefault let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button> -->
  </div>
  <div class="modal-body">
    Are you sure to change the default Tenant selection?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
      No
    </button>
    <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
      Yes
    </button>
  </div>
</ng-template>

<ng-template #modalOneDriveJobSetting let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Teams Connection</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row d-flex align-items-center">
      <label for="driveTenentName" class="col-sm-4 col-form-label">
        <div>
          Teams Tenant
          <span class="text-danger">*</span>
        </div>
        <div class="help-tip">
          <div class="tooltip">
            <p>Please Enter the Teams Tenant </p>
          </div>
        </div>
      </label>
      <div class="col-sm-8">
        <input type="text" class="form-control {{(fieldValidation.tenantName) ? 'is-invalid' : ''}}"
          id="driveTenentName" placeholder="Enter Teams Tenant" [(ngModel)]="currentJoSetting.tenantName"
          (input)="onValidateFields('tenantName')" (focusout)="onValidateFields('tenantName')">
        <div *ngIf="isTenantNameAlredyExist" class="text-danger">
          Tenant name have already exist
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="driveTenent" class="col-sm-4 col-form-label">
        <div>
          Tenant Id
          <span class="text-danger">*</span>
        </div>
        <div class="help-tip">
          <div class="tooltip">
            <p>Please generate the tenant Id using azure app registration service </p>
          </div>
        </div>
      </label>
      <div class="col-sm-8">
        <input type="text" class="form-control {{(fieldValidation.tenantId) ? 'is-invalid' : ''}}" id="driveTenent"
          placeholder="Enter Tenant Id" [(ngModel)]="currentJoSetting.tenantId" (input)="onValidateFields('tenantId')"
          (focusout)="onValidateFields('tenantId')">
        <div *ngIf="isTenantIdAlredyExist" class="text-danger">
          Tenant Id have already exist
        </div>
      </div>
    </div>
    <!-- SHAREPOINT ONLINE -->
    <div class="form-group row">
      <label for="Client ID" class="col-sm-4 col-form-label">
        <div>
          Client ID
          <span class="text-danger">*</span>
        </div>
        <div class="help-tip">
          <div class="tooltip">
            <p>Please Enter the Client ID </p>
          </div>
        </div>
      </label>
      <div class="col-sm-8">
        <input id="con_pop_spClientId" type="text"
          class="form-control {{(fieldValidation.clientId) ? 'is-invalid' : ''}}" placeholder="Enter Client ID"
          [(ngModel)]="currentJoSetting.clientId" (input)="onValidateFields('clientId')"
          (focusout)="onValidateFields('clientId')">
      </div>
    </div>
    <div class="alignTop form-group row">
      <label for="Client Sectret" class="col-sm-4 col-form-label">
        <div>
          Client Secret
          <span class="text-danger">*</span>
        </div>
        <div class="help-tip">
          <div class="tooltip">
            <p>Please Enter the Client Secret </p>
          </div>
        </div>
      </label>
      <div class="col-sm-8">
        <input type="password" class="form-control {{(fieldValidation.clientSecret) ? 'is-invalid' : ''}}"
          placeholder="Enter Client Secret" [(ngModel)]="currentJoSetting.clientSecret"
          (input)="onValidateFields('clientSecret')" (focusout)="onValidateFields('clientSecret')">
        <div class="form-control-edit-secret" *ngIf="isEditMode && !currentJoSetting.clientSecret">For security reason
          please re-enter Client Secret.</div>
      </div>
    </div>
    <div class="form-group row">
      <label for="Owner Email" class="col-sm-4 col-form-label">
        <div>
          Owner Email
          <span class="text-danger">*</span>
        </div>
        <div class="help-tip">
          <div class="tooltip">
            <p>Please Enter the Owner Email </p>
          </div>
        </div>
      </label>
      <div class="col-sm-8">
        <input type="text" class="form-control {{(fieldValidation.ownerEmail) ? 'is-invalid' : ''}}"
          placeholder="Enter Owner Email" [(ngModel)]="currentJoSetting.ownerEmail"
          (input)="onValidateFields('ownerEmail')" (focusout)="onValidateFields('ownerEmail')">
      </div>
    </div>
    <div>
      <a class="selected-url"
        (click)="ndvs.onShowBrowser('https://www.netdocshare.com/docs/sp2013/generate-client-id-and-secret-id/#onedrive-clientid-secretid')">
        Click here
      </a>
      to download the User Guide for Client ID and Client Secret generation
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn im-primary-btn" (click)="onConnectTeams()" [disabled]="isSaveDisabled">
      Connect
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">
      Cancel
    </button>
  </div>
</ng-template>

<!-- UPLOAD BULK SETTINGS -->
<ng-template #modalUpload let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Setting Bulk Upload </h4>
  </div>
  <div class="modal-body">
    <div class="drop-area text-center" *ngIf="!isUploadProcess" (dragover)="onFileDragOver($event)"
      (drop)="onFileDropSuccess($event)">
      <div>
        <div class="text-center">Drag & Drop file here</div>
        <div class="text-center">or</div>
        <div class="text-center">
          <button type="button" class="browse-files text-center" (click)="onBrowseFile(elenentFileUpload)"
            placement="center">
            Browse Files
          </button>
        </div>

      </div>
      <input #elenentFileUpload id="elenentFileUpload" (change)="onFileChange($event)" type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden>
    </div>
    <div *ngIf="(selectedFile.name!=undefined)" class="alert alert-info px-3 py-1 my-2" role="alert">
      {{selectedFile.name}}
    </div>
    <div *ngIf="(fileError!='')" class="alert alert-danger px-3 py-1 my-2" role="alert">
      {{fileError}}
    </div>
    <div class="my-2">
      <table *ngIf="(excelData.length>0)" class="table table-bordered bg-white">
        <thead class="thead-light">
          <tr>
            <th class="col-3">Row</th>
            <th class="col-3">Data</th>
            <th class="col-3">Reason</th>
            <th class="col-3">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let excelRow of excelData">
            <td>{{excelRow.row}}</td>
            <td>
              {{(excelRow.isDataInvalied) ? 'Invalid' : 'Valid' }}
            </td>
            <td>{{excelRow.reason}}</td>
            <td class="{{statusColor[excelRow.dataProcessStatus.split(' ')[0]]}}">{{excelRow.dataProcessStatus}}</td>
            <!-- green color:text-success, red color:text-danger, orange color:text-amber -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">
      Cancel
    </button>
    <button type="button" class="btn im-primary-btn {{(isUploadProcess) ? 'show-loader':''}}"
      (click)="onValidateSettings()" [hidden]="(selectedFile.name==undefined && !isUploadProcess)"
      [disabled]="(isUploadProcess)">
      Upload
      <img *ngIf="(isUploadProcess)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
    </button>
  </div>
</ng-template>