<div class="sync-popup custom-scrollbar">
  <div class="sync-popup-container sync-popup-sm">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="channelCreateLoader||isNDLoading">
      <div class="loader-container loader-container-absolute">
        <div class="loader"></div>
      </div>
    </div>
    <div class="sync-popup-header" style="z-index: 110;position: relative;">
      {{global.isChannelEdit||requestFromBulkEdit?"Edit Channel":"Add New Channels"}}
      <button class="im-btn-link" [disabled]="channelCreateLoader||isNDLoading" (click)="onCloseModal()">
        <img src="./../../../assets/images/icons/modal-close.svg">
      </button>
    </div>
    <div class="sync-popup-content" [formGroup]="formChannel">
      <div class="loader-holder">
        <!-- <div
          *ngIf="channelCreateLoader||isNDLoading"
          class="loader-container loader-container-absolute">
          <div class="loader"></div>
        </div> -->
        <div class="form-group form-row">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Channel Name *</label>
          </div>
          <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="channelName" #inputChannelName
              [attr.disabled]="(channelCreateLoader||isNDLoading)?true:null">
          </div>
        </div>
        <div class="form-group form-row">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Channel Description</label>
          </div>
          <div class="col-sm-8">
            <textarea class="form-control-custom" formControlName="channelDescription"
              [attr.disabled]="(channelCreateLoader||isNDLoading)?true:null"></textarea>
          </div>
        </div>

        <div class="form-group form-row" *ngIf="!global.isChannelEdit">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Privacy</label>
          </div>
          <div class="col-sm-8">
            <div>
              <select [attr.disabled]="(channelCreateLoader||isNDLoading)?true:null" class="form-control" id="privacy"
                formControlName="optionPrivacy">
                <option value="standard">
                  Standard
                  <!-- - Only team owners can add members -->
                </option>
                <option value="private">
                  Private
                  <!-- - Anyone in your organization can join -->
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          *ngIf="formChannel.value.optionPrivacy=='private'||global.selectedChannel.membershipType?.toLowerCase()=='private'||global.selectedChannel.optionPrivacy?.toLowerCase()=='private'">
          <app-members
            *ngIf="formChannel.value.optionPrivacy=='private'||(global.isChannelEdit&&global.selectedChannel.membershipType?.toLowerCase()=='private' ||global.isChannelEdit&&global.selectedChannel.optionPrivacy?.toLowerCase()=='private' )"
            [teamId]="global.selectedGroup.Id" (channelMembers)="onChangeChannelMembers($event)"
            [isChannelEdit]="global.isChannelEdit" [channelId]="global.selectedChannel.Id"></app-members>
        </div>
        <!-- Sync UI START From Template -->
        <div *ngIf="!requestFromBulkEdit">
          <div *ngIf="checkSync(global.selectedGroup) &&!global.isChannelEdit&& global.haveSyncAddOn">
            <div class="form-group row mr-0 ml-0 mb-4">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

                <label class="form-label" for="elementSyncType">
                  Sync Location
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncLocation}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8" *ngIf="!global.maxSyncJobCreated">
                <input type="checkbox" id="syncCheck" style="display:none" [checked]="syncCheck"
                  title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':''}}"
                  [attr.disabled]="(channelCreateLoader||isNDLoading||global.maxSyncJobCreated)?true:null"
                  (change)="onChangeSyncCheck($event)">
                <label for="syncCheck" class="check-toggle ml-0 mt-0">
                  <span></span>
                </label>

              </div>

              <div class="col-sm-8" *ngIf="global.maxSyncJobCreated">
                Maximum Sync Job Created
              </div>
            </div>

            <div class="form-group row mr-0 ml-0 mb-4" *ngIf="syncCheck">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                <label class="form-label" for="elementSyncType">
                  Sync Type
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncType}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <select class="form-control" id="elementSyncType" [(ngModel)]="syncType"
                  (ngModelChange)="onChangeSyncType($event)"
                  [attr.disabled]="(channelCreateLoader||isNDLoading)?true:null" formControlName="optionSync">
                  <option value="{{''}}" disabled>
                    Select Type
                  </option>
                  <option *ngFor="let sType of syncTypes" value="{{sType.value}}">
                    {{sType.text}}
                  </option>
                </select>
              </div>
            </div>
            <div *ngIf="!requestFromBulkEdit">
              <div class="col-md-12 mb-3 pl-0 pl-lg-2 pr-0" *ngIf="syncCheck && syncType!=''">
                <div class="nds-outer-box h-100 mh-200 right-content">
                  <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
                    <div class="pg-heading">Select NetDocuments Location</div>
                    <button type="button" placement="left"
                      class="btn btn-sm btn-blue h-100 {{( isNDLoading)? 'show-loader' :''}}"
                      [disabled]="((!isNDLoading && ndvs.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading) || channelCreateLoader)"
                      (click)="onRefreshNetDocumentContants()">
                      <span *ngIf="(!isNDLoading)" class="material-icons-sharp md-refresh"></span>
                      <img *ngIf="(isNDLoading)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                    </button>
                  </div>
                  <div class="col-md-12 px-0">
                    <div #ndFormNav>
                      <div class="form-group row mb-2 align-items-center">
                        <div class="col-md-12 col-xl-4">
                          <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label col-label-info">Cabinet</label>
                            <div class="help-tip">
                              <div class="tooltip">
                                <p>{{toolTip.ndCabinet}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-xl-8">
                          <div class="input-group">
                            <div class="input-group-append cus-drop w-100 {{(isCabinetLoader) ? 'show-loader' : ''}}"
                              ngbDropdown #cabinetDropdown="ngbDropdown">
                              <input formControlName="selectedCabinetName" type="text" class="form-control"
                                placeholder="Select Cabinet" readonly [(ngModel)]="selectedCabinet.name"
                                ngbDropdownToggle
                                [disabled]="(selectedCabinet.name!='' && isNDLoading)|| channelCreateLoader">
                              <button class="dropdown-toggle btn btn-blue" ngbDropdownToggle
                                [disabled]="(selectedCabinet.name!='' && isNDLoading)|| channelCreateLoader">
                                <img src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                              </button>
                              <div ngbDropdownMenu aria-labelledby="sourceDropdown2"
                                class="form-inline mb-2 dropdownBody {{(cabinets.length==0) ? 'd-none' : ''}}">
                                <div *ngFor="let cabinet of cabinets"
                                  class="form-check context-dropdown-item source-dropdown-item"
                                  (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)"
                                  ngbDropdownItem>
                                  <img src="./assets/images/cabinet.svg" class="thumbnail mr-2">
                                  <label class="form-check-label context-dropdown-text">{{cabinet.name}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <nav aria-label="breadcrumb"
                        class="mb-2 border-bottom {{(isNDLoading)|| channelCreateLoader ? 'breadcrumb-disabled' : ''}}"
                        *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
                        <ol class="breadcrumb p-0 border bg-light mb-2">
                          <li class="breadcrumb-item crumb py-2" (click)="!channelCreateLoader&&onResetRoot()">
                            <img src="./assets/images/cabinet.svg" class="thumbnail mx-2">
                            <a title="Confidential">{{selectedCabinet.name}}</a>
                          </li>
                          <li *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )"
                            class="breadcrumb-item crumb py-2" (click)="!channelCreateLoader&&onResetWorkspace()">
                            <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mx-2">
                            <a title="Confidential">{{selectedWorkSpace.name}}</a>
                          </li>
                          <li class="breadcrumb-item crumb py-2"
                            *ngFor="let folder of ndvs.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index"
                            (click)="!channelCreateLoader&&onLoadBreadCurmp(folder,breadcurmbIndex)">
                            <img
                              src="./assets/images/netdocumentsIcons/{{(fileIcons[folder.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[folder.Attributes.Ext.toLowerCase()]}}"
                              class="thumbnail mx-2">
                            <a title="Confidential">{{folder.Attributes.Name}}</a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div class="file-grid search-results">
                      <div class="d-flex justify-content-between">
                        <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0)"
                          class="pg-heading fs-16 link {{(isNDLoading)|| channelCreateLoader ? 'section-disabled' : ''}}"
                          (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')"
                          title="{{selectedWorkSpace.name}}">
                          {{selectedWorkSpace.name}}
                        </div>
                        <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0)"
                          class="pg-heading fs-16 link {{(isNDLoading)|| channelCreateLoader ? 'section-disabled' : ''}}"
                          (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)"
                          title="{{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}">
                          {{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}
                        </div>

                        <div>
                          <div class="form-check float-left"
                            *ngIf="((syncType=='bidirectionalTeam' || syncType=='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.workspaceId!='' || ndvs.syncJobSetting.netDocuments.folderId!='')&& !isNDSSearchResults && !isNDLoading)">
                            <input class="form-check-input" type="checkbox" value="" id="allCheck"
                              [checked]="ndvs.syncJobSetting.netDocuments.selectAll" (change)="onNDSSelectAll()">
                            <label class="form-check-label" for="allCheck">Select all</label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mb-2 mt-2">
                        <div
                          *ngIf="(ndvs.syncJobSetting.netDocuments.cabinetId!='' &&ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null)"
                          class="pg-heading input-group fs-16">
                          <input formControlName="cabinetSearch" class="form-control" type="search" placeholder="Search"
                            [(ngModel)]="cabinetSearch" (input)="onChangeCabinetSearch()"
                            (keypress)="onSearchKeyPress($event)" [attr-disabled]="channelCreateLoader?true:null">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-blue" (click)="onSearchNDSContents()"
                              [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading|| channelCreateLoader)">
                              <i class="material-icons-sharp md-search"></i>
                            </button>
                          </div>

                          <img
                            *ngIf="((isNDSUserAccess && ndvs.syncJobSetting.netDocuments.breadCurmb!=undefined && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0) || (isNDSUserAccess && (ndvs.syncJobSetting.netDocuments.breadCurmb!=undefined && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0 && (ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ndflt' && ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ndsq' && ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ss'))))"
                            class="nd-create-folder {{(isNDLoading)? 'create-folder-disable':''}}"
                            src="./assets/images/netdocumentsIcons/Plusfolder_nd.svg"
                            (click)="onNDSCreateFolder(modalNDSCreateFolder)" title="Create Folder" />
                        </div>
                      </div>



                      <!-- Selected Folders and Files status  -->
                      <div #NDSSectionSelectedFiles class="d-flex justify-content-between mb-2 mt-2"
                        *ngIf="((syncType=='bidirectionalTeam' || syncType=='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null && ndvs.syncJobSetting.netDocuments.cabinetId!='') && ((ndvs.syncJobSetting.netDocuments.workspaceId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId!=null && ndvs.syncJobSetting.netDocuments.workspaceId!='') || (ndvs.syncJobSetting.netDocuments.folderId!=undefined && ndvs.syncJobSetting.netDocuments.folderId!=null && ndvs.syncJobSetting.netDocuments.folderId!='')) && !ndvs.syncJobSetting.netDocuments.selectAll)">
                        <div class="pg-heading justify-content-between input-group fs-16">
                          <span>Selected Folders ({{(ndvs.syncJobSetting.netDocuments.sources.folders==undefined) ? '0'
                            :
                            ndvs.syncJobSetting.netDocuments.sources.folders.length}}),
                            Files({{(ndvs.syncJobSetting.netDocuments.sources.files==undefined) ? '0' :
                            ndvs.syncJobSetting.netDocuments.sources.files.length}})</span>
                          <div
                            *ngIf="((ndvs.syncJobSetting.netDocuments.sources.workspaces!=undefined && ndvs.syncJobSetting.netDocuments.sources.workspaces.length>0) || (ndvs.syncJobSetting.netDocuments.sources.folders!=undefined && ndvs.syncJobSetting.netDocuments.sources.folders.length>0) || (ndvs.syncJobSetting.netDocuments.sources.files!=undefined && ndvs.syncJobSetting.netDocuments.sources.files.length>0))"
                            class="action-button al text-right">
                            <button type="button" class="btn btn-link btn-sm " (click)="onClearNDSSelectedItems()"
                              title="Clear">Clear</button>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="showFolderInput" class="d-flex justify-content-between mb-2 mt-2 folder-holder">
                        <label class="col-form-label">Folder Name</label>
                        <div>
                          <input class="form-control" formControlName="NDSFolderName" id="folderName"
                            [(ngModel)]="NDSFolderName" type="text" (keypress)="onNDSFolderNameKeyPress($event)"
                            [disabled]="isNDSFolderCreating" (input)="NDSValidateFolder()">
                          <div *ngIf="isNDSDuplicateFolder" class="alert alert-danger px-3 py-1" role="alert">
                            {{error_message}}
                          </div>
                        </div>
                        <div>
                          <button type="button" class="btn btn-secondary" (click)="onNDSCreateFolderHide()"
                            [disabled]="isNDSFolderCreating">Cancel</button>
                          <button type="button" class="btn btn-blue" (click)="NDSCreateNewFolder()"
                            [disabled]="(NDSFolderName.trim()=='' || isNDSFolderCreating || isNDSDuplicateFolder)">
                            <span>Create </span>
                          </button>
                        </div>
                      </div>

                      <!-- MESSAGE FOR SP-ND Bidirectional, SP-ND, OD-ND Bidirectional, OD-ND, TM-ND Bidirectional, TM-ND  -->
                      <div #NDSSectionInformation
                        *ngIf="((syncType!='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null && ndvs.syncJobSetting.netDocuments.cabinetId!='') && ((ndvs.syncJobSetting.netDocuments.workspaceId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId!=null && ndvs.syncJobSetting.netDocuments.workspaceId!='') || (ndvs.syncJobSetting.netDocuments.folderId!=undefined && ndvs.syncJobSetting.netDocuments.folderId!=null && ndvs.syncJobSetting.netDocuments.folderId!='')))"
                        class="text-nd-info text-danger">* Folder container only supported for this sync.</div>

                      <div class="d-flex flex-column nd-file-manger">
                        <div class="row"
                          *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
                          <div class="col-md-12 text-center my-3">Cabinet or folders not found</div>
                        </div>
                        <div class="{{'border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover'}}"
                          *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))"
                          (scroll)="onNDScroll($event)">

                          <div #ndBlocker *ngIf="(isNDLoading)" class="container-blocker">
                            <div class="text-center w-100">
                              <span>
                                Loading...
                                <img src="./assets/images/Spinner.gif" class="loadingIcon">
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3" *ngFor="let wSpace of workSpaces">
                            <!-- |filterWorkspacename:cabinetSearch -->
                            <div class="d-flex">
                              <div class="d-flex align-items-start"
                                (click)="!channelCreateLoader&&onSelectWorkSpace(wSpace)">
                                <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mr-2">
                                <a title="{{wSpace.name}}" class="fs-14 text-dark text-decoration-none">
                                  {{wSpace.name}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3"
                            *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:NDSSearchString">
                            <!--  -->
                            <div class="d-flex">
                              <div class="d-flex align-items-start"
                                (click)="!channelCreateLoader&&onSelectCabinetFolder(cabinetFolder)">
                                <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mr-2">
                                <a title="{{cabinetFolder.Attributes.Name}}"
                                  class="fs-14 text-dark text-decoration-none">
                                  {{cabinetFolder.Attributes.Name}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3" *ngFor="let content of workSpaceContents">
                            <div class="d-flex">
                              <input *ngIf="(ndvs.syncJobSetting.syncType!='teamUni' )" type="checkbox"
                                class="mt-1 mr-2" [checked]="content.isChecked"
                                (change)="onChooseNDContent($event,content)"
                                [disabled]="content.isRestricted|| channelCreateLoader">
                              <div class="d-flex align-items-start {{(content.isRestricted) ? 'content-restricted':''}}"
                                (click)="!channelCreateLoader&&onSelectContent(content)">
                                <img
                                  src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}"
                                  class="thumbnail mr-2">
                                <a title="{{content.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                                  {{content.Attributes.Name}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="selectedCabinet.name!=''">
                          <div class="row"
                            *ngIf="((!isNDLoading && workSpaces.length==0 && cabinetFolders.length==0) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)))">
                            <div class="col-md-12 text-center my-3">Workspace or folders not found</div>
                          </div>
                          <div class="row"
                            *ngIf="(!isNDLoading && workSpaceContents.length==0) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined))">
                            <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                          </div>
                          <div class="row"
                            *ngIf="(!isNDLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined && (selectCabinetFolder.Attributes==undefined))">
                            <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                          </div>
                          <div *ngIf="folderCreationError!=''" class="mt-4">
                            <div *ngIf="folderCreationErrorMessage.status==409">
                              <div class="red">
                                The folder already contains subfolder '{{this.folderCreationError}}'. Create a new
                                folder
                                or
                                choose another location.
                              </div>
                              <div class="form-group row mr-0 ml-0 mt-3">
                                <div
                                  class="col-sm-4 position-relative d-flex justify-content-between align-items-center pl-0">
                                  <label class="form-label">New Folder Name</label>
                                </div>
                                <div class="col-sm-8 pr-0">
                                  <input type="text" class="form-control" formControlName="newFolderName"
                                    placeholder="Give a new folder name"
                                    [attr.disabled]="channelCreateLoader?true:null">
                                </div>
                              </div>
                            </div>
                            <div *ngIf="folderCreationErrorMessage.status==403">
                              <div class="red">
                                You don't have rights to create folder in the selected location.
                              </div>
                            </div>
                            <div *ngIf="folderCreationErrorMessage.status!=403&&folderCreationErrorMessage.status!=409">
                              <div class="red">Error in creating folder.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Sync UI END -->

            <!-- TAB Creation start -->
            <div *ngIf="!requestFromBulkEdit">
              <div class="form-group row mr-0 ml-0 mb-4" *ngIf="global.haveTeamsAppAddOn && !global.isChannelEdit">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

                  <label class="form-label" for="allowTabCreation">
                    Create Tab
                  </label>
                  <div class="help-tip mt-0">
                    <div class="tooltip">
                      <p>{{toolTip.tabCreation}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <input type="checkbox" id="allowTabCreation" style="display:none" [(ngModel)]="tabCreationCheck"
                    [ngModelOptions]="{standalone: true}" [attr.disabled]="(createLoader||isNDLoading)?true:null"
                    (change)="onAllowTabCreation($event)">
                  <label for="allowTabCreation" class="check-toggle ml-0 mt-0">
                    <span></span>
                  </label>

                </div>
              </div>
            </div>
            <create-tab *ngIf="tabCreationCheck" [tabsList]="tabs" [tabDetail]="selectedTab"
              (onConfigChange)="setConfigChange($event)" [creationFromTeam]="true"></create-tab>
            <!-- TAB Creation End -->

            <div *ngIf="(isRequestError)">
              Network or Server issue, try again later.
            </div>
            <div *ngIf="(isChannelExist)">{{message}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sync-popup-footer" style="position: relative;
    z-index: 111;">
      <button class="btn im-primary-outline-btn" [disabled]="channelCreateLoader||isNDLoading" (click)="onCloseModal()">
        Cancel
      </button>
      <button class="btn im-primary-btn" *ngIf="requestFromBulkEdit" (click)="onChannelSave()">
        Save
      </button>
      <button *ngIf="!requestFromBulkEdit" class="btn im-primary-btn"
        [disabled]="(checkDisable()|| channelCreateLoader||isNDLoading)" (click)="onCreateChannel()">
        {{(global.isChannelEdit) ? 'Save' : 'Create'}}
        <img *ngIf="channelCreateLoader||isNDLoading" src="assets/images/Spinner.gif" class="search-button-img">
      </button>
    </div>
  </div>