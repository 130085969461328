import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor() { }

    public static IS_ADMIN() {
        let profile = environment.APP_PROFILE;
        try {
            return (profile.indexOf("admin:") == 0)
        } catch (e) {
            let logService: LogService
            logService.updateLogMessage("APP_PROFILE is missining in ENVIRONMENT VARIABLES.");
            return false;
        }
    }

    public static STORED_KEY() {
        let profile = environment.APP_PROFILE;
        try {
            let key = (profile.indexOf("client:") >= 0 || profile.indexOf("admin:") >= 0) ? profile.split(":")[1] : "no-key-found";
            return key;
        } catch (e) {
            let logService: LogService
            logService.updateLogMessage("APP_PROFILE is missining in ENVIRONMENT VARIABLES or KEY Not found");
            return "no-key-found";
        }
    }

    public static ND_SITE_URL() {
        let siteUrl = environment.ndSiteURLUS;
        let netDocsBaseUrl = localStorage.getItem("netDocsBaseUrl");
        switch (netDocsBaseUrl) {
            case "https://api.de.netdocuments.com/v2": {
                siteUrl = environment.ndSiteURLDE;
                break;
            }
            case "https://api.eu.netdocuments.com/v2": {
                siteUrl = environment.ndSiteURLEU;
                break;
            }
            case "https://api.au.netdocuments.com/v2": {
                siteUrl = environment.ndSiteURLAU;
                break;
            }
            default: {
                siteUrl = environment.ndSiteURLUS;
                break;
            }
        }

        return siteUrl;
    }

    public static SET_SELECTED_TAB() {
        let selTab = localStorage.getItem('createdJob');
        localStorage.setItem('createdJob', '');
        return (selTab == undefined || selTab == null || selTab == '') ? "sharepoint" : selTab;
    }

    public static getISOTimeNow() {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
        return localISOTime;
    }

}
