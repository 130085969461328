<app-container-top></app-container-top>
<div class="col-12 my-3 inner-container-rounded">
    <section class="col page-title">
        <h2 class="title">Choose Teams from existing teams</h2>
    </section>
    <div class="col-12 tabs-conatiner">
        <div class="col-12 p-0">
            <div class="col-md-6 py-2 ml-auto d-flex">
                <div class="search-box ml-auto">
                    <img class="search-icon" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
                    <input placeholder="Search Teams"
                           type="text"
                           class="table-search-input form-control">
                    <img class="clear-icon" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxLjk3NiA1MS45NzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxLjk3NiA1MS45NzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8cGF0aCBkPSJNNDQuMzczLDcuNjAzYy0xMC4xMzctMTAuMTM3LTI2LjYzMi0xMC4xMzgtMzYuNzcsMGMtMTAuMTM4LDEwLjEzOC0xMC4xMzcsMjYuNjMyLDAsMzYuNzdzMjYuNjMyLDEwLjEzOCwzNi43NywwICAgQzU0LjUxLDM0LjIzNSw1NC41MSwxNy43NCw0NC4zNzMsNy42MDN6IE0zNi4yNDEsMzYuMjQxYy0wLjc4MSwwLjc4MS0yLjA0NywwLjc4MS0yLjgyOCwwbC03LjQyNS03LjQyNWwtNy43NzgsNy43NzggICBjLTAuNzgxLDAuNzgxLTIuMDQ3LDAuNzgxLTIuODI4LDBjLTAuNzgxLTAuNzgxLTAuNzgxLTIuMDQ3LDAtMi44MjhsNy43NzgtNy43NzhsLTcuNDI1LTcuNDI1Yy0wLjc4MS0wLjc4MS0wLjc4MS0yLjA0OCwwLTIuODI4ICAgYzAuNzgxLTAuNzgxLDIuMDQ3LTAuNzgxLDIuODI4LDBsNy40MjUsNy40MjVsNy4wNzEtNy4wNzFjMC43ODEtMC43ODEsMi4wNDctMC43ODEsMi44MjgsMGMwLjc4MSwwLjc4MSwwLjc4MSwyLjA0NywwLDIuODI4ICAgbC03LjA3MSw3LjA3MWw3LjQyNSw3LjQyNUMzNy4wMjIsMzQuMTk0LDM3LjAyMiwzNS40NiwzNi4yNDEsMzYuMjQxeiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=" />
                </div>
               
                <label class="dropdown-icon">
                    <div class="dropdown-icon-button">
                        <img src="../../assets/images/icons/filter-icon.svg" />
                    </div>
                    <input type="checkbox" class="dropdown-icon-input" id="test">
                  
                    <ul class="dropdown-icon-menu">
                      <li>A-Z</li>
                      <li>Z-A</li>
                      <li>Date Added</li>
                    </ul>
                </label>

                <button class="im-btn-link"><img src="../../assets/images/icons/setting-icon.svg" /></button>
            </div>
        </div>
        <table class="table team-setting-table">
            <thead>
                <th>Name <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
                <th>Channels <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
                <th>Private Channels <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
                <th>Members <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
                <th>Owners <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
                <th>Guests <button class="im-btn-link ml-2"><img src="../../assets/images/icons/sort-icon.svg"/></button></th>
            </thead>
            <tbody>
                <tr>
                    <td class="create-team-channel-list">
                        <div class="col p-0 d-flex align-items-center">
                            <div class="teams-name-highlight mr-2">KL</div> imDocShare Provisioning
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm im-primary-btn sync-btn waves-effect ml-2 me-2" type="button">
                                <img src="assets/images/icons/sync-white-icon.svg" />
                            </button>
                            <button class="btn btn-sm im-white-outline-btn imdoc-btn waves-effect" type="button">
                                <img src="assets/images/icons/imdocshare-logo.svg" />
                            </button>
                        </div>
                    </td>
                    <td>2</td>
                    <td>1</td>
                    <td>2</td>
                    <td>1</td>
                    <td>3</td>
                </tr>

                <tr>
                    <td class="create-team-channel-list">
                        <div class="col p-0 d-flex align-items-center">
                            <div class="teams-name-highlight mr-2">KL</div> imDocShare Provisioning
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm im-primary-btn sync-btn waves-effect ml-2 me-2" type="button">
                                <img src="assets/images/icons/sync-white-icon.svg" />
                            </button>
                            <button class="btn btn-sm im-white-outline-btn imdoc-btn waves-effect" type="button">
                                <img src="assets/images/icons/imdocshare-logo.svg" />
                            </button>
                        </div>
                    </td>
                    <td>2</td>
                    <td>1</td>
                    <td>2</td>
                    <td>1</td>
                    <td>3</td>
                </tr>

                <tr>
                    <td class="create-team-channel-list">
                        <div class="col p-0 d-flex align-items-center">
                            <div class="teams-name-highlight mr-2">KL</div> imDocShare Provisioning
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm im-primary-btn sync-btn waves-effect ml-2 me-2" type="button">
                                <img src="assets/images/icons/sync-white-icon.svg" />
                            </button>
                            <button class="btn btn-sm im-white-outline-btn imdoc-btn waves-effect" type="button">
                                <img src="assets/images/icons/imdocshare-logo.svg" />
                            </button>
                        </div>
                    </td>
                    <td>2</td>
                    <td>1</td>
                    <td>2</td>
                    <td>1</td>
                    <td>3</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="create-teams-footer d-flex">
        <button class="btn im-primary-outline-btn" (click)="onCancel()">Cancel</button>
        <button class="btn im-primary-btn">Next</button>
    </div>
</div>
<app-footer></app-footer>