import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgbPanelChangeEvent, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-channels-config',

  templateUrl: './channels-config.component.html',
  styleUrls: ['./channels-config.component.scss']
})
export class ChannelsConfigComponent implements OnInit {
  /* THIS COMPONENT IS NOT IN USE */
  @ViewChild('acc') accordionComponent: NgbAccordion;

  @Output() syncCheck: EventEmitter<any> = new EventEmitter<any>();

  @Input() public channel: string = "";


  @Input("syncData") set onSetParentId(syncData) {
    //console.log(syncData)
    this.channelPid = syncData.pid;//teams.channelName;
    this.channelData = { id: this.channelPid, name: syncData.teams.channelName, syncCheckToggle: false, locationChoosed: syncData.pid.split("_")[1] % 2 ? true : false, syncJobSetting: this.syncJobSetting }
    this.syncJobSetting = { ...this.syncJobSetting, syncType: this.channelData.id == "cc1" ? "teamUni" : this.channelData.id == "cc2" ? "bidirectionalTeam" : "netDocumentsUniTeam" }
    this.channelData = { ...this.channelData, syncJobSetting: this.syncJobSetting }
  }

  public channelPid: string = "";
  public accIsOpen: boolean = false;
  public channelData: any = {};

  public syncJobSetting: any = {
    key: "",
    syncFrequency: "00:00:30",
    syncFrequencyInSecond: 30,
    syncType: this.channelData.id == "cc1" ? "teamUni" : this.channelData.id == "cc2" ? "bidirectionalTeam" : "netDocumentsUniTeam",
    syncMetaData: false,
    applyPermissions: false,
    syncSource: "ProvisioningTool",
    fileMove: false,
    fileMoveOn: "immediate",
    syncAllSPList: false,
    columnOptions: [],
    netDocuments: {
      userName: "",
      siteUrl: "",
      cabinetId: "",
      cabinetName: "",
      repositoryId: "",
      workspaceId: "",
      workspaceName: "",
      workspaceEnvId: "",
      folderId: "",
      folderName: "",
      sources: {
        folders: [],
        files: [],
      },
      breadCurmb: [],
      selectAll: false,
    },
    sharePoint: {
      connectionType: null,
      siteAddress: "",
      connectionId: "",
      clientId: "",
      clientSecret: "",
      username: "",
      password: "",
      libraryType: "document",
      transferType: "flatten",
      tenantUrl: "",
      breadCurmb: [],
      multiFactorAuthu: false,
      appPassword: "",
      listUrl: "",
      listName: "",
      listId: "",
      sources: {
        folders: [],
        files: [],
      },
      selectAll: false
    },
    oneDrive: {
      connectionId: "",
      tenantName: "",
      tenantId: "",
      clientId: "",
      clientSecret: "",
      userEmail: "",
      userName: "",
      folderId: "",
      folderName: "",
      breadCurmb: [],
      sources: {
        folders: [],
        files: []
      },
      selectAll: false
    },
    selectAll: false,
  }


  public syncCheckToggle: boolean = false;
  public selectedChannel: string = "";



  public toolTip: any = {
    syncLocation: "Select Sync Location",
  }

  public activeIds = "";

  constructor() { this.channelData = { id: this.channelPid, name: "Channel", syncCheckToggle: false, locationChoosed: true } }

  ngOnInit(): void {

  }

  public toggleAccordian(props: NgbPanelChangeEvent) {

    //console.log(props.panelId)
  }

  public onChangeSyncCheck($event, panel) {
    //console.log($event, panel)
    this.accIsOpen = !this.accIsOpen;
    if (this.accIsOpen)
      this.accordionComponent.expandAll();//toggle(panel.id);
    else {
      //console.log("this.syncJobSetting===", this.syncJobSetting)
      this.accordionComponent.collapseAll();
    }
  }


}
