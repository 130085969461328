import { Component, Input, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { LogService } from 'src/app/services/log.service';
@Component({
  selector: 'app-net-doc-auth',
  templateUrl: './net-doc-auth.component.html',
  styleUrls: ['./net-doc-auth.component.scss']
})
export class NetDocAuthComponent implements OnInit {

  @ViewChild("authFrame") authFrame: ElementRef;
  public _authUrl: SafeUrl;
  public _authMsg: string;
  public authCheck: boolean = false;
  public ndAuthWin = null;
  private intervalHandler;

  @Input() set authURL(url: string) {
    if (url != "" || url != null) {
      this._authUrl = this.ds.bypassSecurityTrustResourceUrl(url);
    }
  }

  @Input() set authMsg(msg: string) {
    if (msg !== "" || msg !== null || msg !== undefined) {
      this._authMsg = msg;
    }
  }
  constructor(public activeModal: NgbActiveModal, private ds: DomSanitizer, public ndvs:NetDocumentViewerService, private logService:LogService) { }

  ngOnInit() {
    if (localStorage.getItem("access_token") === null) {
      this.authCheck = true;
    }
    this.ndAuthWin = (e) => {
      this.logService.updateLogMessage("NetDocumentss message Event => "+JSON.stringify(e));
      if (e.origin == environment.serviceUrl ||
        e.origin === environment.serviceRootUrl ||
        e.origin === 'https://netdocshare.azurewebsites.net') {
        try {
          let data = JSON.parse(e.data);
          if (data.code) {
            this.activeModal.close(data.code);
            this.ndvs.theAuthModal.close();            
          }
          if (data.error) {
            this.activeModal.close('deny');
          }          
        } catch (ex) {
          this.logService.updateLogMessage("NetDocuments auth model error => "+JSON.stringify(ex));
        }
      }else{

      }
    }
    window.addEventListener('message', this.ndAuthWin);
  }
}
