import { Component, Input, OnInit, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { TeamsService } from 'src/app/services/teams.service';
import * as momenttz from "moment-timezone";
import * as moment from "moment";
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
//import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SyncNetDocLocationComponent } from '../sync-net-doc-location/sync-net-doc-location.component';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { HelperService } from 'src/app/services/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerRequestsService } from 'src/app/services/server-requests.service';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  @Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input("role") role: string = "";
  @ViewChild(SyncNetDocLocationComponent) syncLocation;
  @Input("activeTab") set onSetActiveTab(data) {
    this.activeTab = data;
    if (this.activeTab === "requests" || this.activeTab === "myRequests") this.getTeamSearchRequest(this.activeTab === 'requests' ? 'admin' : 'client');
  }
  public activeTab: string = "";
  public createdTeamId = "";
  public formReq: FormGroup;
  public teamRequestData: any = [];
  public roleData: string = '';
  public status: string = '';
  public createLoaderTeam: Boolean = false;
  public getLoaderTeam: Boolean = false;
  public teamStatus: any = [{ value: "Pending", text: "Pending" }, { value: "Approved", text: "Approved" }, { value: "Rejected", text: "Rejected" }];
  public filterEnabled: boolean = false;
  public isFilterSelect: boolean = false;
  public isFilterSearch: boolean = false;
  public channelCreateLoader: boolean = false;
  public maxDate = { day: moment().date(), month: moment().month() + 1, year: moment().year() }
  public currentFilterSetting: any = { CreatedStartDate: "", CreatedEndDate: "", ModifyStartDate: "", ModifyEndDate: "" };
  public selectedStatus: any = { status: "" };
  public requestId = '';
  public adminListData: any = [];

  constructor(public teamsService: TeamsService,
    private router: Router,
    public global: GlobalService,
    //public authService: AuthService,
    private toaster: ToastrService,
    public ndvs: NetDocumentViewerService,
    private ngZone: NgZone,
    public helper: HelperService,
    private modalService: NgbModal,
    public srs: ServerRequestsService,
    public netLocalStorage: NetdocshareLocalstorageService
  ) {
    this.formReq = new FormGroup({
      rejectComments: new FormControl('')
    })
  }

  ngOnInit(): void {
    this.getTeamSearchRequest(this.role);
  }

  public getTeamSearchRequest(role) {
    this.getLoaderTeam = true;
    let filter = "";
    if (this.role == null || this.role == "") this.role = role;
    const licenseKey = this.netLocalStorage.licenseInfo.clientId;
    let endPoint = 'api/teams/getTeamSearchRequest/' + this.role + '/' + licenseKey;
    if (this.isFilterSelect) {
      this.currentFilterSetting.CreatedStartDateStr ? filter = filter + `?createdStartDate=${this.currentFilterSetting.CreatedStartDateStr}` : ""
      this.currentFilterSetting.CreatedEndDateStr ? filter = filter + `&createdEndDate=${this.currentFilterSetting.CreatedEndDateStr}` : ""
      this.currentFilterSetting.ModifyStartDateStr ? filter = `${filter}${filter.indexOf("?") > -1 ? "&" : "?"}modifyStartDate=${this.currentFilterSetting.ModifyStartDateStr}` : ""
      this.currentFilterSetting.ModifyEndDateStr ? filter = `${filter}${filter.indexOf("?") > -1 ? "&" : "?"}modifyEndDate=${this.currentFilterSetting.ModifyEndDateStr}` : ""
      this.selectedStatus.status ? filter = `${filter}${filter.indexOf("?") > -1 ? "&" : "?"}status=${this.selectedStatus.status}` : ""
      this.filterEnabled = true;
    }
    endPoint += filter;
    this.teamRequestData = [];
    this.srs.getSearch(endPoint).subscribe((res) => {
      if (res.status == "success") {
        this.teamRequestData = res.data;
        this.getLoaderTeam = false;
      }
    })
  }
  openTeam(modal) {   
    this.modalService.open(modal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then((res) => { });

  }
  editRequest(ReqId) {
    this.router.navigate([`/editrequest/${ReqId}`]);
  }

  public createGroup(teamId) {
    this.change.emit(JSON.parse(JSON.stringify(teamId)));
    this.ndvs.getUserJobsCount();
  }
  public onChangeFilterSelect() {
    if (this.isFilterSelect) {
      this.isFilterSearch = (this.currentFilterSetting.CreatedStartDate && this.currentFilterSetting.CreatedEndDate) ? true : false;
      this.currentFilterSetting.CreatedStartDateStr = (this.currentFilterSetting.CreatedStartDate) ? this.global.dateObjectFromString(this.currentFilterSetting.CreatedStartDate) : "";
      this.currentFilterSetting.CreatedEndDateStr = (this.currentFilterSetting.CreatedEndDate) ? this.global.dateObjectFromString(this.currentFilterSetting.CreatedEndDate) : "";

    } else {
      this.currentFilterSetting.CreatedStartDate = "";
      this.currentFilterSetting.CreatedEndDate = "";
      this.isFilterSelect = false;
      this.isFilterSearch = false;
    }
  }



  public onChangeFilterStatus() {
    if (this.isFilterSelect) {
      this.isFilterSearch = this.selectedStatus.status ? true : false;
      this.selectedStatus.statusSelect = this.selectedStatus.status ? this.selectedStatus.status : "";

    } else {
      this.selectedStatus.status = "";
      this.isFilterSelect = false;
      this.isFilterSearch = false;
      this.filterEnabled = false;
    }
  }


  public onChangeFilterModifySelect() {
    if (this.isFilterSelect) {
      this.isFilterSearch = (this.currentFilterSetting.ModifyStartDate && this.currentFilterSetting.ModifyEndDate) ? true : false;
      this.currentFilterSetting.ModifyStartDateStr = (this.currentFilterSetting.ModifyStartDate) ? this.global.dateObjectFromString(this.currentFilterSetting.ModifyStartDate) : "";
      this.currentFilterSetting.ModifyEndDateStr = (this.currentFilterSetting.ModifyEndDate) ? this.global.dateObjectFromString(this.currentFilterSetting.ModifyEndDate) : "";
    } else {
      this.currentFilterSetting.ModifyStartDate = "";
      this.currentFilterSetting.ModifyEndDate = "";
      this.isFilterSelect = false;
      this.isFilterSearch = false;
    }
  }

  public onChooseFilterDate(model) {
    this.isFilterSelect = true;
    if (!this.filterEnabled) {
      this.currentFilterSetting = { CreatedStartDate: "", CreatedEndDate: "", ModifyStartDate: "", ModifyEndDate: "" };
    }
    this.modalService.open(model).result.then((res) => {
      if (res == "yes") {
      }
    }).catch((error) => {

    })
  }


  public resetFilter() {
    this.isFilterSelect = false;
    this.isFilterSearch = false;
    this.filterEnabled = false;
    this.currentFilterSetting.CreatedStartDate = "";
    this.currentFilterSetting.CreatedEndDate = "";
    this.currentFilterSetting.ModifyStartDate = "";
    this.currentFilterSetting.ModifyEndDate = "";
    this.selectedStatus.status = "";
    this.getTeamSearchRequest(this.role);
  }


  public rejectRequest(model, reqId) {
    this.requestId = reqId;
    this.modalService.open(model).result.then((res) => {
      if (res == "yes") {
        this.editRequestComments(this.requestId);
        this.modalService.dismissAll();
      }
    }).catch((error) => {

    })
  }

  public editRequestComments(requestId) {
    let sendData = this.teamRequestData;
    sendData.forEach(item => {
      if (item._id == requestId) {
        item.status = "Rejected";
        item.comments = this.formReq.get('rejectComments').value;
        this.teamsService.editRequestForm(requestId, item).subscribe((res) => {
          if (res.status == 'success') {
            this.createLoaderTeam = false;
            this.toaster.success("Requested Team was rejected", "Success", { extendedTimeOut: 10 });
            this.sendEmailRejectRequest(item);
            this.getTeamSearchRequest(this.role);
            this.formReq.get('rejectComments').setValue('');
            this.modalService.dismissAll();
          } else {
          }
        })
      }
    })
  }
  public approveRequest(req) {
    this.createLoaderTeam = true;
    let sendData = req;
    const reqId = req._id;
    sendData.teamName = req.teamName;
    sendData.teamDescription = req.teamDescription;
    sendData.connectionId = this.global.connectionData._id;
    sendData.visibility = req.teamType;
    sendData.adminEmail =  this.ndvs.currentUser.email
    sendData.sharepointSiteTemplate = req.sharepointSiteTemplate;
    if (req.syncEnabled && !this.global?.haveSyncAddOn) {
      console.log("globalSyncAdon1", this.global.haveSyncAddOn);
      this.createLoaderTeam = false;
      this.toaster.error("Sync Addon is not active. This team require Sync Addon.", "Failure", { timeOut: 5000 });
      return;
    }

    this.teamsService.createAdminTeam(sendData).subscribe((res) => {
      if (res.data == null) {
        this.toaster.error("Team not created. " + res.message, "Failure", { timeOut: 5000 });
        this.createLoaderTeam = false;
        return;
      }
      if (res.status == 'success') {
        this.createdTeamId = res.data;
        this.createLoaderTeam = false;
        this.toaster.success("Team created successfully! Channels, tabs, and the site collection are currently being created. You'll be notified once the process is complete.", "Success", { extendedTimeOut: 10 });
        this.sendEmailApproveRequest();
        this.getTeamSearchRequest(this.role);
      } else if (res.status == 'failed') {
        this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
        this.createLoaderTeam = false;
      } else {
        this.toaster.error("Team Not Created", "Failure", { extendedTimeOut: 10 });
        this.createLoaderTeam = false;
      }
    }, (error) => {
      this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
      this.createLoaderTeam = false;
    });
  }




  public sendEmailApproveRequest() {
    let subject = "Approved Team";
    let sendData = this.teamRequestData;
    sendData.optionSync = this.syncLocation?.syncType
    //sendData.status = "Approved";
    sendData.body = `<html>
    <head></head>
  <body>
      <div style='font-family:Arial;'>
          <p>Hi,</p>
          <br/>
          <p>Congrats, This was an approval mail for team creation ${sendData.teamName} was successfully done.
          </p>
          <br/>
          <p>Regards</p>
          <h3>${this.ndvs.currentUser.id}</h3>
      </div>
  </body>
    </html>`
   sendData.createdBy = this.ndvs.currentUser.email;
    this.teamsService.sendEmailAdminListTeamRequest(sendData).subscribe((res) => {
      if (res.status == "success") {
        res.data.forEach((item: any) => {
          this.adminListData.push(item.email);
        })
        //this.authService.getAdminListSendEmail(subject, this.adminListData, sendData);
      } else {

      }
    })
  }

  public sendEmailRejectRequest(rejectList) {
    let subject = "Rejected Team";
    let sendData = rejectList;
    sendData.optionSync = this.syncLocation?.syncType;
    sendData.body = `<html>
    <head></head>
  <body>
      <div style='font-family:Arial;'>
          <p>Hi,</p>
          <br/>
          <h2>OopS! Team ${sendData.teamName} was not approved by manager ${this.netLocalStorage?.currentUser?.id},
          and provided an reason as ${sendData.comments}.
          Further, Please check with your approval manager.
          </h2>
          <br/>
          <p>Regards</p>
          <h3>${this.netLocalStorage?.currentUser?.id}</h3>
      </div>
  </body>
    </html>`
    //sendData.createdBy = this.authService.currentUser.email;
    this.teamsService.sendEmailAdminListTeamRequest(sendData).subscribe((res) => {
      if (res.status == "success") {
        res.data.forEach((item: any) => {
          this.adminListData.push(item.email);
        })
      } 
    })
  }

}
