import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public syncCreated: EventEmitter<any> = new EventEmitter<any>();
  public channelCreationSync: EventEmitter<any> = new EventEmitter<any>();
  public heightTabConfig: EventEmitter<any> = new EventEmitter<any>();
  public closeAllPopup: EventEmitter<any> = new EventEmitter<any>();
  public isGlobalAdminChange: EventEmitter<any> = new EventEmitter<any>();

  public connectionData: any = {};
  public isNDSAppInstalled: boolean = false;
  public selectedGroup: any = {};
  public selectedChannel: any = {};
  public selectedTab: any = {};
  public isGlobalAdmin: boolean = false;
  public isChannelEdit: boolean = false;
  public isTabEdit: boolean = false;
  public tenantId: string = "";
  public loadingSyncIFrame: boolean = false;
  public syncFrom: string = "";
  public currentUser: any = { defaultTenant: {}, currentTenant: {} };
  public isIframe: boolean = window.self !== window.top;
  public haveSyncAddOn: boolean = false;
  public haveTeamsAppAddOn: boolean = false;
  public maxSyncJobCreated: boolean = false;
  public inElectronIframe: boolean = false;
  public currentClientInfo: any = {};
  public isDevMode: boolean = window.location.href.indexOf("#/dev") > -1 &&
  window.location.href.indexOf("localhost:") > -1;
  constructor() { }
  public isInElectronIframe(isElectronApp) {
    this.inElectronIframe = window.location.origin.indexOf("8295") > 0 ? true : false;
    return this.inElectronIframe ? false : isElectronApp;
  }

  public dateToString(dateObj) {

    if (typeof dateObj === "object")

      return `${dateObj.month}/${dateObj.day}/${dateObj.year}`

    return dateObj;

  }

  public dateFromString(dateStr) {
    if (typeof dateStr === "string") {

      dateStr = dateStr.replace(/\-/g, "/");

      const dateStrArr = dateStr.split("/");

      return { month: Number(dateStrArr[0]), day: Number(dateStrArr[1]), year: Number(dateStrArr[2]) };

    }
    return dateStr;
  }
  public dateObjectFromString(dateObj) {
    if (typeof dateObj === "object")
      return moment(`${dateObj.year}-${dateObj.month}-${dateObj.day}`).format("YYYY-MM-DD");
    return dateObj;
  }
  endWithSlash(url: string) {
    return url?.slice(-1) === "/" ? url : `${url}/`;
  }

  // public encryptLocData(stringData){
  //   if(stringData==undefined && stringData==null && stringData=="") return "";
  //   let inputEncrypted:string=btoa(stringData);
  //   let memData = inputEncrypted.slice(-2);
  //   let dataForProcess=inputEncrypted.substring(0, inputEncrypted.length - 2);
  //   dataForProcess=dataForProcess.split("").reverse().join("");
  //   let position=(dataForProcess.length<5) ? dataForProcess.length : 5;
  //   var dataOut = [dataForProcess.slice(0, position), this.getRandomString(), dataForProcess.slice(position)].join('') + memData;
  //   return dataOut;
  // }

  // public decryptLocData(stringData){
  //   if(stringData==undefined && stringData==null && stringData=="") return "";
  //   let memData = stringData.slice(-2);
  //   let dataForProcess=stringData.substring(0, stringData.length - 2);
  //   let actualStrLength=dataForProcess.length-8;
  //   let position=(actualStrLength<5) ? actualStrLength : 5;
  //   dataForProcess = dataForProcess.replace(dataForProcess.substring(position, (position+8)), "").split("").reverse().join("")+memData;
  //   let dataOut:string=atob(dataForProcess);
  //   return dataOut;
  // }

  // public encryptData(stringData){
  //   return stringData;
  //   // if(stringData==undefined && stringData==null && stringData=="") return "";
  //   // let inputEncrypted:string=btoa(stringData);
  //   // let memData = inputEncrypted.slice(-2);
  //   // let dataForProcess=inputEncrypted.substring(0, inputEncrypted.length - 2);
  //   // dataForProcess=dataForProcess.split("").reverse().join("");
  //   // let position=(dataForProcess.length<5) ? dataForProcess.length : 5;
  //   // var dataOut = [dataForProcess.slice(0, position), this.getRandomString(), dataForProcess.slice(position)].join('') + memData;
  //   // return dataOut;
  // }

  // public decryptData(stringData){
  //   return stringData;
  //   // if(stringData==undefined && stringData==null && stringData=="") return "";
  //   // let memData = stringData.slice(-2);
  //   // let dataForProcess=stringData.substring(0, stringData.length - 2);
  //   // let actualStrLength=dataForProcess.length-8;
  //   // let position=(actualStrLength<5) ? actualStrLength : 5;
  //   // dataForProcess = dataForProcess.replace(dataForProcess.substring(position, (position+8)), "").split("").reverse().join("")+memData;
  //   // let dataOut:string=atob(dataForProcess);
  //   // return dataOut;
  // }
  // private getRandomString(stringLength=8){
  //   let randomString="";
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const charactersLength = characters.length;
  //   let counter = 0;
  //   while (counter < stringLength) {
  //     randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }
  //   return randomString;
  // }    

}
