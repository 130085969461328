


<div class="netDocShare-dropdown input-group" #comboBoxDropdown="ngbDropdown" ngbDropdown placement="left bottom">
    <input type="text" class="netDocShare-input form-control combo-box-input" #comboBoxInput [placeholder]="placeholder"
        autocomplete="off" (input)="onSearch(comboBoxDropdown, comboBoxInput)"
        (click)="onClickInput(comboBoxDropdown, comboBoxInput)" [(ngModel)]="searchString">
    <span class="clear-lookup-value" *ngIf="searchString" (click)="onClear()">
        <icon [appConfig]="appConfig" [name]="'times'" [width]="15" [height]="15" title="img">
        </icon>
    </span>
    <div class="input-group-append" role="group">
        <button class="btn btn-blue !important netDocShare-btn-primary netDocShare-dropdown-toggle" ngbDropdownToggle 
            #dropdownBtn>
        </button>
    </div>
    <div ngbDropdownMenu class="netDocShare-dropdown-menu" (scroll)="onScroll($event)">
        <div>
            <button ngbDropdownItem class="netDocShare-dropdown-item" *ngFor="let data of lookupData"
                (click)="onSelect(data, comboBoxDropdown)">
                <icon  [appConfig]="appConfig" *ngIf="sourceType=='cabinet'" [name]="sourceType" [width]="15" [height]="15" title="{{data?.name || data?.key}}">
                </icon>
                {{data?.name ||data.key||data.Title}}
                {{data.description ? ' - ' + data.description : ''}}
            </button>
        </div>
        <span ngbDropdownItem class="netDocShare-dropdown-item text-center"
        *ngIf="(!lookupData || !lookupData?.data || lookupData?.data?.length===0) && !lookupData?.isFetching">       
            {{message}}
        </span>
        <span ngbDropdownItem class="netDocShare-dropdown-item text-center" *ngIf="isFetching">
            <icon [appConfig]="appConfig" [name]="'spinner'" [width]="40" [height]="40"> </icon>
        </span>
    </div>
</div>