<div *ngIf="!isAccessFromExternalApp && !isLoading">
  <app-container-top></app-container-top>
  <div class="request-page">
    <ul ngbNav #navData="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs netDocShare-nav-tabs"
      (navChange)="onTabChange($event)">
      <ng-container *ngFor="let tab of tabs">
        <li [ngbNavItem]="tab.value" class="netDocShare-nav-item" *ngIf="tab.isVisible">
          <a ngbNavLink class="netDocShare-nav-item-btn">
            {{tab.title}}
          </a>
          <ng-template ngbNavContent>
            <div class="netDocShare-tab-nav-content-body" id="{{tab.value}}Tab">
              <teams class="netDocShare-tab-nav-content-body" *ngIf="tab.value === 'teams'"></teams>
              <app-requests *ngIf="tab.value === 'requests' || tab.value === 'myRequests'" [activeTab]="activeTab"
                [role]="tab.value === 'requests' ? 'admin' : 'client'">
              </app-requests>
              <!-- <bulk-team-edit *ngIf="tab.value === 'myRequests'" ></bulk-team-edit> -->
              <app-create-request [role]="tab.value === 'requests' ? 'admin' : 'client'"
                *ngIf="tab.value === 'createRequest'"></app-create-request>
            </div>
          </ng-template>
        </li>
      </ng-container>
    </ul>
    <div [ngbNavOutlet]="navData"></div>

  </div>
  <!-- <app-footer></app-footer> -->

  <div *ngIf="ndvs.showSyncPopup" class="sync-popup custom-scrollbar">
    <div class="sync-popup-container">
      <div class="sync-popup-header">
        {{ndvs.syncDataId?"Job ID - "+ndvs.syncDataId:"Save Job"}}
        <button class="im-btn-link" (click)="onCloseNDSection()">
          <img src="./../../../assets/images/icons/modal-close.svg">
        </button>
      </div>
      <div class="sync-popup-content sync-popup-content-relative">
        <div class="loader-container loader-container-absolute" *ngIf="global.loadingSyncIFrame">
          <div class="loader"></div>
        </div>
        <iframe id="SyncContainerFrame" [src]="ndvs.syncUrl" class="syncContainer">

        </iframe>
        <!-- <netdocuments></netdocuments> -->
      </div>
      <!-- <div class="sync-popup-footer">
      <button
        class="btn im-primary-outline-btn"
        (click)="onCloseNDSection()">
        Close
      </button>
      <button class="btn im-primary-btn">Save</button>
  </div> -->
    </div>
  </div>
</div>
<app-create-request [role]="'client'" [clientMatterInfo]="clientMatterInfo"
  *ngIf="isAccessFromExternalApp"></app-create-request>