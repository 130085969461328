import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToasterMessageService {

  public showMessage:boolean=false;
  public background:string="success";
  public title:string="";
  public message:string="";
  constructor() { 

  }

  public success(title=null,message=null,options=null){
    this.background="success";
    this.title=(title!=null) ? title : '';
    this.message=(message!=null) ? message : '';
    this.showMessage=true;
    this.onHideMessage();
  }

  public warning(title=null,message=null,options=null){
    this.background="warning";
    this.title=(title!=null) ? title : '';
    this.message=(message!=null) ? message : '';    
    this.showMessage=true;
    this.onHideMessage();
  }  

  public danger(title=null,message=null,options=null){
    this.background="danger";
    this.title=(title!=null) ? title : '';
    this.message=(message!=null) ? message : '';   
    this.showMessage=true; 
    this.onHideMessage();
  }  
  
  private onHideMessage(){
    let currentTimer= setTimeout(()=>{
      this.showMessage=false;
      clearTimeout(currentTimer);
    },2000)
  }

}
