<div class="ip-container-box-sm  ip-container-holder">
  <div class="part-1 ip-container-box-sm-header p-2 border-bottom d-flex justify-content-between align-items-center">
    <b>Channels </b>
    <div class="col-auto p-0">
      <label class="dropdown-icon">
        <div class="dropdown-icon-button">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAACm0lEQVR4nO3cP2gUQRgF8I1/QQTBQtDSwsrCzkawsFFu5yUpVksrr7PU3EyKLdXOVitLTaOYb05FIYWNhWBhZWGpYCEIIvh/5YhnJORUvAvz3t48+Or98W5uttqvKHJycnJyJhZ467lgCyyVIsRmoykY43rLZxHs+2BcsG5BEJkCSx/n4O3LEOh8/Da7GM+kdkkUWPr+CQT7uB7pgn0uQzyV0kZfIHp21Hl7PwoKHz+Ui/1jyXzMBXb88mHn7e3I8tZO4rvZYEdSGGkL7PTsoPP2+m/lrd2J9mauFw+ldlNkvn6wzwV78a/l/TYvceHOgWKaUy083ON8fPYf5TU/53lV399bTGPK+u4ueHs8RnnN6p0Yn1T1yu5imlLVSzvgrT9uefg19ujk+f7OqXiJVNXSVoR4a3LlxWGJt4/XK9taXmAz44Jdn3x5cTg3Bs9obYEIdmUTy2tW70S72uITqA0FqUsGClKXLpQ1yAXmApMG+QS2s0CQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMkEucLzkAsdMLnBaCgQplNUlAwWpKzkUPl4e9cxJjQvx0mb5CX7pZgY+XlP9DJfir1LX9Rbn7abih+A0d023+3S74ioCqpR5Gcb4yetYEi8Emg/39k/CIJ/OYCVViK/kVlIh0UtEdSkadYEKa/noC/zjYkgfP6VeDClR4IarSUP86rydLtgC0gLXL8eFj+cKxoC4wEGcjxcHk9qRk5OTU7QoPwAUkaabdYzjsgAAAABJRU5ErkJggg==">
        </div>
        <input type="checkbox" class="dropdown-icon-input" id="test">

        <ul class="dropdown-icon-menu">
          <li (click)="sortAcending()" class="{{sort=='asc'?'li-disabled':'li-enabled'}}">A-Z</li>
          <li (click)="sortDecending()" class="{{sort=='desc'?'li-disabled':'li-enabled'}}">Z-A</li>
          <li (click)="clearSorting()" *ngIf="sort!==''" class="sort-clear">Clear</li>
        </ul>
      </label>

      <button class="btn btn-sm im-primary-outline-btn waves-effect ml-2" type="button"
        [disabled]="(connectionData.isConnected==undefined || !connectionData.isConnected || selectedGroup.Id==undefined)"
        (click)="onShowCreateChannel(modalChannel)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
            <path id="Path_787" data-name="Path 787" d="M18,7.5v21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
            <path id="Path_788" data-name="Path 788" d="M7.5,18h21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
          </g>
        </svg>
        Channel
      </button>
    </div>
  </div>
  <div class="part-2 col-12 p-2">
    <div class="search-box">
      <img class="search-icon"
        src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K">

      <input placeholder="Search Channels" type="search" class="search-input-text form-control"
        [disabled]="(connectionData.isConnected==undefined || !connectionData.isConnected || selectedGroup.Id==undefined)"
        (ngModelChange)="onChangeSearch($event)" [(ngModel)]="channelSearch">

      <img class="clear-icon"
        src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxLjk3NiA1MS45NzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxLjk3NiA1MS45NzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8cGF0aCBkPSJNNDQuMzczLDcuNjAzYy0xMC4xMzctMTAuMTM3LTI2LjYzMi0xMC4xMzgtMzYuNzcsMGMtMTAuMTM4LDEwLjEzOC0xMC4xMzcsMjYuNjMyLDAsMzYuNzdzMjYuNjMyLDEwLjEzOCwzNi43NywwICAgQzU0LjUxLDM0LjIzNSw1NC41MSwxNy43NCw0NC4zNzMsNy42MDN6IE0zNi4yNDEsMzYuMjQxYy0wLjc4MSwwLjc4MS0yLjA0NywwLjc4MS0yLjgyOCwwbC03LjQyNS03LjQyNWwtNy43NzgsNy43NzggICBjLTAuNzgxLDAuNzgxLTIuMDQ3LDAuNzgxLTIuODI4LDBjLTAuNzgxLTAuNzgxLTAuNzgxLTIuMDQ3LDAtMi44MjhsNy43NzgtNy43NzhsLTcuNDI1LTcuNDI1Yy0wLjc4MS0wLjc4MS0wLjc4MS0yLjA0OCwwLTIuODI4ICAgYzAuNzgxLTAuNzgxLDIuMDQ3LTAuNzgxLDIuODI4LDBsNy40MjUsNy40MjVsNy4wNzEtNy4wNzFjMC43ODEtMC43ODEsMi4wNDctMC43ODEsMi44MjgsMGMwLjc4MSwwLjc4MSwwLjc4MSwyLjA0NywwLDIuODI4ICAgbC03LjA3MSw3LjA3MWw3LjQyNSw3LjQyNUMzNy4wMjIsMzQuMTk0LDM3LjAyMiwzNS40NiwzNi4yNDEsMzYuMjQxeiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=">
    </div>
  </div>
  <div class="part-3 col-12 middle-list-height">
    <div class="scroll-content-holder">
      <!-- <div *ngIf="channelsLoader" class="section-loader">
        <img src="assets/images/Spinner.gif">
      </div> -->
      <div *ngIf="isChannelsRequestFailed">Channels data fetching error</div>
      <div *ngIf="teamsChannelsRequest!=null && !channelsLoader && channels.length==0">Channels not found</div>

      <ul class="list-group list-border-0 team-channel-list">
        <li *ngFor="let channel of channels; let channelIndex=index"
          class="{{(channel.id==this.global.selectedChannel.id)?'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions selected-channel':'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions'}}">
          <div class="col-12 p-0 d-flex align-items-center teams-channels-name" title="{{channel.DisplayName}}"
            (click)="onSelectChannel(channel)"><img src="assets/images/icons/channel-icon.svg" class="default-icon">
            <div class="name">
              <div class="title">{{channel.DisplayName}}</div><img
                *ngIf="channel.membershipType.toLowerCase()=='private'" src="assets/images/icons/private-lock.svg">
            </div>
          </div>
          <div class="elipse-action"
            *ngIf="channel.DisplayName=='General' && channel.isSyncScheduled  && global.haveSyncAddOn && global.isGlobalAdmin">
            <div ngbDropdown placement="bottom-right" class="d-inline-block"><img ngbDropdownToggle
                src="./../../../assets/images/icons/ellipse-hover-icon.svg" width="25px">
              <div ngbDropdownMenu><button *ngIf="(channel.isSyncScheduled)" ngbDropdownItem
                  (click)="onRemoveChannelSync(modalRemoveSync,channel)">
                  Remove Sync
                </button>
              </div>
            </div>
          </div>
          <div class="col-auto p-0 d-flex">
            <div class="elipse-action" *ngIf="channel.DisplayName!='General' && global.isGlobalAdmin">
              <div ngbDropdown placement="bottom-right" class="d-inline-block" *ngIf="global.isGlobalAdmin"><img
                  ngbDropdownToggle src="./../../../assets/images/icons/ellipse-hover-icon.svg" width="25px">
                <div ngbDropdownMenu><button *ngIf="(channel.isSyncScheduled)  && global.haveSyncAddOn" ngbDropdownItem
                    (click)="onRemoveChannelSync(modalRemoveSync,channel)">
                    Remove Sync
                  </button><button ngbDropdownItem (click)="onEditChannel(modalChannel,channel,channelIndex)">
                    Edit
                  </button><button ngbDropdownItem (click)="onDeleteChannel(modalDelete,channel,channelIndex)">
                    Delete
                  </button>
                </div>
              </div>
            </div><button
              *ngIf="((channel.isSyncScheduled==undefined || channel.isSyncScheduled==null || !channel.isSyncScheduled) && channel.teamsSyncData) && global.haveSyncAddOn"
              class="btn btn-icon im-white-outline-btn sync-btn waves-effect ml-2 me-2" type="button"
              [disabled]="true||global.maxSyncJobCreated" title="{{'This channel have team level sync job'}}"
              (click)="onConfigureSync($event, channel,modalInfo )"><img src="assets/images/icons/netdocshare-logo.svg">
              <!-- <img src="assets/images/icons/sync-white-icon.svg"> -->
            </button><button
              *ngIf="((channel.isSyncScheduled==undefined || channel.isSyncScheduled==null || !channel.isSyncScheduled )&& isTeamSync()) && global.haveSyncAddOn"
              class="btn btn-icon im-white-outline-btn sync-btn waves-effect ml-2 me-2" type="button"
              [disabled]="true||global.maxSyncJobCreated" title="{{'This channel have team level sync job.'}}"
              (click)="onConfigureSync($event, channel,modalInfo )"><img src="assets/images/icons/netdocshare-logo.svg">
            </button><button
              *ngIf="((channel.isSyncScheduled==undefined || channel.isSyncScheduled==null || !channel.isSyncScheduled) && !isTeamSync()) && global.haveSyncAddOn"
              class="btn btn-icon im-primary-btn sync-btn waves-effect ml-2 me-2" type="button"
              [disabled]="global.maxSyncJobCreated"
              title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':'Create Sync job'}}"
              (click)="onConfigureSync($event, channel,modalInfo )"><img src="assets/images/icons/sync-white-icon.svg">
            </button><button
              *ngIf="channel.isSyncScheduled && getNetDocsCabinet(channel) && global.haveSyncAddOn && !tabSync(channel)"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button" id="btn"
              (click)="onConfigureSync($event, channel)"><img src="assets/images/icons/netdocshare-logo.svg">
            </button><button
              *ngIf="channel.isSyncScheduled && getNetDocsCabinet(channel) && global.haveSyncAddOn && tabSync(channel)"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button" id="btn" [disabled]="true"
              title="{{'This channel have tab level sync job.'}}"
              (click)="onTabSyncError($event, channel, modalInfo)"><img src="assets/images/icons/netdocshare-logo.svg">
            </button><button *ngIf="channel.isSyncScheduled && !getNetDocsCabinet(channel) && global.haveSyncAddOn"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button"
              [disabled]="global.maxSyncJobCreated"
              title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':'Map sync location'}}"
              (click)="onConfigureSync($event, channel,modalInfo)"><img src="assets/images/icons/info-icon.svg">
            </button>
          </div>
        </li>
      </ul>
      <div *ngIf="channelsLoader" class="section-loader">
        <img src="assets/images/Spinner.gif">
      </div>
      <div class="d-flex justify-content-center">
        <button *ngIf="nextPageRequest&&!channelsLoader&&channels.length>0" class="btn btn-sm" type="button"
          (click)="onFetchMore()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
              <path id="Path_787" data-name="Path 787" d="M18,7.5v21" fill="none" stroke="#296191"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
              <path id="Path_788" data-name="Path 788" d="M7.5,18h21" fill="none" stroke="#296191"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
          </svg>
          More
        </button>
      </div>
    </div>
  </div>

</div>
<ng-template #modalChannel>
  <create-channel (syncChannel)="onSyncChannel($event)"></create-channel>
</ng-template>

<ng-template #modalDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
  </div>
  <div class="modal-body">Are you sure to delete this channel?</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
      No
    </button>
    <button type="button" class="btn im-primary-btn" [disabled]="isChannelDeleteLoader"
      (click)="onDeleteConfirm(modal)">
      Yes
      <img *ngIf="isChannelDeleteLoader" src="assets/images/Spinner.gif" class="search-button-img">
    </button>
  </div>
</ng-template>

<ng-template #modalRemoveSync let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
  </div>
  <div class="modal-body">
    Are you sure to remove this channel sync job?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
      No
    </button>
    <button type="button" class="btn im-primary-btn" (click)="onRemoveConfirm(modal)">
      Yes
      <img *ngIf="isChannelRemoveLoader" src="assets/images/Spinner.gif" class="search-button-img">
    </button>
  </div>
</ng-template>

<ng-template #modalInfo>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cannot create channel level sync job.</h4>
  </div>
  <div class="modal-body">
    This Channel has a Team level sync job. To create a Channel level sync job, remove Team level sync job and then
    create a Channel level sync job.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
      Close
    </button>
  </div>
</ng-template>