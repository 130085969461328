<ngb-accordion
  (panelChange)="toggleAccordian($event)"
  #acc="ngbAccordion"
  [closeOthers]="true">
  <ngb-panel id="{{channelPid}}">
    <ng-template ngbPanelHeader>
      <div class="headPanel">
        <div>
          <div class="mr-3">{{channelData.name}}</div>
          <div class="{{!accIsOpen?'cGreen':channelData.locationChoosed?'cGreen':'cRed'}}">
            {{!accIsOpen?"No channel sync":channelData.locationChoosed?"Sync location mapped":"Sync location not mapped"}}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="position-relative d-flex justify-content-between align-items-center mr-4">
            <label
              class="form-label mb-0 text-nowrap"
              for="{{channelPid}}">
              Sync Location
            </label>
            <!-- <div class="help-tip mt-0">
              <div class="tooltip">
                <p>{{toolTip.syncLocation}}</p>
              </div>
            </div> -->
          </div>
          <div>
            <input
              type="checkbox"
              id="{{channelPid}}"
              style="display:none"
              [(ngModel)]="channelData.syncCheckToggle"
              [ngModelOptions]="{standalone: true}"
              (change)="onChangeSyncCheck($event, channelData)">
            <!-- [attr.disabled]="disable" -->
            <label
              for="{{channelPid}}"
              class="check-toggle ml-0">
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-sync-net-doc-location [syncToggle]="channelData"></app-sync-net-doc-location>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
