import { Component, OnInit, NgZone } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  public selectedFile: any = {};
  public fileError: string = "";
  public isUploadProcess: boolean = false;
  public excelProcess: boolean = false;
  public excelData: any = [];
  public statusColor: any = { Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber' }
  private teamAppRequest: any = null;
  public syncChange: any = null;
  public teams = "";
  public jsonData = null;
  public validJsonData = [];
  public bulkUploadProcess = false;
  public bulkUpdateStatus = null;
  public bulkTeamCreationProcess = false;
  public bulkTeamCreationStatus = null;
  public bulkConfigUpdateProcess = false;
  public loadTeam = false;
  public refreshTeamsConnection = false;
  public disconnection = false;
  constructor(
    public global: GlobalService,
    public ndvs: NetDocumentViewerService,
    public teamsService: TeamsService,
    public toaster: ToastrService,
    public excelService: ExcelService,
    private modalService: NgbModal,
    public zone: NgZone,
    public router: Router
  ) {




  }

  ngOnInit(): void {
  }

  public onChangeRefresh(data) {
    this.refreshTeamsConnection = data;
    if (data) {
      this.global.selectedGroup = {};
      this.global.selectedChannel = {};
      this.global.selectedTab = {};
    }
  }

  public onChangeConnection(data) {
    this.global.connectionData = data;
    this.global.tenantId = this.global.connectionData.tenantId;
    this.disconnection = false;
    if (this.global.connectionData._id == undefined) {
      this.global.selectedGroup = {};
      this.global.selectedChannel = {};
      this.global.selectedTab = {};
      this.disconnection = true;
    }
    this.onGetNetDocShareTeamApp();
  }

  public onChangeSync(data) {
    this.syncChange = data;
  }

  public onLoadTeam(data) {
    this.loadTeam = data;
  }

  public onSelectGroup(data) {
    if (data.type !== "change") {
      this.global.selectedGroup = data;
      //if (this.global.selectedGroup.Id == undefined) {
      this.global.selectedChannel = {};
      this.global.selectedTab = {};
    }
    //}
  }

  public onSelectChannels(data) {
    if (data.type !== "change") {
      this.global.selectedChannel = data;
      if (this.global.selectedChannel.Id == undefined) {
        this.global.selectedTab = {};
      }
      this.showSyncPopup();
    }
  }

  public onSelectTabs(data) {
    //console.log("onSelectTabs====data===", data)
    this.global.selectedTab = data.data;
  }

  private onGetNetDocShareTeamApp() {
    if (this.teamAppRequest != null) this.teamAppRequest.unsubscribe();
    let sendData = { connectionId: this.global.connectionData._id }
    this.teamAppRequest = this.teamsService.checknetDocShareAppInstalled(sendData).subscribe((resData) => {
      if (resData.status == "success" && resData.data.Id != undefined) {
        localStorage.setItem('ndsApp', btoa(JSON.stringify(resData.data)));
        this.global.isNDSAppInstalled = true;
      } else {
        localStorage.removeItem('ndsApp');
        this.global.isNDSAppInstalled = false;
      }
    }, (error) => {
      localStorage.removeItem('ndsApp');
      this.global.isNDSAppInstalled = false;
    })
  }

  private showSyncPopup() {
    if (!this.ndvs.showSyncPopup) return;
  }

  private excelJsonData(teams) {
    let elemCount;
    let finalData = [];
    for (const [key, value] of Object.entries(teams[0])) {
      elemCount = teams.length;

      for (let i = 0; i < elemCount; i++) {
        if (!finalData[i]) {
          finalData[i] = {
            "Team Name": "",
            "Team ID": "",
            "Channel Name": "",
            "Channel ID": "",
            "NDS Tab Name": "",
            "View Type": "",
            "Cabinet": "",
            "Workspace": "",
            "Folder": "",
            "CollabSpace": "",
            "ShareSpace": ""
          };
        }
        finalData[i][key] = value[i];
      }
    }
    //console.log(finalData)
    return finalData;
  }


  public teamExcelExport(e) {
    /* this.teamsService.getTeamsGroups(this.global.connectionData._id).subscribe(
      (teamsData) => {
        var teamsGroup = teamsData.data;
        for (var i = 0; i <= teamsGroup.length - 1; i++) {
          //console.log(i)
          var team = teamsGroup[i];
          this.teamsService.getTeamsChannels(team.id).subscribe((channelsData) => {
            for (var j = 0; j <= channelsData.data.length - 1; j++) {
              var channel = channelsData.data[j];
              //console.log(team.id, team.displayName, channel.id, channel.displayName)
            }
          })

        }
      }); */
    this.teamsService.saveBulkTeams().subscribe();
    this.excelService.exportAsExcelFile(JSON.parse(this.teams), 'Teams_Data');
  }

  public navigateToBulkUpload() {
    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.router.navigateByUrl('/bulk-upload')
  }
  public navigateToBulkTeamCreation() {
    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.router.navigateByUrl('/bulk-team-creation')
  }

  public navigateToBulkTeam() {
    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.router.navigateByUrl('/bulk-team-creation')
  }
  public navigateToBulkConfigUpdate() {
    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.router.navigateByUrl('/bulk-config-update')
  }



  async ExecuteAsyncCode(i: number) {
    return [] as Item[]
  }
  async teamExcelExport2() {
    let items = new Array<Item>();
    this.teamsService.getTeamsGroups(this.global.connectionData._id).subscribe(
      (teamsData) => {
        var teamsGroup = teamsData.data;
        for (var i = 0; i <= teamsGroup.length - 1; i++) {

          var team = teamsGroup[i];
          let temp = this.teamsService.getTeamsChannels(this.global.connectionData._id).subscribe((channelsData) => {

            var channel = channelsData.data;
            items.push({ tid: team.id, tName: team.displayName, cid: channel.id, cName: channel.displayName });

            if (items.length < i) {
              return;
            }
          }
          )
        }
      });
    //console.log(items)
  }


  public onChooseFile(popUp) {
    this.modalService.dismissAll();
    if (this.ndvs.userSyncJobs >= this.ndvs.syncJobLimit) return;
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onFileDragOver(event) {
    event.preventDefault();
  }

  public onFileDropSuccess(event) {
    event.preventDefault();
    this.uploadSettings(event.dataTransfer.files);
  }

  public onBrowseFile(element) {
    element.click()
  }

  public onFileChange(event) {
    this.uploadSettings(event.target.files);
  }

  private uploadSettings(fileObject) {
    this.excelData = [];
    this.validJsonData = [];
    this.jsonData = null;
    if (fileObject.length > 1) {
      this.fileError = "Not supported for multiple files";
      return;
    } else {
      let fileExt = fileObject[0].name.split(".").pop();
      let allowedFiles: any = ["xls", "xlsx", "csv"];
      if (allowedFiles.indexOf(fileExt.toLowerCase()) == -1) {
        this.fileError = "Invalid file";
        return;
      } else {
        this.fileError = "";
      }

    }
    this.selectedFile = fileObject[0];
  }


  public showBulkUpdateStatus(popUp) {
    this.modalService.open(popUp, { scrollable: true, size: "lg" }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onValidateSettings() {
    if (((this.selectedFile.name == undefined || this.selectedFile.name == null || this.selectedFile.name == "") && !this.isUploadProcess)) return;

    this.excelProcess = true;
    this.isUploadProcess = true;
    this.bulkUpdateStatus = null;
    let workBook = null;
    // let jsonData = null;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      if (this.jsonData.Sheet1?.length > 0) {
        this.jsonData.Sheet1.forEach((element, index) => {
          element.row = index + 1;
          this.excelData.push(element);
          this.validateSPBulkData(element);
        });
        this.validJsonData = this.jsonData.Sheet1.filter(d => !d.isDataInvalid);


      } else {
        this.fileError = "File have no records";
      }
      this.isUploadProcess = false;
      this.excelProcess = false;
    }
    reader.readAsBinaryString(this.selectedFile);
    this.selectedFile = {}
  }

  public startBulkUpload() {
    this.bulkUploadProcess = true;
    this.modalService.dismissAll();
    this.teamsService.uploadExcelData({ "bulkData": this.validJsonData }).subscribe((resData) => {
      this.bulkUploadProcess = false;

      var bulkUploadStatus = resData.data.data.map(((e, i) => { return { ...e, teamName: this.validJsonData[i].TeamName, channelName: this.validJsonData[i].ChannelName } }))


      this.bulkUpdateStatus = { status: resData.status, data: bulkUploadStatus }
    }, (error) => {
      this.bulkUploadProcess = false;
      this.bulkUpdateStatus = { status: "error", error: error }
    })
  }

  private validateSPBulkData(jobData) {
    jobData.reason = "";
    jobData.isDataInvalid = true;
    jobData.dataProcessStatus = "Not Processed";
    jobData.TeamID = jobData.TeamID?.toString();
    jobData.ChannelID = jobData.ChannelID?.toString();
    jobData.TabName = jobData.TabName?.toString();
    jobData.TabConfig = jobData.TabConfig?.toString();
    jobData.SyncLocation = jobData.SyncLocation?.toString();

    //VALIDATE TEAM ID
    if (jobData.TeamID == undefined || jobData.TeamID == null || jobData.TeamID == "" || jobData.TeamID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "TeamID field missing";
    }

    //VALIDATE CHANNEL ID
    if (jobData.ChannelID == undefined || jobData.ChannelID == null || jobData.ChannelID == "" || jobData.ChannelID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "ChannelID field missing";
    }

    //VALIDATE Tab Name
    if (jobData.TabName == undefined || jobData.TabName == null || jobData.TabName == "" || jobData.TabName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "TabName field missing";
    }

    if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "") && (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "")) {
      //VALIDATE Tab Config
      if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "")) {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "TabConfig field missing";
      }
      //VALIDATE Sync Location
      if (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "") {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "SyncLocation field missing";
      }
    }

    if (jobData.reason == "") {
      jobData.isDataInvalid = false;
      jobData.reason = "-";
    }

  }


}


interface Item {
  tid: string, tName: string, cid: string, cName: string
}