import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'connectionName'
})
export class ConnectionNamePipe implements PipeTransform {

  transform(value: any, args: any): any {
    if(args!=undefined && args!=null && args!=""){
      if(value.length>0){
        return value.filter(connection=>{
          if(connection.tenantName.toLowerCase().indexOf(args.toLowerCase())>=0) return true;
        })        
      }else{
        return value;
      }
    }else{
      return value;
    }
  }

}
