import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTeamName'
})
export class TeamNamePipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (args != undefined && args != null && args != "") {
      if (value.length > 0) {
        return value.filter(team => {
          if (team.displayName.toLowerCase().indexOf(args.toLowerCase()) >= 0) return true;
        })
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

}
