<div class="sync-popup custom-scrollbar">
  <div class="sync-popup-container sync-popup-sm">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="isNDLoading">
      <div class="loader-container loader-container-absolute">
        <div class="loader"></div>
      </div>
    </div>
    <div class="sync-popup-header p-4" style="z-index: 110;position: relative;">
      <div>Edit Team</div>
      <button class="im-btn-link btnbox" (click)="onClosePopup()" [disabled]="isNDLoading">
        <img src="assets/images/icons/modal-close.svg">
      </button>
    </div>
    <div class="sync-popup-content p-4">
      <div [formGroup]="form">
        <div class="form-group row mr-0 ml-0">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Team Name *</label>
          </div>
          <div class="col-sm-8">
            <input #name type="text" class="form-control" formControlName="teamName" placeholder="Give your team a name"
              [attr.disabled]="(isNDLoading)?true:null">
          </div>
        </div>
        <div class="form-group row mr-0 ml-0 mb-4">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Description</label>
          </div>
          <div class="col-sm-8">
            <textarea class="form-control-custom" rows="5" formControlName="description"
              placeholder="{{'Let people know what this team is all about'}}"
              [attr.disabled]="(isNDLoading)?true:null"></textarea>
          </div>
        </div>
        <div class="form-group row mr-0 ml-0 mb-4">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Privacy</label>
          </div>
          <div class="col-sm-8">
            <div>
              <select [attr.disabled]="(isNDLoading)?true:null" class="form-control" id="privacy"
                formControlName="optionPrivacy">
                <option value="private">
                  Private
                  <!-- - Only team owners can add members -->
                </option>
                <option value="public">
                  Public
                  <!-- - Anyone in your organization can join -->
                </option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <div class="form-group row mr-0 ml-0 mb-4">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
          <label class="form-label">Members</label>
        </div>
        <div class="col-sm-8">
          <div class="input-group mb-2">
            <input type="email" class="form-control netDocShare-input" id="inlineFormInputGroup"
              placeholder="Search and add members to your team." [(ngModel)]="searchUserString"
              (ngModelChange)="setSearchUserString($event)" (keydown.enter)="setSearchUserString($event)"
              autocomplete="off" />
            <div class="input-group-append">
            </div>
          </div>
          <div class="emailSuggestions netDocShare-dropdown">
            <ul class="netDocShare-dropdown-menu" [(ngModel)]="selectedItem">
              <li *ngFor="let searchmember of searchmembers" class="netDocShare-dropdown-item">
                <button class="btn btn-default col-sm-12" type="button"
                  title="{{capitalizeFirstLetter(searchmember.name)}}" role="button"
                  (click)="addMembers(searchmember)">{{capitalizeFirstLetter(searchmember.name)}}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div>

        <div class="vh50" overflow-hidden>

          <p>Search and add members to your team.</p>

          <div class="col-sm-12 position-relative d-flex justify-content-between align-items-center  nopadding">
            <label class="form-label">Search Members:</label>
          </div>
          <div class="form-group row mr-0 ml-0 mb-0">
            <div class="col-sm-12 nopadding inp-search-holder">
              <input type="text" [(ngModel)]="searchUserString" class="form-control inp-search"
                (ngModelChange)="setSearchUserString($event)">
              <button *ngIf="searchUserString!=''" class="icn-btn inp-search-close" (click)="clearSearch($event)">
                <i class="material-icons-sharp md-close"></i>
              </button>
            </div>
          </div>
          <div class="form-group row mr-0 ml-0 height500" [hidden]="!searchUserString && !dropDownForm.value.myItems.length">
            <div class="search-member col-sm-12 nopadding">
              <form [formGroup]="dropDownForm" class="w-100">
                <div>
                  <div class="dd-loader-holder" *ngIf="loadingMember">
                    <img class="loader-image" src="assets/images/Spinner.gif">
                  </div>
                  <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="searchmembers"
                    [disabled]="loadingMember||loadingAddMember||searchmembers.length==0" formControlName="myItems"
                    class="ms-drop">
                  </ng-multiselect-dropdown>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> -->
      <div class="form-group row mr-0 ml-0 mb-4">

        <table class="table table-bordered bg-white" *ngIf="members.length>0">
          <thead class="thead-light">
            <tr>
              <th class="col-1">Name</th>
              <!-- <th class="col-2">Email</th> -->
              <th class="col-2">Role</th>
              <th class="col-2">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let member of members">
              <td class="col-5">{{member.displayName}}</td>
              <!-- <td class="col-5">{{member.mail}}</td> -->
              <td class="col-5">
                <span *ngIf="member.userType?.toLowerCase() == 'guest'">{{member.userType}}</span>
                <!-- <span *ngIf="member.owners!='undefined' && member.userType.toLowerCase() != 'guest'">Owners</span> -->
                <select *ngIf="member.userType?.toLowerCase() != 'guest'" [(ngModel)]="member.role"
                  (change)="member.isNeedToSave=true">
                  <option value="Member">Member</option>
                  <option value="Owner">Owner</option>
                </select>
              </td>
              <td class="col-2" disabled="member.role.toLowerCase() !='owner'">
                <div (click)="removeMember(member)" style="display: inline-block;cursor: pointer;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash"
                    viewBox="0 0 16 16" style="cursor: pointer;">
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="members.length==0">
          No active members are assigned to this team.
        </div>
      </div>
    </div>
    <div class="sync-popup-footer" style="position: relative;
    z-index: 111;">
      <button class="btn im-primary-outline-btn" (click)="goBack()" [disabled]="isNDLoading">
        Back
      </button>
      <button class="btn im-primary-outline-btn" (click)="onUpdate()" [disabled]="isNDLoading">
        {{"Save"}}
        <img class="loader-image" *ngIf="isDataSave" src="assets/images/Spinner.gif">
      </button>
    </div>
  </div>
</div>