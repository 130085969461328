import { Component, OnInit, NgZone, Input } from '@angular/core';
import { NetDocumentViewerService } from '../../services/netdocument-viewer';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NetDocAuthComponent } from '../net-doc-auth/net-doc-auth.component';
import { Router } from '@angular/router';
import { NetdocshareLocalstorageService } from '../../services/netdocshare-localstorage.service';


@Component({
  selector: 'app-net-doc-login',
  templateUrl: './net-doc-login.component.html',
  styleUrls: ['./net-doc-login.component.scss']
})
export class NetDocLoginComponent implements OnInit {
  @Input() public AppConfig: any;
  public userName: string = "";
  public isValidated: boolean;
  public fail: boolean;
  public obtainedUsername: boolean;
  public unauthorized: boolean;
  public allowedDomains: any[];

  constructor(public ndvs: NetDocumentViewerService,
    private zone: NgZone,
    private modalService: NgbModal,
    private router: Router,
    public netDocLocalStorage: NetdocshareLocalstorageService) { }

  /* ngOnInit() {
    this.ndvs.NDAuthComponent = NetDocAuthComponent;
    if (this.ndvs.currentUser.access_token !== undefined && this.ndvs.currentUser.access_token !== null && !this.ndvs.tokenExpired()) {
      this.getUser();
    } else if (this.ndvs.currentUser.access_obtained != null && this.ndvs.tokenExpired()) {
      this.ndvs.getSystemInfo().subscribe((r) => {
        if (r === 'fail') {
          this.isValidated = false;
          this.fail = true;
        } else {
          this.getUser();
        }
      })
    } else {
      this.router.navigateByUrl('/');
    }
  } */

  ngOnInit() {
    this.ndvs.NDAuthComponent = NetDocAuthComponent;
    this.ndvs.getLicenseInfo();
    if (this.ndvs.currentUserLicense.clientId != undefined && this.ndvs.currentUserLicense.clientId != null && this.ndvs.currentUserLicense.clientId != "") {
      // let NDInfo=localStorage.getItem('ndInfo');
      let NDInfo = this.netDocLocalStorage.ndInfo;
      this.ndvs.currentUser = (NDInfo != undefined && NDInfo != null && NDInfo != "") ? NDInfo : {}
      if (this.ndvs.currentUser.access_token !== undefined && this.ndvs.currentUser.access_token !== null && !this.ndvs.tokenExpired()) {
        this.getUser();
      } else if (this.ndvs.currentUser.access_obtained !== null && this.ndvs.tokenExpired()) {
        this.ndvs.getSystemInfo().subscribe((r) => {
          if (r === 'fail') {
            this.isValidated = false;
            this.fail = true;
          } else {
            this.getUser();
          }
        })
      } else {
        this.router.navigateByUrl('/');
      }
    } else {
      this.router.navigateByUrl('/');
    }

  }

  doLogin() {
    if (!localStorage.access_token || this.ndvs.tokenExpired()) {
      this.netDocLocalStorage.last_page_url = window.location.href;
      this.ndvs.getSystemInfo().subscribe((res) => {
        if (!res) {
          this.unauthorized = true;
        } else {
          this.userAuthorized();
        }
      })
    } else {
      this.accessTokenFound();
    }
  }

  btnClick() {
    this.router.navigateByUrl('/provisioning');
  }

  accessTokenFound() {
    this.isValidated = true;
    this.fail = false;
  }

  userAuthorized() {
    this.unauthorized = false;
    this.isValidated = true;
    this.fail = false;
  }

  getUser() {
    this.ndvs.getNetDocUserInfo().subscribe((r: any) => {
      this.isValidated = true;
      this.fail = false;
      this.userName = r.displayName;
      this.obtainedUsername = true;
    })
  }
}
