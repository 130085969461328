
    <div class="form-group row mr-0 ml-0 mb-4">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Cabinet</label>
        </div>
        <div class="col-sm-8 col-xl-8">
            <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Cabinet name'" [sourceType]="'cabinet'"
                [isFetching]="cabinet?.isFetching" [lookupData]="cabinet" [selectedItem]="cabinet.selected"
                (onChange)="onSourceCabinetChange($event)" (onFetch)="fetchCabinets($event)"
                (onReset)="resetCabinets($event)"></nd-combo-box>
        </div>
    </div>
    <!-- client -->
    <div class="form-group row mr-0 ml-0 mb-4">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Client</label>
        </div>
        <div class="col-sm-8 col-xl-8">
            <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Client name'" [sourceType]="'client'"
                [isFetching]="client.isFetching" [lookupData]="client" [selectedItem]="client.selected"
                (onChange)="onSelectClient($event);" (onFetch)="getClients(cabinet.selected,$event)"
                (onReset)="reset('client')"></nd-combo-box>
        </div>
    </div>
    <!-- Matter -->
    <div *ngIf="this.matter?.matterInfo" class="form-group row mr-0 ml-0 mb-4">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Matter</label>
        </div>
        <div class="col-sm-8 col-xl-8">
            <div>
                <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Matter name'" [sourceType]="'client'"
                    [isFetching]="matter.isFetching" [lookupData]="matter" [selectedItem]="matter.selected"
                    (onChange)="onSelectMatter($event);" (onFetch)="getMatters($event)"
                    (onReset)="reset('matter')"></nd-combo-box>
            </div>
        </div>
    </div>
    <!-- SharePoint Template -->
    <div class="form-group row mr-0 ml-0 mb-4">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">SharePoint Site Template</label>
        </div>
        <div class="col-sm-8 col-xl-8">
            <div>
                <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select SharePoint Site Template'"
                    [sourceType]="'SharePoint'" [isFetching]="matter.isFetching" [lookupData]="matter"
                    [selectedItem]="matter.selected" (onChange)="onSelectMatter($event);" (onFetch)="getMatters($event)"
                    (onReset)="reset('matter')"></nd-combo-box>
            </div>
        </div>
    </div>