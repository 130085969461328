import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/services/helper';
import { GlobalService } from 'src/app/services/global.service';
import { SyncNetDocLocationComponent } from '../sync-net-doc-location/sync-net-doc-location.component';
import { Router } from '@angular/router';
import { TeamsService } from 'src/app/services/teams.service';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';
@Component({
  selector: 'bulk-team-edit',
  templateUrl: './bulk-team-edit.component.html',
  styleUrls: ['./bulk-team-edit.component.scss']
})
export class BulkTeamEditComponent implements OnInit {
  @ViewChild(SyncNetDocLocationComponent) syncLocation;
  myForm: FormGroup;
  public syncCheck;
  public isEditMode: boolean = false;
  public selectedTeamIndex = 0;
  public editSyncDetails: any = {};
  public templateChannelSyncData = {};
  public channelDetail: any = [];
  public tabConfig: any = {}
  public isChannelEdit: boolean = false;
  public selectedTeamName: string;
  public isBulkEditMode: boolean = false;
  public teamDetails: any = {};
  public templateSyncData: any = {};
  public bulkEditTeamLoader: boolean = false;
  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private global: GlobalService,
    private router: Router,
    private teamsService: TeamsService,
    public netDocLocalStorage: NetdocshareLocalstorageService
  ) {
    this.myForm = this.fb.group({
      teams: this.fb.array([this.setFormGroup()])
    });
  }
  public channelsLoader: boolean = false;
  public isChannelsRequestFailed: boolean = false;
  public duplicateMsg = "";
  setFormGroup(): FormGroup {
    return this.fb.group({
      cabinet: new FormControl(''),
      workspace: new FormControl(''),
      teamsTemplate: new FormControl(''),
      optionPrivacy: new FormControl("public"),
      teamName: new FormControl('', Validators.required),
      description: new FormControl(''),
      channelname: new FormControl(''),
      tabname: new FormControl(''),
      channels: new FormArray([]),
      optionSync: new FormControl(this.syncLocation?.syncCheck),
      syncCreated: new FormControl(false),
      syncDetails: new FormControl([]),
      members: new FormControl([]),
      groups: new FormControl([]),
      sharePointSiteTemplate: new FormControl([]),
      sharePointSiteCollectionName: new FormControl(''),
      isCreateSPWebpartInHomePage: new FormControl(false),
      isCreateSPSync: new FormControl(false),
      syncSPDetails: new FormControl([]),
      selectedChennal: new FormControl([]),
      selectedChannelIndex: new FormControl(''),
      teamData: new FormControl([]),
      teamLevelSync: new FormControl(false),
      status: new FormControl(''),
      statusMessage: new FormControl(''),
      tabConfigSetting: new FormControl([])
    });
  }
  public get teams(): FormArray {
    return this.myForm.get('teams') as FormArray;
  }
  // Add a new row to the FormArray
  addteam() {
    this.teams.push(this.setFormGroup());
  }
  back(modal) {
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        this.router.navigateByUrl('/provisioning');
      }
    }, (result) => {
    }).catch((error) => {

    })


  }
  // Remove a row from the FormArray
  removeteam(modal, index: number) {
    if (this.teams.length == 1) {
      this.toaster.info('Cannot delete. At least one team must be present.');
      return;
    }
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        this.teams.removeAt(index);
      }
    }, (result) => {
    }).catch((error) => {

    })

  }
  ngOnInit(): void {
    this.getTeamsConnections();
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    console.log("Leaving site, window:unload");
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    console.log("Leaving site, beforeunload!")
    return false;
  }

  openTeam(modal, teamName, index) {
    this.selectedTeamIndex = index;
    this.selectedTeamName = teamName;
    this.isBulkEditMode = false;
    if (this.teams.controls[this.selectedTeamIndex].get('teamData').value?.teamDetail?.teamName != null || this.teams.controls[this.selectedTeamIndex].get('teamData').value?.teamDetail?.teamName != undefined) {
      this.isBulkEditMode = true;
      this.teamDetails = this.teams.controls[this.selectedTeamIndex].get('teamData').value;
    }
    this.modalService.open(modal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then((res) => { });

  }
  onUpdateTeam(result, modal) {
    if (result != null) {
      this.teams.controls[this.selectedTeamIndex].get('teamData').setValue(result);
      this.teams.controls[this.selectedTeamIndex].get('cabinet').setValue(result?.teamDetail?.cabinet);
      this.teams.controls[this.selectedTeamIndex].get('workspace').setValue(result?.teamDetail?.workspace);
      this.teams.controls[this.selectedTeamIndex].get('teamName').setValue(result?.teamDetail?.teamName);
      this.teams.controls[this.selectedTeamIndex].get('description').setValue(result?.teamDetail?.description);
      this.teams.controls[this.selectedTeamIndex].get('optionPrivacy').setValue(result?.teamDetail?.optionPrivacy);
      //this.teams.controls[this.selectedTeamIndex].get('optionSync').setValue(result?.teamDetail?.optionSync);
      this.teams.controls[this.selectedTeamIndex].get('syncDetails').setValue(result?.teamDetail?.syncDetails);
      this.teams.controls[this.selectedTeamIndex].get('teamLevelSync').setValue(result?.teamDetail?.teamLevelSync);
      this.teams.controls[this.selectedTeamIndex].get('isCreateSPWebpartInHomePage').setValue(result?.teamDetail?.isCreateSPWebpartInHomePage);
      this.teams.controls[this.selectedTeamIndex].get('isCreateSPSync').setValue(result?.teamDetail?.isCreateSPSync);
      this.teams.controls[this.selectedTeamIndex].get('sharePointSiteTemplate').setValue(result?.teamDetail?.sharepointSiteTemplate);
      this.teams.controls[this.selectedTeamIndex].get('members').setValue(result?.teamDetail?.members);
      this.teams.controls[this.selectedTeamIndex].get('groups').setValue(result?.teamDetail?.groups);
      this.teams.controls[this.selectedTeamIndex].get('teamsTemplate').setValue(result?.teamDetail?.teamsTemplate);
      this.teams.controls[this.selectedTeamIndex].get('tabConfigSetting').setValue(result?.teamDetail?.tabConfigSetting);
      if (result?.teamDetail?.teamsTemplate?.config) {
        const channels = this.teams.controls[this.selectedTeamIndex].get('channels') as FormArray
        channels.clear();
        let templateJSON = JSON.parse(result?.teamDetail?.teamsTemplate?.config)
        templateJSON.team.channels.forEach((channelsItem: any, channelIndex) => {
          this.addChannel(channelsItem, this.selectedTeamIndex);
          const channel = channels.at(channelIndex);
          const tabs = channel.get('tabs') as FormArray;
          tabs.clear();
          channelsItem.tabs.forEach(tab => {
            this.addTab(this.selectedTeamIndex, channelIndex, result?.teamDetail?.tabConfigSetting);
          });
        });
        if (channels.length > 0) {
          this.teams.controls[this.selectedTeamIndex].get('selectedChennal').setValue(channels[0]);
          this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').setValue(0);
        }
      }
    }
    modal.close();
  }
  public openChannel(e, modal, teamIndex) {
    this.selectedTeamIndex = teamIndex;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {

      }
    }, (result) => {
    }).catch((error) => {

    })
  }
  public addChannel(item = null, index = null) {
    const channels = this.teams.controls[index].get('channels') as FormArray
    if (item == null)
      channels.push(new FormGroup({
        name: new FormControl('New Channel', [Validators.required]),
        description: new FormControl(''),
        membershipType: new FormControl("standard"),
        sync: new FormControl(false),
        syncEnabled: new FormControl(this.syncLocation?.syncCheck),
        syncDetails: new FormControl({}),
        tabs: new FormArray([]),
        othertabs: new FormArray([])
      }))
    else channels.push(new FormGroup({
      name: new FormControl(item.name, [Validators.required]),
      description: new FormControl(item.description),
      membershipType: new FormControl(item.membershipType != "" ? item.membershipType : "0"),
      sync: new FormControl((item.sync != null && item.sync != undefined) ? item.sync : false),
      syncEnabled: new FormControl(this.syncLocation?.syncCheck),
      syncDetails: new FormControl({}),
      tabs: new FormArray([]),
      othertabs: new FormArray([])
    }))
    setTimeout(() => {
      this.teams.controls[index].get('channels').markAllAsTouched();
    }, 100);
  }
  addtabtoTeam(teamIndex, channelIndex, tab = null) {   
    let config: any;   
    config = JSON.parse(this.tabConfig?.tabConfig?.config);
    config.tabName = this.tabConfig.tabConfig.tabName;
    if (tab != null) {
      tab.value.name = this.tabConfig.tabConfig.tabName;
      tab.value.id = this.tabConfig.tabConfig.tabId;
      tab.value.config = JSON.stringify(config);
    } else {
      this.addTab(teamIndex, channelIndex, {
        name: this.tabConfig?.tabConfig?.tabName,
        id: this.tabConfig.tabConfig.tabId,
        config: JSON.stringify(config)
      });
    }

    this.tabConfig = {};
  }
  addChanneltoTeam(channelName, teamIndex) {
    this.selectedTeamIndex = teamIndex;
    this.addChannel({
      name: channelName,
      description: "",
      membershipType: "",
    }, teamIndex);
    this.teams.controls[teamIndex].get('channelname').setValue('');
  }
  onSelectChannel(teamIndex, channelIndex, channel) {
    this.selectedTeamIndex = teamIndex;
    this.teams.controls[teamIndex].get('selectedChennal').setValue(channel);
    this.teams.controls[teamIndex].get('selectedChannelIndex').setValue(channelIndex);
  }
  public onEditChannel(modal, channelData, channelIndex, teamIndex) {
    this.teams.controls[teamIndex].get('selectedChannelIndex').setValue(channelIndex);
    this.channelDetail = channelData.value;
    this.channelDetail.membershipType = this.channelDetail.membershipType == 0 ? 'standard' : 'private';
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {

    });
  }
  onEditSync(modal, index, channelIndex, channelData) {
    this.selectedTeamIndex = index;
    this.teams.controls[index].get('selectedChennal').setValue(channelData);
    this.teams.controls[index].get('selectedChannelIndex').setValue(channelIndex);
    this.editSyncDetails.syncJobSetting = channelData.value?.syncDetails;
    this.editSyncDetails.syncType = channelData.value?.syncDetails?.syncType || 'bidirectionalTeam';
    if (this.teams.controls[index].get('cabinet').value != null && this.teams.controls[index].get('cabinet').value != undefined) {
      this.editSyncDetails.cabinet = this.teams.controls[index].get('cabinet').value;
    }
    if (this.teams.controls[index].get('workspace').value?.data) {
      this.editSyncDetails.workspace = this.teams.controls[index].get('workspace').value?.data;
    }
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {

    });
  }
  public onShowCreateChannel(modal, isChannelEdit = false) {
    this.global.isChannelEdit = false;
    this.isChannelEdit = isChannelEdit;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      this.isChannelEdit = false;
    }, (channelData) => {


    }).catch((error) => {
      this.isChannelEdit = false;
    })
  }

  public removeChannel(teamIndex, channelIndex, modal) {
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        const channels = this.teams.controls[teamIndex].get('channels') as FormArray
        channels.removeAt(channelIndex);
        this.teams.controls[teamIndex].get('selectedChennal').setValue(new FormControl([]));
      }
    }, (result) => {
    }).catch((error) => {

    })
  }
  onSyncJobSettingChange(data) {
    const channels = this.teams.controls[this.selectedTeamIndex].get('channels') as FormArray;
    const channel = channels.at(parseInt(this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').value));
    let syncJobSetting = data.syncJobSetting;
    if (syncJobSetting?.netDocuments) {
      syncJobSetting.netDocuments.userName = this.netDocLocalStorage?.ndInfo?.displayName;
      syncJobSetting.netDocuments.siteUrl = this.netDocLocalStorage?.licenseInfo?.netDocBaseUrl;
    }
    channel.get('syncDetails').setValue(syncJobSetting);
    channel.get('sync').setValue(true);
  }
  opentabConfig(modal, teamIndex, channelIndex, channel) {
    this.selectedTeamIndex = teamIndex;
    this.teams.controls[teamIndex].get('selectedChennal').setValue(channel);
    this.teams.controls[teamIndex].get('selectedChannelIndex').setValue(channelIndex);
    this.global.selectedTab.tabTemplateData = this.teams.controls[teamIndex].get('tabConfigSetting').value?.tabConfig?.config;
    this.global.selectedTab.configuration = this.teams.controls[teamIndex].get('tabConfigSetting').value?.tabConfig?.config;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        this.addtabtoTeam(this.selectedTeamIndex, parseInt(this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').value), null);
      }
    });
  }

  public addTab(teamIndex, channelIndex, tab = null) {
    const channels = this.teams.controls[teamIndex].get('channels') as FormArray;
    const channel = channels.at(channelIndex);
    const tabs = channel.get('tabs') as FormArray;
    if (tab == null)
      tabs.push(new FormGroup({
        name: new FormControl('New Tab', [Validators.required]),
        id: new FormControl('', []),
        config: new FormControl({}, [Validators.required])
      }))
    else tabs.push(new FormGroup({
      name: new FormControl(tab.name || tab.tabConfig.tabName, [Validators.required]),
      id: new FormControl(tab.id || tab.tabConfig.tabId, []),
      config: new FormControl(tab.config || tab.tabConfig.config, [Validators.required])
    }));
    console.log(this.teams.value);
  }
  onAddTab(tabconfig) {
    this.tabConfig = tabconfig;
  }
  public removetab(teamIndex, tabIndex, modal) {
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        const channels = this.teams.controls[teamIndex].get('channels') as FormArray;
        const channel = channels.at(parseInt(this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').value));
        const tabs = channel.get('tabs') as FormArray;
        tabs.removeAt(tabIndex);
      }
    }, (result) => {
    }).catch((error) => {

    })
  }
  public onEditTab(modal, tabData, tabIndex) {
    if (tabData?.value?.config) {
      this.global.selectedTab.entityId = tabData?.value?.id;
      this.global.isTabEdit = true;
      var config = JSON.parse(tabData?.value?.config);
      var tabConfigSetting = this.teams.controls[this.selectedTeamIndex].get('tabConfigSetting').value;
      if (tabConfigSetting?.tabConfig?.config) {
        config = JSON.parse(tabConfigSetting?.tabConfig?.config);
      }
      let workspace = this.teams.controls[this.selectedTeamIndex].get('workspace').value;
      if (workspace?.data?.selectedCabinet) {
        let ws = workspace?.data?.selectedCabinet;
        const primarySource = {
          cabinet: ws.name,
          cabinetId: ws.id,
          id: workspace?.data.Attributes.Id,
          name: workspace?.data.Attributes.Name,
          type: "workspace",
          workspace: workspace?.data.Attributes.Name,
          workspaceId: workspace?.data.Attributes.Id,
        }
        config.primarySource = primarySource;

      }
      this.global.selectedTab.tabTemplateData = JSON.stringify(config);
      this.global.selectedTab.configuration = JSON.stringify(config);
    }
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
      if (res == 'yes') {
        this.addtabtoTeam(this.selectedTeamIndex, parseInt(this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').value), tabData);
      }
    });
  }
  public onCloseModal() {
    this.modalService.dismissAll();
  }
  public onChangeChannelSync(e) {
    if (this.syncCheck) {
      e.target.checked = false;
      this.toaster.error("Team have team level sync. Cannot create channel level sync.", "Failure", { timeOut: 3000 });
    }
  }

  checkTeamName(i) {
    const control = this.myForm.get('teams') as FormArray;
    var array: string[] = [];
    for (let i of control.value) {
      array.push(i.teamName);
    }
    if ((new Set(array)).size !== array.length) {
      this.duplicateMsg = `Team Name is duplicate`;
      this.teams.controls[i].get('teamName').setErrors({ duplicate: true })
    } else {
      this.duplicateMsg = '';
    }
  }
  onChangeChannelData(data) {
    const channels = this.teams.controls[this.selectedTeamIndex].get('channels') as FormArray;
    const channel = channels.at(parseInt(this.teams.controls[this.selectedTeamIndex].get('selectedChannelIndex').value));
    channel.get('name').setValue(data.channelName);
    channel.get('description').setValue(data.channelDescription);
    channel.get('membershipType').setValue(data.optionPrivacy == 'standard' ? 0 : 1);
    this.bulkEditTeamLoader = false;
  }
  onSaveSync() {
    this.modalService.dismissAll();
  }
  async onSubmit() {
    this.myForm.value.teams.forEach(async (team, index) => {
      if (team.status != "success") {
        this.teams.controls[index].get("status").setValue('process');
        let sendData = team;
        sendData.createdBy = this.netDocLocalStorage?.ndInfo?.displayName;
        sendData.teamDescription = team.description;
        sendData.connectionId = this.global.connectionData._id;
        sendData.visibility = team.optionPrivacy;
        sendData.adminEmail = team.teamData?.teamDetail?.createdBy != undefined ? team.teamData?.teamDetail?.createdBy : "";
        sendData.sharepointSiteTemplate = team.sharePointSiteTemplate;
        sendData.teamLevelSync = team.teamLevelSync;
        sendData.members = team?.members;
        sendData.groups = team?.groups;

        if (!team.teamLevelSync) {
          sendData.syncDetails = null;
        }
        if (team?.syncEnabled && this.global.currentClientInfo?.addons && this.global.currentClientInfo.addons.indexOf('netDocShare Sync') > -1) {
          this.teams.controls[index].get("status").setValue("failed");
          this.teams.controls[index].get("statusMessage").setValue("Sync Addon is not active. This team require Sync Addon.");
          return;
        }
        await this.teamsService.createBulkTeam(sendData).subscribe((res) => {
          this.teams.controls[index].get("status").setValue(res.status);
          this.teams.controls[index].get("statusMessage").setValue(res.message);

        }, (error) => {
          this.teams.controls[index].get("status").setValue("failed");
          this.teams.controls[index].get("statusMessage").setValue(error.message);
        });
      }

      // }
    });

  }

  public getTeamsConnections() {
    this.teamsService.getTeamsConnections().subscribe((resData) => {
      if (resData != undefined && resData.status == "success" && resData.data != null) {
        var defaultConnection = resData.data?.find(d => d.isDefault && d._id);
        if (defaultConnection != null) this.global.connectionData = defaultConnection;
        this.global.tenantId = this.global.connectionData.tenantId;
      }
    })
  }
}
