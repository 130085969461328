import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';

@Component({
  selector: 'create-tab',
  templateUrl: './create-tab.component.html',
  styleUrls: ['./create-tab.component.scss']
})
export class CreateTabComponent implements OnInit, AfterViewInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfigChange: EventEmitter<any> = new EventEmitter<any>();

  public tabsList: any = {};
  @Input("tabsList") set onChangeTabsList(data) {
    this.tabsList = data;
  };

  @Input() public tabDetail: any = null;
  @Input("modal") public modal: any = null;
  @Input() public isBulkEdit: boolean = false;
  @Input() public creationFromTeam: boolean = false;

  public formTab: FormGroup;
  public tabCreateLoader: boolean = false;
  public isRequestError: boolean = false;
  public isRequestFailed: boolean = false;
  public isTabExist: boolean = false;
  public message: string = "";
  public configSettingUrl: SafeUrl;
  public configSetting: any = {};
  public tabConfigSetting: any = {};
  public tabConfiguration: any = {};
  public configSettingListener: any;
  public heightTabConfig: number = 400;

  public iFrameObj: any = null;
  public showSave = true;

  @ViewChild('iFrameTabConfig') iFrameTabConfig: ElementRef;
  @ViewChild('iframeContainer') iframeContainer: ElementRef;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private teamsService: TeamsService,
    public global: GlobalService,
    private toaster: ToastrService,
    private domSanitizer: DomSanitizer,
    private ndvs: NetDocumentViewerService,
    public netDocLocalStorage: NetdocshareLocalstorageService
  ) {
    this.configSettingUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.configSettingUrl + '?tenantId=' + this.global.tenantId);
    //this.configSettingUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("http://localhost:4200/#/config");
    this.global.heightTabConfig.subscribe(data => {
      if (data?.height)
        this.heightTabConfig = data.height + 30
    })
  }

  ngOnInit(): void {
    this.setFormData();
    this.loadConfigSetting();
    this.listenConfigSetting();
    this.listenChildWindow();

    console.log('formTab' + this.formTab);
    /* setInterval(() => {
      //console.log("configSetting===", this.configSetting)

    }, 1000) */
    /* setInterval(() => {
      const configWinElement: HTMLIFrameElement = document.getElementById('configSettingWin') as HTMLIFrameElement;
      const configcontentWin = configWinElement.contentWindow;
      //configcontentWin.document.body.style.overflow = 'hidden';
      console.log(configcontentWin)
    }, 1000) */
  }

  ngAfterViewInit(): void {
    this.iframeContainer && this.iframeContainer.nativeElement.focus();

  }

  public onCloseModal() {
    this.global.isTabEdit = false;
    window.removeEventListener('message', this.configSettingListener);
    this.modalService.dismissAll();
  }

  private setFormData() {
    if (this.global.isTabEdit) {
      this.formTab = this.formBuilder.group({
        tabName: [this.global.selectedTab.DisplayName, Validators.required],
        tabId: [this.global.selectedTab.Id, Validators.required]
      })
    } else {
      this.formTab = this.formBuilder.group({
        tabName: ["", Validators.required],
      })
    }

    this.formTab.controls["tabName"].valueChanges.subscribe(() => {
      if (this.isRequestError || this.isRequestFailed || this.isTabExist) this.onChangeTabName();
    })
  }

  public onChangeTabName() {
    this.isRequestError = false;
    this.isRequestFailed = false;
    this.isTabExist = false;
  }

  public onCreateTab() {
    if (this.isBulkEdit) {
      this.modal?.close('yes');
    }
    if ((this.formTab.status == 'INVALID') || this.configSetting.viewType == '' || (this.configSetting.viewType == 'normalView' && (!this.configSetting.primarySource || (this.configSetting.primarySource && this.configSetting.primarySource.id == ''))) || (this.configSetting.viewType == 'Tree' && this.configSetting.sources && this.configSetting.sources.length == 0) || this.tabCreateLoader) return
    this.tabCreateLoader = true;
    this.isRequestError = false;
    this.isRequestFailed = false;
    this.isTabExist = false;
    this.message = "";
    let sendData = this.formTab.value;
    sendData.connectionId = this.global.connectionData._id;
    sendData.teamId = this.global.selectedGroup.Id;
    sendData.channelId = this.global.selectedChannel.Id;
    sendData.tabName = this.formTab.controls["tabName"].value.trim();
    sendData.configuration = this.tabConfiguration
    this.formTab.controls["tabName"].disable();
    if (!this.global.isTabEdit) {
      let tabExist = this.tabsList.find(element => element.DisplayName.toLowerCase() == sendData.tabName.toLowerCase());
      if (tabExist == undefined || tabExist == null) {
        this.teamsService.createNewTabInDB({ channelId: this.global.selectedChannel.Id, teamId: this.global.selectedGroup.Id, config: this.tabConfigSetting.tabConfig.config, tabName: this.tabConfigSetting.tabConfig.tabName, tabDetail: null }).subscribe(resp => {
          if (resp.status === "success") {
            this.change.emit({ data: JSON.parse(JSON.stringify(resp.data)), isProcessed: false });
            this.toaster.success("Tab successfully created", "Success", { extendedTimeOut: 10 });
            this.global.isTabEdit = false;
            window.removeEventListener('message', this.configSettingListener);
            this.modalService.dismissAll(resp.data);
            this.tabCreateLoader = false;
            this.isRequestError = false;
          } else {
            this.tabCreateLoader = false;
            this.isRequestError = false;
            this.toaster.error("Tab creation failed. " + resp.message, "Failure", { timeOut: 5000 });
          }
        }, (error) => {
          this.tabCreateLoader = false;
          this.isRequestError = true;
        })
      } else {
        this.formTab.controls["tabName"].enable();
        this.message = "Tab name already exist";
        this.isTabExist = true;
        this.tabCreateLoader = false;
      }

    } else {
      sendData.tabId = this.formTab.controls["tabId"].value.trim();
      //let tabExist = this.tabsList.find(element => (element.DisplayName.toLowerCase() == sendData.tabName.toLowerCase() && element.Id != sendData.tabId));
      //if (tabExist == undefined || tabExist == null) {
      this.teamsService.createNewTabInDB({
        ...this.tabConfigSetting.tabConfig, channelId: this.global.selectedChannel.Id, teamId: this.global.selectedGroup.Id, config: this.tabConfigSetting.tabConfig.config, tabName: this.tabConfigSetting.tabConfig.tabName, tabDetail: this.tabDetail
      }).subscribe(resp => {
        if (resp.status === "success") {

          this.toaster.success("Tab data successfully updated", "Success", { extendedTimeOut: 10 });
          this.global.selectedTab.DisplayName = sendData.tabName;
          this.global.isTabEdit = false;
          this.tabCreateLoader = false;
          window.removeEventListener('message', this.configSettingListener);
          this.modalService.dismissAll(this.global.selectedTab);

          /* this.teamsService.updateTab(sendData).subscribe((res) => {
            if (res.status == 'success') {
              this.toaster.success("Tab data successfully updated", "Success", { extendedTimeOut: 10 });
              this.global.selectedTab.DisplayName = sendData.tabName;
              this.global.isTabEdit = false;
              window.removeEventListener('message', this.configSettingListener);
              this.modalService.dismissAll(this.global.selectedTab);
            } else {
              this.formTab.controls["tabName"].enable();
              if (res.message != undefined && typeof (res.message) == "string" && res.message.indexOf("NameAlreadyExists") >= 0) {
                this.message = "Tab name already exist";
                this.isTabExist = true;
              } else {
                this.isRequestFailed = true;
              }
            }
            this.tabCreateLoader = false;
          }, (error) => {
            this.tabCreateLoader = false;
            this.isRequestError = true;
          }) */
        } else {
          this.tabCreateLoader = false;
          this.isRequestError = true;
        }
      }, (error) => {
        this.tabCreateLoader = false;
        this.isRequestError = true;
      })
      /* } else {
        this.formTab.controls["tabName"].enable();
        this.message = "Tab name already exist";
        this.isTabExist = true;
        this.tabCreateLoader = false;
      } */
    }
  }

  loadConfigSetting() {
    const configWinListener = event => {
      try {
        const configWinElement: HTMLIFrameElement = document.getElementById('configSettingWin') as HTMLIFrameElement;
        const configcontentWin = configWinElement.contentWindow;
        if (!this.global.isTabEdit && event.data === "confirm-access-netDocShare-config-new") {
          const teamsTabData = {
            message: "yes-access-netDocShare-config-new", ndToken: this.netDocLocalStorage.ndInfo, teamsData: {
              tenantId: this.global.tenantId, entityId: "",
              tabTemplateData: this.global.selectedTab.tabTemplateData ? this.global.selectedTab.tabTemplateData : null
            }
          };
          console.log("teamsDatafromchild", teamsTabData);
          configcontentWin.postMessage(teamsTabData, "*");
          window.removeEventListener('message', configWinListener);
        } else if (event.data == "confirm-access-netDocShare-config-edit") {
          let entityId = "";
          if (this.global.selectedTab && this.global.selectedTab?.configuration?.entityId) {
            entityId = this.global.selectedTab?.configuration?.entityId
          } else if (this.global.selectedTab && this.global.selectedTab?.configuration?.EntityId) {
            entityId = this.global.selectedTab?.configuration?.EntityId
          } else {
            entityId = "";
          }
          const teamsTabData = { message: "yes-access-netDocShare-config-edit", ndToken: this.netDocLocalStorage.ndInfo, teamsData: { tenantId: this.global.tenantId, entityId: entityId, tabTemplateData: this.global.selectedTab.tabTemplateData ? this.global.selectedTab.tabTemplateData : null } };
          configcontentWin.postMessage(teamsTabData, "*");
          window.removeEventListener('message', configWinListener);
        }
      } catch (e) {
        //console.log(e);
      }
    }

    const newConfigListener = event => {
      if (event.data == "config-setting-update-new") {
        if (this.isBulkEdit) {
          this.showSave = true;           
        }else{
          this.showSave = false;
        }
      }

    }

    window.addEventListener('message', configWinListener);
    window.addEventListener('message', newConfigListener);
  }


  listenConfigSetting() {
    this.configSettingListener = event => {
      try {
        if (event.data && event.data.config) {
          this.configSetting = event.data.config;
          this.tabConfiguration = event.data.tabConfiguration;
          let tabName = event.data.tabName ? event.data.tabName : (this.global.selectedTab && this.global.selectedTab.DisplayName) ? this.global.selectedTab.DisplayName : ''
          if (this.formTab.get('tabName').value !== tabName) this.formTab.get('tabName').setValue(tabName);
          this.tabConfigSetting = {
            "tabConfig": { tabId: event.data.entityId, config: JSON.stringify(this.configSetting), tabName: tabName },
            "tenantId": this.global.tenantId
          }
        }
      } catch (e) {
        //console.log(e);
      }
      this.onConfigChange.emit(this.tabConfigSetting);
    };
    window.addEventListener('message', this.configSettingListener);
  }

  listenChildWindow() {
    const childWinListener = event => {
      try {
        const configWinElement: HTMLIFrameElement = document.getElementById('configSettingWin') as HTMLIFrameElement;
        const configcontentWin = configWinElement.contentWindow;
        if (event.data === "confirm-access-netDocShare-provisioning") {
          let provisioningToolData = {
            licenseKey: this.ndvs.currentUserLicense.clientId,
            clientId: this.ndvs.currentUserLicense.clientId,
            accessToken: this.ndvs.currentUser.access_token,
            refreshToken: this.ndvs.currentUser.refresh_token,
            accessExpiry: this.ndvs.currentUser.access_expiry
          };
          //console.log("provisioningToolData====", provisioningToolData)
          configcontentWin.postMessage({ message: "yes-access-netDocShare-provisioning", provisioningToolData: btoa(JSON.stringify(provisioningToolData)) }, "*");
          window.removeEventListener('message', childWinListener);
        }
      } catch (e) {
        //console.log(e);
      }
    }
    window.addEventListener('message', childWinListener);
  }

}
