import { Component, OnInit, Output, Renderer2, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
import { HelperService } from 'src/app/services/helper';
import { Router } from '@angular/router';

@Component({
  selector: 'tenant-picker',
  templateUrl: './tenant-picker.component.html',
  styleUrls: ['./tenant-picker.component.scss']
})
export class TenantPickerComponent implements OnInit {

  public teamLoading: any = {};
  @Input("loadTeam") set onChangeConnection(data) {
    this.teamLoading = data;
    this.changeRefresh.emit(data);
  };


  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeRefresh: EventEmitter<any> = new EventEmitter<any>();
  public isConnectionListRequested: boolean = false;
  public connectionsList: any = [];
  public showConnectionsList: boolean = false;
  public tenantName: string = "";
  public selectedConnection: any = {};
  public connectionLoader: boolean = false;
  public isAdmin: boolean = HelperService.IS_ADMIN();

  constructor(
    public global: GlobalService,
    private teamsService: TeamsService,
    private renderer: Renderer2,
    private toaster: ToastrService, public router: Router
  ) {
    this.renderer.listen('window', 'click', (event: any) => {
      setTimeout(() => {
        if (event.path.length > 0) {
          let filtredData = event.path.filter(element => element.id == "tenantPicker");
          if (filtredData == undefined || filtredData == null || filtredData.length == 0) this.showConnectionsList = false;;
        } else {
          this.showConnectionsList = false;
        }
      }, 100)
    })
  }

  ngOnInit(): void {
    this.getTeamsConnections();
  }

  public onShowTeamsSetting() {

    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.router.navigateByUrl('/teams-connections')
  }

  public getTeamsConnections() {
    this.isConnectionListRequested = true;
    this.connectionLoader = true;
    this.teamsService.getTeamsConnections().subscribe((resData) => {
      this.isConnectionListRequested = false;
      if (resData != undefined && resData.status == "success" && resData.data != null) {
        this.connectionsList = resData.data;
        var defaultConnection = this.connectionsList.length === 1 ? this.connectionsList[0] : this.connectionsList.find(d => d.isDefault && d._id);
        if (defaultConnection != null) {
          this.global.connectionData = defaultConnection;
          this.global.tenantId = this.global.connectionData.tenantId;
        }
        if (this.global.connectionData._id != undefined) {
          let findConnection = this.connectionsList.find(element => element._id == this.global.connectionData._id);
          if (findConnection != undefined && findConnection != null && findConnection._id != undefined) {
            this.onSelectConnection(findConnection);
            this.selectedConnection.isConnected = true;
          }
        }
        this.connectionLoader = false;
      } else {
        this.connectionsList = [];
      }
    })
  }

  public onShowConnectionsList() {
    this.showConnectionsList = (this.showConnectionsList) ? false : true;
  }

  public onChangeConnectionName(event) {

  }

  onRefreshTeams() {
    this.changeRefresh.emit(true);
  }

  public onSelectConnection(connection) {
    this.selectedConnection = JSON.parse(JSON.stringify(connection));
    this.tenantName = this.selectedConnection.tenantName;
    this.showConnectionsList = false;
    this.onTenantConnect();
  }

  public onTenantConnect() {
    //console.log(this.selectedConnection._id)
    if (this.selectedConnection._id == undefined) {
      this.toaster.error("Tenant not selected", "Failed", { timeOut: 1500 })
      return;
    }
    this.connectionLoader = true;
    this.teamsService.validateTeamsConnection(this.selectedConnection._id).subscribe((resData) => {
      this.connectionLoader = false;
      if (resData.status == "success") {
        this.selectedConnection.isConnected = true;
        this.toaster.success("Tenant connected", "Success", { extendedTimeOut: 10 })
      } else {
        this.selectedConnection.isConnected = false;
        this.toaster.success("Tenant not connected", "Failed", { extendedTimeOut: 10 });
      }
      this.change.emit(this.selectedConnection);
    }, (error) => {
      this.connectionLoader = false;
      this.toaster.success("Tenant connection error", "Failed", { extendedTimeOut: 10 });
      this.change.emit({});
    })
  }

  public onResetConnection() {
    this.tenantName = "";
    this.selectedConnection = {};
    this.global.selectedGroup = {};
    this.global.selectedChannel = {};
    this.global.selectedTab = {}
    this.change.emit(this.selectedConnection);
  }

}
