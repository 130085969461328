<div
  class="form-group row mr-0 ml-0 mb-4"
  *ngIf="syncCheck">
  <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
    <label
      class="form-label"
      for="elementSyncType">
      Sync Type
    </label>
    <div class="help-tip mt-0">
      <div class="tooltip">
        <p>{{toolTip.syncType}}</p>
      </div>
    </div>
  </div>
  <!-- formControlName="optionSync" -->
  <div class="col-sm-8">
    <select
      class="form-control"
      id="elementSyncType"
      [(ngModel)]="syncType"
      (ngModelChange)="onChangeSyncType($event)"
      [attr.disabled]="(createLoader||isNDLoading)?true:null">
      <option
        value
        disabled>
        Select Type
      </option>
       <ng-container *ngFor="let sType of syncTypes">
          <option *ngIf="sType.isVisible" value="{{sType.value}}">
            {{sType.text}}
          </option>
        </ng-container>
    </select>
  </div>
</div>
<div
  class="col-md-12 mb-3 pl-0 pl-lg-2 pr-0"
  *ngIf="syncCheck">
  <div class="nds-outer-box h-100 mh-200 right-content">
    <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
      <div class="pg-heading">Select NetDocuments Location</div>
      <button
        type="button"
        placement="left"
        class="btn btn-sm btn-blue h-100 {{( isNDLoading)? 'show-loader' :''}}"
        [disabled]="((!isNDLoading && ndvsNew.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading) || createLoader)"
        (click)="onRefreshNetDocumentContants()">
        <span
          *ngIf="(!isNDLoading)"
          class="material-icons-sharp md-refresh"></span>
        <img
          *ngIf="(isNDLoading)"
          src="./assets/images/Spinner.gif"
          alt="Loader"
          class="loader-img">
      </button>
    </div>
    <div class="col-md-12 px-0">
      <div #ndFormNav>
        <div class="form-group row mb-2 align-items-center">
          <div class="col-md-12 col-xl-4">
            <div class="clearfix d-flex justify-content-between align-items-center">
              <label class="col-form-label col-label-info">Cabinet</label>
              <div class="help-tip mt-1">
                <div class="tooltip">
                  <p>{{toolTip.ndCabinet}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-xl-8">
            <div class="input-group">
              <div
                class="input-group-append cus-drop w-100 {{(isCabinetLoader) ? 'show-loader' : ''}}"
                ngbDropdown
                #cabinetDropdown="ngbDropdown">
                <input
                  formControlName="selectedCabinetName"
                  type="text"
                  class="form-control"
                  placeholder="Select Cabinet"
                  readonly
                  [(ngModel)]="selectedCabinet.name"
                  ngbDropdownToggle
                  [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                <button
                  class="dropdown-toggle btn btn-blue"
                  ngbDropdownToggle
                  [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                  <img
                    src="./assets/images/Spinner.gif"
                    alt="Loader"
                    class="loader-img">
                </button>
                <div
                  ngbDropdownMenu
                  aria-labelledby="sourceDropdown2"
                  class="form-inline mb-2 dropdownBody {{(cabinets.length==0) ? 'd-none' : ''}}">
                  <div 
                    *ngFor="let cabinet of cabinets"
                    class="form-check context-dropdown-item source-dropdown-item"
                    (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)"
                    ngbDropdownItem>
                    <img
                      src="./assets/images/cabinet.svg"
                      class="thumbnail mr-2">
                    <label class="form-check-label context-dropdown-text">{{cabinet.name}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          aria-label="breadcrumb"
          class="mb-2 border-bottom {{(isNDLoading)|| createLoader ? 'breadcrumb-disabled' : ''}}"
          *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
          <ol class="breadcrumb p-0 border bg-light mb-2">
            <li
              class="breadcrumb-item crumb py-2"
              (click)="!createLoader&&onResetRoot()">
                                        <img
                src="./assets/images/cabinet.svg"
                class="thumbnail mx-2">
                                        <a title="Confidential">{{selectedCabinet.name}}</a></li>
            <li
              *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )"
              class="breadcrumb-item crumb py-2"
              (click)="!createLoader&&onResetWorkspace()">
                                        <img
                src="./assets/images/netdocumentsIcons/workspace.svg"
                class="thumbnail mx-2">
                                        <a title="Confidential">{{selectedWorkSpace.name}}</a>
                                    </li>
            <li
              class="breadcrumb-item crumb py-2"
              *ngFor="let folder of ndvsNew.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index"
              (click)="!createLoader&&onLoadBreadCurmp(folder,breadcurmbIndex)">
                                        <img
                src="./assets/images/netdocumentsIcons/folder_nd.svg"
                class="thumbnail mx-2">
                                        <a title="Confidential">{{folder.Attributes.Name}}</a>
                                    </li>
          </ol>
        </nav>
      </div>
      <div class="file-grid search-results">
        <div class="d-flex justify-content-between">
          <div
            *ngIf="( !isWorkspaceLoader && ndvsNew.syncJobSetting.netDocuments.breadCurmb.length==0)"
            class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
            (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')"
            title="{{selectedWorkSpace.name}}">
            {{selectedWorkSpace.name}}
          </div>
          <div
            *ngIf="( !isWorkspaceLoader && ndvsNew.syncJobSetting.netDocuments.breadCurmb.length>0)"
            class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
            (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvsNew.syncJobSetting.netDocuments.breadCurmb[(ndvsNew.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)"
            title="{{ndvsNew.syncJobSetting.netDocuments.breadCurmb[(ndvsNew.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}">
            {{ndvsNew.syncJobSetting.netDocuments.breadCurmb[(ndvsNew.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}
          </div>
          <div></div>
        </div>
        <div class="d-flex justify-content-between mb-2 mt-2">
          <!-- formControlName="cabinetSearch" -->
          <div
            *ngIf="(ndvsNew.syncJobSetting.netDocuments.cabinetId!='' &&ndvsNew.syncJobSetting.netDocuments.cabinetId!=undefined && ndvsNew.syncJobSetting.netDocuments.workspaceId=='' && ndvsNew.syncJobSetting.netDocuments.folderId=='')"
            class="pg-heading input-group fs-16">
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              [(ngModel)]="cabinetSearch"
              (input)="onChangeCabinetSearch()"
              (keypress)="onSearchKeyPress($event)"
              [attr-disabled]="createLoader?true:null">
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-blue"
                (click)="onSearchWorkspace()"
                [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading|| createLoader)">
                <i class="material-icons-sharp md-search"></i>
              </button>
            </div>
          </div>

        </div>

        <div class="d-flex nd-file-manger">
          <div
            class="row"
            *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
            <div class="col-md-12 text-center my-3">Cabinet or folders not found</div>
          </div>
          <div
            class="{{'border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover'}}"
            *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))"
            (scroll)="onNDScroll($event)">

            <div
              #ndBlocker
              *ngIf="(isNDLoading)"
              class="container-blocker">
              <div class="text-center w-100">
                <span>
                                            Loading...
                                            <img
                    src="./assets/images/Spinner.gif"
                    class="loadingIcon">
                                            </span>
              </div>
            </div>
            <div
              class="col-lg-6 mb-3"
              *ngFor="let wSpace of workSpaces|filterWorkspacename:cabinetSearch">
              <div class="d-flex">
                <div
                  class="d-flex align-items-start"
                  (click)="!createLoader&&onSelectWorkSpace(wSpace)">
                  <img
                    src="./assets/images/netdocumentsIcons/workspace.svg"
                    class="thumbnail mr-2">
                  <a
                    title="{{wSpace.name}}"
                    class="fs-14 text-dark text-decoration-none">
                    {{wSpace.name}}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mb-3"
              *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:cabinetSearch">
              <div class="d-flex">
                <div
                  class="d-flex align-items-start"
                  (click)="!createLoader&&onSelectCabinetFolder(cabinetFolder)">
                  <img
                    src="./assets/images/netdocumentsIcons/folder_nd.svg"
                    class="thumbnail mr-2">
                  <a
                    title="{{cabinetFolder.Attributes.Name}}"
                    class="fs-14 text-dark text-decoration-none">
                    {{cabinetFolder.Attributes.Name}}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mb-3"
              *ngFor="let content of workSpaceContents">
              <div class="d-flex">
                <input
                  *ngIf="(ndvsNew.syncJobSetting.syncType=='netDocumentsUni' || ndvsNew.syncJobSetting.syncType=='netDocumentsUniOd' )"
                  type="checkbox"
                  class="mt-1 mr-2"
                  (click)="onChooseNDContent($event,content)"
                  [checked]="content.isChecked"
                  [disabled]="content.isRestricted|| createLoader">
                <div
                  class="d-flex align-items-start"
                  (click)="!createLoader&&onSelectContent(content)">
                  <img
                    src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}"
                    class="thumbnail mr-2">
                  <a
                    title="{{content.Attributes.Name}}"
                    class="fs-14 text-dark text-decoration-none">
                    {{content.Attributes.Name}}
                  </a>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="((!isFolerLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined || (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined)))">
              <div class="col-md-12 text-center my-3">Folders or Files not found</div>
            </div>
          </div>
          <div
            *ngIf="folderCreationName!=''"
            class="mt-4">
            <div *ngIf="folderCreationErrorMessage.status==409">
              <div class="red">
                The folder already contains subfolder '{{this.folderCreationName}}'. Create a new folder or choose another location.
              </div>
              <div class="form-group row mr-0 ml-0 mt-3">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center pl-0">
                  <label class="form-label">New Folder Name</label>
                </div>
                <div class="col-sm-8 pr-0">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="newFolderName"
                    placeholder="Give a new folder name"
                    [attr.disabled]="createLoader?true:null">
                </div>
              </div>
            </div>
            <div *ngIf="folderCreationErrorMessage.status==403">
              <div class="red">
                You don't have rights to create folder in the selected location.
              </div>
            </div>
            <div *ngIf="folderCreationErrorMessage.status!=403&&folderCreationErrorMessage.status!=409">
              <div class="red">Error in creating folder.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
