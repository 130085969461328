export const environmentOld = {
  production: false,
  serviceRootUrl: "https://provisioningbeta.netdocshare.com",
  appUrl: "https://provisioningbeta.netdocshare.com",
  serviceUrl: "https://provisioningbeta.netdocshare.com/service/v1/",
  authSuffix: "https://provisioningbeta.netdocshare.com/service/v1/auth",

  configSettingUrl: "https://klobotappsdev.azurewebsites.net/apps/netdocshare/#/config",
  netDocShareServiceUrl: "https://klobotappsdev.azurewebsites.net/apps/netdocshareservice/",
  syncUrl: "https://syncbeta.netdocshare.com",

  provisioningServiceUrl: "https://netdocshare-provbeta.azurewebsites.net/",
  syncServiceUrl: "https://syncbeta.netdocshare.com/service/v1/api",
  teamsServiceUrl: "https://provisioningbeta.netdocshare.com/api/v1.0/",

  ndSiteURLEU: "https://eu.netdocuments.com/neWeb2/",
  ndSiteURLDE: "https://de.netdocuments.com/neWeb2/",
  ndSiteURLAU: "https://au.netdocuments.com/neWeb2/",
  ndSiteURLUS: "https://vault.netvoyage.com/neWeb2/",
  siteKey: "6LflF6MaAAAAAB9rlWFxCFOmEgUlWQtMMycT_kT4",
  APP_PROFILE: "admin:no-key",
  teamAppId: "f1a2add5-d007-4062-a10a-60f4ea29fdbc"
};


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceRootUrl: "https://provisioningbeta.netdocshare.com",
  appUrl: "https://provisioningbeta.netdocshare.com",

  serviceUrl: "https://provisioningbeta.netdocshare.com/service/v2/",
  // serviceUrl: "http://localhost:52398/",
  spserviceUrl: "https://klobotappsdev.klobot.ai/apps/beta/proxies/netdocshare/v1",
  //spserviceUrl: "https://klobotappsdev.klobot.ai/apps/beta/proxies/imdocshare/v3",
  authSuffix: "https://provisioningbeta.netdocshare.com/service/v2/auth",

  configSettingUrl: "https://klobotappsdev.klobot.ai/apps/beta/netdocshare/teamsapp/#/config",
  //configSettingUrl: "http://localhost:4200/#/config", 
  //netDocShareServiceUrl: "https://klobotappsdev.klobot.ai/apps/beta/netdocshare/srvc/",
  netDocShareServiceUrl: "https://klobotappsdev.klobot.ai/apps/beta/netdocshare/service/v1/",

  provisioningServiceUrl: "https://netdocshare-provbeta.azurewebsites.net/",//Not used in the application

  syncUrl: "https://syncbeta.netdocshare.com",
  //syncUrl: "http://localhost:4300",
  syncServiceUrl: "https://syncbeta.netdocshare.com/service/v2/api",

  teamsServiceUrl: "https://provisioningbeta.netdocshare.com/api/v2.0/",
  /* teamsServiceUrl: "http://localhost:54420/", */

  ndSiteURLEU: "https://eu.netdocuments.com/neWeb2/",
  ndSiteURLDE: "https://de.netdocuments.com/neWeb2/",
  ndSiteURLAU: "https://au.netdocuments.com/neWeb2/",
  ndSiteURLUS: "https://vault.netvoyage.com/neWeb2/",
  siteKey: "6LflF6MaAAAAAB9rlWFxCFOmEgUlWQtMMycT_kT4",
  APP_PROFILE: "admin:9f5f0044-05b3-43e2-a74a-546deeac1477",
  teamAppIdOld: "f1a2add5-d007-4062-a10a-60f4ea29fdbc",
  teamAppId: "f76ad98c-7ffd-4d93-9930-3420e9aeea98"
};
// export const environment = {
//   production: false,
//   serviceRootUrl: "https://provisioningbeta.netdocshare.com",
//   appUrl: "https://provisioning-stg.netdocshare.com",
//   serviceUrl: "https://provisioning-stg.netdocshare.com/service/v2/",
//   spserviceUrl: "https://klobotappsstg.klobot.ai/apps/stage/proxies/netdocshare/v1",
//   authSuffix: "https://provisioningbeta.netdocshare.com/service/v1/auth",

//   configSettingUrl: "https://klobotappsstg.klobot.ai/apps/stage/netdocshare/#/config",
//   netDocShareServiceUrl: "https://klobotappsstg.klobot.ai/service/v1",
//   syncUrl: "https://sync-stg.netdocshare.com",

//   syncServiceUrl: "https://sync-stg.netdocshare.com/service/v9/api",
//   teamsServiceUrl: "https://provisioning-stg.netdocshare.com/api/v1.0/",

//   ndSiteURLEU: "https://eu.netdocuments.com/neWeb2/",
//   ndSiteURLDE: "https://de.netdocuments.com/neWeb2/",
//   ndSiteURLAU: "https://au.netdocuments.com/neWeb2/",
//   ndSiteURLUS: "https://vault.netvoyage.com/neWeb2/",
//   siteKey: "6LflF6MaAAAAAB9rlWFxCFOmEgUlWQtMMycT_kT4",
//   APP_PROFILE: "admin:no-key",
//   teamAppId: "dd7c6bc6-22f5-4242-9761-54aece35b5e5"
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
