

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bulk-config-update',
  templateUrl: './bulk-config-update.component.html',
  styleUrls: ['./bulk-config-update.component.scss']
})

export class BulkConfigUpdateComponent implements OnInit {

  @ViewChild('search') searchElement: ElementRef;

  public selectedFile: any = {};
  public fileError: string = "";
  public isUploadProcess: boolean = false;
  public excelProcess: boolean = false;
  public excelData: any = [];
  //public statusColor: any = { Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber' }
  public syncChange: any = null;
  public teams: any = "";
  public jsonData = null;
  public validJsonData = [];
  public bulkUploadProcess = false;

  public processIndex = 0;
  public showStatusProgress = false;
  public processComplete = false;

  public downloadProgress = false;
  public uniqueTable = [];
  public teamsList = [];
  public showTeamsList = false;
  public teamName: string = "";
  public selectedTeam: any = {}
  public teamsLoader: Boolean = true;

  constructor(
    public excelService: ExcelService,
    public router: Router,
    public ndvs: NetDocumentViewerService,
    public teamsService: TeamsService,
    private modalService: NgbModal,
    public global: GlobalService,
    public toaster: ToastrService
  ) { }

  ngOnInit(): void {
    if (!this.global.connectionData._id) this.router.navigateByUrl('/provisioning');

    this.teamsLoader = true;
    this.teamsService.getAllTeamsGroups(this.global.connectionData._id).subscribe((resData) => {
      if (resData.status == "success") {
        if (resData.data != undefined && resData.data.length > 0) {
          this.teamsList = resData.data;
          this.teamsList.sort(this.compare);
        }
      } else {
        this.toaster.success("Can not able to fetch teams list", "Failed", { timeOut: 1000 });
      }

      this.teamsLoader = false;
    }, (error) => { });
  }

  public compare(a, b) {
    if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
      return -1;
    }
    if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  public goHome() {
    this.router.navigateByUrl("/provisioning");
  }

  public selectTeam(data) {
    this.selectedTeam = data;
    this.teamName = this.selectedTeam.displayName;
    //console.log(this.selectedTeam)
    this.showTeamsList = false;
  }

  public onShowTeamsList() {
    this.showTeamsList = !this.showTeamsList;
  }


  public onChangeTeamName(data) {
    this.selectedTeam = {};
    if (this.teamName != "") this.showTeamsList = true;
  }

  public onTeamNameKeypress(event) {
    if (event.keyCode == 13) {
      let currentListTop = this.teamsList.find(team => (team.DisplayName.toLowerCase().indexOf(this.teamName.toLocaleLowerCase()) >= 0));
      if (currentListTop != undefined && currentListTop != null) {
        this.selectTeam(currentListTop);
      }
    }
  }


  public onChooseFile() {
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
  }

  public reUpload() {
    if (this.bulkUploadProcess) return;
    this.excelData = [];
    this.uniqueTable = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
    this.validJsonData = [];
    this.showStatusProgress = false;
    this.processComplete = false;
    this.setProcessComplete();
  }

  public onFileDragOver(event) {
    event.preventDefault();
  }

  public onFileDropSuccess(event) {
    event.preventDefault();
    this.uploadSettings(event.dataTransfer.files);
  }

  public onBrowseFile(element) {
    element.click()
  }

  public onFileChange(event) {
    this.uploadSettings(event.target.files);
  }

  private uploadSettings(fileObject) {
    this.excelData = [];
    this.validJsonData = [];
    this.jsonData = null;
    if (fileObject.length > 1) {
      this.fileError = "Not supported for multiple files";
      return;
    } else {
      let fileExt = fileObject[0].name.split(".").pop();
      let allowedFiles: any = ["xls", "xlsx", "csv"];
      if (allowedFiles.indexOf(fileExt.toLowerCase()) == -1) {
        this.fileError = "Invalid file";
        return;
      } else {
        this.fileError = "";
      }

    }
    this.selectedFile = fileObject[0];
  }


  public showBulkUpdateStatus(popUp) {
    this.modalService.open(popUp, { scrollable: true, size: "lg" }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onValidateSettings() {
    if (((this.selectedFile.name == undefined || this.selectedFile.name == null || this.selectedFile.name == "") && !this.isUploadProcess)) return;

    this.excelProcess = true;
    this.isUploadProcess = true;
    let workBook = null;
    // let jsonData = null;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      if (this.jsonData.Sheet1?.length > 0) {
        this.jsonData.Sheet1.forEach((element, index) => {
          element.row = index + 1;
          this.excelData.push(element);
          let tmp = this.uniqueTable.filter(i => i.IDRef == element.IDRef);
          if (tmp.length != 0) {
            element.TeamName = tmp[0].TeamName;
            element.TeamDescription = tmp[0].TeamDescription;
            element.TeamPrivacy = tmp[0].TeamPrivacy;
          } else {
            this.uniqueTable.push(element)
          }

          let teamPrivacy = element.TeamPrivacy?.toLowerCase();
          element.TeamPrivacy = teamPrivacy;

          let channelPrivacy = element.ChannelPrivacy?.toLowerCase();
          element.ChannelPrivacy = channelPrivacy;

          element.index = index;

          this.validateSPBulkData(element);
        });
        this.validJsonData = this.jsonData.Sheet1.filter(d => !d.isDataInvalid);
        this.processComplete = false;
        this.showStatusProgress = true;
      } else {
        this.fileError = "File have no records";
      }
      this.isUploadProcess = false;
      this.excelProcess = false;
    }
    reader.readAsBinaryString(this.selectedFile);
    this.selectedFile = {}
  }

  public startBulkUpload(e) {
    if (e != null) {
      if (this.bulkUploadProcess || this.processComplete) return;
    }
    this.bulkUploadProcess = true;
    //var index = this.excelData.findIndex(e => e.TeamID == this.validJsonData[this.processIndex].TeamID && e.ChannelID == this.validJsonData[this.processIndex].ChannelID && e.TabName == this.validJsonData[this.processIndex].TabName);
    var index = this.validJsonData[this.processIndex].index;
    this.excelData[index] = { ...this.excelData[index], configStatus: "Processing...", cStatus: "progress" }
    this.teamsService.uploadTemplateConfig({ "templateConfigData": [this.validJsonData[this.processIndex]] }).subscribe((resData) => {
      if (resData.data == undefined || resData.data == null) {
        this.excelData[index] = { ...this.excelData[index], configStatus: "Not updated", cStatus: "fail" }
      } else if (resData.data.status == undefined || resData.data.status == null || resData.data.status.trim() == "") {
        this.excelData[index] = { ...this.excelData[index], configStatus: "Not updated", cStatus: "fail" }
      } else {

        this.excelData[index] = { ...this.excelData[index], configStatus: resData.data.data[0].configStatus, cStatus: resData.data.data[0].configStatus.toLowerCase() == "updated" ? "pass" : "fail" }

      }
      if (this.processIndex == this.validJsonData.length - 1) {
        this.setProcessComplete();
      } else {
        this.processIndex++;
        this.startBulkUpload(null);
      }
    }, (error) => {
      this.bulkUploadProcess = false;
    })
  }

  public statusColor(status) {
    //Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber'
    return status == "pass" ? "text-success" : status == "progress" ? "text-amber" : "text-danger";
  }

  public setProcessComplete() {
    this.processIndex = 0;
    this.bulkUploadProcess = false;
    this.processComplete = true;
    this.uniqueTable = [];
  }

  private validateSPBulkData(jobData) {
    jobData.reason = "";
    jobData.isDataInvalid = true;
    jobData.dataProcessStatus = "Not Processed";

    jobData.TeamName = jobData.TeamName?.toString();
    jobData.TeamID = jobData.TeamID?.toString();
    jobData.ChannelName = jobData.ChannelName?.toString();
    jobData.ChannelID = jobData.ChannelID?.toString();
    jobData.TabName = jobData.TabName?.toString();
    jobData.TabID = jobData.TabID?.toString();
    jobData.TabConfig = jobData.TabConfig?.toString();


    //VALIDATE TEAM Name
    if (jobData.TeamName == undefined || jobData.TeamName == null || jobData.TeamName == "" || jobData.TeamName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Name field missing";
    }

    //VALIDATE TEAM Description
    if (jobData.TeamID == undefined || jobData.TeamID == null || jobData.TeamID == "" || jobData.TeamID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Id field missing";
    }

    //VALIDATE CHANNEL Name
    if (jobData.ChannelName == undefined || jobData.ChannelName == null || jobData.ChannelName == "" || jobData.ChannelName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Name field missing";
    }

    //VALIDATE Channel Description
    if (jobData.ChannelID == undefined || jobData.ChannelID == null || jobData.ChannelID == "" || jobData.ChannelID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Id field missing";
    }

    //VALIDATE Tab Name
    if (jobData.TabName == undefined || jobData.TabName == null || jobData.TabName == "" || jobData.TabName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Tab Name field missing";
    }


    //VALIDATE Tab Name
    if (jobData.TabID == undefined || jobData.TabID == null || jobData.TabID == "" || jobData.TabID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Tab Id field missing";
    }

    //VALIDATE Tab Config
    if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "")) {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "TabConfig field missing";
    }


    if (jobData.reason == "") {
      jobData.isDataInvalid = false;
      jobData.reason = "-";
    }

  }


  public openTabConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public openSyncConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public copyTabConfig() {
    var copyText = document.getElementById("tab-config");
    navigator.clipboard.writeText(copyText.textContent);
  }

  public copySyncConfig() {
    var copyText = document.getElementById("sync-config");
    navigator.clipboard.writeText(copyText.textContent);
  }


  public templateConfigExport(e) {
    if (this.downloadProgress || this.teamsLoader || !this.selectedTeam.id) return;
    this.downloadProgress = true;
    this.teamsService.saveTemplateConfig({ teamId: this.selectedTeam.id, teamName: this.selectedTeam.displayName }).subscribe((res) => {
      if (res.data.status == "success") {
        this.teams = res.data.teams;
        if (JSON.parse(this.teams).length == 0) {
          this.excelService.exportAsExcelFile([
            {
              "No Tab is created in this team.": ""
            }
          ], 'Template_Config');
        } else {
          this.excelService.exportAsExcelFile(JSON.parse(this.teams), 'Template_Config');
        }


        this.downloadProgress = false;
      } else {
        this.downloadProgress = false;
      }
    })
  }

  public toFirstLetterCase(str) {
    if (str == null || str == undefined || str.trim() == "") return "";
    //return str.replace(/\w\S*/g, function (txt) {
    return str.charAt(0).toUpperCase() + str.substr(1);
    //});
  }

  public clearTeamSelection() {
    this.teamName = "";
    this.searchElement.nativeElement.focus();
  }

}
