<div class="page-title-box">
  <div class="d-md-flex page-title-left justify-content-center">
    <div class="col-auto navbar navbar-default nds-login-logo">
      <img
        src="./assets/images/netDocShare-logo.svg"
        alt="Logo"
        class="nds-logo"
        (click)="logoClick()">
      <h4 class="header-title">netDocShare Provisioning</h4>
    </div>
    <!-- <div class="align-items-center d-flex justify-content-md-center mx-4 my-2 page-title-content">
            <svg *ngIf="(netDocService.pageTitle=='SharePoint Connection Settings')" class="side-bar-list-item-icon" style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">    <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 6 L 19 8 L 19 10 L 19 12 C 20.034094 12 20.94693 11.477652 21.486328 10.681641 C 22.25202 11.121532 22.88107 11.751461 23.320312 12.517578 A 3 3 0 0 0 22 15 A 3 3 0 0 0 23.320312 17.484375 C 22.881099 18.249829 22.251524 18.878753 21.486328 19.318359 C 20.94693 18.522348 20.034094 18 19 18 L 19 20 L 19 22 L 19 24 C 20.544077 24 21.800676 22.829821 21.966797 21.330078 C 23.437835 20.636449 24.627916 19.448062 25.324219 17.978516 A 3 3 0 0 0 28 15 A 3 3 0 0 0 25.322266 12.019531 C 24.625803 10.551069 23.437048 9.3631801 21.966797 8.6699219 C 21.800676 7.1701794 20.544077 6 19 6 z M 10.435547 9.9980469 C 11.811547 9.9980469 12.520609 10.243141 12.849609 10.369141 L 12.849609 12.386719 C 12.687609 12.274719 11.811641 11.712891 10.556641 11.712891 C 9.0096406 11.712891 8.9316406 12.540453 8.9316406 12.689453 C 8.9316406 14.309453 13.275391 13.920188 13.275391 17.117188 C 13.275391 18.849188 12.0845 20.001953 9.4375 20.001953 C 7.9345 20.001953 7.0296875 19.642891 6.6796875 19.462891 L 6.6796875 17.304688 C 7.0706875 17.632687 7.94575 18.287109 9.34375 18.287109 C 9.61775 18.287109 10.980469 18.248547 10.980469 17.310547 C 10.980469 15.615547 6.6367188 16.026094 6.6367188 12.871094 C 6.6367188 11.549094 7.4615469 9.9980469 10.435547 9.9980469 z"/></svg>
            <svg *ngIf="(netDocService.pageTitle=='OneDrive Connection Settings')" class="side-bar-list-item-icon" style="margin-top: -2px;" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 13 5 C 11.189 5 9.6182344 5.9720625 8.7402344 7.4140625 C 8.2122344 7.1570625 7.627 7 7 7 C 4.791 7 3 8.791 3 11 C 3 11.36 3.0633438 11.703156 3.1523438 12.035156 C 1.9383437 12.206156 1 13.238 1 14.5 C 1 15.881 2.119 17 3.5 17 L 4.0507812 17 C 4.0317812 16.834 4 16.671 4 16.5 C 4 14.439 5.3931094 12.697016 7.2871094 12.166016 C 8.0631094 9.7520156 10.331 8 13 8 C 14.322 8 15.56975 8.426125 16.59375 9.203125 C 17.01475 9.078125 17.453437 9.0168125 17.898438 9.0078125 C 17.438437 6.7228125 15.421 5 13 5 z M 13 10 C 10.791 10 9 11.791 9 14 C 9 14.018 9.0058594 14.033781 9.0058594 14.050781 C 8.8428594 14.017781 8.673 14 8.5 14 C 7.119 14 6 15.119 6 16.5 C 6 17.881 7.119 19 8.5 19 L 21 19 C 22.105 19 23 18.105 23 17 C 23 15.895 22.105 15 21 15 C 20.935 15 20.874547 15.013531 20.810547 15.019531 C 20.926547 14.700531 21 14.36 21 14 C 21 12.343 19.657 11 18 11 C 17.32 11 16.698312 11.235187 16.195312 11.617188 C 15.466312 10.641187 14.312 10 13 10 z"/></svg>
            <i *ngIf="(netDocService.pageTitle=='Scheduled Jobs')" class="material-icons-sharp md-description"></i>
            <i *ngIf="(netDocService.pageTitle=='Create Jobs')" class="material-icons-sharp md-add"></i>
            <span class="page-title-name">{{netDocService.pageTitle}}</span><span *ngIf="(netDocService.pageTitle.toLocaleLowerCase()=='edit job' && netDocService.syncJobId!='')" class="page-title-name"> ({{netDocService.syncJobId}})</span>
        </div> -->
  </div>
  <div class="col-auto page-title-right ml-auto">
    <div
      ngbDropdown
      placement="bottom-right"
      class="col-12 d-inline-block text-right">
      <a
        class="userLink"
        style="position: relative;"
        ngbDropdownToggle
        id="dropdownBasic1">
        Hi {{(netDocService.currentUser!='userDropdown' &&
                netDocService.currentUser.displayName!=undefined) ? netDocService.currentUser.displayName :'User'}}
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1">
        <button
          ngbDropdownItem
          (click)="logout()">
          Log out
        </button>
      </div>
    </div>

    <div class="col-12 text-right">
      <span class="pr-4 pr-md-0">
                
        
        
        
        
        
        
        
        <!-- <i
          *ngIf="(isAdmin)"
          class="cp"
          (click)="onShowTeamsSetting()"><img
            src="./assets/images/icons/setting-header-icon.svg"
            class="page-title-icon"></i> -->
                
        
        
        
        
        
        
        
        <!-- <span
          *ngIf="(isAdmin)"
          class="mx-3">|</span> -->
                <i class="material-icons-sharp md-corporate_fare page-title-icon"></i> {{netDocService.currentUserLicense.client}}</span>
      |
      <span class="pr-4 pr-md-0 fs-14">
                <i class="align-text-bottom fs-16 material-icons-sharp md-verified pl-3 text-success"></i> {{(netDocService.currentUserLicense.licenseType=="standard") ? 'Standard' : 'Enterprise'}}
                </span>
    </div>
    <!-- <div style="text-align: right;
    padding-right: 15px;
    margin-top: 5px;
    color: red;">LIMITED PILOT RELEASE ONLY</div> -->
  </div>
</div>
<!-- <div *ngIf="(netDocService.licenseExpOn!=null && netDocService.licenseExpOn!='' && netDocService.isLicWarnShow)" class="px-30 my-3 license-experience justify-content-center" >
    <button *ngIf="(!netDocService.isLicenseExpired)" class="close-btn" (click)="onCloseWarrning()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/></svg>
    </button>
    <div class="col-sm-12 text-center px-0">
        <div *ngIf="(netDocService.isLicenseExpired)" class="alert alert-danger px-5 py-2 mb-0" role="alert">
            Your license has expired on {{netDocService.licenseExpOn}}. Please contact <a href="mailto:licensing@netdocshare.com">licensing@netdocshare.com </a> 
        </div>                     
        <div *ngIf="(!netDocService.isLicenseExpired)" class="alert alert-danger px-5 py-2 mb-0" role="alert">
            Your license will expire on {{netDocService.licenseExpOn}}. Please contact <a href="mailto:licensing@netdocshare.com">licensing@netdocshare.com </a>
        </div>                    
    </div>
</div> -->
<div
  class="loader-container"
  *ngIf="showLoader">
  <div class="loader"></div>
</div>
