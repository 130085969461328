
<div class="sync-popup custom-scrollbar" *ngIf="!creationFromTeam">
  <div class="sync-popup-container sync-popup-sm" style="position: relative;">
    <!--  -->
    
    <div class="sync-popup-header">
      {{global.isTabEdit ? 'Edit Tab' : 'Add New Tab' }}
      <button [disabled]="tabCreateLoader" class="im-btn-link" (click)="onCloseModal()">
        <img src="./../../../assets/images/icons/modal-close.svg">
      </button>
    </div>    
    <div  #iframeContainer tabindex="1" style="outline: none;">
      <div class="iframe-holder"> 
        <!-- <div class="iframe-holder-loader">
          <div class="loader"></div>
        </div> -->
        <div class="iframe-div" >
          <iframe [src]="configSettingUrl" id="configSettingWin"  width="580" frameBorder="0" height="{{heightTabConfig}}"
           >
          </iframe>
        </div>
      </div>
      <div *ngIf="(isRequestError)">
        Network or Server issue, try again later.
      </div>
      <div *ngIf="(isTabExist)">{{message}}</div>
    </div>
  
    <div class="sync-popup-footer">
      <button class="btn im-primary-outline-btn" [disabled]="tabCreateLoader" (click)="onCloseModal()">
        {{!showSave?"Close":"Cancel"}}
      </button>  
      
      <button *ngIf="showSave" class="btn im-primary-btn"    
        [disabled]="(formTab.status=='INVALID'&&!isBulkEdit) || configSetting.viewType == '' || (configSetting.viewType=='normalView' && (!configSetting.primarySource || (configSetting.primarySource && configSetting.primarySource.id ==''))) 
            || (configSetting.viewType=='Tree' && configSetting.sources && configSetting.sources.length==0)||tabCreateLoader" (click)="onCreateTab()">
        {{(global.isTabEdit) ? 'Save' : 'Create'}}
        <img *ngIf="tabCreateLoader" src="assets/images/Spinner.gif" class="search-button-img">
      </button>
    </div>   
  </div>
</div>
<div *ngIf="creationFromTeam">
  <div class="iframe-holder">
    <div class="iframe-holder-loader">
      <div class="loader"></div>
    </div>
    <div class="iframe-div">
      <iframe [src]="configSettingUrl" id="configSettingWin" frameBorder="0" width="480" height="{{heightTabConfig}}"
        #iFrameTabConfig>
      </iframe>
    </div>
  </div>
  <div *ngIf="(isRequestError)">
    Network or Server issue, try again later.
  </div>
  <div *ngIf="(isTabExist)">{{message}}</div>
</div>