import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input("appConfig") public appConfig: any = {};
  @Input("name") set onSetIconName(data: any) {
    this.setIcon(data)
  }
  @Input("customClass") public customClass: string = "";
  @Input("width") public width: number = 20;
  @Input("height") public height: number = 20;

  @Output() onLoadImage: EventEmitter<any> = new EventEmitter<any>();

  public iconUrl: any;

  constructor(public globalSrvc: GlobalService) {

  }

  setIcon(icon: string) {
    icon = icon?.toLowerCase();
    let iconName = this.icons[icon] ? this.icons[icon] : this.icons.file;
    if (this.globalSrvc.isDevMode) this.iconUrl = `${this.globalSrvc.endWithSlash(this.appConfig.assetPath)}icons/${iconName}`;
    else if (this.appConfig.assetPath && this.appConfig.assetPath.indexOf(".klobot.ai") > -1) this.iconUrl = `${environment.appUrl}/assets/icons/${iconName}`;
    else if (this.appConfig.assetPath && this.appConfig.assetPath.indexOf("/SiteAssets/netDocShare/folderIcons") > -1) this.iconUrl = `${this.appConfig.assetPath.replace("folderIcons", "")}assets/${iconName}`;
    else if (this.appConfig.assetPath) this.iconUrl = `${this.globalSrvc.endWithSlash(this.appConfig.assetPath)}${iconName}`;
    else if (this.appConfig.altAssetPath) this.iconUrl = `${this.globalSrvc.endWithSlash(this.appConfig.altAssetPath)}${iconName}`;
  }

  public icons: any = {
    advanced_search: "advanced-search.svg",
    cabinet: "cabinet.svg",
    workspace: "workspace.svg",
    folder: "folder.svg",
    folder_open: "folder-open.svg",
    filtersearch: "filter.svg",
    savedsearch: "saved-search.svg",
    collabspace: "collabspace.svg",
    sharedspace: "sharespace.svg",
    ndws: "workspace.svg",
    ndfld: "folder.svg",
    ndflt: "filter.svg",
    ndsq: "saved-search.svg",
    ndcs: "collabspace.svg",
    ss: "sharespace.svg",
    doc: "word.svg",
    docx: "word.svg",
    ppt: "power-point.svg",
    pptx: "power-point.svg",
    xls: "excel.svg",
    xlsx: "excel.svg",
    csv: "excel.svg",
    pdf: "pdf.svg",
    eml: "email.svg",
    msg: "email.svg",
    file: "file.svg",
    favorite: "favorite.svg",
    not_favorite: "not-favorite.svg",
    more: "more.svg",
    netdocshare_animated_logo: "netdocshare-animated-logo.gif",
    netdocshare_logo: "netdocshare-logo.svg",
    netdocshare_brand_logo: "netdocshare-brand-logo.svg",
    spinner: "spinner.svg",
    sync: "sync.svg",
    sync_blue: "sync-blue.svg",
    export: "export.svg",
    filter_on: "filter-on.svg",
    filter_off: "filter-off.svg",
    refresh: "refresh.svg",
    download: "download.svg",
    post: "post.svg",
    summary_view: "summary-view.svg",
    list_view: "list-view.svg",
    expand: "expand.svg",
    margin_notes: "margin-notes.svg",
    external_user: "external-user.svg",
    checkin: "checkin.svg",
    checkedout_me: "checkedout-me.svg",
    checkedout_other: "checkedout-other.svg",
    clientmatter: "cabinet.svg",
    add: "add.svg",
    expand_item: "expand-item.svg",
    expand_all: "expand-all.svg",
    collapse_all: "collapse-all.svg",
    collapse_item: "collapse-item.svg",
    clipboard: "clipboard.svg",
    locked: "locked.svg",
    co_author: "co-author.svg",
    times: "times.svg",
    calendar: "calendar.svg",
    left_chevron: "left-chevron.svg",
    right_chevron: "right-chevron.svg",
    search: "search.svg",
    success: "success.svg",
    waiting: "waiting.svg",
    in_progress: "in-progress.svg",
    error: "error.svg",
    create_folder: "create-folder.svg",
    cloud: "cloud.png",
    refresh_black: "refresh-black.svg",
    mp4: "video.svg",
    mkv: "video.svg",
    mov: "video.svg",
    gif: "video.svg",
    avi: "video.svg",
    wmv: "video.svg",
    mpg: "video.svg",
    mpeg: "video.svg",
    m2v: "video.svg",
    webm: "video.svg",
    flv: "video.svg",
    nsv: "video.svg",
    jpg: "image.svg",
    jpeg: "image.svg",
    png: "image.svg",
    svg: "image.svg",
    checkmark: "checkmark.svg",
    export_blue: "export-blue.svg",
    print: "print.svg",
    print_blue: "print-blue.svg",
    open_new_window: "open-new-window.svg",
    edit: "edit.svg",
    save: "save.svg",
    netdocuments_logo: "netdocuments-logo.png",
    recent: "doc-clock.svg"
  };

}
