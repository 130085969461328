<div class="main-container">
  <div class="inner-container grey-bg middle-section-height">
    <div class="sec-1 col-12 z-depth-1 rounded-2 bg-white">
      <div class="ip-container-header border-bottom p-2 d-flex justify-content-between align-items-center">
        <b class="ip-container-header-title">Microsoft Teams</b>
        <div class="text-right ml-auto button-container" *ngIf="this.global.connectionData._id">
          <!-- <button
            type="button"
            class="btn btn-blue"
            placement="right"
            (click)="teamExcelExport($event)"
            [disabled]="false">
            <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
              <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z"/>
            </svg>
            Export Bulk Excel
          </button> -->
          <!-- <button type="button" class="btn btn-blue" placement="right" [disabled]="bulkTeamCreationProcess"
            (click)="navigateToBulkTeamCreation()">
            <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
              <path
                d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
            </svg>
            {{!bulkTeamCreationProcess?bulkUpdateStatus!=null?"Bulk Team Creation Updated":"Bulk Team Creation":"Bulk
            Team Creation Processing..."}}
          </button>

          <button type="button" class="btn btn-blue" placement="right" [disabled]="bulkConfigUpdateProcess"
            (click)="navigateToBulkConfigUpdate()">
            <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
              <path
                d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
            </svg>
            {{!bulkConfigUpdateProcess?bulkUpdateStatus!=null?"Bulk Config Updated":"Bulk Config Update":"Bulk Config
            Update Processing..."}}
          </button>

          <button type="button" class="btn btn-blue" placement="right" [disabled]="bulkUploadProcess"
            (click)="navigateToBulkUpload()">
            <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
              <path
                d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
            </svg>
            {{!bulkUploadProcess?bulkUpdateStatus!=null?"Bulk Team Upload Updated":(global.haveSyncAddOn?"Bulk Creation
            of ND Tab and Sync Job":"Bulk Creation of Tab Config"):"Bulk Upload Processing..."}}
          </button> -->
          <button type="button" class="btn btn-blue" placement="right" (click)="navigateToBulkTeam()"> Bulk Team
            Creation
          </button>
        </div>
        <!-- <button
          class="btn btn-sm im-primary-btn m-0 waves-effect"
          type="button">
          <img src="assets/images/icons/sync-white-icon.svg">
        </button> -->
      </div>
      <tenant-picker (change)="onChangeConnection($event)" (changeRefresh)="onChangeRefresh($event)"
        [loadTeam]="loadTeam"></tenant-picker>

    </div>

    <div class="sec-3 col-12 row mx-0 p-0">
      <div class="sec-2 col-12 z-depth-1 rounded-2 bg-white">
        <div class="wrap-break-all">
          {{global.selectedGroup.DisplayName}} {{global.selectedChannel.DisplayName&&">"}}
          {{global.selectedChannel.DisplayName}}
        </div>
      </div>
      <div class="section-holder">
        <team-groups class="col-md-4 p-0 ip-container-box-sm" [connection]="global.connectionData"
          [syncChange]="syncChange" [refreshTeamsConnection]="refreshTeamsConnection" [disconnection]="disconnection"
          (changeLoadTeam)="onLoadTeam($event)" (change)="onSelectGroup($event)"></team-groups>
        <team-channels class="col-md-4 p-0 ip-container-box-sm" [connection]="global.connectionData"
          [refreshTeamsConnection]="refreshTeamsConnection" [disconnection]="disconnection"
          [group]="global.selectedGroup" (change)="onSelectChannels($event)"
          (syncChange)="onChangeSync($event)"></team-channels>
        <team-tabs class="col-md-4 p-0 ip-container-box-sm" [connection]="global.connectionData"
          [group]="global.selectedGroup" [channel]="global.selectedChannel" [disconnection]="disconnection"
          (change)="onSelectTabs($event)"></team-tabs>
      </div>
    </div>
  </div>
</div>

<!-- UPLOAD BULK EXCEL -->
<ng-template #modalUpload let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bulk Upload </h4>
  </div>
  <div class="modal-body">
    <div class="drop-area text-center" *ngIf="!isUploadProcess" (dragover)="onFileDragOver($event)"
      (drop)="onFileDropSuccess($event)">
      <div>
        <div class="text-center">Drag & Drop file here</div>
        <div class="text-center">or</div>
        <div class="text-center">
          <button type="button" class="browse-files text-center" (click)="onBrowseFile(elenentFileUpload)"
            placement="center">
            Browse Files
          </button>
        </div>

      </div>
      <input #elenentFileUpload id="elenentFileUpload" (change)="onFileChange($event)" type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden>
    </div>
    <div *ngIf="(excelProcess)" class="text-center process-loader">
      <img src="./assets/images/Spinner.gif" alt="Loader" class="button-loader">
    </div>
    <div *ngIf="(selectedFile.name!=undefined)" class="alert alert-info px-3 py-1 my-2" role="alert">
      {{selectedFile.name}}
    </div>
    <div *ngIf="(fileError!='')" class="alert alert-danger px-3 py-1 my-2" role="alert">
      {{fileError}}
    </div>
    <div class="my-2">
      <div *ngIf="(excelData.length>0)" class="text-center mb-2">
        Valid Data: {{validJsonData.length}}/{{jsonData.Sheet1.length}}
        <button *ngIf="validJsonData.length>0" class="btn btn-blue" (click)="startBulkUpload()">
          Process Data
        </button>
      </div>
      <table *ngIf="(excelData.length>0)" class="table table-bordered bg-white">
        <thead class="thead-light">
          <tr>
            <th class="col-3">Row</th>
            <th class="col-3">Data</th>
            <th class="col-3">Reason</th>
            <!-- <th class="col-3">Status</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let excelRow of excelData">
            <td class="col-3">{{excelRow.row}}</td>
            <td class="col-3">
              {{(excelRow.isDataInvalid) ? 'Invalid' : 'Valid' }}
            </td>
            <td class="col-3">{{excelRow.reason }}</td>
            <!-- <td class="col-3 {{statusColor[excelRow.dataProcessStatus.split(' ')[0]]}}">{{excelRow.dataProcessStatus}}</td> -->
            <!-- green color:text-success, red color:text-danger, orange color:text-amber -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">
      Cancel
    </button>
    <button *ngIf="this.selectedFile.name != undefined" type="button"
      class="btn btn-blue {{(isUploadProcess) ? 'show-loader':''}}" (click)="onValidateSettings()"
      [disabled]="(isUploadProcess)">
      Upload
      <img *ngIf="(isUploadProcess)" src="./assets/images/Spinner.gif" alt="Loader" class="button-loader">
    </button>
  </div>
</ng-template>

<!-- UPLOAD BULK EXCEL -->
<ng-template #modalUploadStatus let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bulk Upload Status</h4>
  </div>
  <div class="modal-body">
    <div class="my-2">
      <table *ngIf="(bulkUpdateStatus.data.length>0)" class="table table-bordered bg-white">
        <thead class="thead-light">
          <tr>
            <th class="col-2">Team Name</th>
            <th class="col-2">Channel Name</th>
            <th class="col-2">Tab Name</th>
            <th class="col-3">Tab Status</th>
            <th class="col-3">Sync Status</th>
            <!-- <th class="col-3">Status</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let excelRow of bulkUpdateStatus.data">
            <td class="col-2">{{excelRow.teamName}}</td>
            <td class="col-2">{{excelRow.channelName}}</td>
            <td class="col-2">{{excelRow.tabName}}</td>
            <td class="col-3">{{excelRow.tabStatus}}</td>
            <td class="col-3">{{excelRow.syncStatus}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">
      Cancel
    </button>
    <button type="button" class="btn btn-blue {{(isUploadProcess) ? 'show-loader':''}}"
      (click)="onChooseFile(modalUpload)">
      Bulk Upload
    </button>
  </div>
</ng-template>