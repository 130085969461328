import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTemplate'
})
export class FilterTemplatePipe implements PipeTransform {


  transform(value: any, args: any): any {
    if (args != undefined && args != null && args != "") {
      if (value.length > 0) {
        return value.filter(template => {
          if (template.name.toLowerCase().indexOf(args.toLowerCase()) >= 0) return true;
        })
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}
