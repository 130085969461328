import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ElectronService } from 'ngx-electron';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LogService } from './app/services/log.service';

if (environment.production) {
  enableProdMode();
}
environment.APP_PROFILE = "admin:9f5f0044-05b3-43e2-a74a-546deeac1477";
// environment.APP_PROFILE="client:9f5f0044-05b3-43e2-a74a-546deeac1477";
try {
  let electron = new ElectronService()
  if (electron.isElectronApp) {
    let remote = electron.remote;
    let app_config = remote.require('./app_config.js');
    app_config.read((config) => {
      if (config != null) {
        try {
          config = JSON.parse(config);
          environment.APP_PROFILE = config.profile + ":" + config.license_key;
          environment["VERSION"] = config.version;
          document.title = ((environment.APP_PROFILE.indexOf("admin:") == 0) ? "netDocShare Sync Admin v" : "netDocShare OneDrive Sync v") + environment["VERSION"];
        } catch (e) {
          // //console.log(e)
          alert("Invalid app configuration!");
        }
      }
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
    })
  } else {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.log(err));
  }
} catch (e) {
  // //console.log(e)
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

