<div class="d-flex justify-content-center align-items-center nds-with-footer overflow-auto">
    <div class="nds-login-box">
        <div class="nds-login-logo bb-thick p-2">
            <img src="./assets/images/netDocShare-logo.svg" alt="logo" class="nds-logo" />
            <!-- <img src="https://services.klobot.ai/apps/beta/netDocShareSync/assets/images/netDocShare-logo.svg" alt="logo" class="nds-logo" /> -->
        </div>
        <div *ngIf="!isValidated && !unauthorized">
            <h6 class="nds-login-title my-3 text-center">Please login to NetDocuments with your credentials to continue</h6>
            <div class="bb-thick"></div>
            <div class="px-30 py-3 text-right">
                <button type="submit" class="btn im-primary-btn" (click)="doLogin()">Login to
                    NetDocuments</button>
            </div>
        </div>
        <div *ngIf="unauthorized">
            <h2 class="nds-login-title my-3 text-center">401: Unauthorized</h2>
        </div>
        <div class="px-30">
            <div *ngIf="!isValidated && fail" class="alert alert-danger py-1 mt-3" role="alert">
                Error: Failed to log-in NetDocuments. Please try again.
            </div>
            <div *ngIf="obtainedUsername && isValidated && !fail" class="py-1" role="alert">
                <h6 class="nds-login-title text-center">Hello <b>{{userName}}</b>!</h6>
                <div *ngIf="isValidated && !fail" class="alert alert-info py-1" role="alert">
                    You are connected to NetDocuments. Click next to go to the main menu.
                </div>
            </div>
            <div *ngIf="!obtainedUsername && isValidated && !fail" class="alert alert-info py-1 mt-3" role="alert">
                Success! You are now authenticated to NetDocuments. Click next to go to the main menu.
            </div>
            <div *ngIf="unauthorized" class="alert alert-danger py-1 mt-3" role="alert">
                Error: You are unauthorized to access this page.
            </div>
        </div>
        <div *ngIf="isValidated && !fail" class="bb-thick"></div>
        <div *ngIf="isValidated && !fail" class="px-30 py-3 text-right">
            <button type="submit" class="btn im-primary-btn text-uppercase" (click)="btnClick()">Next</button>
        </div>

    </div>
</div>
<app-footer></app-footer>