import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HelperService } from '../services/helper';
import { NetDocumentViewerService } from '../services/netdocument-viewer';
import { GlobalService } from '../services/global.service';
import { NetdocshareLocalstorageService } from '../services/netdocshare-localstorage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private ndvs: NetDocumentViewerService,
    private router: Router,
    private toastr: ToastrService,
    private global: GlobalService,
    private netLocalStorage: NetdocshareLocalstorageService
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (window.location.href?.toLowerCase()?.indexOf("?apptype=teams") > -1) return true;
    this.isValidUser();
    return true;
  }

  public isValidUser() {
    let userData = this.netLocalStorage.userSettingInfo;
    let sessionVal = sessionStorage.getItem('license');
    if (sessionVal == undefined && sessionVal == null || sessionVal == '') {
      this.ndvs.logOut();
      return false;
    } else {
      this.ndvs.validateNetDocumentsUser().subscribe((resData) => {
        if (resData.status == "success") {
          this.ndvs.validateCurentUserLicense().subscribe((resData) => {
            if (resData.status == "success") {
              return true;
            } else {
              this.ndvs.logOut()
              return false;
            }
          }, (error) => {
            this.ndvs.logOut()
            return false;
          })
          return true;
        } else {
          this.ndvs.logOut();
          return false;
        }
      });
    }
  }

  private getLicenseData() {
    this.ndvs.getLicenceData().subscribe((res) => {
      if (res.status == "success") {
        this.ndvs.currentUserLicense = res.data;
        if (res.data.validDomains != undefined && res.data.validDomains != null && res.data.validDomains.length > 0) {
          let domain = this.ndvs.currentUser.email.split("@")[1];
          let dominExist = res.data.validDomains.find(domainname => domainname.toLowerCase() == domain.toLowerCase());
          if (dominExist == undefined || dominExist == null || dominExist.length < 0) this.ndvs.logOut();
        } else {
          this.toastr.error("Session has timeout. Please  login again")
          this.ndvs.logOut();
        }
        if (HelperService.IS_ADMIN()) {
          this.ndvs.isValidAdminUser(res.data.validAdmins, this.ndvs.currentUser)
          this.ndvs.getUserJobsCount();
        } else {
          this.ndvs.getUserJobsCount();
        }
        this.ndvs.currentUser.companyName = res.data.client;
        this.ndvs.currentUser.licenseType = res.data.licenseType;
        localStorage.setItem('curentUser', btoa(JSON.stringify(this.ndvs.currentUser)))
      }
    })
  }

  // public getCurrentUser(){
  //   let currentUserData=localStorage.getItem('curentUser')
  //   let currentUser=(currentUserData!=undefined && currentUserData!=null) ? JSON.parse(atob(currentUserData)) : {};
  //   this.ndvs.currentUser=currentUser;
  //   const licenseData = localStorage.getItem('license_key');
  //   //console.log(this.ndvs.currentUser);

  //   this.ndvs.getNetDocUserInfo().subscribe((resData:any)=>{
  //     //console.log(resData);
  //     if(resData!=undefined ){
  //       this.ndvs.currentUser=resData;           
  //       if(HelperService.IS_ADMIN()){
  //         let validater:any=JSON.parse(atob(localStorage.getItem("validater")))
  //         if(this.ndvs.isValidAdminUser(validater.adminUsers,resData.data.user)){
  //         }else{
  //           localStorage.clear();
  //           this.router.navigateByUrl("/login");
  //         }
  //       }else{
  //         localStorage.setItem('curentUser', btoa(JSON.stringify(resData.data.user)))
  //       }
  //     }else{
  //       localStorage.clear();
  //       this.router.navigateByUrl("/login");
  //     }
  //   });    
  // }

  // private getLicenseDetails(){
  //   this.ndvs.getLicenceData().subscribe((res: any) => {
  //     if(res.result=='success' && res.data!=undefined){
  //       // this.syncService.syncJobsLimit=(res.data.syncJobsLimit!=undefined && res.data.syncJobsLimit!=null && res.data.syncJobsLimit!="") ? res.data.syncJobsLimit :0;
  //       // this.syncService.totalSyncJobs=(res.data.totalSyncJobs!=undefined && res.data.totalSyncJobs!=null && res.data.totalSyncJobs!="") ? res.data.totalSyncJobs :0;
  //       // /// UPDATE VALIDATERS
  //       // this.licenseService.setValidationData(res.data);          
  //     }else{
  //       // this.syncService.syncJobsLimit=0;
  //       // this.syncService.totalSyncJobs=0;
  //     }
  //     // let menuCreateSync=this.syncService.sideMenus.find(element=>element.id==3);
  //     // if(this.syncService.totalSyncJobs>=this.syncService.syncJobsLimit){
  //     //   if(menuCreateSync!=undefined && menuCreateSync!=null) menuCreateSync.isDisabled=true;
  //     // }else{
  //     //   if(menuCreateSync!=undefined && menuCreateSync!=null) menuCreateSync.isDisabled=false;
  //     // }
  //   },(error)=>{

  //   })  
  // }    

}
