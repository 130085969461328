<app-container-top></app-container-top>

<div class="container">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="bulkEditTeamLoader">
        <div class="loader-container loader-container-absolute">
            <div class="loader"></div>
        </div>
    </div>
    <div class="col-md-12 ">
        <button type="button" class="btn im-primary-outline-btn" (click)="back(modalunload)">Back</button>
        <button style="float: right;" type="button" class="btn im-primary-btn" (click)="addteam()">+ Team</button>
    </div>
    <form [formGroup]="myForm">
        <table class="table table-bordered">
            <tbody formArrayName="teams">
                <!-- <tr>
                    <div  class="col-sm-12"> <small class="text-danger" *ngIf="duplicateMsg"></small></div>
                </tr> -->
                <tr *ngFor="let team of teams.controls; let i = index" [formGroupName]="i">
                    <td width="35%">
                        <div class="form-group row mr-0 ml-0 mb-4 d-flex">
                            <div class="col-sm-10">
                                <input class="form-control" (keyup)="checkTeamName(i)" formControlName="teamName"
                                    placeholder="Team Name" />
                            </div>
                            <div class="col-sm-2">
                                <button data-title="Config More" class="btn btn-icon icon-grid  waves-effect ml-2 me-2"
                                    [disabled]="team.get('teamName').invalid" type="button"
                                    (click)="openTeam(modalTeam,team.get('teamName').value,i)"><img
                                        src="assets/images/icons/setting-icon.svg">
                                </button>
                            </div>
                            <div *ngIf="team.get('teamName').errors?.required && team.get('teamName').touched"
                                class="col-sm-10 error">
                                Team Name is required
                            </div>
                            <div *ngIf="team.get('teamName')?.errors?.duplicate" class="col-sm-10 error">
                                {{duplicateMsg}}
                            </div>
                        </div>
                    </td>
                    <td width="35%">
                        <div class="form-group row d-flex">
                            <div class="col-sm-10">
                                <input class="form-control" formControlName="channelname" placeholder="Channel Name" />
                            </div>
                            <div class="col-sm-2">
                                <button data-title="Add Channels"
                                    [disabled]="team.get('channelname').value==''||team.get('teamName').invalid"
                                    class="btn btn-icon icon-grid im-primary-outline-btn waves-effect ml-2 me-2"
                                    type="button" (click)="addChanneltoTeam(team.get('channelname').value,i)"><img
                                        src="assets/images/plus.svg" style="color: #fff;">
                                </button>
                            </div>
                        </div>
                        <div class="form-group row d-flex">
                            <div class="col-sm-10">
                                <div class="col-12 p-0 middle-list-height team-list">
                                    <div class="list-holder">
                                        <div *ngIf="channelsLoader" class="section-loader">
                                            <img src="assets/images/Spinner.gif">
                                        </div>
                                        <div *ngIf="isChannelsRequestFailed">Channels data fetching error</div>
                                        <div class="col-sm-12"
                                            *ngIf="teams?.controls[i]?.get('channels')?.controls.length==0">
                                            Channels not found</div>
                                        <ul class="list-group list-border-0 team-channel-list">
                                            <li *ngFor="let channel of teams?.controls[i]?.get('channels').controls; let channelIndex=index;"
                                                [formGroupName]="channelIndex"
                                                class="{{(channel.controls.name.value==teams?.controls[i]?.get('selectedChennal')?.value?.value?.name)?'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions selected-channel':'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions'}}">
                                                <div class="col-12 p-0 d-flex align-items-center teams-channels-name"
                                                    data-title="{{channel.controls.name.value}}"
                                                    (click)="onSelectChannel(i,channelIndex,channel)">
                                                    <img src="assets/images/icons/channel-icon.svg"
                                                        class="default-icon">
                                                    <span>{{channel.controls?.name?.value}}</span>


                                                </div>
                                                <div class="col-auto p-0 d-flex">
                                                    <div class="elipse-action">
                                                        <div ngbDropdown placement="bottom-right"
                                                            class="d-inline-block"><img ngbDropdownToggle
                                                                src="./../../../assets/images/icons/ellipse-hover-icon.svg"
                                                                width="25px">
                                                            <div ngbDropdownMenu><button ngbDropdownItem
                                                                    (click)="onEditChannel(modalChannel,channel,channelIndex,i)">
                                                                    Edit
                                                                </button><button ngbDropdownItem
                                                                    (click)="removeChannel(i,channelIndex,modalDeleteChannel)">
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button
                                                    class="btn btn-icon im-primary-btn  sync-btn waves-effect ml-2 me-2"
                                                    type="button"
                                                    title="{{(teams?.controls[i]?.get('teamLevelSync')?.value?'Team level Sync Enabled':'Channel Sync')}}"
                                                    [disabled]="teams?.controls[i]?.get('teamLevelSync')?.value"
                                                    (click)="onEditSync(modalSync,i,channelIndex,channel )"><img
                                                        src="assets/images/sync.svg">
                                                </button>
                                                <button title="Add Tabs"
                                                    class="btn btn-icon im-white-outline-btn  waves-effect ml-2 me-2"
                                                    type="button"
                                                    (click)="opentabConfig(modalTab,i,channelIndex,channel)"><img
                                                        src="assets/images/plus.svg">
                                                </button>

                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </td>
                    <td width="30%">
                        <div class="row col-sm-12">
                            <span style="color:gray;"
                                *ngIf="teams?.controls[i]?.get('selectedChennal')?.value?.value?.name!=undefined">{{team.get('teamName')?.value
                                |uppercase }} >
                                {{teams?.controls[i]?.get('selectedChennal')?.value?.value?.name|uppercase }}</span>
                        </div>
                        <div class="row col-sm-12"
                            *ngIf="teams?.controls[i]?.get('channels')?.controls[team?.get('selectedChannelIndex')?.value]?.get('tabs')?.controls.length==0 ||teams?.controls[i]?.get('channels')?.controls.length==0">
                            Tabs not found
                        </div>
                        <div class="form-group row d-flex">
                            <div class="col-sm-12">
                                <div class="col-12 p-0 middle-list-height team-list">
                                    <div class="list-holder">
                                        <div *ngIf="channelsLoader" class="section-loader">
                                            <img src="assets/images/Spinner.gif">
                                        </div>
                                        <ul class="list-group list-border-0 team-channel-list">
                                            <li *ngFor="let tab of  teams?.controls[i]?.get('channels')?.controls[(team?.get('selectedChannelIndex')?.value |number)]?.get('tabs')?.controls; let tabIndex=index"
                                                class="list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions">
                                                <div class="col-12 p-0 d-flex align-items-center teams-channels-name"
                                                    data-title="{{tab?.value?.name}}">
                                                    <img src="assets/images/icons/channel-icon.svg"
                                                        class="default-icon">
                                                    <span>{{tab?.value?.name}}</span>
                                                </div>
                                                <div class="col-auto p-0 d-flex">
                                                    <div class="elipse-action">
                                                        <div ngbDropdown placement="bottom-right"
                                                            class="d-inline-block"><img ngbDropdownToggle
                                                                src="./../../../assets/images/icons/ellipse-hover-icon.svg"
                                                                width="25px">
                                                            <div ngbDropdownMenu><button ngbDropdownItem
                                                                    (click)="onEditTab(modalTab,tab,tabIndex)">
                                                                    Edit
                                                                </button><button ngbDropdownItem
                                                                    (click)="removetab(i,tabIndex,modalDeleteTab)">
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td width="10%">
                        <div *ngIf="team.get('status').value==''"><button data-title="Delete Team"
                                class="btn btn-icon icon-grid im-white-outline-btn waves-effect btn-edit-remove"
                                type="button">
                                <img (click)="removeteam(modalDelete,i)"
                                    src="assets/images/delete_forever_black_24dp.svg" />
                            </button>
                        </div>
                        <div *ngIf="team.get('status').value=='success'">
                            <button data-title="Team Created Successfully!."
                                class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                <img src="assets/images/check_icon.svg" />

                            </button>
                        </div>
                        <div *ngIf="team.get('status').value=='failed'">
                            <button data-title="{{team.get('statusMessage').value}}"
                                class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                <img src="assets/images/error-auth.png" />

                            </button>
                        </div>
                        <div *ngIf="team.get('status').value=='process'" class="loading-div w-100">
                            <button data-title="Team Creation in Progress"
                                class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                <img class="info-icon" src="assets/images/Spinner.gif" />

                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="text-align: right;"><button type="button" class="btn im-primary-btn"
                [disabled]="myForm.invalid||duplicateMsg" (click)="onSubmit()">Submit</button></div>
    </form>
</div>

<ng-template #modalChannel let-modal>
    <create-channel [requestFromBulkEdit]='true' [channelDetail]="channelDetail"
        (onChangeChannelData)="onChangeChannelData($event)"></create-channel>
</ng-template>
<ng-template #modalSync let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sync Config</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: scroll;height: 450px;">
        <app-sync-net-doc-location [isbulkEdit]="true" (onSyncJobSettingChange)="onSyncJobSettingChange($event)"
            [editDetails]="editSyncDetails"></app-sync-net-doc-location>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="onSaveSync()">
            Save Sync
        </button>
    </div>
</ng-template>

<ng-template #modalDelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure want to delete this Team?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            No
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #modalDeleteChannel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure want to delete this Channel?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            No
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #modalDeleteTab let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure want to delete this Tab?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            No
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #modalunload let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure to reload this page? May affect the background team creation process.</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            No
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #modalTeam let-modal style="max-width: 700px !important;">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Team Details</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: scroll;height: 450px;">
        <app-create-request [requestFromBulkEdit]='true' [isBulkEditMode]="isBulkEditMode" [teamDetail]="teamDetails"
            [teamName]="selectedTeamName" (onSaveTeam)="onUpdateTeam($event,modalTeam)"></app-create-request>
    </div>
</ng-template>

<ng-template #modalTab let-modal>
    <create-tab [isBulkEdit]="true" [modal]="modal" (onConfigChange)="onAddTab($event)"></create-tab>
</ng-template>