import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElectronService } from 'ngx-electron';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/services/helper';
import { LogService } from 'src/app/services/log.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/global.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-teams-settings',
  templateUrl: './teams-settings.component.html',
  styleUrls: ['./teams-settings.component.scss']
})
export class TeamsSettingsComponent implements OnInit {

  public isProduction = environment.production;
  public isAdmin = HelperService.IS_ADMIN();
  public showLoader: boolean = true;
  public isSaveDisabled: boolean = true;
  public JobSettings: any = [];
  public currentJoSetting = { clientId: "", clientSecret: "", ownerEmail: "", tenantId: "", tenantName: "" };
  public fieldValidation = { clientId: false, clientSecret: false, ownerEmail: false, tenantId: false, tenantName: false };
  public isTenantIdAlredyExist: boolean = false;
  public isTenantNameAlredyExist: boolean = false;
  public currentJobSettingIndex: number = null;
  public isEditMode: boolean = false;
  public downloadFileUrl: any = "";
  public selectedFile: any = {};
  public fileError: string = "";
  public isUploadProcess: boolean = false;
  public excelData: any = [];
  public statusColor: any = { Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber' }
  private existingValue: any = {};
  public defaultSelectionProcess = false;

  constructor(
    public ndvs: NetDocumentViewerService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public electron: ElectronService,
    private sanitizer: DomSanitizer,
    private logService: LogService,
    private teamsService: TeamsService,
    private router: Router,
    public global: GlobalService
  ) {
    this.ndvs.pageTitle = "OneDrive Connection Settings";
    this.logService.updateLogMessage("OneDrive Connection Settings");
  }

  ngOnInit(): void {
    this.getTeamsConnections();
    //console.log(this.ndvs)
    //console.log(this.ndvs.currentUser)
  }

  private getTeamsConnections() {
    this.showLoader = true;
    this.teamsService.getTeamsConnectionSettings().subscribe((resData) => {
      this.showLoader = false;
      if (resData != undefined && resData.status == "success" && resData.data != null) {
        this.JobSettings = resData.data;
      } else {
        this.JobSettings = [];
      }
    })
  }

  public onValidateFields(fieldName) {
    this.fieldValidation[fieldName] = ((this.currentJoSetting[fieldName] == undefined || this.currentJoSetting[fieldName] == null || this.currentJoSetting[fieldName] == ''))
    this.onSaveEnable();
  }

  public onCreateOneDriveJobSetting(model, curJobSet = null, JobSettingIndex = null) {
    if (this.ndvs.currentUser.role == undefined || this.ndvs.currentUser.role != 'globalAdmin') return;
    this.isEditMode = (curJobSet != null) ? true : false;
    this.currentJobSettingIndex = JobSettingIndex;
    this.fieldValidation = { clientId: false, clientSecret: false, ownerEmail: false, tenantId: false, tenantName: false };
    this.currentJoSetting = (curJobSet != null) ? JSON.parse(JSON.stringify(curJobSet)) : { tenantId: "", tenantName: "", clientId: "", clientSecret: "", ownerEmail: "" }
    if (curJobSet != null) this.currentJoSetting.clientSecret = "";
    this.isTenantIdAlredyExist = false;
    this.isTenantNameAlredyExist = false;
    this.modalService.open(model, { backdrop: false }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
    setTimeout(() => {
      this.onSaveEnable();
    }, 100)

  }

  public showDeleteConfirm(model, JobSetting) {
    if ((JobSetting.jobAssigned != undefined && JobSetting.jobAssigned) || (this.ndvs.currentUser.role == undefined || this.ndvs.currentUser.role != 'globalAdmin')) return;
    this.modalService.open(model).result.then((res) => {
      if (res == "yes") {
        this.showLoader = true;
        this.teamsService.deleteTeamsConnectionSetting(JobSetting._id).subscribe((resData) => {
          this.showLoader = false;
          if (resData.status == "success") {
            this.toastr.success("Sync job setting removed", "Success");
            this.getTeamsConnections();
          } else {
            this.toastr.warning(resData.message, "Failed")
          }
        })
      }
    }, (reson) => {

    }).catch((error) => {

    })
  }

  public onSelect(e, job) {
    var settings = this.JobSettings.map(d => {
      if (d._id != job._id) {
        return { ...d, isDefault: false }
      } else {
        return { ...d, isDefault: true }
      }
    })
    this.JobSettings = settings;
    var defaultConnection = this.JobSettings.find(d => d.isDefault && d._id);
    //if (defaultConnection != null) this.global.connectionData = defaultConnection;
    this.defaultSelectionProcess = true;
    this.teamsService.setDefaultConnections(defaultConnection._id).subscribe(() => {
      this.defaultSelectionProcess = false;
    }, (error) => { this.defaultSelectionProcess = false; })

  }



  public showDefaultChangeConfirm(model, job) {
    this.modalService.open(model).result.then((res) => {
      if (res == "yes") {
        this.showLoader = true;
        var settings = this.JobSettings.map(d => {
          if (d._id != job._id) {
            return { ...d, isDefault: false }
          } else {
            return { ...d, isDefault: true }
          }
        })
        this.JobSettings = settings;
        var defaultConnection = this.JobSettings.find(d => d.isDefault && d._id);
        //if (defaultConnection != null) this.global.connectionData = defaultConnection;
        this.defaultSelectionProcess = true;
        this.teamsService.setDefaultConnections(defaultConnection._id).subscribe((resData) => {
          this.defaultSelectionProcess = false; this.showLoader = false;
          if (resData.status == "success") {
            this.toastr.success("Team default selection changed", "Success");
          } else {
            this.toastr.warning(resData.message, "Failed")
          }

        }, (error) => { this.defaultSelectionProcess = false; this.showLoader = false; })
      } else {

        var settings = this.JobSettings.map(d => {
          if (d._id != job._id) {
            return { ...d }
          } else {
            return { ...d, isDefault: false }
          }
        })
        this.JobSettings = settings;
      }
    }, (reson) => {

    }).catch((error) => {

    })
  }


  public onConnectTeams() {
    this.isTenantNameAlredyExist = false;
    let tenentNameExist = this.JobSettings.filter(element => (element.tenantName != undefined && element.tenantName != null && element.tenantName.toLowerCase() == this.currentJoSetting.tenantName.toLowerCase()));
    if (this.isEditMode) {
      if (tenentNameExist != undefined && tenentNameExist != null) {
        if (tenentNameExist.length > 1) {
          this.isTenantNameAlredyExist = true;
        } else if (tenentNameExist.length == 1) {
          if (tenentNameExist[0]._id != this.currentJoSetting['_id']) this.isTenantNameAlredyExist = true;
        }
      }
    } else {
      if (tenentNameExist != undefined && tenentNameExist != null && tenentNameExist.length > 0) this.isTenantNameAlredyExist = true;
    }
    if (this.isTenantIdAlredyExist || this.isTenantNameAlredyExist) return;
    this.showLoader = true;
    this.isSaveDisabled = true;
    this.teamsService.checkTeamsConnection(this.currentJoSetting).subscribe((resData: any) => {
      console.log("resData===", resData)
      if (resData.status == "success") {
        this.onSaveTeamsConnection();
      } else if (resData.status == "error") {
        this.showLoader = false;
        this.toastr.warning(resData.message, "Connection Error:");
      } else {
        this.showLoader = false;
        resData.message.includes("owner") ? this.toastr.warning(resData.message, "Connection Error:") :
          this.toastr.warning(resData.message, "Connection Error:");
      }
    })
  }

  public onSaveEnable() {
    this.isSaveDisabled = ((this.currentJoSetting.tenantId == undefined || this.currentJoSetting.tenantId == null || this.currentJoSetting.tenantId.trim() == '') || (this.currentJoSetting.tenantName == undefined || this.currentJoSetting.tenantName == null || this.currentJoSetting.tenantName.trim() == '') || (this.currentJoSetting.clientId == undefined || this.currentJoSetting.clientId == null || this.currentJoSetting.clientId.trim() == '') || (this.currentJoSetting.clientSecret == undefined || this.currentJoSetting.clientSecret == null || this.currentJoSetting.clientSecret.trim() == '') || (this.currentJoSetting.ownerEmail == undefined || this.currentJoSetting.ownerEmail == null || this.currentJoSetting.ownerEmail.trim() == ''))
  }

  public onSaveTeamsConnection() {
    this.isSaveDisabled = true;
    //this.teamsService.saveTeamsConnectionSetting(this.currentJoSetting).subscribe((resData) => {
    this.showLoader = false;
    /* if (resData.status == "success") { */
    if (this.currentJoSetting['_id']) {
      this.toastr.success("Team connection setting has been updated", "Success");
    } else {
      this.toastr.success("Team connection setting has been created", "Success");
    }
    this.isTenantIdAlredyExist = false;
    this.isTenantNameAlredyExist = false;
    this.getTeamsConnections();
    this.modalService.dismissAll();
    /* } else {

      this.toastr.warning(resData.message, "Failed")
    } */
    //})
  }

  public onDownloadSample() {
    if (this.ndvs.currentUser.role == undefined || this.ndvs.currentUser.role != 'globalAdmin') return;
    this.downloadFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + "/assets/documents/teams_connection_template.xlsx");
  }

  public onChooseFile(popUp) {
    if (this.ndvs.currentUser.role == undefined || this.ndvs.currentUser.role != 'globalAdmin') return;
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
    this.modalService.open(popUp, { backdrop: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public onFileDragOver(event) {
    event.preventDefault();
  }

  public onFileDropSuccess(event) {
    event.preventDefault();
    this.uploadSettings(event.dataTransfer.files);
  }

  public onBrowseFile(element) {
    element.click()
  }

  public onFileChange(event) {
    this.uploadSettings(event.target.files);
  }

  private uploadSettings(filrObject) {
    this.excelData = [];
    if (filrObject.length > 1) {
      this.fileError = "Not supported for multiple files";
      return;
    } else {
      let fileExt = filrObject[0].name.split(".").pop();
      let allowedFiles: any = ["xls", "xlsx", "csv"];
      if (allowedFiles.indexOf(fileExt.toLowerCase()) == -1) {
        this.fileError = "Invalid file";
        return;
      } else {
        this.fileError = "";
      }

    }
    this.selectedFile = filrObject[0];
  }


  public onValidateSettings() {
    this.isUploadProcess = true;
    let workBook = null;
    // let jsonData = null;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      let jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      if (jsonData.Sheet1.length > 0) {

        jsonData.Sheet1.forEach((element, index) => {
          element.dataProcessStatus = "Processing";
          element.reason = "";
          element.row = index + 1;
          this.excelData.push(element);

          // TenantURL Field validattion
          if (element.TeamsTenant == undefined) {
            element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
            element.reason += "TeamsTenant field missing";
            element.isDataInvalied = true;
            element.dataProcessStatus = "Failed";
          } else {
            if (element.TeamsTenant == null || element.TeamsTenant == '') {
              element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
              element.reason += "TeamsTenant field value missing";
              element.isDataInvalied = true;
              element.dataProcessStatus = "Failed";
            }
          }


          // TenantID Field validattion
          if (element.TenantID == undefined) {
            element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
            element.reason += "TenantID field missing";
            element.isDataInvalied = true;
            element.dataProcessStatus = "Failed";
          } else {
            if (element.TenantID == null || element.TenantID == '') {
              element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
              element.reason += "TenantID field value missing";
              element.isDataInvalied = true;
              element.dataProcessStatus = "Failed";
            }
          }


          // ClientID Field validattion
          if (element.ClientID == undefined) {
            element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
            element.reason += "ClientID field missing";
            element.isDataInvalied = true;
            element.dataProcessStatus = "Failed";
          } else {
            if (element.ClientID == null || element.ClientID == '') {
              element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
              element.reason += "ClientID field value missing";
              element.isDataInvalied = true;
              element.dataProcessStatus = "Failed";
            }
          }

          // ClientSecret Field validattion
          if (element.ClientSecret == undefined) {
            element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
            element.reason += "ClientSecret field missing";
            element.isDataInvalied = true;
            element.dataProcessStatus = "Failed";
          } else {
            if (element.ClientSecret == null || element.ClientSecret == '') {
              element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
              element.reason += "ClientSecret field value missing";
              element.isDataInvalied = true;
              element.dataProcessStatus = "Failed";
            }
          }

          // OwnerEmail Field validattion
          if (element.OwnerEmail == undefined) {
            element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
            element.reason += "OwnerEmail field missing";
            element.isDataInvalied = true;
            element.dataProcessStatus = "Failed";
          } else {
            if (element.OwnerEmail == null || element.OwnerEmail == '') {
              element.reason += (element.reason == undefined || element.reason == null || element.reason == '') ? "" : ", ";
              element.reason += "OwnerEmail field value missing";
              element.isDataInvalied = true;
              element.dataProcessStatus = "Failed";
            }
          }

          if (element.dataProcessStatus == "Processing") {
            this.checkTeamsConnection(element);
          } else {
            this.checkUploadStatus();
          }
        });
      } else {
        this.fileError = "File have no records";
        this.isUploadProcess = false;
      }
    }
    reader.readAsBinaryString(this.selectedFile);
  }

  private checkTeamsConnection(settingData) {
    let tenentNameExist = this.JobSettings.filter(element => (element.tenantName != undefined && element.tenantName != null && element.tenantName.toLowerCase() == settingData.TeamsTenant.toLowerCase()));
    if (tenentNameExist == undefined || tenentNameExist == null || tenentNameExist.length == 0) {
      this.teamsService.checkTeamsConnection({ clientId: settingData.ClientID, clientSecret: settingData.ClientSecret, ownerEmail: settingData.OwnerEmail, tenantId: settingData.TenantID, tenantName: settingData.TeamsTenant }).subscribe((resData: any) => {
        if (resData.status == "success") {
          settingData.isDataInvalied = false;
          this.onSaveBulkData(settingData);
        } else {
          settingData.isDataInvalied = true;
          settingData.dataProcessStatus = "Failed";
        }
      })
    } else {
      settingData.isDataInvalied = false;
      settingData.dataProcessStatus = "Already exist";
    }
    this.checkUploadStatus();
  }

  private onSaveBulkData(settingData) {
    this.teamsService.saveTeamsConnectionSetting({ clientId: settingData.ClientID, clientSecret: settingData.ClientSecret, ownerEmail: settingData.OwnerEmail, tenantId: settingData.TenantID, tenantName: settingData.TeamsTenant }).subscribe((resData) => {
      if (resData.status == "success") {
        settingData.dataProcessStatus = "Success";
        this.getTeamsConnections();
      } else {
        if (resData.message == "Setting already exist") {
          settingData.dataProcessStatus = "Already exist";
        } else {
          settingData.dataProcessStatus = "Failed";
        }
      }
      this.checkUploadStatus();
    })
  }

  private checkUploadStatus() {
    let isProcessed: boolean = false;
    if (this.excelData.length > 0) {
      this.excelData.forEach(element => {
        if (element.dataProcessStatus.toLowerCase() == "processing") isProcessed = true;
      });
      this.isUploadProcess = isProcessed;
    } else {
      this.isUploadProcess = false;
    }
    if (!this.isUploadProcess) {
      this.selectedFile = {};
    }
  }

  public onCloseTeamsSetting() {
    this.router.navigateByUrl("/provisioning");
  }
}


