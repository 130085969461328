<div class="col-12 px-0 custom-scrollbar">
    <div class="nds-outer-box mb-3 mt-3 mt-md-0 py-xl-3 py-2">
        <div class="row align-items-md-end align-items-xl-center">
            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-6')}} border-left">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-4 col-lg-12'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="elementndvs.syncJobSetting.syncType">Sync Type</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.syncType}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-8 col-lg-12'}}">
                        <select class="form-control" id="elementSyncType" [(ngModel)]="ndvs.syncJobSetting.syncType" (ngModelChange)="onChangeSyncType()" [disabled]="isOnpermRestriction">
                        <option value="" disabled>Select Type</option>
                        <option *ngFor="let sType of syncTypes" value="{{sType.value}}">{{sType.text}}</option>
                    </select>
                    </div>
                </div>
            </div>

            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-6')}} border-left">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-4 col-lg-12'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="syncFreq">Sync Frequency (hours:minutes:seconds)</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.syncFeequency}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-8 col-lg-12'}}">
                        <div class="input-group justify-content-between">
                            <sync-frequency (onChange)="onChangeFrequency($event)" [syncFrequency]="syncTime"></sync-frequency>
                        </div>
                    </div>
                </div>
            </div>                        

            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' && ndvs.syncJobSetting.fileMove) ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='teamsUni' && !ndvs.syncJobSetting.fileMove ) ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-6'))}} border-left" *ngIf="(ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams' || ndvs.syncJobSetting.syncType=='teamsUni')">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-lg-12 col-xl-8'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="copyMetaCol">Copy Metadata Columns</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.copyMetaData}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-lg-12 col-xl-4'}}">
                        <select class="form-control" id="copyMetaCol" [(ngModel)]="ndvs.syncJobSetting.syncMetaData" (change)="showSPColumnOption()"  [disabled]="isOnpermRestriction">
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                    </div>
                </div>
            </div>
            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' && ndvs.syncJobSetting.fileMove) ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='teamsUni' && !ndvs.syncJobSetting.fileMove) ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-6'))}} border-left" *ngIf="(ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams' || ndvs.syncJobSetting.syncType=='teamsUni')">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-6 col-lg-12'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="copyMetaCol">Apply Permissions</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.applyPermissions}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' || ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') ? 'col-xl-12' : 'col-xl-6 col-lg-12 pl-xl-0'}} ">
                        <select class="form-control" id="copyMetaCol" [(ngModel)]="ndvs.syncJobSetting.applyPermissions" (change)="onChangeApplyPermission()" [disabled]="isOnpermRestriction">
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                    </div>
                </div>
            </div>

            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni' && ndvs.syncJobSetting.fileMove) ? 'col-xl-4 col-lg-3 col-md-4' : ((ndvs.syncJobSetting.syncType=='teamsUni' && !ndvs.syncJobSetting.fileMove) ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-4')}} border-left" *ngIf="(ndvs.syncJobSetting.syncType=='teamsUni')">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-12' : 'col-xl-8 col-lg-12'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="elementFileMove">Move Files</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.fileMove}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-12' : 'col-xl-4 col-lg-12 pl-xl-0'}}">
                        <select class="form-control" id="elementFileMove" [(ngModel)]="ndvs.syncJobSetting.fileMove" (change)="onChangeFileMove()" [disabled]="isOnpermRestriction">
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                    </div>
                </div>
            </div> 
            
            <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-4 col-lg-3 col-md-4' : 'col-md-4'}} border-left" *ngIf="(ndvs.syncJobSetting.fileMove)">
                <div class="form-group row mb-2 mb-md-0 align-items-center">
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-12' : 'col-xl-8 col-lg-12'}}">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label" for="elementFileMoveOn">Move On</label>
                            <div class="help-tip mt-0">
                                <div class="tooltip">
                                    <p>{{toolTip.fileMoveOn}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="{{(ndvs.syncJobSetting.syncType=='teamsUni') ? 'col-xl-12' : 'col-xl-4 col-lg-12 pl-xl-0'}}">
                        <select class="form-control" id="elementMoveFilesOn" [(ngModel)]="ndvs.syncJobSetting.fileMoveOn" [disabled]="isOnpermRestriction">
                            <option *ngFor="let priority of moveOnList" value="{{priority.key}}">{{priority.title}}</option>
                        </select>
                    </div>
                </div>
            </div>                         
            
            
        </div>
    </div>
</div>
<div class="col-md-12 col-lg-6 mb-3 pl-0 custom-scrollbar">
    <div class="nds-outer-box  nd-outer-box-footer h-100 mh-360 right-content">
        <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
            <div class="pg-heading">Select NetDocuments Location</div>
            <button type="button" placement="left" class="btn btn-sm im-primary-btn h-100 {{( isNDLoading)? 'show-loader' :''}}" [disabled]="((!isNDLoading && ndvs.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading)) " (click)="onRefreshNetDocumentContants()">
                <span *ngIf="(!isNDLoading)" class="material-icons-sharp md-refresh"></span> 
                <img  *ngIf="(isNDLoading)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
            </button>
        </div>
        <div class="col-md-12 px-0">
            <div #ndFormNav>
                <div class="form-group row mb-2 align-items-center">
                    <div class="col-md-12 col-xl-4">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                            <label class="col-form-label col-label-info">Cabinet</label>
                            <div class="help-tip">
                                <div class="tooltip">
                                    <p>{{toolTip.ndCabinet}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xl-8">
                        <div class="input-group {{(isCabinetLoader) ? 'show-loader' : ''}}" ngbDropdown #cabinetDropdown="ngbDropdown">
                                <input type="text" class="form-control" placeholder="Select Cabinet" readonly [(ngModel)]="selectedCabinet.name" ngbDropdownToggle [disabled]="(selectedCabinet.name!='' && isNDLoading)">
                                <div class="input-group-append"> 
                                    <button class="dropdown-toggle btn im-primary-btn " ngbDropdownToggle [disabled]="(selectedCabinet.name!='' && isNDLoading)">
                                        <img src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="sourceDropdown2" class="dropdownBody im-dropdown {{(cabinets.length==0) ? 'd-none' : ''}}">
                                        <div *ngFor="let cabinet of cabinets" class="form-check context-dropdown-item im-dropdown-item" (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)" ngbDropdownItem>
                                            <img src="./assets/images/netdocumentsIcons/cabinet.svg"/>
                                            <label class="form-check-label context-dropdown-text">
                                        {{cabinet.name}}
                                    </label>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" class="mb-2 border-bottom {{(isNDLoading) ? 'breadcrumb-disabled' : ''}}" *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
                    <ol class="breadcrumb p-0 border bg-light mb-2">
                        <li class="breadcrumb-item crumb py-2" (click)="onResetRoot()">
                            <img src="./assets/images/netdocumentsIcons/cabinet.svg" class="thumbnail mx-2" />
                            <a title="Confidential">{{selectedCabinet.name}}</a>                                        
                        </li>
                        <li *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )" class="breadcrumb-item crumb py-2" (click)="onResetWorkspace()" >
                            <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mx-2" />
                            <a title="Confidential">{{selectedWorkSpace.name}}</a>
                        </li>
                        <li class="breadcrumb-item crumb py-2" *ngFor="let folder of ndvs.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index" (click)="onLoadBreadCurmp(folder,breadcurmbIndex)">
                            <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mx-2" />
                            <a title="Confidential">{{folder.Attributes.Name}}</a>
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="file-grid search-results">
                <div class="d-flex justify-content-between">
                    <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0)" class="pg-heading fs-16 link {{(isNDLoading) ? 'section-disabled' : ''}}" (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')">{{selectedWorkSpace.name}}</div>
                    <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0)" class="pg-heading fs-16 link {{(isNDLoading) ? 'section-disabled' : ''}}" (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)">{{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}</div>
                    <div >
                        <div class="form-check" *ngIf="((ndvs.syncJobSetting.syncType=='netDocumentsUniTeams') && (workSpaceContents.length>0))">
                            <input class="form-check-input" type="checkbox" value="" id="allCheck" [(ngModel)]="ndvs.syncJobSetting.netDocuments.selectAll" (ngModelChange)="onNDSelectAll()">
                            <label class="form-check-label" for="allCheck">Select all</label>
                        </div>
                    </div>                                
                </div>
                <div class="d-flex justify-content-between mb-2 mt-2">
                    <div *ngIf="(ndvs.syncJobSetting.netDocuments.cabinetId!='' && ndvs.syncJobSetting.netDocuments.workspaceId=='' && ndvs.syncJobSetting.netDocuments.folderId=='')" 
                        class="pg-heading input-group fs-16">
                        <input class="form-control" type="search" placeholder="Search" [(ngModel)]="cabinetSearch" (input)="onChangeCabinetSearch()" (keypress)="onSearchKeyPress($event)" >
                        <div class="input-group-append">
                            <button type="button" class="btn im-primary-btn"  (click)="onSearchWorkspace()" [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading)"> 
                                <i class="material-icons-sharp md-search"></i>
                            </button>
                        </div>
                    </div>

                 
                </div>
                <div class="row" *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
                    <div class="col-md-12 text-center my-3">
                        Cabinet or folders not found
                    </div>
                </div>     
                <div class="d-flex nd-file-manger">
                    <div class="border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover" *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))" (scroll)="onNDScroll($event)" >  
                        <div #ndBlocker *ngIf="(isNDLoading)" class="container-blocker">
                            <div class="text-center w-100">
                                <span>
                                Loading...
                                <img src="./assets/images/Spinner.gif" class="loadingIcon" />
                                </span>
                            </div>                                    
                        </div>
                        <div class="col-lg-6 mb-3" *ngFor="let wSpace of workSpaces|filterWorkspacename:cabinetSearch">
                            <div class="d-flex">
                                <div class="d-flex align-items-start" (click)="onSelectWorkSpace(wSpace)">
                                    <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mr-2" />
                                    <a title="{{wSpace.name}}" class="fs-14 text-dark text-decoration-none">{{wSpace.name}}</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3" *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:cabinetSearch">
                            <div class="d-flex">
                                <div class="d-flex align-items-start" (click)="onSelectCabinetFolder(cabinetFolder)">
                                    <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mr-2" />
                                    <a title="{{cabinetFolder.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">{{cabinetFolder.Attributes.Name}}</a>
                                </div>
                            </div>
                        </div>                                

                        <div class="col-lg-6 mb-3" *ngFor="let content of workSpaceContents">
                            <div class="d-flex">
                                <input *ngIf="(ndvs.syncJobSetting.syncType=='netDocumentsUniTeams')" type="checkbox" class="mt-1 mr-2" (click)="onChooseNDContent($event,content)" [checked]="content.isChecked" [disabled]="content.isRestricted" />
                                <div class="d-flex align-items-start" (click)="onSelectContent(content)">
                                    <img src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}" class="thumbnail mr-2" />
                                    <a title="{{content.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">{{content.Attributes.Name}}</a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>                      
                                           
                <div class="row" *ngIf="((!isFolerLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined || (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined)))">
                    <div class="col-md-12 text-center my-3">
                        Folders or Files not found
                    </div>
                </div>                                                                              
            </div>
        </div>
        <div class="footer-outer">
            <button *ngIf="isEditMode" type="button" class="btn im-primary-btn mt-2" (click)="onCancelSyncJob()"><span class="material-icons-sharp md-cancel"></span>&nbsp;&nbsp;Cancel</button>
            <button *ngIf="!isOnpermRestriction" type="button" (click)="onSaveSyncJob()" class="btn im-primary-btn mt-2" [disabled]="(isDisabledSave || isODLoading)"><span class="material-icons-sharp md-save"></span>&nbsp;&nbsp;Save</button>
        </div>
    </div>
</div>

<div>
    <div class="mx-3 pb-3" *ngIf="(ndvs.syncJobSetting.syncMetaData && (ndvs.syncJobSetting.syncType=='bidirectionalTeams' || ndvs.syncJobSetting.syncType=='netDocumentsUniTeams' || ndvs.syncJobSetting.syncType=='teamsUni'))">
        <button class="btn im-primary-btn" (click)="onOpenMetaDataPopup(metaDtaPopup)" [disabled]="((ndvs.syncJobSetting.netDocuments.cabinetId==undefined || ndvs.syncJobSetting.netDocuments.cabinetId==null || ndvs.syncJobSetting.netDocuments.cabinetId=='') || (ndvs.syncJobSetting.sharePoint.listName==undefined || ndvs.syncJobSetting.sharePoint.listName==null || ndvs.syncJobSetting.sharePoint.listName=='') )">
            <i class="material-icons-sharp md-launch mr-2"></i>Columns List
        </button>

        <span class="nds-notes mx-3 fs-14">( Click to view the Metadata Column lists for <span class="nds-note-highlight">{{selectedSyncType.text}}</span> Sync Type. )</span>
    </div>
</div>