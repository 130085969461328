<app-container-top></app-container-top>
<div class="main-container">
    <div class="inner-container">
        <section class="col-12 teams-setting-header">
            <div class="tenent-title">Tenant <span class="red-text">*</span></div>
            <div class="tenent-name">
                https://www.tenant365.com
            </div>
        </section>
        <section class="col-12 breadcrumb-list">
            <div class="d-flex align-items-center">
                Create a team:
            </div>
            <li class="d-flex align-items-center">
                Choose starting point
            </li>
            <li class="d-flex align-items-center">
                Teams settings
            </li>
            <li class="d-flex align-items-center">
                Channels, tabs, and apps
            </li>
        </section>

        <div class="col-12 my-3 inner-container-rounded">
            <section class="col page-title">
                <h2 class="title">Teams settings</h2>
            </section>
            <section class="col-12 mt-3">
                <div class="form-group row">
                    <div class="col-sm-2 position-relative d-flex justify-content-between align-items-center">
                        <label class="form-label">Team name *</label>
                        <div class="help-tip mt-0">
                            <div class="tooltip">
                                <p>Write your teams name</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="Add a name for your team">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-2  position-relative d-flex justify-content-between align-items-center">
                        <label class="form-label">Short Description *</label>
                        <div class="help-tip mt-0">
                            <div class="tooltip">
                                <p>Write a short description about this teams</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <textarea class="form-control-custom" placeholder="Write short team description"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-2  position-relative d-flex justify-content-between align-items-center">
                        <label class="form-label">Long Description *</label>
                        <div class="help-tip mt-0">
                            <div class="tooltip">
                                <p>Write a breif explaination about this teams</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <textarea class="form-control-custom" placeholder="Write long team description"></textarea>
                    </div>
                </div>
            </section>

            <div class="create-teams-footer d-flex">
                <button class="btn im-primary-outline-btn" (click)="onCancel()">Cancel</button>
                <button class="btn im-primary-btn">Next</button>
            </div>
        </div>

        <!-- <app-create-teams-template-from-existing></app-create-teams-template-from-existing> -->

        <app-create-channels-tabs-apps></app-create-channels-tabs-apps>
    </div>

</div>
<app-footer></app-footer>