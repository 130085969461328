import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {

  public selectedFile: any = {};
  public fileError: string = "";
  public isUploadProcess: boolean = false;
  public excelProcess: boolean = false;
  public excelData: any = [];
  //public statusColor: any = { Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber' }
  public syncChange: any = null;
  public teams = "";
  public jsonData = null;
  public validJsonData = [];
  public bulkUploadProcess = false;

  public processIndex = 0;
  public showStatusProgress = false;
  public processComplete = false;

  public downloadProgress = false;

  constructor(
    public excelService: ExcelService,
    public router: Router,
    public ndvs: NetDocumentViewerService,
    public teamsService: TeamsService,
    private modalService: NgbModal,
    public global: GlobalService,
  ) { }

  ngOnInit(): void {
    if (!this.global.connectionData._id) this.router.navigateByUrl('/provisioning');
  }

  public goHome() {
    this.router.navigateByUrl("/provisioning");
  }



  public onChooseFile() {
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
  }

  public reUpload() {
    if (this.bulkUploadProcess) return;
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
    this.validJsonData = [];
    this.showStatusProgress = false;
    this.processComplete = false;
    this.setProcessComplete();
  }

  public onFileDragOver(event) {
    event.preventDefault();
  }

  public onFileDropSuccess(event) {
    event.preventDefault();
    this.uploadSettings(event.dataTransfer.files);
  }

  public onBrowseFile(element) {
    element.click()
  }

  public onFileChange(event) {
    this.uploadSettings(event.target.files);
  }

  private uploadSettings(fileObject) {
    this.excelData = [];
    this.validJsonData = [];
    this.jsonData = null;
    if (fileObject.length > 1) {
      this.fileError = "Not supported for multiple files";
      return;
    } else {
      let fileExt = fileObject[0].name.split(".").pop();
      let allowedFiles: any = ["xls", "xlsx", "csv"];
      if (allowedFiles.indexOf(fileExt.toLowerCase()) == -1) {
        this.fileError = "Invalid file";
        return;
      } else {
        this.fileError = "";
      }

    }
    this.selectedFile = fileObject[0];
  }


  public showBulkUpdateStatus(popUp) {
    this.modalService.open(popUp, { scrollable: true, size: "lg" }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onValidateSettings() {
    if (((this.selectedFile.name == undefined || this.selectedFile.name == null || this.selectedFile.name == "") && !this.isUploadProcess)) return;

    this.excelProcess = true;
    this.isUploadProcess = true;
    let workBook = null;
    // let jsonData = null;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      if (this.jsonData.Sheet1?.length > 0) {
        this.jsonData.Sheet1.forEach((element, index) => {
          element.row = index + 1;
          this.excelData.push(element);
          this.validateSPBulkData(element);
        });
        this.validJsonData = this.jsonData.Sheet1.filter(d => !d.isDataInvalid);
        this.processComplete = false;
        this.showStatusProgress = true;
      } else {
        this.fileError = "File have no records";
      }
      this.isUploadProcess = false;
      this.excelProcess = false;
    }
    reader.readAsBinaryString(this.selectedFile);
    this.selectedFile = {}
  }

  public startBulkUpload(e) {
    if (e != null) {
      if (this.bulkUploadProcess || this.processComplete) return;
    }
    this.bulkUploadProcess = true;
    var index = this.excelData.findIndex(e => e.TeamID == this.validJsonData[this.processIndex].TeamID && e.ChannelID == this.validJsonData[this.processIndex].ChannelID && e.TabName == this.validJsonData[this.processIndex].TabName);
    this.excelData[index] = { ...this.excelData[index], tabStatus: "Processing...", syncStatus: "Processing...", tStatus: "progress", sStatus: "progress" }
    this.teamsService.uploadExcelData({ "bulkData": [this.validJsonData[this.processIndex]] }).subscribe((resData) => {


      if (resData.data == undefined || resData.data == null) {
        this.excelData[index] = { ...this.excelData[index], tabStatus: "Team or Channel not found", syncStatus: "Team or Channel not found", tStatus: "fail", sStatus: "fail" }
      } else if (resData.data.status == undefined || resData.data.status == null || resData.data.status.trim() == "") {
        this.excelData[index] = { ...this.excelData[index], tabStatus: "Team or Channel not found", syncStatus: "Team or Channel not found", tStatus: "fail", sStatus: "fail" }
      } else {
        this.excelData[index] = { ...this.excelData[index], tabStatus: resData.data.data[0].tabStatus, syncStatus: resData.data.data[0].syncStatus, tStatus: resData.data.data[0].tabStatus == "created" ? "pass" : "fail", sStatus: resData.data.data[0].syncStatus == "created" ? "pass" : "fail" }
      }

      if (this.processIndex == this.validJsonData.length - 1) {
        this.setProcessComplete();
      } else {
        this.processIndex++;
        this.startBulkUpload(null);
      }
    }, (error) => {
      this.bulkUploadProcess = false;
    })
  }

  public statusColor(status) {
    //Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber'
    return status == "pass" ? "text-success" : status == "progress" ? "text-amber" : "text-danger";
  }

  public setProcessComplete() {
    this.processIndex = 0;
    this.bulkUploadProcess = false;
    this.processComplete = true;
  }

  private validateSPBulkData(jobData) {
    jobData.reason = "";
    jobData.isDataInvalid = true;
    jobData.dataProcessStatus = "Not Processed";
    jobData.TeamID = jobData.TeamID?.toString();
    jobData.ChannelID = jobData.ChannelID?.toString();
    jobData.TabName = jobData.TabName?.toString();
    jobData.TabConfig = jobData.TabConfig?.toString();
    jobData.SyncLocation = jobData.SyncLocation?.toString();

    //VALIDATE TEAM ID
    if (jobData.TeamID == undefined || jobData.TeamID == null || jobData.TeamID == "" || jobData.TeamID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "TeamID field missing";
    }

    //VALIDATE CHANNEL ID
    if (jobData.ChannelID == undefined || jobData.ChannelID == null || jobData.ChannelID == "" || jobData.ChannelID.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "ChannelID field missing";
    }

    //VALIDATE Tab Name
    if (jobData.TabName == undefined || jobData.TabName == null || jobData.TabName == "" || jobData.TabName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "TabName field missing";
    }

    if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "") && (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "")) {
      //VALIDATE Tab Config
      if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "")) {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "TabConfig field missing";
      }
      //VALIDATE Sync Location
      if (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "") {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "SyncLocation field missing";
      }
    }

    if (jobData.reason == "") {
      jobData.isDataInvalid = false;
      jobData.reason = "-";
    }

  }


  public openTabConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public openSyncConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public copyTabConfig() {
    var copyText = document.getElementById("tab-config");
    navigator.clipboard.writeText(copyText.textContent);
  }

  public copySyncConfig() {
    var copyText = document.getElementById("sync-config");
    navigator.clipboard.writeText(copyText.textContent);
  }


  public teamExcelExport(e) {
    if (this.downloadProgress) return;
    this.downloadProgress = true;
    this.teamsService.saveBulkTeams().subscribe((res) => {
      if (res.data.status == "success") {
        this.teams = res.data.teams;
        this.excelService.exportAsExcelFile(JSON.parse(this.teams), 'Teams_Data');
        this.downloadProgress = false;
      } else {
        this.downloadProgress = false;
      }
    })
  }

  public toFirstLetterCase(str) {
    if (str == null || str == undefined || str.trim() == "") return "";
    //return str.replace(/\w\S*/g, function (txt) {
    return str.charAt(0).toUpperCase() + str.substr(1);
    //});
  }

}
