import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWorkspacename'
})
export class FilterWorkspacenamePipe implements PipeTransform {

  transform(value: any, args:string): unknown {
    if(args!=undefined && args!=null && args!=""){
      return value.filter(elementValue=>{
        if(elementValue.name.toLowerCase().indexOf(args.toLowerCase())>=0) return true;
      })
    }else{
      return value;
    }
  }

}
