import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sync-frequency',
  templateUrl: './sync-frequency.component.html',
  styleUrls: ['./sync-frequency.component.scss']
})
export class SyncFrequencyComponent implements OnInit {
  public syncTime: any;
  public syncForm: FormGroup;
  public isShowResetIcon: boolean = false;
  public isSyncTimeValidate: boolean = true;
  private freQuencyInSeconds: number = 0;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  // @Input() public syncFrequency: any;
  @Input('syncFrequency') set onSetFrequency(data) {
    this.syncTime = data;
    this.setFormData();
  };


  constructor(private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    // this.setFormData();
  }

  private setFormData() {
    this.syncForm = this.formBuilder.group({
      second: [this.syncTime.second, [Validators.max(59), Validators.min(0)]],
      minute: [this.syncTime.minute, [Validators.max(59), Validators.min(0)]],
      hour: [this.syncTime.hour, [Validators.min(0)]],
    })
    this.isShowResetIcon = (Number(this.syncForm.value.hour) != 0 || Number(this.syncForm.value.minute) != 0 || Number(this.syncForm.value.second)) ? true : false;
    this.convertFrequencyInSeconds();
  }

  onMinuteKeyup(e) {
    if (e.keyCode !== 46
      && e.keyCode !== 8 && this.syncForm.value.minute > 59) {
      this.syncForm.controls.minute.setValue(59);
      e.preventDefault();
    }
  }

  onSecondKeyup(e) {
    if (e.keyCode !== 46
      && e.keyCode !== 8 && this.syncForm.value.second > 59) {
      this.syncForm.controls.second.setValue(59);
      e.preventDefault();
    }
  }

  onKeyPress(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
  }

  changeFrequency() {
    if (this.syncForm.value.hour == null || this.syncForm.value.hour == "" || this.syncForm.value.hour == 0) {
      this.syncForm.controls.hour.setValue('00');
    } else {
      if (this.syncForm.value.hour < 10 && this.syncForm.value.hour.length == 1) this.syncForm.controls.hour.setValue('0' + this.syncForm.value.hour);
    }
    if (this.syncForm.value.minute == null || this.syncForm.value.minute == "" || this.syncForm.value.minute == 0) {
      this.syncForm.controls.minute.setValue('00');
    } else {
      if (this.syncForm.value.minute < 10 && this.syncForm.value.minute.length == 1) this.syncForm.controls.minute.setValue('0' + this.syncForm.value.minute);
    }
    if (this.syncForm.value.second == null || this.syncForm.value.second == "" || this.syncForm.value.second == 0) {
      this.syncForm.controls.second.setValue('00');
    } else {
      if (this.syncForm.value.second < 10 && this.syncForm.value.second.length == 1) this.syncForm.controls.second.setValue('0' + this.syncForm.value.second);
    }
    this.convertFrequencyInSeconds();
    this.onValidateSyncFrequency();
    this.onChange.emit(this.syncForm.value);
  }

  onFrequencyInput() {
    if (Number(this.syncForm.value.hour) != 0 || Number(this.syncForm.value.minute) != 0 || Number(this.syncForm.value.second) != 0) {
      this.isShowResetIcon = true;
    } else {
      this.isShowResetIcon = false;
    }
  }

  private convertFrequencyInSeconds() {
    this.freQuencyInSeconds = ((60 * 60) * this.syncForm.value.hour) + (this.syncForm.value.minute * 60) + (this.syncForm.value.second * 1);
  }

  onFrequencyFocus(event) {
    event.target.select()
  }

  private onValidateSyncFrequency() {
    this.isSyncTimeValidate = (Number(this.freQuencyInSeconds) >= 30) ? true : false;
  }

  onResetFrequency() {
    this.syncForm.controls.hour.setValue('00');
    this.syncForm.controls.minute.setValue('00');
    this.syncForm.controls.second.setValue('30');
    this.isShowResetIcon = false;
    this.onChange.emit(this.syncForm.value);
  }

}
