import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';

@Component({
  selector: 'netdocuments',
  templateUrl: './netdocuments.component.html',
  styleUrls: ['./netdocuments.component.scss']
})
export class NetDocumentsComponent implements OnInit {

  public ndSiteUrl: string = HelperService.ND_SITE_URL();

  public syncTypes: any = [
    { value: "bidirectionalTeams", text: "Bidirectional" },
    { value: "netDocumentsUniTeams", text: "NetDocuments to Teams" },
    { value: "teamsUni", text: "Teams to NetDocuments" }
  ]
  public moveOnList: any = [
    { key: 'immediate', title: 'Immediate' },
    { key: '15d', title: '15 days' },
    { key: '30d', title: '30 days' },
    { key: '60d', title: '60 days' },
    { key: '90d', title: '90 days' },
    { key: '6m', title: '6 months' },
    { key: '12m', title: '12 months' },
    { key: '2y', title: '2 years' },
    { key: '5y', title: '5 years' },
    { key: '10y', title: '10 years' }
  ];

  public fileIcons: any = {
    ndfld: "folder_nd.svg",
    pdf: "pdf_nd.svg",
    doc: "word_nd.svg",
    docx: "word_nd.svg",
    pptx: "powerPoint_nd.svg",
    ppt: "powerPoint_nd.svg",
    xlsx: "excel_nd.svg",
    xls: "excel_nd.svg",
    csv: "excel_nd.svg",
    jpg: "image_nd.svg",
    jpeg: "image_nd.svg",
    png: "image_nd.svg",
    svg: "image_nd.svg",
    txt: "blankDocument_nd.svg",
    url: "webLink_nd.svg",
    zip: "blankDocument_nd.svg",
    ndsq: "savedSearch_nd.svg",
    ndcs: "collaborationSpace_nd.svg",
    eml: "email_nd.svg",
    gen: "genericfile_icon.svg",
    file: "file_icon.svg",
    html: "webLink_nd.svg",
    htm: "webLink_nd.svg",
    css: "blankDocument_nd.svg",
    js: "blankDocument_nd.svg",
    one: "onenote_nd.svg",
    onetoc: "onenote_nd.svg",
    onetoc2: "onenote_nd.svg"
  }

  public toolTip: any = {
    syncFeequency: "This value affects how often syncing will occur. The minimum value should maintain 30 seconds",
    syncType: "Select any one Sync Type",
    copyMetaData: "Turning on this option will add metadata columns from Sharepoint to NetDocuments as custom columns",
    ndCabinet: "Please choose the cabinet where you would like to sync your content",
    ndWorkspace: "Please choose a workspace to select it as your destination",
    ndFolder: "Please choose a folder to select it as your destination",
    applyPermissions: "Turning on this option will add folders and file permission from source path to destination path",
    fileMove: "Turning on this option, Files and folders move from SharePoint to NetDocuments",
    fileMoveOn: "Selecting on this option Files and folders move from SharePoint to NetDocuments on selected period."
  }

  public showLoader: boolean = true;
  public serviceResuest: any = null;
  public isOnpermRestriction: boolean = false;
  public syncTime: any = { hour: "00", minute: "00", second: "30" };
  public selectedSyncType: any = this.syncTypes[2];
  public newColRecord: any = { TeamsCol: '', TeamsColTitle: "", TeamsColDataType: '', NDCol: '', NDColId: null, NDColDataType: '', dataDirection: this.ndvs.syncJobSetting.syncType }
  public isDisabledSave: boolean = true;
  public isNDLoading: boolean = false;
  public isFolerLoading: boolean = false;
  public isShowColumnOptions: boolean = false;
  public isEditMode: boolean = false;

  public isCabinetLoader: boolean = false;
  public cabinets: any = [];
  public selectedCabinet: any = { name: "" };
  public workSpaces: any = [];
  public selectedWorkSpace: any = {};
  public cabinetFolders: any = [];
  public selectCabinetFolder: any = {};
  public workSpaceContents: any = [];
  public cabinetSearch: string = "";
  public isWorkspaceLoader: boolean = false;
  public breadcurmbClicked: boolean = false;
  public isCabinetFolderLoader: boolean = false;

  public ndNextRecord: string = null;
  public ndFilesNextRecord: string = null;
  public ndContentType: string = "folders";
  public isFloderScrollEnded: boolean = false;
  public isFileScrollEnded: boolean = false;
  public executedPagingTokens: any = [];



  public fieldValidation: any = {
    syncFrequency: false,
  }

  constructor(
    public ndvs: NetDocumentViewerService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    //console.log("this.getCabinets===ngOnInit===NetDocumentsComponent")
    this.getCabinets();
  }

  public onChangeSyncType() {
    this.selectedSyncType = this.syncTypes.find(element => element.value == this.ndvs.syncJobSetting.syncType);
    this.ndvs.syncJobSetting.syncType = this.ndvs.syncJobSetting.syncType;
    this.newColRecord.dataDirection = this.ndvs.syncJobSetting.syncType;
    this.ndvs.syncJobSetting.columnOptions = [];
    this.ndvs.syncJobSetting.syncMetaData = false;
    this.ndvs.syncJobSetting.fileMove = false;
    this.ndvs.syncJobSetting.sharePoint.libraryType = "document";
    this.ndvs.syncJobSetting.sharePoint.transferType = "flatten";
    switch (this.ndvs.syncJobSetting.syncType) {
      case "teamsUni":
      case "bidirectionalTeams": ;
        this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
        this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
        this.ndvs.syncJobSetting.netDocuments.selectAll = false;
        this.ndvs.syncJobSetting.netDocuments.cabinetId = this.selectedCabinet.id;
        this.ndvs.syncJobSetting.netDocuments.cabinetName = this.selectedCabinet.name;
        this.ndvs.syncJobSetting.netDocuments.repositoryId = this.selectedCabinet.repositoryId;
        this.ndvs.syncJobSetting.netDocuments.workspaceId = this.selectedWorkSpace.id
        this.ndvs.syncJobSetting.netDocuments.workspaceName = this.selectedWorkSpace.name
        this.ndvs.syncJobSetting.netDocuments.workspaceEnvId = this.selectedWorkSpace.envId
        this.ndvs.syncJobSetting.oneDrive = { tenantId: "", clientId: "", clientSecret: "", userEmail: "", folderId: "", folderName: "", breadCurmb: [], sources: { folders: [], files: [] }, selectAll: false }
        if (this.ndvs.syncJobSetting.netDocuments.workspaceId == '' && this.ndvs.syncJobSetting.netDocuments.folderId == '') {
          this.onSelectCabinet(this.selectedCabinet);
        } else {
          this.onSelectCabinet(this.selectedCabinet, null, null, this.ndvs.syncJobSetting.netDocuments);
        }

        this.onRestrictFile();
        break;
      case "netDocumentsUniTeams":
        this.ndvs.syncJobSetting.oneDrive = { tenantId: "", clientId: "", clientSecret: "", userEmail: "", folderId: "", folderName: "", breadCurmb: [], sources: { folders: [], files: [] }, selectAll: false }
        this.ndvs.syncJobSetting.sharePoint.sources.folders = [];
        this.ndvs.syncJobSetting.sharePoint.sources.files = [];
        this.ndvs.syncJobSetting.sharePoint.selectAll = false;
        break;
    }
    this.ndvs.onChangeSyncType.emit(this.ndvs.syncJobSetting.syncType);
    this.onSaveEnable()
  }

  public onChangeFrequency(syncTime = null) {
    if (syncTime == 'init') {
      this.syncTime = { hour: this.ndvs.syncJobSetting.syncFrequency.split(":")[0], minute: this.ndvs.syncJobSetting.syncFrequency.split(":")[1], second: this.ndvs.syncJobSetting.syncFrequency.split(":")[2] };
    }
    else {
      this.syncTime = syncTime;
      this.ndvs.syncJobSetting.syncFrequency = syncTime.hour + ':' + syncTime.minute + ':' + syncTime.second;
    }
    this.ndvs.syncJobSetting.syncFrequencyInSecond = ((60 * 60) * this.syncTime.hour) + (this.syncTime.minute * 60) + (this.syncTime.second * 1);
    // this.isSyncTimeLoaded = true;
    this.onValidateSyncFrequency();
  }

  public onValidateSyncFrequency() {
    this.fieldValidation.syncFrequency = (this.ndvs.syncJobSetting.syncFrequencyInSecond == "NaN" || this.ndvs.syncJobSetting.syncFrequencyInSecond < 30) ? true : false;
    this.onSaveEnable();
  }

  public showSPColumnOption() {
    this.ndvs.syncJobSetting.syncMetaData = (this.ndvs.syncJobSetting.syncMetaData == true || this.ndvs.syncJobSetting.syncMetaData == "true") ? true : false;
    this.isShowColumnOptions = ((this.ndvs.syncJobSetting.sharePoint.listName != undefined && this.ndvs.syncJobSetting.sharePoint.listName != null && this.ndvs.syncJobSetting.sharePoint.listName != '') && this.ndvs.syncJobSetting.syncMetaData) ? true : false;
    this.ndvs.syncJobSetting.columnOptions = []
    this.onSaveEnable()
  }

  public onChangeApplyPermission() {
    this.ndvs.syncJobSetting.applyPermissions = (this.ndvs.syncJobSetting.applyPermissions == true || this.ndvs.syncJobSetting.applyPermissions == "true") ? true : false;
    this.onSaveEnable();
  }

  public onChangeFileMove() {
    this.ndvs.syncJobSetting.fileMove = (this.ndvs.syncJobSetting.fileMove == true || this.ndvs.syncJobSetting.fileMove == "true") ? true : false;
    this.onSaveEnable();
  }

  public getCabinets(netDocData = null) {
    this.isCabinetLoader = true;
    this.ndvs.getCabinets().subscribe((resData) => {
      this.isCabinetLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinets = resData.data;
        if (netDocData != null) {
          let curSelCabinet = this.cabinets.find((element => element.id == netDocData.cabinetId));
          this.onSelectCabinet(curSelCabinet, null, null, netDocData);
        } else {
          this.showLoader = false;
        }
      } else {
        this.cabinets = [];
        this.showLoader = false;
      }
    })
  }

  public onNDScroll(event) {
    if (event.target.scrollHeight - event.target.clientHeight <= event.target.scrollTop) {
      if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
        if (this.isFileScrollEnded) return;
        let contentSource: string = "";
        if ((this.ndvs.syncJobSetting.netDocuments.workspaceId != "" && this.ndvs.syncJobSetting.netDocuments.folderId != "") || (this.ndvs.syncJobSetting.netDocuments.workspaceId == "" && this.ndvs.syncJobSetting.netDocuments.folderId != "")) {
          contentSource = this.ndvs.syncJobSetting.netDocuments.folderId;
        } else {
          contentSource = this.ndvs.syncJobSetting.netDocuments.workspaceId;
        }
        this.isNDLoading = true;
        this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
      } else {
        if (this.ndNextRecord != null) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
      }
    }
  }

  public onSelectCabinetFolder(cabFolder) {
    if (this.isNDLoading) return;
    this.selectCabinetFolder = cabFolder;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
    this.executedPagingTokens = [];
    this.isFolerLoading = false;
    this.onSelectContent(cabFolder);
  }

  public onSelectContent(content) {
    if (this.isNDLoading) return;
    if (content.Attributes.Ext == "ndfld" || content.Attributes.Ext == "ndsq") {
      if (this.ndvs.syncJobSetting.syncType == 'netDocumentsUniTeams' && (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0)) {
        this.onChooseNDContent(null, content);
      } else {
        if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) return
        this.cabinetSearch = "";
        this.ndvs.syncJobSetting.netDocuments.breadCurmb.push({ DocId: content.DocId, Attributes: { Name: content.Attributes.Name, Ext: content.Attributes.Ext }, EnvId: content.EnvId });
        this.ndvs.syncJobSetting.netDocuments.folderId = content.DocId;
        this.ndvs.syncJobSetting.netDocuments.folderName = content.Attributes.Name;
        this.onResetScrollVars();
        this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, content.DocId);
      }
    } else {
      if (this.ndvs.syncJobSetting.syncType == 'netDocumentsUniTeams') this.onChooseNDContent(null, content);
    }
    this.onSaveEnable();
  }

  public onResetRoot() {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.selectedWorkSpace = {};
    this.selectCabinetFolder = {};
    this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    this.onResetScrollVars();
    this.onSelectCabinet(this.selectedCabinet);
    this.onSaveEnable();
  }

  public onResetWorkspace() {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    this.onResetScrollVars();
    this.onSelectWorkSpace(this.selectedWorkSpace);
    this.onSaveEnable()
  }

  public onLoadBreadCurmp(currentFolder, breadcurmbIndex) {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    if (breadcurmbIndex < (this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1)) {
      let removeItems = this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - breadcurmbIndex;
      this.ndvs.syncJobSetting.netDocuments.breadCurmb.splice(breadcurmbIndex + 1, removeItems);
    }
    this.ndvs.syncJobSetting.netDocuments.folderId = currentFolder.DocId;
    this.ndvs.syncJobSetting.netDocuments.folderName = currentFolder.Attributes.Name;
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.onResetScrollVars();
    this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, currentFolder.DocId);
  }

  public onChooseNDContent(event, content) {
    if (content.isRestricted) return;
    if (event == null) {
      content.isChecked = (content.isChecked) ? false : true;
    } else {
      content.isChecked = event.target.checked;
    }
    if (content.isChecked) {
      if (content.Attributes.Ext == "ndfld" || content.Attributes.Ext == "ndsq") {
        this.ndvs.syncJobSetting.netDocuments.sources.folders.push(content.DocId)
      } else {
        this.ndvs.syncJobSetting.netDocuments.sources.files.push(content.DocId)
      }
    } else {
      if (content.Attributes.Ext == "ndfld" || content.Attributes.Ext == "ndsq") {
        let foderIndex = this.ndvs.syncJobSetting.netDocuments.sources.folders.findIndex((element => element == content.DocId))
        if (foderIndex != null && foderIndex >= 0) this.ndvs.syncJobSetting.netDocuments.sources.folders.splice(foderIndex, 1);
      } else {
        let fileIndex = this.ndvs.syncJobSetting.netDocuments.sources.files.findIndex((element => element == content.DocId))
        if (fileIndex != null && fileIndex >= 0) this.ndvs.syncJobSetting.netDocuments.sources.files.splice(fileIndex, 1);
      }
    }
    this.updateNDSelectAllStatus();
  }

  public onNDSelectAll() {
    if (this.workSpaceContents.length > 0) {
      this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
      this.workSpaceContents.forEach(element => {
        element.isChecked = (element.isRestricted) ? false : this.ndvs.syncJobSetting.netDocuments.selectAll;
        if (element.isChecked) {
          if (element.Attributes.Ext == "ndfld" || element.Attributes.Ext == "ndsq") {
            this.ndvs.syncJobSetting.netDocuments.sources.folders.push(element.DocId);
          } else {
            this.ndvs.syncJobSetting.netDocuments.sources.files.push(element.DocId);
          }
        }
      });
    }
    this.onSaveEnable();
  }

  public onSelectCabinet(cabinet, dropdown = null, modalTemplate = null, netDocData = null) {
    if (dropdown != null) dropdown.close();
    this.assignSelectdCabined(cabinet, netDocData);
    // if(this.selectedCabinet.id==undefined || this.ndvs.syncJobSetting.columnOptions.length==0){
    //   this.assignSelectdCabined(cabinet,netDocData);
    // }else{
    //   this.modalService.open(modalTemplate).result.then((resData)=>{
    //     if(resData=="yes"){
    //       this.ndvs.syncJobSetting.columnOptions=[];
    //       this.assignSelectdCabined(cabinet,netDocData);
    //     }
    //   }).catch((resError)=>{
    //     this.logService.updateLogMessage("SharePoint column property confirmation popup error => "+ JSON.stringify(resError))
    //   });

    // }
  }

  public assignSelectdCabined(cabinet, netDocData) {
    this.selectedCabinet = cabinet;
    this.selectedWorkSpace = {}
    this.selectCabinetFolder = {};
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.cabinetSearch = "";
    this.onResetScrollVars();
    if (netDocData == null) this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.cabinetId = this.selectedCabinet.id;
    this.ndvs.syncJobSetting.netDocuments.cabinetName = this.selectedCabinet.name
    this.ndvs.syncJobSetting.netDocuments.repositoryId = this.selectedCabinet.repositoryId;
    if (netDocData != null) {
      if (netDocData.folderId != undefined && netDocData.folderId != null && netDocData.folderId != "") {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {
          this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData.folderId);
          // this.onSelectWorkSpace({id:netDocData.workspaceId,name:netDocData.workspaceName},null,netDocData);
        }
      } else {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {
          if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData)
          // this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId,netDocData); 
        }
      }
    } else {
      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId)
    }
  }

  public onRestrictFile() {
    this.workSpaceContents.forEach(fileElement => {
      if (this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType != null && this.ndvs.syncJobSetting.sharePoint.connectionType == 'online') {
        if (fileElement.Attributes.Size >= 100000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else if (this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType != null && this.ndvs.syncJobSetting.sharePoint.connectionType == 'onpremises') {
        if (fileElement.Attributes.Size >= 15000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else {
        fileElement.isRestricted = false;
      }
    });
  }

  public getWorkspace(cabinetId, netDocData = null, skipToken = null) {
    if (cabinetId == undefined) return;
    this.isNDLoading = true;
    if (skipToken == null) {
      this.selectedWorkSpace = {};
      this.workSpaces = [];
      this.ndvs.syncJobSetting.netDocuments.workspaceId = "";
    }
    this.isWorkspaceLoader = true;
    this.ndvs.getWorkspace(cabinetId, skipToken).subscribe((resData) => {
      this.isWorkspaceLoader = false;
      this.breadcurmbClicked = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.ndNextRecord = resData.skipToken;
        if (skipToken == null) {
          this.workSpaces = resData.data;
        } else {
          this.workSpaces = this.workSpaces.concat(resData.data);
        }
        if (this.workSpaces.length > 0) {
          if (netDocData != null) {
            if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
              let curSelWS = this.workSpaces.find((element => element.id == netDocData.workspaceId));
              this.onSelectWorkSpace(curSelWS, null, netDocData);
            } else {
              // this.workSpaces=[];
              this.showLoader = false;
              if (this.ndNextRecord == null) {
                this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
              } else {
                this.isNDLoading = false;
              }
            }
          } else {
            this.showLoader = false;
            if (this.ndNextRecord == null) {
              this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
            } else {
              this.isNDLoading = false;
            }
          }
        } else {
          this.showLoader = false;
          if (this.ndNextRecord == null) {
            this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
          } else {
            this.isNDLoading = false;
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
      }
    })
  }

  public onChangeCabinetSearch() {
    this.ndNextRecord = null;
    if (this.cabinetSearch != "") return;
    this.onResetScrollVars();
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvs.syncJobSetting.netDocuments.folderId != "") ? this.ndvs.syncJobSetting.netDocuments.folderId : this.ndvs.syncJobSetting.netDocuments.workspaceId;
      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.workSpaces = [];
      this.cabinetFolders = [];
      if (this.selectedCabinet.id != undefined) {
        this.ndvs.getWorkspace(this.selectedCabinet.id, this.ndNextRecord).subscribe((resData) => {
          this.isWorkspaceLoader = false;
          if (resData.status == "success" && resData.data != undefined) {
            this.workSpaces = resData.data;
          }
          this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId);
        });
      }
    }
  }

  public onSearchKeyPress(event) {
    if (event.keyCode == 13) this.onSearchWorkspace();
  }

  public onSearchWorkspace(skipToken = null) {
    if (this.cabinetSearch.trim() == '' || this.cabinetSearch.length < 3 || this.isNDLoading) return;
    if (skipToken == null) this.onResetScrollVars();
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvs.syncJobSetting.netDocuments.folderId != "") ? this.ndvs.syncJobSetting.netDocuments.folderId : this.ndvs.syncJobSetting.netDocuments.workspaceId;
      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.ndvs.getSearchWorkspace(this.selectedCabinet.id, this.cabinetSearch, skipToken).subscribe((resData) => {
        this.isWorkspaceLoader = false;
        if (resData.status == "success" && resData.data != undefined) {
          this.ndNextRecord = resData.skipToken;
          if (skipToken == null) {
            this.workSpaces = resData.data;
          } else {
            this.workSpaces = this.workSpaces.concat(resData.data);
          }
        } else {
          this.showLoader = false;
        }
      });
    }
  }

  public getCabinetFolders(cabinetId, netDocData = null) {
    this.isNDLoading = true;
    this.selectCabinetFolder = {};
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    let recordFrom = null;
    this.isCabinetFolderLoader = true;
    this.ndvs.getCabinetFolder(cabinetId, recordFrom).subscribe((resData) => {
      this.isCabinetFolderLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinetFolders = resData.data;
        if (this.cabinetFolders.length > 0) {
          if (netDocData != null) {
            let curSelCabFolder = this.cabinetFolders.find((element => element.DocId == netDocData.folderId));
            if (curSelCabFolder != undefined && curSelCabFolder != null) {
              this.selectCabinetFolder = curSelCabFolder
              this.ndvs.syncJobSetting.netDocuments.folderId = this.selectCabinetFolder.DocId;
              this.ndvs.syncJobSetting.netDocuments.folderName = this.selectCabinetFolder.Attributes.Name;
              this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.selectCabinetFolder.DocId);
            } else {
              if (netDocData.contents != undefined && netDocData.contents != null && netDocData.contents.length > 0) {
                this.cabinetFolders.forEach(foldFindEle => {
                  foldFindEle.isChecked = (netDocData.contents.indexOf(foldFindEle.DocId) >= 0) ? true : false;
                })
              }
              this.showLoader = false;
              this.isNDLoading = false;
            }
          } else {
            this.showLoader = false;
            this.isNDLoading = false;
            // this.onSelectWorkSpace(this.workSpaces[0]);   
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
        this.workSpaces = [];
      }
    })
  }

  public onSelectWorkSpace(workspace, dropdown = null, netDocData = null) {
    this.selectedWorkSpace = workspace;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.ndvs.syncJobSetting.netDocuments.workspaceId = this.selectedWorkSpace.id;
    this.ndvs.syncJobSetting.netDocuments.workspaceName = this.selectedWorkSpace.name;
    this.ndvs.syncJobSetting.netDocuments.workspaceEnvId = this.selectedWorkSpace.envId;
    if (dropdown != null) dropdown.close();
    this.onResetScrollVars();
    if (netDocData == null || this.ndvs.syncJobSetting.netDocuments.breadCurmb == undefined || this.ndvs.syncJobSetting.netDocuments.breadCurmb.length == 0) {
      this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
      if (netDocData == null) this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
      this.ndvs.syncJobSetting.netDocuments.folderId = "";
      this.ndvs.syncJobSetting.netDocuments.folderName = "";
      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.ndvs.syncJobSetting.netDocuments.workspaceId);
    } else {
      this.ndvs.syncJobSetting.netDocuments.folderId = this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1].DocId;
      this.ndvs.syncJobSetting.netDocuments.folderName = this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1].Attributes.Name;
      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.ndvs.syncJobSetting.netDocuments.folderId)
      // }    
    }
  }

  public getWorkspaceContents(cabinetId, workspaceId, skipToken = null) {
    this.isNDLoading = true;
    this.isFolerLoading = true;
    if (skipToken != null) {
      if (this.executedPagingTokens.indexOf(skipToken) < 0) {
        this.executedPagingTokens.push(skipToken);
      } else {
        return;
      }
    } else {
      this.executedPagingTokens = [];
    }
    this.workSpaces = [];
    this.cabinetFolders = [];
    if (skipToken == null && !this.isFloderScrollEnded) this.workSpaceContents = [];
    if (this.ndContentType == "folders" && this.ndvs.syncJobSetting.netDocuments.folderId == "") {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      this.serviceResuest = this.ndvs.getNetDocumentsFolders(cabinetId, workspaceId, skipToken).subscribe((resData) => {
        this.showLoader = false;
        this.breadcurmbClicked = false;
        if (resData.status == "success" && resData.data != undefined) {
          if (resData.data.length > 0) {
            resData.data.forEach(element => {
              if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
                element.isChecked = true;
              } else {
                if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
                if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
              }
              this.workSpaceContents.push(element);
            });
            if (resData.skipToken != undefined && resData.skipToken != null && resData.skipToken != "") {
              this.ndFilesNextRecord = resData.skipToken;
              this.isFolerLoading = false;
            } else {
              this.isFolerLoading = false;
              this.isFloderScrollEnded = true;
              this.ndContentType = "files";
              this.getWorkspaceContents(cabinetId, workspaceId)
            }
          } else {
            this.isFolerLoading = false;
            this.isFloderScrollEnded = true;
            this.ndContentType = "files";
            this.getWorkspaceContents(cabinetId, workspaceId)
          }
        } else {
          this.isFolerLoading = false;
          this.isFloderScrollEnded = true;
          this.ndContentType = "files";
          this.getWorkspaceContents(cabinetId, workspaceId)
        }
      });
    } else {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      this.serviceResuest = this.ndvs.getFilesAdnFolders(cabinetId, workspaceId, this.cabinetSearch, this.ndContentType, skipToken).subscribe((resData) => {
        this.isFolerLoading = false;
        this.showLoader = false;
        this.breadcurmbClicked = false;
        if (resData.status == "success" && resData.data != undefined) {
          if (resData.data.Results != undefined && resData.data.Results.length > 0) {
            this.ndFilesNextRecord = resData.skipToken;
            this.workSpaceContents = this.workSpaceContents.concat(resData.data.Results);
            this.onRestrictFile();
            this.workSpaceContents.forEach(element => {
              if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
                element.isChecked = true;
              } else {
                if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
                if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
              }
            });
            this.isNDLoading = false;
            this.updateNDSelectAllStatus(this.ndvs.syncJobSetting.netDocuments.selectAll);
          }
          if (this.ndFilesNextRecord == null && !this.isFloderScrollEnded) {
            this.isFloderScrollEnded = true;
            this.ndContentType = "files"
            this.getWorkspaceContents(cabinetId, workspaceId, this.ndFilesNextRecord);
          } else if (this.ndFilesNextRecord == null && this.isFloderScrollEnded && !this.isFileScrollEnded) {
            this.isFileScrollEnded = true;
            this.isNDLoading = false;
          }
          this.onSaveEnable();
        }
      })
    }
  }

  public updateNDSelectAllStatus(selectAllStstus = null) {
    if (selectAllStstus != null) {
      this.ndvs.syncJobSetting.netDocuments.selectAll = selectAllStstus;
    } else {
      let restricetedFiles = this.workSpaceContents.filter(resFile => (resFile.isRestricted == true));
      let filesCount = (restricetedFiles == undefined || restricetedFiles == null || restricetedFiles.length == 0) ? this.workSpaceContents.length : (this.workSpaceContents.length - restricetedFiles.length)
      if ((this.ndvs.syncJobSetting.netDocuments.sources.folders.length + this.ndvs.syncJobSetting.netDocuments.sources.files.length) == filesCount) {
        this.ndvs.syncJobSetting.netDocuments.selectAll = true;
      } else {
        this.ndvs.syncJobSetting.netDocuments.selectAll = false;
      }
    }
    this.onSaveEnable();
  }

  public onRefreshNetDocumentContants() {
    if ((!this.isNDLoading && this.ndvs.syncJobSetting.netDocuments.cabinetId == '') || (this.isNDLoading)) return;
    this.onResetScrollVars()
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      if ((this.ndvs.syncJobSetting.netDocuments.workspaceId != "" && this.ndvs.syncJobSetting.netDocuments.folderId != "") || (this.ndvs.syncJobSetting.netDocuments.workspaceId == "" && this.ndvs.syncJobSetting.netDocuments.folderId != "")) {
        contentSource = this.ndvs.syncJobSetting.netDocuments.folderId;
      } else {
        contentSource = this.ndvs.syncJobSetting.netDocuments.workspaceId;
      }
      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
    }
  }

  public onSaveEnable() {
    this.isDisabledSave = true
    if (this.isNDLoading || this.isFolerLoading) return;
    switch (this.ndvs.syncJobSetting.syncType) {
      case "bidirectionalTeams":
      case "teamsUni":
      case "netDocumentsUniTeams":
        if (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && (this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "")) && this.ndvs.syncJobSetting.sharePoint.siteAddress != "" && this.ndvs.syncJobSetting.sharePoint.listUrl != "") {
          if (this.ndvs.syncJobSetting.syncType == "teamsUni") {
            if (this.ndvs.syncJobSetting.sharePoint.libraryType == 'list') {
              this.isDisabledSave = false;
            } else {
              if ((this.ndvs.syncJobSetting.sharePoint != undefined && this.ndvs.syncJobSetting.sharePoint != null && this.ndvs.syncJobSetting.sharePoint.sources != undefined && this.ndvs.syncJobSetting.sharePoint.sources != null) && (this.ndvs.syncJobSetting.sharePoint.sources.files != undefined && this.ndvs.syncJobSetting.sharePoint.sources.files != null && this.ndvs.syncJobSetting.sharePoint.sources.files.length > 0) || (this.ndvs.syncJobSetting.sharePoint.sources.folders != undefined && this.ndvs.syncJobSetting.sharePoint.sources.folders != null && this.ndvs.syncJobSetting.sharePoint.sources.folders.length > 0)) this.isDisabledSave = false;
            }
          } else if (this.ndvs.syncJobSetting.syncType == "netDocumentsUniTeams") {
            if (this.ndvs.syncJobSetting.netDocuments != undefined && this.ndvs.syncJobSetting.netDocuments.sources != undefined && (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0)) this.isDisabledSave = false;
          } else {
            if ((this.ndvs.syncJobSetting.sharePoint != undefined && this.ndvs.syncJobSetting.sharePoint != null && this.ndvs.syncJobSetting.sharePoint.listUrl != undefined && this.ndvs.syncJobSetting.sharePoint.listUrl != null && this.ndvs.syncJobSetting.sharePoint.listUrl != "") && (this.ndvs.syncJobSetting.netDocuments != undefined && this.ndvs.syncJobSetting.netDocuments != null && this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined && this.ndvs.syncJobSetting.netDocuments.cabinetId != null && this.ndvs.syncJobSetting.netDocuments.cabinetId != '' && ((this.ndvs.syncJobSetting.netDocuments.workspaceId != undefined && this.ndvs.syncJobSetting.netDocuments.workspaceId != null && this.ndvs.syncJobSetting.netDocuments.workspaceId != "") || (this.ndvs.syncJobSetting.netDocuments.folderId != undefined && this.ndvs.syncJobSetting.netDocuments.folderId != null && this.ndvs.syncJobSetting.netDocuments.folderId != "")))) this.isDisabledSave = false;
          }
        } else {
          this.isDisabledSave = true;
        }
        break;
    }

  }

  public onCancelSyncJob() {
    this.router.navigateByUrl("/syncjobs");
  }


  private isfileCharValidated(fileName) {
    let validateChars = ['"', '*', ':', '<', '>', '?', '/', '\\', '|'];
    let isAvailable: boolean = true;
    validateChars.forEach((char) => {
      if (fileName.indexOf(char) >= 0) isAvailable = false;
    })
    return isAvailable;
  }

  private onResetScrollVars() {
    this.ndNextRecord = null;
    this.ndFilesNextRecord = null;
    this.ndContentType = "folders";
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
  }

}
