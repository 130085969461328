import { Component, OnInit, EventEmitter } from '@angular/core';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { GlobalService } from "../../services/global.service"
import { ActivatedRoute, Router } from '@angular/router';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';
@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss']
})
export class ProvisioningComponent implements OnInit {

  public isLoading: boolean = false;
  public isAccessFromExternalApp: boolean = false;
  public syncUrl = "";
  public tabs = [];
  public activeTab = "";
  public clientMatterInfo = {};
  constructor(
    public ndvs: NetDocumentViewerService,
    public global: GlobalService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private netDocLocalStorage: NetdocshareLocalstorageService
  ) {


  }


  ngOnInit(): void {
    this.externalAppListener().subscribe(isAccessFromExternalApp => {
      this.isAccessFromExternalApp = isAccessFromExternalApp;
      this.isLoading = false;
      if (!isAccessFromExternalApp) {
        if (this.ndvs.currentUser.role == "globalAdmin" || this.ndvs.currentUser.role == "syncAdmin") { this.global.isGlobalAdmin = true; }
        this.tabs = [
          { title: "Teams", value: "teams", isVisible: this.global.isGlobalAdmin },
          { title: "Requests", value: "requests", isVisible: this.global.isGlobalAdmin },
          // { title: "Create Request", value: "createRequest", isVisible: this.global.isGlobalAdmin },
          { title: "Create Request", value: "createRequest", isVisible: !this.global.isGlobalAdmin },
          { title: "My Requests", value: "myRequests", isVisible: !this.global.isGlobalAdmin }
        ];
        if (this.global.isGlobalAdmin) { this.activeTab = "teams" } else { this.activeTab = "createRequest" };
        this.activatedRoute.params.subscribe((param) => {
          if (param.activetab) {
            const validActiveTab = this.tabs.find(tab => tab.value?.toLowerCase() == param.activetab?.toLowerCase() && tab.isVisible);
            if (validActiveTab) {
              this.activeTab = validActiveTab.value;
            }
          }
        });
        //this.activeTab = this.activatedRoute.snapshot.params.activeTab;
      }
    })

  }
  onTabChange(tab) {
    this.router.navigateByUrl(`/provisioning/${tab.nextId}`);
  }


  public onCloseNDSection() {
    this.ndvs.showSyncPopup = false;
    this.global.syncFrom = ""
  }

  public externalAppListener() {
    this.isLoading = true;
    let outData: EventEmitter<any> = new EventEmitter<any>();
    const externalAppListenerEvent = (data: any) => {
      if (data.data != undefined && data.data.app != undefined) {
        if (data.data.app == "netDocShare-core") {
          window.parent.postMessage({ type: "loading", isLoading: false }, "*");
          try {
            this.netDocLocalStorage.ndInfo = atob(data.data.ndInfo);
            this.netDocLocalStorage.licenseInfo = JSON.parse(atob(data.data.licenseInfo));
            this.clientMatterInfo = data.data.clientMatterInfo;
          } catch (ex) { }
          setTimeout(() => {
            outData.emit(true);
          }, 200)
        } else if (data.data.app == "netDocShare-core-removeListener") {
          window.removeEventListener("message", externalAppListenerEvent);
        } else if (data.data.app == "netDocShare-core-request-reload") {
          window.location.reload();
        }
      }
    };
    let isIFrame: boolean = window.self !== window.top;
    if (isIFrame) {
      window.parent.postMessage("check-netDocShare-core", "*");
      window.addEventListener("message", externalAppListenerEvent);
    } else {
      setTimeout(() => {
        outData.emit(false);
      }, 200)
    }
    return outData;
  }

}
