<div class="ip-container-body p-2">
  <div class="col-md-8 p-0 row mx-0">
    <div class="col-md-3 p-0">
      Tenant
      <span class="red-text">*</span>
    </div>
    <div class="col-md-9 p-0">
      <div
        class="input-group"
        id="tenantPicker">
        <input
          type="text"
          class="form-control"
          placeholder="Tenant Name"
          (click)="onShowConnectionsList()"
          (input)="onChangeConnectionName($event)"
          [(ngModel)]="tenantName"
          [disabled]="((selectedConnection.isConnected!=undefined && selectedConnection.isConnected)) || connectionLoader">
        <div class="input-group-append">
          <button
            *ngIf="(selectedConnection.isConnected==undefined || !selectedConnection.isConnected)"
            class="btn btn-md im-primary-btn waves-effect"
            type="button"
            (click)="onTenantConnect()"
            [disabled]="((selectedConnection.tenantName==undefined || selectedConnection.tenantName==null || selectedConnection.tenantName=='') || connectionLoader )">
            <img
              *ngIf="!connectionLoader"
              src="assets/images/icons/link-icon.svg"
              class="search-button-img">
            <img
              *ngIf="connectionLoader"
              src="assets/images/Spinner.gif"
              class="search-button-img">
          </button>
          <button
            *ngIf="(selectedConnection.isConnected!=undefined && selectedConnection.isConnected)"
            class="btn btn-md im-primary-btn waves-effect"
            type="button"
            (click)="onResetConnection()">
            <!-- <span class="material-icons-sharp md-refresh fs-20"></span> -->
            <span class="material-icons-sharp md-close fs-20"></span>

            <!-- <img
              src="assets/images/icons/sync-white-icon.svg"
              class="search-button-img"> -->
          </button>
        </div>
        <div class="d-flex ml-2">
          <button
            *ngIf="(selectedConnection.isConnected!=undefined && selectedConnection.isConnected)"
            class="btn btn-blue"
            type="button"
            (click)="onRefreshTeams()">
            <!-- <img
              *ngIf="!teamLoading"
              src="assets/images/icons/sync-white-icon.svg"
              class="search-button-img"> -->
              <span *ngIf="!teamLoading" class="material-icons-sharp md-refresh"></span>
            <img
              *ngIf="teamLoading"
              src="assets/images/Spinner.gif"
              class="search-button-img">
          </button>
          <i
            *ngIf="(isAdmin)"
            class="cp"
            (click)="onShowTeamsSetting()"><img
              src="./assets/images/icons/setting-header-icon.svg"
              class="page-title-icon"></i>

        </div>
        <div
          *ngIf="showConnectionsList"
          class="tenant-list-conteiner">
          <li
            class="tenant-list"
            *ngFor="let connection of connectionsList|connectionName:tenantName"
            (click)="onSelectConnection(connection)">{{connection.tenantName}}
                    </li>
        </div>
      </div>
    </div>
  </div>
</div>
