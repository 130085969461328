import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, Form } from '@angular/forms';
import { SyncNetDocLocationComponent } from '../sync-net-doc-location/sync-net-doc-location.component';
import { GlobalService } from 'src/app/services/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsService } from 'src/app/services/teams.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { SpService } from 'src/app/services/sp.service';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss']
})
export class CreateRequestComponent implements OnInit {
  @ViewChild(SyncNetDocLocationComponent) syncLocation;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveTeam: EventEmitter<any> = new EventEmitter<any>();
  @Input("role") role: string = "";
  @Input("requestFromBulkEdit") requestFromBulkEdit: boolean = false;
  @Input("isBulkEditMode") public isBulkEditMode: boolean = false;
  @Input("isFromRequestPage") public isFromRequestPage: boolean = false;
  @Input("teamName") teamName: string;
  @Input("teamDetail") teamData: any = {}
  @Input("clientMatterInfo") set onSetClientMatterInfo(data: any) {
    this.clientMatterInfo = data;
    this.populateClientMatterData();
  }
  public isEditMode: boolean = false;
  public createRequestLoader: boolean = false;
  public formTeamReq: FormGroup;
  public tabCreatenableorDiasble: boolean = false;
  public channelData: any = {};
  //public syncJobSetting: any = {};
  public channelPid: any;
  public tabConfigSetting: any = {};
  public tabConfiguration: any = {};
  public tabSetting: boolean = false;
  public configSetting: any = null;
  public selectedTab: any = null;
  private reqFormId: string = '';
  public tabCreationCheck: boolean = false;
  public syncAddon: Boolean = false;
  public teamsAddon: Boolean = false;
  public syncCreateCheck: Boolean = false;
  public adminListData: any = [];
  public connectionsList: any = [];
  public configSettingListener: any;
  @Output() onConfigChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRequestCreated: EventEmitter<any> = new EventEmitter<any>();
  public isSync: string = "";
  public editDetails: any = {};
  public syncCheck: boolean = false;
  public channelIndex: number = -1;
  public tabIndex: number = -1;
  public ClientMatterWsInfo: any;
  public templateTab: Boolean = false;
  httpResponse: any = {};
  customizedClientMatters: any = [];
  customizedClientMatterIndex: number = -1;
  isFetchingWorkspaceInfo: boolean = false;
  lookupDataCabinet: any = {};
  lookupDataClient: any = {};
  lookupDataMatter: any = {};
  public validateSPSiteCollection = {
    isValid: false,
    isFetching: false,
    message: "",
    typing: null,
    status: ""
  }
  public sharePointSiteTemplate: any = {
    data: [],
    isFetching: false,
    selected: {},
    syncDetails: {}
  }
  cabinet: any = {
    data: [],
    message: "",
    selected: {},
    isFetching: false
  };
  public client: any = {
    data: [],
    message: "",
    selected: {},
    isFetching: false,
    clientInfo: { name: "Client" }
  };
  public matter: any = {
    data: [],
    message: "",
    selected: {},
    isFetching: false,
    matterInfo: { name: "Matter" }
  };
  public template: any = {
    data: [],
    message: "",
    selected: {},
    isFetching: false
  };
  public clientMatterTop = 10;

  public lookupDataTemplate: any = {};
  public sharepointConnection: any = {};
  public sharePointSyncDetails: any = {};
  public selectedSharePointTemplate: any = {};
  public workspaceMembers = [];
  public workspaceGroups = [];
  public repositoryUsers: any = [];
  appConfig: any = {
    viewType: 'createConfig',
    assetPath: `${environment.appUrl}` + '/assets/images/'
    //assetPath: 'http://localhost:4500/assets/images/'
  };
  public clientMatterInfo: any = {};

  constructor(public global: GlobalService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    public teamsService: TeamsService,
    public ndvs: NetDocumentViewerService,
    private router: Router,
    private _ngzone: NgZone,
    private cdr: ChangeDetectorRef,
    private spService: SpService,
    private netDocLocalStorage: NetdocshareLocalstorageService

  ) {
    this.fetchCabinets();
    this.getSharePointSiteTemplate();
  }

  ngOnInit(): void {
    let RequestId = this.activatedRoute.snapshot.params.reqId;
    this.reqFormId = RequestId;
    if (RequestId != undefined && RequestId != null && RequestId != '') this.isEditMode = true;
    if (this.isEditMode) {
      this.teamsService.findRequest(RequestId).subscribe((resData: any) => {
        this.editDetails = resData.data;
        this.cabinet.selected = resData.data?.cabinet;
        if (resData.data?.cabinet) {
          this.onSourceCabinetChange(resData.data?.cabinet);
        }
        if (resData.data?.client) {
          this.client.selected = resData.data?.client;
          this.matter.selected = resData.data?.matter;
          this.formTeamReq.get('client').setValue(resData.data.client);
          this.formTeamReq.get('matter').setValue(resData.data.matter);
        }
        this.ClientMatterWsInfo = resData.data?.workspace;
        this.template.selected = resData.data?.teamsTemplate;
        this.formTeamReq.get('teamName').setValue(resData.data.teamName);
        this.formTeamReq.get('description').setValue(resData.data.teamDescription);
        this.formTeamReq.get('optionPrivacy').setValue(resData.data.teamType);
        this.formTeamReq.get('syncDetails').setValue(resData.data.syncDetails[0]);
        this.formTeamReq.get('teamLevelSync').setValue(resData.data.teamLevelSync);
        this.formTeamReq.get('sharepointSiteTemplate').setValue(resData.data.sharepointSiteTemplate);
        this.formTeamReq.get('isCreateSPSync').setValue(resData.data?.isCreateSPSync || resData.data.sharepointSiteTemplate?.isCreateSPSync);
        this.formTeamReq.get('sharePointSiteCollectionName').setValue(resData.data?.sharepointSiteTemplate?.newSiteCollectionName);
        this.formTeamReq.get('isCreateSPWebpartInHomePage').setValue(resData.data?.sharepointSiteTemplate?.isCreateSPWebpartInHomePage);
        this.workspaceMembers = resData.data?.members;
        this.workspaceGroups = resData.data?.groups;
        if (resData.data?.sharepointSiteTemplate?.Name) {
          this.sharePointSiteTemplate.selected = { Name: resData.data?.sharepointSiteTemplate?.Name, Title: resData.data?.sharepointSiteTemplate?.Title, value: resData.data?.sharepointSiteTemplate?.value }
          this.selectedSharePointTemplate = this.sharePointSiteTemplate.selected;
        }

        if (resData.data?.isCreateSPSync || resData.data.sharepointSiteTemplate?.isCreateSPSync) {
          this.sharePointSyncDetails.syncJobSetting = resData.data.sharepointSiteTemplate?.syncJobSetting;
          this.sharePointSiteTemplate.syncDetails = resData.data.sharepointSiteTemplate?.syncJobSetting;
          this.sharePointSiteTemplate.syncType = resData.data.sharepointSiteTemplate?.syncJobSetting?.syncType;
          this.sharePointSyncDetails.syncType = resData.data.sharepointSiteTemplate?.syncJobSetting?.syncType;
          if (resData.data?.cabinet) {
            this.sharePointSyncDetails.cabinet = resData.data?.cabinet;
            this.cabinet.selected = resData.data?.cabinet;
            this.formTeamReq.get('cabinet').setValue(resData.data?.cabinet);
          }
          if (resData.data?.workspace?.data) {
            this.sharePointSyncDetails.workspace = resData.data?.workspace.data;
            this.formTeamReq.get('workspace').setValue(resData.data?.workspace);
          }
        }
        if (resData.data.teamLevelSync) {
          this.editDetails.syncJobSetting = resData.data.syncDetails[0];
          this.editDetails.syncType = resData.data.syncDetails[0].syncType;
          if (this.cabinet?.selected) {
            this.editDetails.cabinet = this.cabinet?.selected;
          }
          if (this.ClientMatterWsInfo?.data) {
            this.editDetails.workspace = this.ClientMatterWsInfo.data;
          }
        }
        this.editDetails.channels.forEach((channelItem, index) => {
          if (channelItem?.sync) {
            this.editDetails.syncJobSetting = channelItem?.syncDetails;
            this.editDetails.syncType = channelItem?.syncDetails?.syncType;
            if (this.cabinet?.selected) {
              this.editDetails.cabinet = this.cabinet?.selected;
            }
            if (this.ClientMatterWsInfo?.data) {
              this.editDetails.workspace = this.ClientMatterWsInfo.data;
            }
          }
          this.addChannel(channelItem);
          channelItem.tabs.forEach(tabItem => {
            this.addTab(index, tabItem, tabItem.app || "netDocShare");
          })
        });
        setTimeout(() => {
          this.cabinet.selected = this.formTeamReq.value?.cabinet;
          if (this.formTeamReq.value?.client) {
            this.client.selected = this.formTeamReq.value?.client;
            this.matter.selected = this.formTeamReq.value?.matter;
          }
        }, 100);
        setTimeout(() => {
          if (this.ClientMatterWsInfo?.data) {
            this.getTemplates();
          }
        }, 5000);
      });

    }
    if (this.global.haveSyncAddOn || this.syncAddon) {
      this.syncCreateCheck = true;
    } else {
      this.syncCreateCheck = false;
    }
    if (this.global.haveTeamsAppAddOn || this.teamsAddon) {
      this.tabCreationCheck = true;
    } else {
      this.tabCreationCheck = false;
    }

    this.getTeamsConnections();
    this.setFormData();
    //this.listenConfigSetting();
    // this.setSyncType();
    if (this.requestFromBulkEdit) {
      this.formTeamReq.get('teamName').setValue(this.teamName);
    }
    if (this.isBulkEditMode) {

      this.cabinet.selected = this.teamData?.teamDetail?.cabinet;
      if (this.teamData?.teamDetail?.cabinet) {
        this.onSourceCabinetChange(this.teamData?.teamDetail?.cabinet);
      }
      if (this.teamData?.teamDetail?.client) {
        this.client.selected = this.teamData?.teamDetail?.client;
        this.matter.selected = this.teamData?.teamDetail?.matter;
        this.formTeamReq.get('client').setValue(this.teamData?.teamDetail?.client);
        this.formTeamReq.get('matter').setValue(this.teamData?.teamDetail?.matter);
      }
      this.ClientMatterWsInfo = this.teamData?.teamDetail?.workspace;
      this.template.selected = this.teamData?.teamDetail?.teamsTemplate;
      this.formTeamReq.get('cabinet').setValue(this.teamData?.teamDetail?.cabinet);
      this.formTeamReq.get('teamsTemplate').setValue(this.teamData?.teamDetail?.teamsTemplate);
      this.formTeamReq.get('description').setValue(this.teamData?.teamDetail?.description);
      this.formTeamReq.get('optionPrivacy').setValue(this.teamData?.teamDetail?.optionPrivacy);
      if (this.teamData?.teamDetail?.syncDetails) {
        this.formTeamReq.get('syncDetails').setValue(this.teamData?.teamDetail?.syncDetails[0]);
      }
      this.formTeamReq.get('sharePointSiteCollectionName').setValue(this.teamData?.teamDetail?.sharePointSiteCollectionName);
      this.formTeamReq.get('sharepointSiteTemplate').setValue(this.teamData?.teamDetail?.sharepointSiteTemplate);
      this.formTeamReq.get('isCreateSPWebpartInHomePage').setValue(this.teamData?.teamDetail?.isCreateSPWebpartInHomePage);
      this.formTeamReq.get('isCreateSPSync').setValue(this.teamData?.teamDetail?.isCreateSPSync);
      this.formTeamReq.get('teamLevelSync').setValue(this.teamData?.teamDetail?.teamLevelSync);
      this.formTeamReq.get('tabConfigSetting').setValue(this.teamData?.teamDetail?.tabConfigSetting);
      this.sharePointSiteTemplate = this.teamData?.teamDetail?.sharepointSiteTemplate;
      this.workspaceMembers = this.teamData?.teamDetail?.members;
      this.workspaceGroups = this.teamData?.teamDetail?.groups;
      if (this.teamData?.teamDetail?.sharepointSiteTemplate?.Name) {
        this.sharePointSiteTemplate.selected = { Name: this.teamData?.teamDetail?.sharepointSiteTemplate?.Name, Title: this.teamData?.teamDetail?.sharepointSiteTemplate?.Title, value: this.teamData?.teamDetail?.sharepointSiteTemplate?.value }
        this.selectedSharePointTemplate = this.sharePointSiteTemplate.selected;
      }

      if (this.teamData?.teamDetail?.isCreateSPSync) {
        this.sharePointSyncDetails.syncJobSetting = this.teamData?.teamDetail?.sharepointSiteTemplate?.syncJobSetting;
        this.sharePointSiteTemplate.syncDetails = this.teamData?.teamDetail?.sharepointSiteTemplate?.syncJobSetting;
        this.sharePointSiteTemplate.syncType = this.teamData?.teamDetail?.sharepointSiteTemplate?.syncJobSetting?.syncType;
        this.sharePointSyncDetails.syncType = this.teamData?.teamDetail?.sharepointSiteTemplate?.syncJobSetting?.syncType;
        if (this.teamData?.teamDetail?.cabinet) {
          this.sharePointSyncDetails.cabinet = this.teamData?.teamDetail?.cabinet;
          this.cabinet.selected = this.teamData?.teamDetail?.cabinet;
          this.formTeamReq.get('cabinet').setValue(this.teamData?.teamDetail?.cabinet);
        }
        if (this.teamData?.teamDetail?.workspace?.data) {
          this.sharePointSyncDetails.workspace = this.teamData?.teamDetail?.workspace.data;
          this.formTeamReq.get('workspace').setValue(this.teamData?.teamDetail?.workspace);
        }
      }
      if (this.teamData?.teamDetail?.teamLevelSync) {
        this.editDetails.syncJobSetting = this.teamData?.teamDetail?.syncDetails[0];
        this.editDetails.syncType = this.teamData?.teamDetail?.syncDetails[0].syncType;
        if (this.cabinet?.selected) {
          this.editDetails.cabinet = this.cabinet?.selected;
        }
        if (this.ClientMatterWsInfo?.data) {
          this.editDetails.workspace = this.ClientMatterWsInfo.data;
        }
      }
      setTimeout(() => {
        this.cabinet.selected = this.formTeamReq.value?.cabinet;
        if (this.formTeamReq.value?.client) {
          this.client.selected = this.formTeamReq.value?.client;
          this.onSelectClient(this.formTeamReq.value?.client);
        }
      }, 100);
      setTimeout(() => {
        if (this.ClientMatterWsInfo?.data) {
          this.getTemplates();
        }
      }, 5000);
    }
  }
  onSourceCabinetChange(cabinet: any) {
    this.cabinet.data?.forEach((cab: any) => {
      cab.isSelected = false;
    });
    cabinet.isSelected = true;
    this.getClientMatterInfo(cabinet);
    this.cabinet.selected = cabinet;
    this.formTeamReq.get('cabinet').setValue(cabinet);
    this.client.clientInfo = {};
    this.matter.matterInfo = {};
    this.client.clients = [];
    this.matter.matters = [];
    this.ndvs.getAllUsersInRepository(cabinet?.repositoryId).subscribe((res) => {
      if (res.status == "success" && res.data) {
        this.repositoryUsers = res.data;
      }
    });
    this.reset("both");
    // if (this.customizedClientMatters.length === 0) this.getClients(cabinet);
  }

  getClientMatterInfo(cabinet: any) {
    this.ndvs.getProfileAttributes(cabinet.id).subscribe(profileRes => {
      let profileData = profileRes?.data ? profileRes.data : [];
      let clientAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsOrgAttrNum : cabinet.wsAttrNum;
      let matterAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsAttrNum : null;
      if (!matterAttrNum) {
        // let profileFound = profileData?.find((profile: any) => profile?.name?.toLowerCase() + 's' === cabinet?.wsAttrPluralName?.toLowerCase());
        let profileFound = profileData?.find((profile: any) => profile?.id === clientAttrNum);
        clientAttrNum = profileFound.linked ? profileFound.linked : profileFound.id;
        matterAttrNum = profileFound.linked ? profileFound.id : null;
        cabinet.wsOrgAttrNum = clientAttrNum;
      }
      this.client.clientInfo = profileData?.find((profile: any) => profile.id === clientAttrNum);
      this.matter.matterInfo = profileData?.find((profile: any) => matterAttrNum && profile.id === matterAttrNum);
      if (this.isEditMode || this.isBulkEditMode && this.matter?.matterInfo) {
        this.onSelectClient(this.client?.selected);
      }
      if (this.customizedClientMatters.length > 0) this.getCustomzedClients();
      else this.getClients(cabinet);
      if (this.client.selected.key && this.matter?.matterInfo?.id) this.getMatters();
    });
  }

  getCustomzedClients() {
    this.customizedClientMatterIndex = this.customizedClientMatters.findIndex((cabinet: any) => cabinet.cabinet.id == this.cabinet?.selected?.id);
    this.client.isFetching = true;
    if (this.customizedClientMatters.length > 0 && this.customizedClientMatters[this.customizedClientMatterIndex]) {
      this.appConfig.columnProperties = this.customizedClientMatters[this.customizedClientMatterIndex].columnProperties || [];
    }
    if (this.appConfig.showAccessibleClientMatter) {
      if (this.httpResponse.validateClientMatter) this.httpResponse.validateClientMatter.unsubscribe();
      if (this.customizedClientMatters[this.customizedClientMatterIndex].isRetrieved) {
        this.client.clients = this.customizedClientMatters[this.customizedClientMatterIndex].clients;
        this.client.isFetching = false;
      } else {
        if (!this.matter?.matterInfo) this.customizedClientMatters[this.customizedClientMatterIndex].matterId = "";
        this.httpResponse.validateClientMatter = this.ndvs.getAccessibleClientMatter(this.customizedClientMatters[this.customizedClientMatterIndex]).subscribe(res => {
          this.client.isFetching = false;
          if (res.status == "success" && res?.data?.clients) {
            this.client.clients = res.data.clients;
            this.customizedClientMatters[this.customizedClientMatterIndex].clients = this.client.clients;
            this.customizedClientMatters[this.customizedClientMatterIndex].isRetrieved = true;
            if (this.client.clients.length == 1) this.onSelectClient(this.client.clients[0]);
          }
        });
      }
    } else {
      this.client.clients = this.customizedClientMatters[this.customizedClientMatterIndex].clients;
      if (this.client.clients.length == 1) this.onSelectClient(this.client.clients[0]);
      this.client.isFetching = false;
    }
  }
  resetCabinets(data: any = null) {
    this.cabinet = {
      data: [],
      message: "",
      selected: {},
      isFetching: false
    };
    this.client = {
      data: [],
      message: "",
      selected: {},
      isFetching: false,
      clientInfo: { name: "Client" }
    };
    this.matter = {
      data: [],
      message: "",
      selected: {},
      isFetching: false,
      matterInfo: { name: "Matter" }
    };
    this.template.selected = {};
    this.ClientMatterWsInfo = {};
    this.tabConfigSetting = {};
    this.configSetting = {};
    const channels = this.formTeamReq.get('channels') as FormArray;
    channels.clear();
    this.formTeamReq.get('tabConfigSetting').setValue("");

  }
  fetchCabinets(data: any = null) {
    this.cabinet.isFetching = true;
    if (data?.fetchBy == "search" && this.lookupDataCabinet?.data?.length > 0) {
      let searchData = this.lookupDataCabinet?.data.filter((item: any) => JSON.stringify(item?.name).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1);
      this.cabinet = {
        data: searchData,
        message: searchData?.length == 0 ? "No Cabinet found" : ""
      }
      this.cabinet.isFetching = false;
    } else {
      this.ndvs.getCabinets().subscribe((resData: any) => {
        this.cabinet.isFetching = false;
        if (resData.status == "success") {
          this.cabinet = {
            data: resData.data,
            message: resData.data.length == 0 ? "Cabinet Not found" : "",
            selected: this.cabinet.selected,
          };
          this.lookupDataCabinet = this.cabinet;
        } else {
          this.cabinet = { data: [], message: "Cabinet Not found" };
        }

      })
    }
  }


  getMatters(data: any = null, searchQuery = null, skip = 0) {
    if (!this.client?.selected) return;
    // if (data?.fetchBy == "toggle") {
    //   let selectedclient = this.client?.data?.filter(item => item.key.includes(this.matter?.selected?.parent));
    //   if (selectedclient.length == 1) {
    //     this.onSelectClient(selectedclient[0]);
    //   }
    // }
    const repoId = this.cabinet?.selected?.repositoryId || this.editDetails?.cabinet?.repositoryId;
    if (repoId) {
      this.matter.isFetching = true;
      let key = "";
      if (this.client?.selected?.key) {
        key = this.client.selected.key;
      }
      searchQuery = searchQuery || data?.searchstring;
      skip = skip || data?.skip || 0;
      if (skip === 0 || (skip === 0 && searchQuery)) {
        this.matter = {
          data: [],
          isFetching: true,
          overflow: this.matter.overflow,
          skip: this.matter.overflow,
          matterInfo: this.matter?.matterInfo,
          selected: (this.isEditMode ? this.formTeamReq.value?.matter : this.matter?.selected),
          message: "",
        }
      }
      this.ndvs.getLookUpData(repoId, this.matter?.matterInfo?.id, key, searchQuery, skip, this.clientMatterTop).subscribe(res => {
        const matterData = res?.data?.rows ? res.data.rows : [];
        this.lookupDataMatter = matterData;
        const allMatterData = skip ? this.matter.data.concat(matterData) : matterData;
        this.matter = {
          data: allMatterData,
          isFetching: false,
          overflow: res?.data?.overflow,
          skip: res?.data?.overflow ? this.clientMatterTop + skip : 0,
          matterInfo: this.matter?.matterInfo,
          selected: (this.isEditMode ? this.formTeamReq.value?.matter : this.matter?.selected),
          message: allMatterData?.length == 0 ? "No Matters found" : ""
        }
      });
    }
  }
  onSelectClient(client: any) {
    client.name = `${client.key}${client.description ? ' - ' + client.description : ''}`;
    this.client.selected = client;
    this.formTeamReq.get('client').setValue(client);
    if (this.customizedClientMatters.length === 0 || !this.appConfig?.showAccessibleClientMatter) {
      if (this.matter?.matterInfo?.id) {
        this.matter.selected = {}
        this.getMatters();
      }
    }
    if (!this.matter?.matterInfo) this.onClickGo();

  }

  onSelectMatter(matter: any) {
    if (matter != null) {
      matter.name = `${matter.key}${matter.description ? ' - ' + matter.description : ''}`;
      this.matter.selected = matter;
      this.formTeamReq.get('matter').setValue(matter);
      this.onClickGo();
      if (this.matter.isSearching) {
        this.matter.isSearching = false;
        this.getMatters();
      }
    }

  }

  getClients(cabinet: any, data: any = null, searchQuery = null, skip = 0) {
    this.client.isFetching = true;
    if (!this.cabinet?.selected) return;
    searchQuery = searchQuery || data?.searchstring;
    skip = skip || data?.skip || 0;
    if (skip === 0 || (skip === 0 && searchQuery)) {
      this.client = {
        data: [],
        isFetching: true,
        overflow: this.client.overflow,
        skip: 0,
        clientInfo: this.client?.clientInfo,
        selected: this.client?.selected,
        message: "",
      }
    }
    const clientAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsOrgAttrNum : cabinet.wsAttrNum;
    this.ndvs.getLookUpData(cabinet.repositoryId, clientAttrNum, null, searchQuery, skip, this.clientMatterTop).subscribe(res => {
      const clientData = res?.data?.rows ? res.data.rows : [];
      this.lookupDataClient = clientData;
      const allClientData = skip ? this.client.data.concat(clientData) : clientData;
      this.client = {
        data: allClientData,
        isFetching: false,
        overflow: res?.data?.overflow,
        skip: res?.data?.overflow ? this.clientMatterTop + skip : 0,
        clientInfo: this.client?.clientInfo,
        selected: this.client?.selected,
        message: allClientData?.length == 0 ? "No Client found" : "",
      }
    });
  }


  onClickGo() {
    if (!this.cabinet?.selected?.id) {
      this.cabinet.selected = this.formTeamReq.value?.cabinet;
    } if (!this.client?.selected) {
      this.client.selected = this.formTeamReq.value?.client;
    }
    let client = this.client?.selected?.name?.split(" - ")[0];
    let matter = this.matter?.selected?.name?.split(" - ")[0];
    this.ndvs.getWorkspaceInfoByClientMatter(this.cabinet?.selected?.id, client?.trim(), matter?.trim()).subscribe(res => {
      if (res.status === "success" && res.data) {
        res.data.cabinetId = this.cabinet.selected.id;
        res.data.selectedCabinet = this.cabinet?.selected;
        res.data.SelectedClient = this.client.selected;
        this.onChangeWorkspaceInfo(res);
        if (!this.isEditMode) {
          this.formTeamReq.get('teamLevelSync').setValue(false);
          this.formTeamReq.get('isCreateSPSync').setValue(false);
        }
        this.formTeamReq.get('syncDetails').setValue({});
        this.sharePointSiteTemplate.syncDetails = {};
        let ws = this.ClientMatterWsInfo.data.selectedCabinet;
        const primarySource = {
          cabinet: ws.name,
          cabinetId: ws.id,
          id: this.ClientMatterWsInfo.data.Attributes.Id,
          name: this.ClientMatterWsInfo.data.Attributes.Name,
          type: "workspace",
          workspace: this.ClientMatterWsInfo.data.Attributes.Name,
          workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
        }

        let mattername = '';
        if (this.matter?.selected?.name) {
          mattername = '_' + this.matter.selected.name
        }
        const tabConfig = {
          tabName: this.client.selected.name + mattername,
          clientMatter: { client: this.client.selected, matter: this.matter.selected },
          cabinet: ws.name,
          cabinetId: ws.id,
          viewType: "normalView", primarySource: primarySource, showLogout: true, showLogo: true
        };
        const tab = {
          tabName: this.client.selected.name + mattername, config: JSON.stringify(tabConfig)
        };
        this.tabConfigSetting = {
          "tabConfig": { tabId: Math.random().toString(), ...tab },
          "tenantId": this.global.tenantId
        }
        this.formTeamReq.get('tabConfigSetting').setValue(this.tabConfigSetting);
      } else {
        if (res?.error?.error) {
          const errorMsg = res?.error?.error?.error + 'You do not have access to this item or location, or you are accessing from outside of approved network locations.' || "Failed to get workspace details";
          this.toaster.error(errorMsg, "", { timeOut: 4000, extendedTimeOut: 1000 });
        } else if (client == undefined) {
          const errorMsg = `Please select ${this.client?.clientInfo?.name}`;
          this.toaster.error(errorMsg);
        } else if (matter == undefined) {
          const errorMsg = `Please select ${this.matter?.matterInfo?.name}`;
          this.toaster.error(errorMsg);
        }
      }
    });
  }

  public getSharePointSiteTemplate(data = null) {
    if (this.sharePointSiteTemplate.isFetching) return;
    if (data?.fetchBy == "search") {
      const searchData = this.sharePointSiteTemplate.searchData.filter((item: any) => JSON.stringify(item?.Title).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1)
      this.sharePointSiteTemplate = {
        data: searchData || [],
        searchData: this.sharePointSiteTemplate.searchData,
        isFetching: false,
        syncDetails: this.sharePointSyncDetails?.syncJobSetting,
        syncType: this.sharePointSyncDetails?.syncJobSetting?.syncType,
        message: searchData?.length == 0 ? "No SharePoint Template Found" : "",
        selected: this.sharePointSiteTemplate.selected || {}
      }
    } else {
      this.sharePointSiteTemplate = {
        data: [],
        searchData: [],
        isFetching: true,
        syncDetails: this.sharePointSyncDetails?.syncJobSetting,
        syncType: this.sharePointSyncDetails?.syncJobSetting?.syncType,
        selected: this.sharePointSiteTemplate.selected || {}
      }
      this.getSharePointConnection().subscribe(connectionRes => {
        if (connectionRes.status === "success") {
          this.spService.getSharePointSiteTemplate(this.sharepointConnection._id).subscribe(res => {
            this.sharePointSiteTemplate = {
              data: res.data || [],
              searchData: res.data || [],
              isFetching: false,
              syncDetails: this.sharePointSyncDetails?.syncJobSetting,
              syncType: this.sharePointSyncDetails?.syncJobSetting?.syncType,
              message: res.data?.length == 0 ? "No SharePoint Template Found" : "",
              selected: this.sharePointSiteTemplate.selected || {}
            }
          });
        } else {
          this.sharePointSiteTemplate.isFetching = false;
        }
      })
    }
  }

  public getSharePointConnection() {
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    if (this.sharepointConnection._id) {
      setTimeout(() => {
        requestOut.emit({ status: "success" });
      }, 100);
    } else {
      this.spService.getSharePointConnection().subscribe(connectionRes => {
        this.sharepointConnection = connectionRes?.data;
        requestOut.emit({ status: this.sharepointConnection._id ? "success" : "failed" });
      })
    }
    return requestOut;
  }
  public onSelectSharePointSiteTemplate(spTemplate) {
    this.sharePointSiteTemplate.selected = spTemplate;
    //this.formTeamReq.get("sharepointSiteTemplate").setValue(spTemplate);
    //this.sharePointSiteTemplate = { ...this.sharePointSiteTemplate, isSyncEnabled: this.sharePointSiteTemplate.isSyncEnabled, syncType: "", cabinet: this.cabinet, workspace: this.ClientMatterWsInfo, clientMatter:{...this.client.selected,...this.matter.selected} };
  }
  public onChangeSPSiteCollectionName() {
    this.validateSPSiteCollection.isFetching = true;
    clearTimeout(this.validateSPSiteCollection.typing);
    this.validateSPSiteCollection.typing = setTimeout(() => {
      if (!this.formTeamReq.value.sharePointSiteCollectionName?.trim()) {
        this.validateSPSiteCollection.isFetching = false;
        this.validateSPSiteCollection.message = "";
        this.validateSPSiteCollection.status = "";
        return;
      }
      this.getSharePointConnection().subscribe(connectionRes => {
        if (connectionRes.status === "success") {
          this.spService.validateSPSiteCollectionName(this.sharepointConnection._id, this.formTeamReq.value.sharePointSiteCollectionName).subscribe(res => {
            this.validateSPSiteCollection.isFetching = false;
            this.validateSPSiteCollection.message = res.message || "";
            this.validateSPSiteCollection.status = res.status;
          });
        } else {
          this.validateSPSiteCollection.isFetching = false;
          this.validateSPSiteCollection.message = "Connection id not found";
          this.validateSPSiteCollection.status = "failed";
        }
      })
    }, 600);
  }
  public onSyncCheckedChange(isChecked, type = "teamSync") {
    if (type === "SPSync") this.formTeamReq.get("isCreateSPSync").setValue(isChecked);
    else this.formTeamReq.get("teamLevelSync").setValue(isChecked);
    if (isChecked) {
      if (type == "teamSync") {
        const channels = this.formTeamReq.get('channels') as FormArray;
        channels.controls.forEach(channel => {
          channel.get('sync').setValue(false);
        });
        this.editDetails.syncJobSetting = {};
        this.editDetails.syncType = this.editDetails?.syncJobSetting?.syncType || 'teamUni';
        if (this.cabinet?.selected) {
          this.editDetails.cabinet = this.cabinet?.selected;
        }
        if (this.ClientMatterWsInfo?.data) {
          this.editDetails.workspace = this.ClientMatterWsInfo.data;
        }
      } else {
        this.sharePointSyncDetails.syncJobSetting = this.sharePointSiteTemplate?.syncDetails;
        this.sharePointSyncDetails.syncType = this.sharePointSiteTemplate?.syncDetails?.syncType || 'bidirectional';
        if (this.cabinet?.selected) {
          this.sharePointSyncDetails.cabinet = this.cabinet?.selected;
        } else if (this.isEditMode) {
          this.cabinet.selected = this.editDetails?.cabinet;
          this.sharePointSyncDetails.cabinet = this.cabinet.selected;
        }
        if (this.ClientMatterWsInfo?.data) {
          this.sharePointSyncDetails.workspace = this.ClientMatterWsInfo.data;
        }
      }
    } else {
      if (type == "teamSync") {
        this.formTeamReq.get("syncDetails").setValue({});
      } else {
        this.sharePointSiteTemplate.syncDetails = {};
      }
    }

  }
  public onSaveTab(tabData: any, modal: any) {
    const channels = this.formTeamReq.get('channels') as FormArray;
    const channel = channels.at(this.channelIndex);
    const tabs = channel.get('tabs') as FormArray;
    const tab = tabs.at(this.tabIndex);
    if (tab instanceof FormGroup) {
      tab.patchValue({ name: this.configSetting.tabConfig?.tabName, id: this.configSetting.tabConfig?.tabId, config: this.configSetting.tabConfig?.config });
    }
    this.configSetting = {};
    this.toaster.success("Tab details are updated successfully.", "Success", { timeOut: 3000 });
    modal.close();
  }

  public getTeamsConnections() {
    this.teamsService.getTeamsConnections().subscribe((resData) => {
      if (resData != undefined && resData.status == "success" && resData.data != null) {
        this.connectionsList = resData.data;
        if (!this.global.connectionData._id) {
          var defaultConnection = this.connectionsList.length === 1 ? this.connectionsList[0] : this.connectionsList.find(d => d.isDefault && d._id);
          if (defaultConnection != null) this.global.connectionData = defaultConnection;
        }
        this.global.tenantId = this.global.connectionData.tenantId;
        this.getTemplates();
      } else {
        this.connectionsList = [];
      }
    })
  }
  listenConfigSetting() {
    this.configSettingListener = event => {
      try {
        if (event.data && event.data.config) {
          if (event.data.tabName == "" || event.data.config.viewType == "" || (event.data.config.viewType == 'normalView' && (!event.data.config.primarySource || (event.data.config.primarySource && event.data.config.primarySource.id == '')))
            || (event.data.config.viewType == 'Tree' && event.data.config.sources && event.data.config.sources.length == 0)) {
            this.tabSetting = false;
          } else {
            this.tabSetting = true;
          }
          this.configSetting = event.data.config;
          this.tabConfiguration = event.data.tabConfiguration;
          let tabName = event.data.tabName ? event.data.tabName : (this.global.selectedTab && this.global.selectedTab.DisplayName) ? this.global.selectedTab.DisplayName : ''
          this.tabConfigSetting = {
            "tabConfig": { tabId: event.data.entityId, config: JSON.stringify(this.configSetting), tabName: tabName },
            "tenantId": this.global.tenantId
          }
        }
      } catch (e) {
        //console.log(e);
      }
      this.onConfigChange.emit(this.configSetting)
    };
    window.addEventListener('message', this.configSettingListener);
  }
  public onTeamSyncJobSettingChange(data) {
    const locallicense = this.netDocLocalStorage.licenseInfo;
    var syncJobSetting = data.syncJobSetting;
    syncJobSetting.licenseKey = locallicense.clientId;
    syncJobSetting.createdBy = this.netDocLocalStorage.currentUser?.email;
    if (syncJobSetting?.netDocuments) {
      syncJobSetting.netDocuments.userName = this.netDocLocalStorage?.ndInfo?.displayName;
      syncJobSetting.netDocuments.siteUrl = this.netDocLocalStorage?.licenseInfo?.netDocBaseUrl;
    }
    this.editDetails.syncJobSetting = syncJobSetting;
    this.editDetails.syncType = syncJobSetting?.syncType;
    this.formTeamReq.get("syncDetails").setValue(syncJobSetting);

  }
  public onSpSyncJobSettingChange(data) {
    const locallicense = this.netDocLocalStorage.licenseInfo;
    var syncJobSetting = data.syncJobSetting;
    syncJobSetting.licenseKey = locallicense.clientId;
    syncJobSetting.createdBy = this.netDocLocalStorage.currentUser?.email;
    if (syncJobSetting?.netDocuments) {
      syncJobSetting.netDocuments.userName = this.netDocLocalStorage?.ndInfo?.displayName;
      syncJobSetting.netDocuments.siteUrl = this.netDocLocalStorage?.licenseInfo?.netDocBaseUrl;
    }
    if (syncJobSetting?.sharePoint) {
      syncJobSetting.sharePoint.connectionType = this.sharepointConnection.connectionType;
      syncJobSetting.sharePoint.connectionId = this.sharepointConnection._id;
      syncJobSetting.sharePoint.tenantUrl = this.sharepointConnection.siteAddress;
    }
    this.sharePointSiteTemplate.syncDetails = syncJobSetting;
  }
  public onChannelSyncJobSettingChange(data, index) {
    const channels = this.formTeamReq.get('channels') as FormArray;
    const channel = channels.at(index);
    const locallicense = this.netDocLocalStorage.licenseInfo;
    var syncJobSetting = data.syncJobSetting;
    syncJobSetting.licenseKey = locallicense.clientId;
    syncJobSetting.createdBy = this.netDocLocalStorage.currentUser?.email;
    if (syncJobSetting?.netDocuments && ((channel?.value?.syncDetails && JSON.stringify(channel?.value?.syncDetails) != JSON.stringify(syncJobSetting)) || (!channel?.value?.syncDetails))) {
      syncJobSetting.netDocuments.userName = this.netDocLocalStorage?.ndInfo?.displayName;
      syncJobSetting.netDocuments.siteUrl = this.netDocLocalStorage?.licenseInfo?.netDocBaseUrl;
      channel.patchValue({ syncDetails: syncJobSetting, sync: true });
    }
  }

  public setConfigChange(data) {
    this.configSetting = data;
  }

  public setSyncType() {
    let channelSync = this.formTeamReq.value.channels.filter(i => i.sync);
    if (channelSync.length > 0) { this.syncLocation.syncType = "channel"; this.isSync = "True" }
    else if (this.formTeamReq.value.sync) { this.syncLocation.syncType = "team"; this.isSync = "True" }
    else { this.syncLocation.syncType = ""; this.isSync = "False" }
  }
  private setFormData() {
    this.formTeamReq = new FormGroup(
      {
        cabinet: new FormControl(''),
        client: new FormControl(''),
        matter: new FormControl(''),
        workspace: new FormControl(''),
        teamsTemplate: new FormControl(''),
        teamName: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        channels: new FormArray([]),
        optionPrivacy: new FormControl('public'),
        teamLevelSync: new FormControl(false),
        syncDetails: new FormControl([]),
        tabAdd: new FormArray([]),
        tabCreate: new FormControl(this.tabCreatenableorDiasble),
        sharepointSiteTemplate: new FormControl(''),
        sharePointSiteCollectionName: new FormControl(''),
        isCreateSPWebpartInHomePage: new FormControl(false),
        isCreateSPSync: new FormControl(false),
        tabConfigSetting: new FormControl({})
      }
    );
    console.log("formValues", this.formTeamReq.value);
  }
  public addChannel(item = null) {
    const channels = this.formTeamReq.get('channels') as FormArray
    if (item == null)
      channels.push(new FormGroup({
        name: new FormControl('New Channel', [Validators.required]),
        description: new FormControl(''),
        membershipType: new FormControl("Standard"),
        sync: new FormControl(false),
        syncDetails: new FormControl({}),
        tabs: new FormArray([]),
        othertabs: new FormArray([]),
        cabinet: new FormControl({}),
        workspace: new FormControl({})
      }))
    else channels.push(new FormGroup({
      name: new FormControl(item.name, [Validators.required]),
      description: new FormControl(item.description),
      membershipType: new FormControl((item.membershipType == '0' || item.membershipType == 'Standard') ? 'Standard' : (item.membershipType == '1' || item.membershipType == 'Private') ? 'Private' : 'Shared'),
      sync: new FormControl((!this.syncCheck) ? item.sync : false),
      syncDetails: new FormControl(item?.syncDetails),
      tabs: new FormArray([]),
      othertabs: new FormArray([]),
      cabinet: new FormControl(this.cabinet?.selected),
      workspace: new FormControl(this.ClientMatterWsInfo?.data)
    }))
    setTimeout(() => {
      this.formTeamReq.controls.channels.markAllAsTouched();
    }, 100);
  }
  public removeChannel(channelIndex) {
    const channels = this.formTeamReq.get('channels') as FormArray
    channels.removeAt(channelIndex);
    //channels.reset();
  }

  public openApps(modal, channelIndex) {
    this.channelIndex = channelIndex;
    this.modalService.open(modal).result.then(res => {
      if (res?.value) {
        if (res.value == "planner") {
          const channels = this.formTeamReq.get('channels') as FormArray;
          const channel = channels.at(channelIndex);
          if (channel?.value?.membershipType?.toLowerCase() == "private") {
            this.toaster.error("Planner app is not supporting to add in private channel");
            return;
          }
        }
        this.addTab(this.channelIndex, res.value == "planner" ? {} : this.tabConfigSetting, res.value);
      }
    });
  }

  public addTab(channelIndex, tab = null, app = null) {
    const channels = this.formTeamReq.get('channels') as FormArray;
    const channel = channels.at(channelIndex);
    const tabs = channel.get('tabs') as FormArray;
    if (tab == null)
      tabs.push(new FormGroup({
        name: new FormControl('', [Validators.required]),
        config: new FormControl(''),
        id: new FormControl(''),
        app: new FormControl(app),
      }))
    else tabs.push(new FormGroup({
      name: new FormControl(tab.tabConfig?.tabName || tab?.name),
      config: new FormControl(tab.tabConfig?.config || tab?.config),
      id: new FormControl(tab?.id || tab.tabConfig?.tabId),
      app: new FormControl(app),
    }))
  }
  public removeTab(channelIndex, tabIndex) {
    const channels = this.formTeamReq.get('channels') as FormArray;
    const channel = channels.at(channelIndex);
    const tabs = channel.get('tabs') as FormArray;
    tabs.removeAt(tabIndex);
  }

  public onEditTab(modal, tabData, channelIndex, tabIndex) {
    this.channelIndex = channelIndex;
    this.tabIndex = tabIndex;
    this.global.selectedTab.entityId = this.tabConfiguration?.entityId;
    if (tabData?.value?.config) {
      this.global.selectedTab.entityId = tabData?.value?.id;
      let config = JSON.parse(tabData?.value?.config);//{tabName:tabData.value?.name,...};
      config.tabName = tabData.value?.name;
      if (this.ClientMatterWsInfo?.data?.selectedCabinet && !config?.primarySource) {
        let ws = this.ClientMatterWsInfo.data.selectedCabinet;
        const primarySource = {
          cabinet: ws.name,
          cabinetId: ws.id,
          id: this.ClientMatterWsInfo.data.Attributes.Id,
          name: this.ClientMatterWsInfo.data.Attributes.Name,
          type: "workspace",
          workspace: this.ClientMatterWsInfo.data.Attributes.Name,
          workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
        }
        config.primarySource = primarySource;

      }
      this.global.isTabEdit = true;
      this.global.selectedTab.tabTemplateData = JSON.stringify(config);
      this.global.selectedTab.configuration = JSON.stringify(config);
    } else {
      this.tabConfigSetting = {};
      this.tabConfigSetting = this.formTeamReq.get('tabConfigSetting').value;
      if (this.tabConfigSetting.tabConfig?.config) {
        let config = JSON.parse(this.tabConfigSetting.tabConfig?.config);
        this.tabConfigSetting.tabConfig.tabName = tabData.value.name;
        config.tabName = tabData.value.name;
        this.global.isTabEdit = true;
        this.global.selectedTab.tabTemplateData = JSON.stringify(config);
        this.global.selectedTab.configuration = JSON.stringify(config);
      }
      else {
        this.global.isTabEdit = true;

        if (this.ClientMatterWsInfo?.data?.selectedCabinet) {
          let ws = this.ClientMatterWsInfo.data.selectedCabinet;
          const primarySource = {
            cabinet: ws.name,
            cabinetId: ws.id,
            id: this.ClientMatterWsInfo.data.Attributes.Id,
            name: this.ClientMatterWsInfo.data.Attributes.Name,
            type: "workspace",
            workspace: this.ClientMatterWsInfo.data.Attributes.Name,
            workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
          }

          const tabConfig = {
            tabName: tabData?.value?.name,
            clientMatter: { client: this.client.selected, matter: this.matter.selected },
            cabinet: ws.name,
            cabinetId: ws.id,
            viewType: "normalView", primarySource: primarySource, showLogout: true, showLogo: true
          };
          const tab = {
            tabName: tabData?.value?.name, config: JSON.stringify(tabConfig)
          };
          this.tabConfigSetting = {
            "tabConfig": { tabId: Math.random().toString(), ...tab },
            "tenantId": this.global.tenantId
          }
          this.formTeamReq.get('tabConfigSetting').setValue(this.tabConfigSetting);
          this.global.selectedTab.tabTemplateData = this.tabConfigSetting.tabConfig?.config;
          this.global.selectedTab.configuration = this.tabConfigSetting.tabConfig?.config;

        } else {
          if (tabData?.value?.name) {
            const tabConfig = {
              tabName: tabData?.value?.name
            };
            this.global.selectedTab.tabTemplateData = JSON.stringify(tabConfig);
            this.global.selectedTab.configuration = JSON.stringify(tabConfig);

          }
        }

      }
    }

    this.onShowTab(modal);
    //this.change.emit({ data: JSON.parse(JSON.stringify(tabData)), isProcessed: true });
  }

  public onCloseModal() {
    this.channelIndex = -1;
    this.tabIndex = -1;
    this.modalService.dismissAll();
  }


  public onChangeChannelSync(e, channelIndex) {
    if (this.formTeamReq.value.teamLevelSync) {
      e.target.checked = false;
      this.toaster.error("Team have team level sync. Cannot create channel level sync.", "Failure", { timeOut: 3000 });
      return;
    }
    if (e.target.checked) {
      const channels = this.formTeamReq.get('channels') as FormArray;
      const channel = channels.at(channelIndex);
      this.editDetails.syncJobSetting = {};
      //this.editDetails.syncJobSetting = channel.get("syncDetails").value;
      this.editDetails.syncType = this.editDetails?.syncJobSetting?.syncType || 'bidirectionalTeam';
      if (this.cabinet?.selected) {
        this.editDetails.cabinet = this.cabinet?.selected;
      }
      if (this.ClientMatterWsInfo?.data) {
        this.editDetails.workspace = this.ClientMatterWsInfo.data;
      }
    }
  }

  public onShowTab(modal) {
    /* if (this.connectionData.isConnected == undefined || !this.connectionData.isConnected || this.channelData.Id == undefined || !this.global.isIMDSAppInstalled) return; */
    this.modalService.open(modal, { backdrop: 'static', keyboard: false }).result.then((res) => {
    }, (resData) => {
      if (resData.Id != undefined) {
      }
    }).catch((error) => {
    })
  }
  public onCancel() {
    this.setFormData();
    this.sharePointSiteTemplate.selected = {};
    this.cabinet.selected = {};
    this.client.selected = {};
    this.matter.selected = {};
    this.template.selected = {};
    if (this.isFromRequestPage || this.requestFromBulkEdit) {
      this.onCloseModal();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    try {
      window.parent.postMessage({ type: "closeCreateRequest" }, "*");
    } catch (ex) { }
    if (this.global.isGlobalAdmin) {
      this.router.navigateByUrl('/provisioning/requests');
    } else {
      this.router.navigateByUrl('/provisioning/myRequests');
    }


  }
  public onCreateTeamRequest() {
    let valid = true;
    this.createRequestLoader = true;
    let sendData = this.formTeamReq.value;
    sendData?.channels.forEach((channel) => {
      if (channel.name.trim() == '') {
        this.toaster.info("Channel Name is Mandatory", "Required", { timeOut: 0, extendedTimeOut: 0 });
        valid = false;
        return;
      }
      channel?.tabs.forEach(tab => {
        if (tab.name.trim() == '') {
          valid = false;
          this.toaster.info("Tab Name is Mandatory for the Channel Name " + channel.name, "Required", { timeOut: 0, extendedTimeOut: 0 });
          return;
        }

      });
    });
    if (!valid) {
      this.createRequestLoader = false;
      return;
    }
    sendData.status = "Pending";
    sendData.createdBy = this.netDocLocalStorage?.ndInfo?.displayName;
    sendData.createdByEmail = this.netDocLocalStorage?.ndInfo?.email;
    sendData.members = this.workspaceMembers;
    sendData.groups = this.workspaceGroups;
    if (sendData.teamLevelSync) {
      let syncJson = JSON.parse(JSON.stringify(sendData.syncDetails));
      sendData.syncDetails = [syncJson];
    }
    if (this.sharePointSiteTemplate?.selected?.Title) {
      let ws = this.ClientMatterWsInfo.data.selectedCabinet;
      const primarySource = {
        cabinet: ws.name,
        cabinetId: ws.id,
        id: this.ClientMatterWsInfo.data.Attributes.Id,
        name: this.ClientMatterWsInfo.data.Attributes.Name,
        type: "workspace",
        workspace: this.ClientMatterWsInfo.data.Attributes.Name,
        workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
      }
      const appConfig = { viewType: "normalView", primarySource, showLogout: true, showLogo: true, contectMenu: [], actionButtons: [] };
      sendData.sharepointSiteTemplate = {
        ...this.sharePointSiteTemplate.selected, siteAddress: this.sharepointConnection.siteAddress,
        connectionId: this.sharepointConnection._id,
        newSiteCollectionName: this.formTeamReq.value.sharePointSiteCollectionName, appConfig: JSON.stringify(appConfig),
        isCreateSPWebpartInHomePage: this.formTeamReq.value.isCreateSPWebpartInHomePage,
        isCreateSPSync: this.formTeamReq.value.isCreateSPSync,
        syncJobSetting: this.formTeamReq.value.isCreateSPSync ? this.sharePointSiteTemplate.syncDetails : null
      };
    }
    if (this.requestFromBulkEdit) {
      this.onSaveTeam.emit({ teamDetail: sendData });
      this.modalService.dismissAll();
      return;
    }


    this.teamsService.createTeamRequests(sendData).subscribe((res) => {
      if (res.status == 'success') {
        this.createRequestLoader = false;
        this.onRequestCreated.emit(true);
        this.toaster.success("Team Request Created", "Success", { extendedTimeOut: 10 });
        this.sendEmailCreateRequest();
        this.onCancel();
      } else {
        this.createRequestLoader = false;
      }
    })
  }

  public onUpdateTeamRequest() {
    this.createRequestLoader = true;
    let valid = true;
    let sendData = this.formTeamReq.value;
    sendData?.channels.forEach((channel) => {
      if (!channel?.name) {
        this.toaster.info("Channel Name is Mandatory", "Required", { timeOut: 0, extendedTimeOut: 0 });
        valid = false;
        return;
      }
      channel?.tabs.forEach(tab => {
        if (!tab?.name) {
          valid = false;
          this.toaster.info("Tab Name is Mandatory for the Channel Name " + channel.name, "Required", { timeOut: 0, extendedTimeOut: 0 });
          return;
        }

      });
    });

    if (!valid) {
      this.createRequestLoader = false;
      return;
    }
    sendData.status = "Pending";
    sendData.createdBy = this.netDocLocalStorage?.ndInfo?.displayName;
    sendData.createdByEmail = this.netDocLocalStorage?.ndInfo?.email;
    sendData.members = this.workspaceMembers;
    sendData.groups = this.workspaceGroups;
    if (sendData.teamLevelSync) {
      let syncJson = JSON.parse(JSON.stringify(sendData.syncDetails));
      sendData.syncDetails = [syncJson];
    }
    if (this.selectedSharePointTemplate && !this.sharePointSiteTemplate?.selected) {
      this.sharePointSiteTemplate.selected = this.selectedSharePointTemplate;
    }
    if (this.sharePointSiteTemplate?.selected?.Title) {
      let ws = this.ClientMatterWsInfo.data.selectedCabinet;
      const primarySource = {
        cabinet: ws.name,
        cabinetId: ws.id,
        id: this.ClientMatterWsInfo.data.Attributes.Id,
        name: this.ClientMatterWsInfo.data.Attributes.Name,
        type: "workspace",
        workspace: this.ClientMatterWsInfo.data.Attributes.Name,
        workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
      }
      const appConfig = { viewType: "normalView", primarySource, showLogout: true, showLogo: true, contectMenu: [], actionButtons: [] };
      sendData.sharepointSiteTemplate = {
        ...this.sharePointSiteTemplate.selected, siteAddress: this.sharepointConnection.siteAddress,
        connectionId: this.sharepointConnection._id,
        newSiteCollectionName: this.formTeamReq.value.sharePointSiteCollectionName, appConfig: JSON.stringify(appConfig),
        isCreateSPWebpartInHomePage: this.formTeamReq.value.isCreateSPWebpartInHomePage,
        isCreateSPSync: this.formTeamReq.value.isCreateSPSync,
        syncJobSetting: this.formTeamReq.value.isCreateSPSync ? this.sharePointSiteTemplate.syncDetails : null
      };
    }
    this.teamsService.editRequestForm(this.reqFormId, sendData).subscribe((res) => {
      if (res.status == 'success') {
        this.createRequestLoader = false;
        this.toaster.success("Team Request Updated", "Success", { extendedTimeOut: 10 });
        this.onCancel();
      } else {
        this.createRequestLoader = false;
      }
    })
  }



  public sendEmailCreateRequest() {
    const payload = { requestStatus: "created", teamName: this.formTeamReq.value.teamName, createdBy: this.ndvs.currentUser.displayName, toEmails: [this.ndvs.currentUser.email] };
    this.teamsService.sendEmailToAdmins(payload).subscribe((res) => {
      console.log("mail status: ", res);
    })
  }

  reset(type: string) {
    const channels = this.formTeamReq.get('channels') as FormArray;
    channels.clear();
    this.formTeamReq.get('tabConfigSetting').setValue("");
    this.template.selected = {};
    if (type == "both") {
      this.client.selected = {};
      this.matter.selected = {};
      this.ClientMatterWsInfo = {};
    }
    if (type == "client") {
      this.client.selected = {};
      this.client.isFetching = false;
      this.matter = {
        isFetching: false,
        selected: {},
        matterInfo: this.matter?.matterInfo
      }
      this.tabConfigSetting = {};
      this.configSetting = {};
      this.ClientMatterWsInfo = {};
      this.formTeamReq.get('client').setValue({});
      this.formTeamReq.get('matter').setValue({});


    }
    if (type == "matter") {
      this.matter.selected = {};
      this.formTeamReq.get('matter').setValue({});
      this.ClientMatterWsInfo = {};
    }
  }
  onSelectTemplate(template: any) {
    this.template.selected = template;;
    this.formTeamReq.get('teamsTemplate').setValue(template);
    if (template) {
      this.templateTab = true;
    }
    this.loadTeamDetails(template);
  }
  getTemplates(data: any = null) {
    if (data?.fetchBy == "search" && this.lookupDataTemplate?.length > 0) {
      this.template.isFetching = true;
      let searchData = this.lookupDataTemplate?.filter((item: any) => JSON.stringify(item?.name).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1);
      this.template = {
        data: searchData,
        isFetching: false,
        message: searchData?.length == 0 ? "No Template found" : "",
        selected: this.formTeamReq.value?.teamsTemplate?.selected,
      }
    } else {
      if (this.template?.data?.length > 0) return;
      this.template.isFetching = true;
      this.teamsService.getTemplates().subscribe((resData) => {
        if (resData.status == "success") {
          var data = resData.data.map(d => { return { ...d, DisplayName: d.name, Id: d._id, Description: d.description } })
          this.lookupDataTemplate = data;
          this.template = {
            data: data,
            message: data.length == 0 ? 'No Template Found' : '',
            isFetching: false,
            selected: this.formTeamReq.value?.teamsTemplate?.selected,
          }
        } else {
          this.template = {
            data: [],
            message: 'No Template Found',
            isFetching: false
          }
        }
        //this.teamsTemplates.sort(this.compare);
      }, (error) => {
        this.template = {
          data: [],
          message: 'No Template Found',
          isFetching: false

        }
      })
    }
  }

  onChangeWorkspaceInfo(workspaceInfo: any) {
    this.workspaceGroups = [];
    this.workspaceMembers = [];
    if (workspaceInfo != null || workspaceInfo != undefined) {
      this.ClientMatterWsInfo = workspaceInfo;
      this.formTeamReq.get("workspace").setValue(workspaceInfo);
      //this.getTemplates();
      this.ndvs.getWorkspaceAcl(workspaceInfo.data.Attributes.Id).subscribe((WsSecurityRes: any) => {
        const acls = WsSecurityRes.data?.Permissions?.Acl || [];
        acls.forEach(aclItem => {
          if (!aclItem.Default && aclItem.Principal.toLowerCase().startsWith(this.ndvs.getUserApiUrl()?.split(".")[1] + '-')) {
            const foundUser = this.workspaceMembers.find(wsMember => wsMember.Principal?.toLowerCase() === aclItem.Principal?.toLowerCase());
            const user = this.repositoryUsers.find(user => user?.id?.toLowerCase() == aclItem?.Principal?.toLowerCase());
            if (!foundUser && user) {
              const accessLevel = (aclItem.View && !aclItem.Edit && !aclItem.Share) || (aclItem.View && aclItem.Share && !aclItem.Edit) ? "read" : (aclItem.View && aclItem.Edit && !aclItem.Share) || (aclItem.View && aclItem.Edit && aclItem.Share) ? "read_write" : (aclItem.View && aclItem.Edit && aclItem.Share && aclItem.Persistent) ? "full_access" : "no_access";
              this.workspaceMembers.push({ email: user.email, id: user.id, fullName: user.displayName, accessLevel: accessLevel });
            }
          } else if (!aclItem.Default && !aclItem.Principal.toLowerCase().startsWith(this.ndvs.getUserApiUrl()?.split(".")[1] + '-')) {
            this.ndvs.getGroupMembers(WsSecurityRes.data?.Locations?.Repositories[0].Guid, aclItem.Principal).subscribe((groupMembersRes: any) => {
              console.log("groupMembers:", groupMembersRes);
              const foundGroup = this.workspaceGroups.find(wsgroup => wsgroup.id?.toLowerCase() === aclItem.id?.toLowerCase());
              if (!foundGroup) {
                const groupMembers = groupMembersRes?.data?.map(membersItem => {
                  return { email: membersItem.Email?.toLowerCase(), id: membersItem.Guid, fullName: membersItem.FirstName };
                })
                const accessLevel = (aclItem.View && !aclItem.Edit && !aclItem.Share) || (aclItem.View && aclItem.Share && !aclItem.Edit) ? "read" : (aclItem.View && aclItem.Edit && !aclItem.Share) || (aclItem.View && aclItem.Edit && aclItem.Share) ? "read_write" : (aclItem.View && aclItem.Edit && aclItem.Share && aclItem.Persistent) ? "full_access" : "no_access";
                this.workspaceGroups.push({ name: aclItem.Name, id: aclItem.Principal, members: groupMembers, accessLevel: accessLevel });
              }
            });
          } else if (aclItem.Default) {
            this.ndvs.getDefaultGroups(aclItem.Principal).subscribe((defaultGroupsRes: any) => {
              console.log("defaultgroupMembers:", defaultGroupsRes);
              const defaultGroups = defaultGroupsRes.data?.Groups;
              defaultGroups.forEach(defaultGroup => {
                this.ndvs.getGroupMembers(WsSecurityRes.data?.Locations?.Repositories[0].Guid, defaultGroup.Id).subscribe((groupMembersRes: any) => {
                  console.log("groupMembers:", groupMembersRes);
                  const foundGroup = this.workspaceGroups.find(wsgroup => wsgroup.id?.toLowerCase() === defaultGroup.Id?.toLowerCase());
                  if (!foundGroup) {
                    const groupMembers = groupMembersRes?.data?.map(membersItem => {
                      return { email: membersItem.Email?.toLowerCase(), id: membersItem.Guid, fullName: membersItem.FirstName };
                    })
                    const rights = defaultGroup.Rights?.toLowerCase();
                    const accessLevel = rights == "v" || rights == "vs" ? "read" : rights == "ve" || rights == "ves" ? "read_write" : rights == "vesa" ? "full_access" : "no_access";
                    this.workspaceGroups.push({ name: defaultGroup.Name, id: defaultGroup.Id, members: groupMembers, accessLevel: accessLevel });
                  }
                });
              });
            });
          }
        });
      });


    } else {
      this.toaster.info("No Workspace available!!");
      return;
    }


  }
  loadTeamDetails(template) {
    template = JSON.parse(template?.config);
    this.formTeamReq.get('optionPrivacy').setValue(template.team?.visibility.toLowerCase());
    this.formTeamReq.get('teamLevelSync').setValue(template.team.sync);
    this._ngzone.run(() => {
      const channels = this.formTeamReq.get('channels') as FormArray;
      channels.clear();
      this.tabConfigSetting = {};
      this.formTeamReq.get('tabConfigSetting').setValue("");
      if (this.ClientMatterWsInfo?.data) {
        let ws = this.ClientMatterWsInfo.data.selectedCabinet;
        const primarySource = {
          cabinet: ws.name,
          cabinetId: ws.id,
          id: this.ClientMatterWsInfo.data.Attributes.Id,
          name: this.ClientMatterWsInfo.data.Attributes.Name,
          type: "workspace",
          workspace: this.ClientMatterWsInfo.data.Attributes.Name,
          workspaceId: this.ClientMatterWsInfo.data.Attributes.Id,
        }
        let mattername = '';
        if (this.matter?.selected?.value) {
          mattername = '_' + this.matter.selected.value
        }
        const tabConfig = {
          tabName: this.client.selected.value + mattername,
          clientMatter: { client: this.client.selected, matter: this.matter.selected },
          cabinet: ws.name,
          cabinetId: ws.id,
          viewType: "normalView", primarySource: primarySource, showLogout: true, showLogo: true
        };
        const tab = {
          tabName: this.client.selected.value + mattername, config: JSON.stringify(tabConfig)
        };
        this.tabConfigSetting = {
          "tabConfig": { tabId: Math.random().toString(), ...tab },
          "tenantId": this.global.tenantId
        }
        this.formTeamReq.get('tabConfigSetting').setValue(this.tabConfigSetting);
      }

      if (this.requestFromBulkEdit) {
        return;
      }
      template.team.channels.forEach((item, channelIndex) => {
        this.addChannel(item);
        const channel = channels.at(channelIndex);
        const tabs = channel.get('tabs') as FormArray;
        tabs.clear();
        item.tabs.forEach(tabItem => {
          this.addTab(channelIndex, this.tabConfigSetting, "netDocShare");
        });
      });
    });


  }

  populateClientMatterData() {
    if (this.clientMatterInfo?.cabinet) {
      this.cabinet.selected = this.clientMatterInfo?.cabinet;
      this.getClientMatterInfo(this.cabinet.selected);
    }
    if (this.clientMatterInfo?.client) this.client.selected = this.clientMatterInfo?.client;
    if (this.clientMatterInfo?.matter) this.matter.selected = this.clientMatterInfo?.matter;
  }

}
