<div class="sync-popup custom-scrollbar">
  <div class="sync-popup-container sync-popup-sm">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="createLoader||isNDLoading">
      <div class="loader-container loader-container-absolute">
        <div class="loader"></div>
      </div>
    </div>
    <!-- This component handle all 3 types
1. Existing - Click on Team Cloning
2. Template - Click on Create Template
3. From Template - Click on 'from template option'. Rest of the step (1 step creation) follows the last step 'team from scratch'. This is '!=exisiting'

Steps are the navigation steps in this modal popup.

Sync job ui is in 3 instance in 3 different type. Any modifications in this has to be done in 3 instance. -->
    <div class="sync-popup-header p-4" style="z-index: 110;position: relative;">
      <div *ngIf="selectedType!='existing' && selectedType!='fromtemplate'">
        {{step==1?templateInfo.displayName:step==2?"What kind of team will this be?":step==4?"Add members to
        "+form.value.teamName:"Some quick details about your "+permissionScope+" team"}}
      </div>
      <div *ngIf="selectedType=='existing'">
        {{step==1?"Clone a team":"Which team do you want to use?"}}
      </div>
      <div *ngIf="selectedType=='template'">
        {{step==1?"Convert a team into template":""}}
      </div>
      <div *ngIf="selectedType=='fromtemplate'">
        {{(step==1)?"Create a team from template":(!updateTemplateInfo?"Create a team from template":"Edit template")}}
      </div>
      <button class="im-btn-link btnbox" (click)="onCancelStep()" [disabled]="createLoader||isNDLoading">
        <img src="assets/images/icons/modal-close.svg">
      </button>
    </div>
    <div class="sync-popup-content p-4" *ngIf="selectedType=='existing'||selectedType=='template'">
      <div *ngIf="fetchingData" class="text-center d-flex p-4 justify-content-center">
        Retrieving Template Information
        <div class="section-loader ml-3">
          <img src="assets/images/Spinner.gif">
        </div>
      </div>
      <div *ngIf="step==3">
        <button class="im-tag-outline-btn w-100 p-4 text-left mb-4" (click)="setPermissionScope('private')">
          <div>
            <h6>Team</h6>
            <span>An existing Microsoft 365 group or team</span>
          </div>
        </button>
      </div>
      <div *ngIf="step==2">
        <p>Copy apps, settings, and channels over to your new team. Your existing team won't be changed.</p>
        <ul class="list-group list-border-0 team-channel-list ml-3">
          <li class="list-unstyled" id="{{group.Id}}" *ngFor="let group of teamsGroups; let i = index">
            <div class="col p-0 d-flex align-items-center teams-channels-name">
              <div class="teams-name-highlight">
                {{group.DisplayName.charAt(0)}}{{group.DisplayName.charAt(1)}}
              </div>
              <div class="name">{{group.DisplayName}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="step==1" class="loader-holder">
        <!-- <div
          *ngIf="createLoader||isNDLoading"
          class="loader-container loader-container-absolute">
          <div class="loader"></div>
        </div> -->

        <div [formGroup]="form">
          <div class="form-group row mr-0 ml-0">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">
                {{(selectedType=='template'||selectedType=='fromtemplate')?'Template Name *':'Team Name *'}}
              </label>
            </div>
            <div class="col-sm-8">
              <input #step1TeamName type="text" class="form-control" formControlName="teamName"
                placeholder="{{(selectedType=='template'||selectedType=='fromtemplate')?'Give your template a name':'Give your team a name'}}"
                [attr.disabled]="(createLoader||isNDLoading)?true:null">
            </div>
          </div>
          <div class="form-group row mr-0 ml-0 mb-4">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Description</label>
            </div>
            <div class="col-sm-8">
              <textarea class="form-control-custom" rows="5" formControlName="description"
                placeholder="{{(selectedType=='template'||selectedType=='fromtemplate')?'Let people know what this template is all about':'Let people know what this team is all about'}}"
                [attr.disabled]="(createLoader||isNDLoading)?true:null"></textarea>
            </div>
          </div>
          <div class="form-group row mr-0 ml-0 mb-4">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Privacy</label>
            </div>
            <div class="col-sm-8">
              <div>
                <select [attr.disabled]="(createLoader||isNDLoading)?true:null" class="form-control" id="privacy"
                  formControlName="optionPrivacy">
                  <option value="public">
                    Public
                    <!-- - Anyone in your organization can join -->
                  </option>
                  <option value="private">
                    Private
                    <!-- - Only team owners can add members -->
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- Sync UI START From Team -->
          <div *ngIf="duplicationTeamSyncCheck() && selectedType!='template' && global.haveSyncAddOn">
            <div class="form-group row mr-0 ml-0 mb-4">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

                <label class="form-label" for="syncCheck">
                  Sync Location
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncLocation}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8" *ngIf="!global.maxSyncJobCreated">
                <input type="checkbox" id="syncCheck" style="display:none" checked="syncCheck"
                  title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':''}}" [(ngModel)]="syncCheck"
                  [ngModelOptions]="{standalone: true}"
                  [attr.disabled]="(createLoader||isNDLoading||global.maxSyncJobCreated)?true:null"
                  (change)="onChangeSyncCheck($event)">
                <label for="syncCheck" class="check-toggle ml-0 mt-0">
                  <span></span>
                </label>

              </div>
              <div class="col-sm-8" *ngIf="global.maxSyncJobCreated">
                Maximum Sync Job Created
              </div>
            </div>

            <div class="form-group row mr-0 ml-0 mb-4" *ngIf="syncCheck">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                <label class="form-label" for="elementSyncType">
                  Sync Type
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncType}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <select class="form-control" id="elementSyncType" [(ngModel)]="syncType"
                  (ngModelChange)="onChangeSyncType($event)" [attr.disabled]="(createLoader||isNDLoading)?true:null"
                  formControlName="optionSync">
                  <option value disabled>
                    Select Type
                  </option>
                  <option *ngFor="let sType of syncTypes" value="{{sType.value}}">
                    {{sType.text}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12 mb-3 pl-0 pl-lg-2 pr-0" *ngIf="syncCheck">
              <div class="nds-outer-box h-100 mh-200 right-content">
                <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
                  <div class="pg-heading">Select NetDocuments Location</div>
                  <button type="button" placement="left"
                    class="btn btn-sm btn-blue h-100 {{( isNDLoading)? 'show-loader' :''}}"
                    [disabled]="((!isNDLoading && ndvs.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading) || createLoader)"
                    (click)="onRefreshNetDocumentContants()">
                    <span *ngIf="(!isNDLoading)" class="material-icons-sharp md-refresh"></span>
                    <img *ngIf="(isNDLoading)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                  </button>
                </div>
                <div class="col-md-12 px-0">
                  <div #ndFormNav>
                    <div class="form-group row mb-2 align-items-center">
                      <div class="col-md-12 col-xl-4">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                          <label class="col-form-label col-label-info">Cabinet</label>
                          <div class="help-tip">
                            <div class="tooltip">
                              <p>{{toolTip.ndCabinet}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-xl-8">
                        <div class="input-group">
                          <div class="input-group-append cus-drop w-100 {{(isCabinetLoader) ? 'show-loader' : ''}}"
                            ngbDropdown #cabinetDropdown="ngbDropdown">
                            <input formControlName="selectedCabinetName" type="text" class="form-control"
                              placeholder="Select Cabinet" readonly [(ngModel)]="selectedCabinet.name" ngbDropdownToggle
                              [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                            <button class="dropdown-toggle btn btn-blue" ngbDropdownToggle
                              [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                              <img src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="sourceDropdown2"
                              class="form-inline mb-2 dropdownBody {{(cabinets.length==0) ? 'd-none' : ''}}">
                              <div *ngFor="let cabinet of cabinets"
                                class="form-check context-dropdown-item source-dropdown-item"
                                (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)"
                                ngbDropdownItem>
                                <img src="./assets/images/cabinet.svg" class="thumbnail mr-2">
                                <label class="form-check-label context-dropdown-text">{{cabinet.name}}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <nav aria-label="breadcrumb"
                      class="mb-2 border-bottom {{(isNDLoading)|| createLoader ? 'breadcrumb-disabled' : ''}}"
                      *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
                      <ol class="breadcrumb p-0 border bg-light mb-2">
                        <li class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetRoot()">
                          <img src="./assets/images/cabinet.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{selectedCabinet.name}}</a>
                        </li>
                        <li *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )"
                          class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetWorkspace()">
                          <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{selectedWorkSpace.name}}</a>
                        </li>
                        <li class="breadcrumb-item crumb py-2"
                          *ngFor="let folder of ndvs.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index"
                          (click)="!createLoader&&onLoadBreadCurmp(folder,breadcurmbIndex)">
                          <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{folder.Attributes.Name}}</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div class="file-grid search-results">
                    <div class="d-flex justify-content-between">
                      <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0)"
                        class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                        (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')"
                        title="{{selectedWorkSpace.name}}">
                        {{selectedWorkSpace.name}}
                      </div>
                      <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0)"
                        class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                        (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)"
                        title="{{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}">
                        {{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2 mt-2">
                      <div
                        *ngIf="(ndvs.syncJobSetting.netDocuments.cabinetId!='' &&ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId=='' && ndvs.syncJobSetting.netDocuments.folderId=='')"
                        class="pg-heading input-group fs-16">
                        <input formControlName="cabinetSearch" class="form-control" type="search" placeholder="Search"
                          [(ngModel)]="cabinetSearch" (input)="onChangeCabinetSearch()"
                          (keypress)="onSearchKeyPress($event)" [attr-disabled]="createLoader?true:null">
                        <div class="input-group-append">
                          <button type="button" class="btn btn-blue" (click)="onSearchWorkspace()"
                            [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading|| createLoader)">
                            <i class="material-icons-sharp md-search"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                    <div class="d-flex flex-column nd-file-manger">
                      <div class="row"
                        *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
                        <div class="col-md-12 text-center my-3">Cabinet or folders not found</div>
                      </div>
                      <div class="{{'border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover'}}"
                        *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))"
                        (scroll)="onNDScroll($event)">

                        <div #ndBlocker *ngIf="(isNDLoading)" class="container-blocker">
                          <div class="text-center w-100">
                            <span>
                              Loading...
                              <img src="./assets/images/Spinner.gif" class="loadingIcon">
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3" *ngFor="let wSpace of workSpaces|filterWorkspacename:cabinetSearch">
                          <div class="d-flex">
                            <div class="d-flex align-items-start" (click)="!createLoader&&onSelectWorkSpace(wSpace)">
                              <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mr-2">
                              <a title="{{wSpace.name}}" class="fs-14 text-dark text-decoration-none">
                                {{wSpace.name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3"
                          *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:cabinetSearch">
                          <div class="d-flex">
                            <div class="d-flex align-items-start"
                              (click)="!createLoader&&onSelectCabinetFolder(cabinetFolder)">
                              <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mr-2">
                              <a title="{{cabinetFolder.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                                {{cabinetFolder.Attributes.Name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3" *ngFor="let content of workSpaceContents">
                          <div class="d-flex">
                            <input
                              *ngIf="(ndvs.syncJobSetting.syncType=='netDocumentsUni' || ndvs.syncJobSetting.syncType=='netDocumentsUniOd' )"
                              type="checkbox" class="mt-1 mr-2" (click)="onChooseNDContent($event,content)"
                              [checked]="content.isChecked" [disabled]="content.isRestricted|| createLoader">
                            <div class="d-flex align-items-start" (click)="!createLoader&&onSelectContent(content)">
                              <img
                                src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}"
                                class="thumbnail mr-2">
                              <a title="{{content.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                                {{content.Attributes.Name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="((!isFolerLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined || (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined)))">
                          <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                        </div>
                      </div>

                      <div *ngIf="folderCreationError!=''" class="mt-4">
                        <div *ngIf="folderCreationErrorMessage.status==409">
                          <div class="red">
                            The folder already contains subfolder '{{this.folderCreationError}}'. Create a new folder or
                            choose another location.
                          </div>
                          <div class="form-group row mr-0 ml-0 mt-3">
                            <div
                              class="col-sm-4 position-relative d-flex justify-content-between align-items-center pl-0">
                              <label class="form-label">New Folder Name</label>
                            </div>
                            <div class="col-sm-8 pr-0">
                              <input type="text" class="form-control" formControlName="newFolderName"
                                placeholder="Give a new folder name" [attr.disabled]="createLoader?true:null">
                            </div>
                          </div>
                        </div>
                        <div *ngIf="folderCreationErrorMessage.status==403">
                          <div class="red">
                            You don't have rights to create folder in the selected location.
                          </div>
                        </div>
                        <div *ngIf="folderCreationErrorMessage.status!=403&&folderCreationErrorMessage.status!=409">
                          <div class="red">Error in creating folder.</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Sync UI END -->
        </div>
        <div class="form-group row mr-0 ml-0 mb-4" *ngIf="selectedType=='existing'">
          <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
            <label class="form-label">Choose to include</label>
          </div>
          <div class="col-sm-8">
            <div class="inp-padding">
              <input type="checkbox" disabled [(ngModel)]="cloneItem.channels" (click)="changeSelection($event)"
                id="channels">
              <label class="form-check-label" for="channels">
                Channels
              </label>
            </div>
            <div class="inp-padding">
              <input type="checkbox" [(ngModel)]="cloneItem.tabs" (click)="changeSelection($event)" id="tabs">
              <label class="form-check-label" for="tabs">
                Tabs
              </label>
            </div>
            <div class="inp-padding">
              <input type="checkbox" disabled="{{cloneItem.tabs}}" [(ngModel)]="cloneItem.apps"
                (click)="changeSelection($event)" id="apps">
              <label class="form-check-label" for="apps">
                Apps
              </label>
            </div>
            <div class="inp-padding">
              <input type="checkbox" (click)="changeSelection($event)" [(ngModel)]="cloneItem.settings" id="settings">
              <label class="form-check-label" for="settings">
                Settings
              </label>
            </div>
            <div class="inp-padding">
              <input type="checkbox" [(ngModel)]="cloneItem.members" (click)="changeSelection($event)" id="members">
              <label class="form-check-label" for="members">
                Members
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sync-popup-footer" style="position: relative;
    z-index: 111;" *ngIf="(selectedType=='existing'||selectedType=='template')&&step==1">
      <button class="btn im-primary-outline-btn"
        (click)="selectedType=='template'?onCreateTemplate():onCreateDuplication()"
        [disabled]="enableDisableNext()||createLoader||isNDLoading">
        {{"Create"}}
        <img class="loader-image" *ngIf="createLoader" src="assets/images/Spinner.gif">
      </button>
    </div>

    <div class="sync-popup-content p-4" *ngIf="selectedType=='fromtemplate'">
      <div *ngIf="fetchingData" class="text-center d-flex p-4 justify-content-center">
        Retrieving Template Information
        <div class="section-loader ml-3">
          <img src="assets/images/Spinner.gif">
        </div>
      </div>
      <div *ngIf="step==1">
        <div class="text-center" *ngIf="teamsTemplates.length==0 && !fetchingData">
          No template is available.
        </div>
        <div class="col-12 p-2" *ngIf="teamsTemplates.length>0">
          <div class="search-box">
            <img class="search-icon"
              src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K">

            <input placeholder="Search Template" type="text" class="search-input-text form-control"
              [(ngModel)]="templateSearch">

            <img class="clear-icon"
              src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxLjk3NiA1MS45NzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxLjk3NiA1MS45NzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8cGF0aCBkPSJNNDQuMzczLDcuNjAzYy0xMC4xMzctMTAuMTM3LTI2LjYzMi0xMC4xMzgtMzYuNzcsMGMtMTAuMTM4LDEwLjEzOC0xMC4xMzcsMjYuNjMyLDAsMzYuNzdzMjYuNjMyLDEwLjEzOCwzNi43NywwICAgQzU0LjUxLDM0LjIzNSw1NC41MSwxNy43NCw0NC4zNzMsNy42MDN6IE0zNi4yNDEsMzYuMjQxYy0wLjc4MSwwLjc4MS0yLjA0NywwLjc4MS0yLjgyOCwwbC03LjQyNS03LjQyNWwtNy43NzgsNy43NzggICBjLTAuNzgxLDAuNzgxLTIuMDQ3LDAuNzgxLTIuODI4LDBjLTAuNzgxLTAuNzgxLTAuNzgxLTIuMDQ3LDAtMi44MjhsNy43NzgtNy43NzhsLTcuNDI1LTcuNDI1Yy0wLjc4MS0wLjc4MS0wLjc4MS0yLjA0OCwwLTIuODI4ICAgYzAuNzgxLTAuNzgxLDIuMDQ3LTAuNzgxLDIuODI4LDBsNy40MjUsNy40MjVsNy4wNzEtNy4wNzFjMC43ODEtMC43ODEsMi4wNDctMC43ODEsMi44MjgsMGMwLjc4MSwwLjc4MSwwLjc4MSwyLjA0NywwLDIuODI4ICAgbC03LjA3MSw3LjA3MWw3LjQyNSw3LjQyNUMzNy4wMjIsMzQuMTk0LDM3LjAyMiwzNS40NiwzNi4yNDEsMzYuMjQxeiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=">
          </div>
        </div>
        <ul class="list-group list-border-0 team-channel-list ml-3">
          <li class="list-unstyled" id="{{template.Id}}"
            *ngFor="let template of teamsTemplates|filterTemplate:templateSearch; let i = index"
            (click)="selectTemplate($event, template.Id, template)" title="{{template.Description}}">
            <div class="wrapper">
              <div>
                <div class="col p-0 d-flex align-items-center teams-channels-name">
                  <div class="teams-name-highlight">
                    {{template.DisplayName.charAt(0)}}{{template.DisplayName.charAt(1)}}
                  </div>
                  <div class="name">{{template.DisplayName}}</div>
                </div>
              </div>
              <div class="d-flex"><button class="btn btn-icon im-white-outline-btn ml-2 waves-effect"
                  (click)="updateTemplate($event, template)" type="button"><img src="assets/images/edit_black_24dp.svg">
                </button><button class="btn btn-icon im-white-outline-btn ml-2 waves-effect"
                  (click)="deleteTemplate($event, template.Id)" type="button"><img
                    src="assets/images/delete_forever_black_24dp.svg">
                </button>
              </div>
            </div>
            <div class="desc">{{template.Description}}</div>
            <div class="confirm mt-3" *ngIf="deleteTemp && deleteTempId==template.Id">
              <div>Sure you want to delete this template?</div>
              <div><button (click)="deleteTemplateCancel($event, template.Id)" class="btn btn-secondary"
                  [disabled]="createLoader">
                  No
                </button><button (click)="deleteTemplateConfirm($event, template.Id)" class="btn im-primary-btn"
                  [disabled]="createLoader">
                  Yes<img class="loader-image" *ngIf="createLoader" src="assets/images/Spinner.gif">
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="step==2||updateTemplateInfo" class="loader-holder">
        <!-- <div
          *ngIf="createLoader||isNDLoading"
          class="loader-container loader-container-absolute">
          <div class="loader"></div>
        </div> -->
        <!-- Template Editing starts here -->
        <div [formGroup]="form">
          <div class="form-group row mr-0 ml-0">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">
                {{(updateTemplateInfo)?'Template Name *':'Team Name *'}}
              </label>
            </div>
            <div class="col-sm-8">
              <input type="text" #step2TeamName class="form-control" formControlName="teamName"
                placeholder="{{(updateTemplateInfo)?'Give your template a name':'Give your team a name'}}"
                [attr.disabled]="(createLoader||isNDLoading)?true:null">
            </div>
          </div>
          <div class="form-group row mr-0 ml-0 mb-4">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Description</label>
            </div>
            <div class="col-sm-8">
              <textarea class="form-control-custom" rows="5" formControlName="description"
                placeholder="{{(updateTemplateInfo)?'Let people know what this template is all about':'Let people know what this team is all about'}}"
                [attr.disabled]="(createLoader||isNDLoading)?true:null"></textarea>
            </div>
          </div>

          <div *ngIf="updateTemplateInfo">
            <app-template-editor [template]="templateToEdit"
              (templateInfo)="onChangeTemplateInfo($event)"></app-template-editor>
          </div>

          <div class="form-group row mr-0 ml-0 mb-4" *ngIf="!updateTemplateInfo">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Privacy</label>
            </div>
            <div class="col-sm-8">
              <div>
                <select [attr.disabled]="createLoader?true:null" class="form-control" id="privacy"
                  formControlName="optionPrivacy">
                  <option value="public">
                    Public
                    <!-- - Anyone in your organization can join -->
                  </option>
                  <option value="private">
                    Private
                    <!-- - Only team owners can add members -->
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- Sync UI START From Template -->
          <div *ngIf="syncTemplateCheck() && selectedType!='fromtemplate'">
            <!-- &&!channelSync -->
            <div class="form-group row mr-0 ml-0 mb-4">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

                <label class="form-label" for="syncCheck">
                  Sync Location
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncLocation}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8" *ngIf="!global.maxSyncJobCreated">
                <input type="checkbox" id="syncCheck" style="display:none" checked="syncCheck"
                  title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':''}}" [(ngModel)]="syncCheck"
                  [ngModelOptions]="{standalone: true}"
                  [attr.disabled]="(createLoader||isNDLoading||global.maxSyncJobCreated)?true:null"
                  (change)="onChangeSyncCheck($event)">
                <label for="syncCheck" class="check-toggle ml-0 mt-0">
                  <span></span>
                </label>

              </div>

              <div class="col-sm-8" *ngIf="global.maxSyncJobCreated">
                Maximum Sync Job Created
              </div>
            </div>

            <div class="form-group row mr-0 ml-0 mb-4" *ngIf="syncCheck">
              <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                <label class="form-label" for="elementSyncType">
                  Sync Type
                </label>
                <div class="help-tip mt-0">
                  <div class="tooltip">
                    <p>{{toolTip.syncType}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <select class="form-control" id="elementSyncType" [(ngModel)]="syncType"
                  (ngModelChange)="onChangeSyncType($event)" [attr.disabled]="createLoader?true:null"
                  formControlName="optionSync">
                  <option value disabled>
                    Select Type
                  </option>
                  <option *ngFor="let sType of syncTypes" value="{{sType.value}}">
                    {{sType.text}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12 mb-3 pl-0 pl-lg-2 pr-0" *ngIf="syncCheck">
              <div class="nds-outer-box h-100 mh-200 right-content">
                <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
                  <div class="pg-heading">Select NetDocuments Location</div>
                  <button type="button" placement="left"
                    class="btn btn-sm btn-blue h-100 {{( isNDLoading)? 'show-loader' :''}}"
                    [disabled]="((!isNDLoading && ndvs.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading) || createLoader)"
                    (click)="onRefreshNetDocumentContants()">
                    <span *ngIf="(!isNDLoading)" class="material-icons-sharp md-refresh"></span>
                    <img *ngIf="(isNDLoading)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                  </button>
                </div>
                <div class="col-md-12 px-0">
                  <div #ndFormNav>
                    <div class="form-group row mb-2 align-items-center">
                      <div class="col-md-12 col-xl-4">
                        <div class="clearfix d-flex justify-content-between align-items-center">
                          <label class="col-form-label col-label-info">Cabinet</label>
                          <div class="help-tip">
                            <div class="tooltip">
                              <p>{{toolTip.ndCabinet}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-xl-8">
                        <div class="input-group">
                          <div class="input-group-append cus-drop w-100 {{(isCabinetLoader) ? 'show-loader' : ''}}"
                            ngbDropdown #cabinetDropdown="ngbDropdown">
                            <input formControlName="selectedCabinetName" type="text" class="form-control"
                              placeholder="Select Cabinet" readonly [(ngModel)]="selectedCabinet.name" ngbDropdownToggle
                              [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                            <button class="dropdown-toggle btn btn-blue" ngbDropdownToggle
                              [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                              <img src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="sourceDropdown2"
                              class="form-inline mb-2 dropdownBody {{(cabinets.length==0) ? 'd-none' : ''}}">
                              <div *ngFor="let cabinet of cabinets"
                                class="form-check context-dropdown-item source-dropdown-item"
                                (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)"
                                ngbDropdownItem>
                                <img src="./assets/images/cabinet.svg" class="thumbnail mr-2">
                                <label class="form-check-label context-dropdown-text">{{cabinet.name}}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <nav aria-label="breadcrumb"
                      class="mb-2 border-bottom {{(isNDLoading)|| createLoader ? 'breadcrumb-disabled' : ''}}"
                      *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
                      <ol class="breadcrumb p-0 border bg-light mb-2">
                        <li class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetRoot()">
                          <img src="./assets/images/cabinet.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{selectedCabinet.name}}</a>
                        </li>
                        <li *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )"
                          class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetWorkspace()">
                          <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{selectedWorkSpace.name}}</a>
                        </li>
                        <li class="breadcrumb-item crumb py-2"
                          *ngFor="let folder of ndvs.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index"
                          (click)="!createLoader&&onLoadBreadCurmp(folder,breadcurmbIndex)">
                          <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mx-2">
                          <a title="Confidential">{{folder.Attributes.Name}}</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div class="file-grid search-results">
                    <div class="d-flex justify-content-between">
                      <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0)"
                        class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                        (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')"
                        title="{{selectedWorkSpace.name}}">
                        {{selectedWorkSpace.name}}
                      </div>
                      <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0)"
                        class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                        (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)"
                        title="{{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}">
                        {{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mb-2 mt-2">
                      <div
                        *ngIf="(ndvs.syncJobSetting.netDocuments.cabinetId!='' &&ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId=='' && ndvs.syncJobSetting.netDocuments.folderId=='')"
                        class="pg-heading input-group fs-16">
                        <input formControlName="cabinetSearch" class="form-control" type="search" placeholder="Search"
                          [(ngModel)]="cabinetSearch" (input)="onChangeCabinetSearch()"
                          (keypress)="onSearchKeyPress($event)" [attr-disabled]="createLoader?true:null">
                        <div class="input-group-append">
                          <button type="button" class="btn btn-blue" (click)="onSearchWorkspace()"
                            [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading|| createLoader)">
                            <i class="material-icons-sharp md-search"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                    <div class="d-flex flex-column nd-file-manger">
                      <div class="row"
                        *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
                        <div class="col-md-12 text-center my-3">Cabinet or folders not found</div>
                      </div>
                      <div class="{{'border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover'}}"
                        *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))"
                        (scroll)="onNDScroll($event)">

                        <div #ndBlocker *ngIf="(isNDLoading)" class="container-blocker">
                          <div class="text-center w-100">
                            <span>
                              Loading...
                              <img src="./assets/images/Spinner.gif" class="loadingIcon">
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3" *ngFor="let wSpace of workSpaces|filterWorkspacename:cabinetSearch">
                          <div class="d-flex">
                            <div class="d-flex align-items-start" (click)="!createLoader&&onSelectWorkSpace(wSpace)">
                              <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mr-2">
                              <a title="{{wSpace.name}}" class="fs-14 text-dark text-decoration-none">
                                {{wSpace.name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3"
                          *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:cabinetSearch">
                          <div class="d-flex">
                            <div class="d-flex align-items-start"
                              (click)="!createLoader&&onSelectCabinetFolder(cabinetFolder)">
                              <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mr-2">
                              <a title="{{cabinetFolder.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                                {{cabinetFolder.Attributes.Name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3" *ngFor="let content of workSpaceContents">
                          <div class="d-flex">
                            <input
                              *ngIf="(ndvs.syncJobSetting.syncType=='netDocumentsUni' || ndvs.syncJobSetting.syncType=='netDocumentsUniOd' )"
                              type="checkbox" class="mt-1 mr-2" (click)="onChooseNDContent($event,content)"
                              [checked]="content.isChecked" [disabled]="content.isRestricted|| createLoader">
                            <div class="d-flex align-items-start" (click)="!createLoader&&onSelectContent(content)">
                              <img
                                src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}"
                                class="thumbnail mr-2">
                              <a title="{{content.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                                {{content.Attributes.Name}}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="((!isFolerLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined || (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined)))">
                          <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                        </div>
                      </div>

                      <div *ngIf="folderCreationError!=''" class="mt-4">
                        <div *ngIf="folderCreationErrorMessage.status==409">
                          <div class="red">
                            The folder already contains subfolder '{{this.folderCreationError}}'. Create a new folder or
                            choose another location.
                          </div>
                          <div class="form-group row mr-0 ml-0 mt-3">
                            <div
                              class="col-sm-4 position-relative d-flex justify-content-between align-items-center pl-0">
                              <label class="form-label">New Folder Name</label>
                            </div>
                            <div class="col-sm-8 pr-0">
                              <input type="text" class="form-control" formControlName="newFolderName"
                                placeholder="Give a new folder name" [attr.disabled]="createLoader?true:null">
                            </div>
                          </div>
                        </div>
                        <div *ngIf="folderCreationErrorMessage.status==403">
                          <div class="red">
                            You don't have rights to create folder in the selected location.
                          </div>
                        </div>
                        <div *ngIf="folderCreationErrorMessage.status!=403&&folderCreationErrorMessage.status!=409">
                          <div class="red">Error in creating folder.</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Sync UI END -->
          <!-- <div *ngIf="channelSync">
            <div *ngFor="let sData of syncData;let i = index">
              <app-channels-config [syncData]="sData"></app-channels-config>
            </div>
          </div> -->

        </div>

      </div>
    </div>

    <!-- <div class="sync-popup-footer">
      <button
        *ngIf="templateActionButtons()"
        class="btn im-primary-outline-btn"
        (click)="goBackTemplate()"
        [disabled]="createLoader||isNDLoading">
        Back
      </button>
      <button
        *ngIf="templateActionButtons()&&!updateTemplateInfo"
        class="btn im-primary-outline-btn"
        (click)="createTeamFromTemplate()"
        [disabled]="enableDisableNext()||createLoader||isNDLoading">
        Create
        <img
          class="loader-image"
          *ngIf="createLoader"
          src="assets/images/Spinner.gif">
      </button>
      <button
        *ngIf="templateActionButtons()&&updateTemplateInfo"
        class="btn im-primary-outline-btn"
        (click)="updateTemplateConfirm()"
        [attr.disabled]="(enableDisableNext()||createLoader||isNDLoading) ?true: null">
        Update
        <img
          class="loader-image"
          *ngIf="createLoader"
          src="assets/images/Spinner.gif">
      </button>
    </div> -->

    <div class="sync-popup-footer" style="position: relative;
    z-index: 111;" *ngIf="templateActionButtons()&&!updateTemplateInfo">
      <button class="btn im-primary-outline-btn" (click)="goBackTemplate()" [disabled]="createLoader||isNDLoading">
        Back
      </button>
      <button class="btn im-primary-outline-btn" (click)="createTeamFromTemplate()"
        [disabled]="enableDisableNext()||createLoader||isNDLoading">
        Create
        <img class="loader-image" *ngIf="createLoader" src="assets/images/Spinner.gif">
      </button>
    </div>

    <div class="sync-popup-footer" *ngIf="templateActionButtons()&&updateTemplateInfo">
      <button class="btn im-primary-outline-btn" (click)="goBackTemplate()" [disabled]="createLoader||isNDLoading">
        Back
      </button>
      <button class="btn im-primary-outline-btn" (click)="updateTemplateConfirm()"
        [disabled]="enableDisableNext()||createLoader||isNDLoading||!formValid">
        Update
        <img class="loader-image" *ngIf="createLoader" src="assets/images/Spinner.gif">
      </button>
    </div>

    <div class="sync-popup-content p-4"
      *ngIf="selectedType!='existing'&&selectedType!='template' &&selectedType!='fromtemplate'">
      <div *ngIf="fetchingData" class="text-center d-flex p-4 justify-content-center">
        Retrieving Template Information
        <div class="section-loader ml-3">
          <img src="assets/images/Spinner.gif">
        </div>
      </div>
      <div *ngIf="step==1 && !fetchingData" class="mb-4">
        <div class="mt-2 mb-4">{{templateInfo.description}}</div>
        <div class="d-flex justify-content-between mr-4 ml-4">
          <div>
            <h6>{{templateInfo.teamDefinition?.channels.length}}
              channel{{templateInfo.teamDefinition?.channels.length>1?"s":""}}</h6>
            <ul class="ml-3">
              <li class="list-unstyled" id="{{channel.id}}"
                *ngFor="let channel of templateInfo.teamDefinition?.channels;let i = index">
                {{channel.displayName}}
              </li>
            </ul>
          </div>
          <div>
            <h6>{{templateInfo.teamDefinition?.apps.length}} app{{templateInfo.teamDefinition?.apps.length>1?"s":""}}
            </h6>
            <ul class="ml-3">
              <li class="list-unstyled" id="{{app.id}}"
                *ngFor="let app of templateInfo.teamDefinition?.apps;let i = index">
                {{app.id}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="step==2">
        <button class="im-tag-outline-btn w-100 p-4 text-left mb-4" (click)="setPermissionScope('private')">
          <div>
            <h6>Private</h6>
            <span>People need permission to join</span>
          </div>
        </button>
        <button class="{{true?'im-tag-outline-btn w-100 p-4 text-left mb-4':'im-tag-outline-btn w-100 p-4 text-left'}}"
          (click)="setPermissionScope('public')">
          <div>
            <h6>Public</h6>
            <span>Anyone in your org can join</span>
          </div>
        </button>
        <button *ngIf="selectedType=='new'" class="im-tag-outline-btn w-100 p-4 text-left"
          (click)="setPermissionScope('org-wide')">
          <div>
            <h6>Org-wide</h6>
            <span>Everyone in your organization automatically joins</span>
          </div>
        </button>

      </div>
      <div *ngIf="step==3" class="mb-4" class="loader-holder">
        <!-- <div
          *ngIf="createLoader||isNDLoading"
          class="loader-container loader-container-absolute">
          <div class="loader"></div>
        </div> -->
        <div [formGroup]="form">
          <div class="form-group row mr-0 ml-0">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Team Name *</label>
            </div>
            <div class="col-sm-8">
              <input type="text" #step3TeamName class="form-control" formControlName="teamName"
                placeholder="Give your team a name" [attr.disabled]="(createLoader||isNDLoading)?true:null">
            </div>
          </div>
          <div class="form-group row mr-0 ml-0 mb-4">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Description</label>
            </div>
            <div class="col-sm-8">
              <textarea class="form-control-custom" rows="5" formControlName="description"
                placeholder="Click here for organization announcements and important information"
                [attr.disabled]="(createLoader||isNDLoading)?true:null"></textarea>
            </div>
          </div>
          <!-- Sync UI START From Scratch -->
          <div class="form-group row mr-0 ml-0 mb-4" *ngIf="global.haveSyncAddOn">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

              <label class="form-label" for="syncCheck">
                Sync Location
              </label>
              <div class="help-tip mt-0">
                <div class="tooltip">
                  <p>{{toolTip.syncLocation}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-8" *ngIf="!global.maxSyncJobCreated">
              <input type="checkbox" id="syncCheck" style="display:none"
                title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':''}}" [(ngModel)]="syncCheck"
                [ngModelOptions]="{standalone: true}"
                [attr.disabled]="(createLoader||isNDLoading||global.maxSyncJobCreated)?true:null"
                (change)="onChangeSyncCheck($event)">
              <label for="syncCheck" class="check-toggle ml-0 mt-0">
                <span></span>
              </label>

            </div>

            <div class="col-sm-8" *ngIf="global.maxSyncJobCreated">
              Maximum Sync Job Created
            </div>
          </div>

          <div class="form-group row mr-0 ml-0 mb-4" *ngIf="syncCheck">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label" for="elementSyncType">
                Sync Type
              </label>
              <div class="help-tip mt-0">
                <div class="tooltip">
                  <p>{{toolTip.syncType}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <select class="form-control" id="elementSyncType" [(ngModel)]="syncType"
                (ngModelChange)="onChangeSyncType($event)" [attr.disabled]="(createLoader||isNDLoading)?true:null"
                formControlName="optionSync">
                <option value disabled>
                  Select Type
                </option>
                <option *ngFor="let sType of syncTypes" value="{{sType.value}}">
                  {{sType.text}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-12 mb-3 pl-0 pl-lg-2 pr-0" *ngIf="syncCheck && syncType!=''">
            <div class="nds-outer-box h-100 mh-200 right-content">
              <div class="border-bottom d-flex justify-content-between pb-2 mb-2 mb-md-3">
                <div class="pg-heading">Select NetDocuments Location</div>
                <button type="button" placement="left"
                  class="btn btn-sm btn-blue h-100 {{( isNDLoading)? 'show-loader' :''}}"
                  [disabled]="((!isNDLoading && ndvs.syncJobSetting.netDocuments.cabinetId=='') || (isNDLoading) || createLoader)"
                  (click)="onRefreshNetDocumentContants()">
                  <span *ngIf="(!isNDLoading)" class="material-icons-sharp md-refresh"></span>
                  <img *ngIf="(isNDLoading)" src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                </button>
              </div>
              <div class="col-md-12 px-0">
                <div #ndFormNav>
                  <div class="form-group row mb-2 align-items-center">
                    <div class="col-md-12 col-xl-4">
                      <div class="clearfix d-flex justify-content-between align-items-center">
                        <label class="col-form-label col-label-info">Cabinet</label>
                        <div class="help-tip">
                          <div class="tooltip">
                            <p>{{toolTip.ndCabinet}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-xl-8">
                      <div class="input-group">
                        <div class="input-group-append cus-drop w-100 {{(isCabinetLoader) ? 'show-loader' : ''}}"
                          ngbDropdown #cabinetDropdown="ngbDropdown">
                          <input formControlName="selectedCabinetName" type="text" class="form-control"
                            placeholder="Select Cabinet" readonly [(ngModel)]="selectedCabinet.name" ngbDropdownToggle
                            [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                          <button class="dropdown-toggle btn btn-blue" ngbDropdownToggle
                            [disabled]="(selectedCabinet.name!='' && isNDLoading)|| createLoader">
                            <img src="./assets/images/Spinner.gif" alt="Loader" class="loader-img">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="sourceDropdown2"
                            class="form-inline mb-2 dropdownBody {{(cabinets.length==0) ? 'd-none' : ''}}">
                            <div *ngFor="let cabinet of cabinets"
                              class="form-check context-dropdown-item source-dropdown-item"
                              (click)="onSelectCabinet(cabinet, cabinetDropdown, modalColumnProperyConfirmation)"
                              ngbDropdownItem>
                              <img src="./assets/images/cabinet.svg" class="thumbnail mr-2">
                              <label class="form-check-label context-dropdown-text">{{cabinet.name}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <nav aria-label="breadcrumb"
                    class="mb-2 border-bottom {{(isNDLoading)|| createLoader ? 'breadcrumb-disabled' : ''}}"
                    *ngIf="(selectedCabinet.name!=undefined && selectedCabinet.name!='')">
                    <ol class="breadcrumb p-0 border bg-light mb-2">
                      <li class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetRoot()">
                        <img src="./assets/images/cabinet.svg" class="thumbnail mx-2">
                        <a title="Confidential">{{selectedCabinet.name}}</a>
                      </li>
                      <li *ngIf="(selectedWorkSpace.name!=undefined && selectedCabinet.name!='' )"
                        class="breadcrumb-item crumb py-2" (click)="!createLoader&&onResetWorkspace()">
                        <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mx-2">
                        <a title="Confidential">{{selectedWorkSpace.name}}</a>
                      </li>
                      <li class="breadcrumb-item crumb py-2"
                        *ngFor="let folder of ndvs.syncJobSetting.netDocuments.breadCurmb; let breadcurmbIndex=index"
                        (click)="!createLoader&&onLoadBreadCurmp(folder,breadcurmbIndex)">
                        <img
                          src="./assets/images/netdocumentsIcons/{{(fileIcons[folder.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[folder.Attributes.Ext.toLowerCase()]}}"
                          class="thumbnail mx-2">
                        <a title="Confidential">{{folder.Attributes.Name}}</a>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div class="file-grid search-results">
                  <div class="d-flex justify-content-between">
                    <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0)"
                      class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                      (click)="onShowBrowser(ndSiteUrl+'workspace/'+selectedWorkSpace.envId+'/docs/summary')"
                      title="{{selectedWorkSpace.name}}">
                      {{selectedWorkSpace.name}}
                    </div>
                    <div *ngIf="( !isWorkspaceLoader && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0)"
                      class="pg-heading fs-16 link {{(isNDLoading)|| createLoader ? 'section-disabled' : ''}}"
                      (click)="onShowBrowser(ndSiteUrl+'folder/'+ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].EnvId)"
                      title="{{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}">
                      {{ndvs.syncJobSetting.netDocuments.breadCurmb[(ndvs.syncJobSetting.netDocuments.breadCurmb.length-1)].Attributes.Name}}
                    </div>


                    <div>
                      <div class="form-check float-left"
                        *ngIf="((syncType=='bidirectionalTeam' || syncType=='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.workspaceId!='' || ndvs.syncJobSetting.netDocuments.folderId!='') && !isNDSSearchResults && !isNDLoading)">
                        <input class="form-check-input" type="checkbox" value="" id="allCheck"
                          [checked]="ndvs.syncJobSetting.netDocuments.selectAll" (change)="onNDSSelectAll()">
                        <label class="form-check-label" for="allCheck">Select all</label>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div class="d-flex justify-content-between mb-2 mt-2">
                    <div
                      *ngIf="(ndvs.syncJobSetting.netDocuments.cabinetId!='' &&ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null)"
                      class="pg-heading input-group fs-16">
                      <input formControlName="cabinetSearch" class="form-control" type="search" placeholder="Search"
                        [(ngModel)]="cabinetSearch" (input)="onChangeCabinetSearch()"
                        (keypress)="onSearchKeyPress($event)" [attr-disabled]="createLoader?true:null">
                      <div class="input-group-append">
                        <button type="button" class="btn btn-blue" (click)="onSearchNDSContents()"
                          [disabled]="(cabinetSearch.trim()=='' || cabinetSearch.length<3 || isNDLoading|| createLoader)">
                          <i class="material-icons-sharp md-search"></i>
                        </button>
                      </div>

                      <img
                        *ngIf="((isNDSUserAccess && ndvs.syncJobSetting.netDocuments.breadCurmb!=undefined && ndvs.syncJobSetting.netDocuments.breadCurmb.length==0) || (isNDSUserAccess && (ndvs.syncJobSetting.netDocuments.breadCurmb!=undefined && ndvs.syncJobSetting.netDocuments.breadCurmb.length>0 && (ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ndflt' && ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ndsq' && ndvs.syncJobSetting.netDocuments.breadCurmb[ndvs.syncJobSetting.netDocuments.breadCurmb.length-1].Attributes.Ext!='ss'))))"
                        class="nd-create-folder {{(isNDLoading)? 'create-folder-disable':''}}"
                        src="./assets/images/netdocumentsIcons/Plusfolder_nd.svg" (click)="onNDSCreateFolder()"
                        title="Create Folder" />
                    </div>
                  </div>

                  <!-- Selected Folders and Files status  -->
                  <div #NDSSectionSelectedFiles class="d-flex justify-content-between mb-2 mt-2"
                    *ngIf="((syncType=='bidirectionalTeam' || syncType=='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null && ndvs.syncJobSetting.netDocuments.cabinetId!='') && ((ndvs.syncJobSetting.netDocuments.workspaceId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId!=null && ndvs.syncJobSetting.netDocuments.workspaceId!='') || (ndvs.syncJobSetting.netDocuments.folderId!=undefined && ndvs.syncJobSetting.netDocuments.folderId!=null && ndvs.syncJobSetting.netDocuments.folderId!='')) && !ndvs.syncJobSetting.netDocuments.selectAll)">
                    <div class="pg-heading justify-content-between input-group fs-16">
                      <span>Selected Folders ({{(ndvs.syncJobSetting.netDocuments.sources.folders==undefined) ? '0' :
                        ndvs.syncJobSetting.netDocuments.sources.folders.length}}),
                        Files({{(ndvs.syncJobSetting.netDocuments.sources.files==undefined) ? '0' :
                        ndvs.syncJobSetting.netDocuments.sources.files.length}})</span>
                      <div
                        *ngIf="((ndvs.syncJobSetting.netDocuments.sources.workspaces!=undefined && ndvs.syncJobSetting.netDocuments.sources.workspaces.length>0) || (ndvs.syncJobSetting.netDocuments.sources.folders!=undefined && ndvs.syncJobSetting.netDocuments.sources.folders.length>0) || (ndvs.syncJobSetting.netDocuments.sources.files!=undefined && ndvs.syncJobSetting.netDocuments.sources.files.length>0))"
                        class="action-button al text-right">
                        <button type="button" class="btn btn-link btn-sm " (click)="onClearNDSSelectedItems()"
                          title="Clear">Clear</button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="showFolderInput" class="d-flex justify-content-between mb-2 mt-2 folder-holder">
                    <label class="col-form-label">Folder Name</label>
                    <div>
                      <input class="form-control" formControlName="NDSFolderName" id="folderName"
                        [(ngModel)]="NDSFolderName" type="text" (keypress)="onNDSFolderNameKeyPress($event)"
                        [disabled]="isNDSFolderCreating" (input)="NDSValidateFolder($event.target.value)">
                      <div *ngIf="isNDSDuplicateFolder" class="alert alert-danger px-3 py-1" role="alert">
                        {{error_message}}
                      </div>
                    </div>
                    <div>
                      <button type="button" class="btn btn-secondary" (click)="onNDSCreateFolderHide()"
                        [disabled]="isNDSFolderCreating">Cancel</button>
                      <button type="button" class="btn btn-blue" (click)="NDSCreateNewFolder()"
                        [disabled]="(NDSFolderName.trim()=='' || isNDSFolderCreating || isNDSDuplicateFolder)">
                        <span>Create </span>
                      </button>
                    </div>
                  </div>

                  <!-- MESSAGE FOR SP-ND Bidirectional, SP-ND, OD-ND Bidirectional, OD-ND, TM-ND Bidirectional, TM-ND  -->
                  <div #NDSSectionInformation
                    *ngIf="((syncType!='netDocumentsUniTeam') && (ndvs.syncJobSetting.netDocuments.cabinetId!=undefined && ndvs.syncJobSetting.netDocuments.cabinetId!=null && ndvs.syncJobSetting.netDocuments.cabinetId!='') && ((ndvs.syncJobSetting.netDocuments.workspaceId!=undefined && ndvs.syncJobSetting.netDocuments.workspaceId!=null && ndvs.syncJobSetting.netDocuments.workspaceId!='') || (ndvs.syncJobSetting.netDocuments.folderId!=undefined && ndvs.syncJobSetting.netDocuments.folderId!=null && ndvs.syncJobSetting.netDocuments.folderId!='')))"
                    class="text-nd-info text-danger">* Folder container only supported for this sync.</div>

                  <div class="d-flex nd-file-manger flex-column">
                    <div class="row"
                      *ngIf="(!isNDLoading && (!isWorkspaceLoader && !isCabinetFolderLoader && (workSpaces.length==0 && cabinetFolders.length==0)) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)) && selectedCabinet.name!=undefined && selectedCabinet.name!='' && workSpaceContents.length==0 )">
                      <div class="col-md-12 text-center my-3">Cabinet or folders not found</div>
                    </div>
                    <div class="{{'border mx-auto overflow-auto row vh-res vh-sm-80 vh-cover'}}"
                      *ngIf="(workSpaces.length>0 || cabinetFolders.length>0 || workSpaceContents.length>0 || (isWorkspaceLoader || isCabinetFolderLoader || isFolerLoading))"
                      (scroll)="onNDScroll($event)">

                      <div #ndBlocker *ngIf="(isNDLoading)" class="container-blocker">
                        <div class="text-center w-100">
                          <span>
                            Loading...
                            <img src="./assets/images/Spinner.gif" class="loadingIcon">
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3" *ngFor="let wSpace of workSpaces">
                        <!-- |filterWorkspacename:cabinetSearch -->
                        <div class="d-flex">
                          <div class="d-flex align-items-start" (click)="!createLoader&&onSelectWorkSpace(wSpace)">
                            <img src="./assets/images/netdocumentsIcons/workspace.svg" class="thumbnail mr-2">
                            <a title="{{wSpace.name}}" class="fs-14 text-dark text-decoration-none">
                              {{wSpace.name}}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3"
                        *ngFor="let cabinetFolder of cabinetFolders|filterCabinetfolder:NDSSearchString">
                        <div class="d-flex">
                          <div class="d-flex align-items-start"
                            (click)="!createLoader&&onSelectCabinetFolder(cabinetFolder)">
                            <img src="./assets/images/netdocumentsIcons/folder_nd.svg" class="thumbnail mr-2">
                            <a title="{{cabinetFolder.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                              {{cabinetFolder.Attributes.Name}}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3" *ngFor="let content of workSpaceContents">
                        <div class="d-flex">
                          <input *ngIf="(ndvs.syncJobSetting.syncType!='teamUni' )" type="checkbox" class="mt-1 mr-2"
                            (change)="onChooseNDContent($event,content)" [checked]="content.isChecked"
                            [disabled]="content.isRestricted|| createLoader">
                          <div class="d-flex align-items-start {{(content.isRestricted) ? 'content-restricted':''}}"
                            (click)="!createLoader&&onSelectContent(content)">
                            <img
                              src="./assets/images/netdocumentsIcons/{{(fileIcons[content.Attributes.Ext.toLowerCase()]==undefined) ? fileIcons['gen'] : fileIcons[content.Attributes.Ext.toLowerCase()]}}"
                              class="thumbnail mr-2">
                            <a title="{{content.Attributes.Name}}" class="fs-14 text-dark text-decoration-none">
                              {{content.Attributes.Name}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="selectedCabinet.name!=''">
                      <div class="row"
                        *ngIf="((!isNDLoading && workSpaces.length==0 && cabinetFolders.length==0) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes==undefined || selectCabinetFolder.Attributes.Name==undefined)))">
                        <div class="col-md-12 text-center my-3">Workspace or folders not found</div>
                      </div>
                      <div class="row"
                        *ngIf="(!isNDLoading && workSpaceContents.length==0) && (selectedWorkSpace.name==undefined && (selectCabinetFolder.Attributes!=undefined && selectCabinetFolder.Attributes.Name!=undefined))">
                        <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                      </div>
                      <div class="row"
                        *ngIf="(!isNDLoading && workSpaceContents.length==0) && (selectedWorkSpace.name!=undefined && (selectCabinetFolder.Attributes==undefined))">
                        <div class="col-md-12 text-center my-3">Folders or Files not found</div>
                      </div>
                    </div>
                    <div *ngIf="folderCreationError!=''" class="mt-4">
                      <div *ngIf="folderCreationErrorMessage.status==409">
                        <div class="red">
                          The folder already contains subfolder '{{this.folderCreationError}}'. Create a new folder or
                          choose another location.
                        </div>
                        <div class="form-group row mr-0 ml-0 mt-3">
                          <div
                            class="col-sm-4 position-relative d-flex justify-content-between align-items-center pl-0">
                            <label class="form-label">New Folder Name</label>
                          </div>
                          <div class="col-sm-8 pr-0">
                            <input type="text" class="form-control" formControlName="newFolderName"
                              placeholder="Give a new folder name" [attr.disabled]="createLoader?true:null">
                          </div>
                        </div>
                      </div>
                      <div *ngIf="folderCreationErrorMessage.status==403">
                        <div class="red">
                          You don't have rights to create folder in the selected location.
                        </div>
                      </div>
                      <div *ngIf="folderCreationErrorMessage.status!=403&&folderCreationErrorMessage.status!=409">
                        <div class="red">Error in creating folder.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center" *ngIf="createLoader">
                <b>Creating the team...</b>
              </div>
              <div *ngIf="!(selectedType=='new')">
                <span role="button" (click)="showCustomizeList()" class="mb-3">
                  <img src="assets/images/folderIcons/down-arrow.png"
                    class="{{showChannelsList?'rotate-0':'rotate-270'}}">
                  <b>Customize channels</b>
                </span>
                <p *ngIf="showChannelsList" class="mt-4">Rename channels to customize them for your team.</p>
                <form class="container mt-3" *ngIf="showChannelsList">
                  <section *ngFor="let channel of channelsList; let i = index;">
                    <input type="text" [(ngModel)]="channel.channel" name="channel_{{i}}" class="border-bottom"
                      [disabled]="channel.disabled">
                  </section>
                </form>

              </div>
            </div>
          </div>
          <!-- Sync UI END -->

          <!-- TAB CONFIG in Team from scratch -->
          <div class="form-group row mr-0 ml-0 mb-4" *ngIf="global.haveTeamsAppAddOn">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

              <label class="form-label" for="allowTabCreation">
                Create Tab
              </label>
              <div class="help-tip mt-0">
                <div class="tooltip">
                  <p>{{toolTip.tabCreation}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <input type="checkbox" id="allowTabCreation" style="display:none" [(ngModel)]="tabCreationCheck"
                [ngModelOptions]="{standalone: true}" [attr.disabled]="(createLoader||isNDLoading)?true:null"
                (change)="onAllowTabCreation($event)">
              <label for="allowTabCreation" class="check-toggle ml-0 mt-2">
                <span></span>
              </label>

            </div>
          </div>
          <create-tab *ngIf="tabCreationCheck" [tabsList]="tabs" [tabDetail]="selectedTab"
            (onConfigChange)="setConfigChange($event)" [creationFromTeam]="true"></create-tab>

          <!-- END of TAB CONFIG in Team from scratch -->

        </div>
      </div>
      <div *ngIf="step==4" class="vh50" overflow-hidden>

        <p>Search and add members to your team.</p>

        <div class="col-sm-12 position-relative d-flex justify-content-between align-items-center  nopadding">
          <label class="form-label">Search Members:</label>
        </div>
        <div class="form-group row mr-0 ml-0 mb-0">
          <div class="col-sm-12 nopadding inp-search-holder">
            <input type="text" [(ngModel)]="searchUserString" name="searchUser" class="form-control inp-search"
              (ngModelChange)="setSearchUserString($event)">
            <button *ngIf="searchUserString!=''" class="icn-btn inp-search-close" (click)="clearSearch($event)">
              <i class="material-icons-sharp md-close"></i>
            </button>
          </div>
        </div>
        <div class="form-group row mr-0 ml-0 height500"
          [hidden]="!searchUserString && !dropDownForm.value.myItems.length">
          <div class="search-member col-sm-12 nopadding">
            <form [formGroup]="dropDownForm" class="w-100">
              <div>
                <div class="dd-loader-holder" *ngIf="loadingMember">
                  <img class="loader-image" src="assets/images/Spinner.gif">
                </div>
                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="members"
                  [disabled]="loadingMember||loadingAddMember" formControlName="myItems" class="ms-drop">
                </ng-multiselect-dropdown>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>

    <div class="sync-popup-footer" *ngIf="step!=4 && selectedType=='new'" style="position: relative;
    z-index: 111;">
      <button *ngIf="!(selectedType=='new'&&step==2)" class="btn im-primary-outline-btn"
        (click)="step==1?onCancelStep():onBackStep()" [disabled]="createLoader||isNDLoading">
        {{step==1?"Cancel":"Back"}}
      </button>
      <button *ngIf="!(selectedType=='new'&&step==2)" class="btn im-primary-btn"
        [disabled]="enableDisableNext()|| createLoader||isNDLoading" (click)="step==3?createTeams():goNextStep()">
        {{step==3?"Create":"Next"}}
        <img *ngIf="createLoader" src="assets/images/Spinner.gif" class="search-button-img">
      </button>
    </div>
    <div class="sync-popup-footer" *ngIf="step==4" style="position: relative;
    z-index: 111;">
      <button *ngIf="!(selectedType=='new'&&step==2)" class="btn im-primary-outline-btn" (click)="onCancelStep()"
        [disabled]="createLoader||loadingAddMember">
        Skip
      </button>
      <button class="btn im-primary-outline-btn" (click)="addMembers()"
        [disabled]="dropDownForm.value.myItems.length==0||loadingAddMember">
        Add
        <img *ngIf="loadingMember||loadingAddMember" src="assets/images/Spinner.gif" class="loader-image">
      </button>
    </div>

    <!-- Loader -->

    <!-- Modal -->
    <ng-template #modalColumnProperyConfirmation let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
      </div>
      <div class="modal-body">
        Your existing metadata collumn setting detail will be cleared. Are you sure to clear?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-blue" (click)="modal.close('yes')">
          Yes
        </button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
          No
        </button>
      </div>
    </ng-template>
  </div>
</div>