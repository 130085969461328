import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogService } from './log.service';
import { NetDocumentViewerService } from './netdocument-viewer';
import { GlobalService } from 'src/app/services/global.service';
import { NetdocshareLocalstorageService } from './netdocshare-localstorage.service';
@Injectable({
    providedIn: 'root'
})
export class TeamsService {

    private groupCallRequest: any;
    private memberCallRequest: any;
    private channelsCallRequest: any;
    private tabssCallRequest: any;
    private teamMemberCallRequest: any;

    constructor(
        private _http: HttpClient,
        private logService: LogService,
        private ndvs: NetDocumentViewerService,
        public global: GlobalService,
        public netLocalStorage:NetdocshareLocalstorageService,
    ) { }


    /* public connectionToken() {
        var obj = {
            appId: "1683d88e-4553-4855-a994-042c34bf3431",
            appSec: "jzN7Q~19JhbS2MRQ1PXWjo232O4txysujPS_W",
            tenantId: "7e560c17-bcf8-4343-927b-b5947774205f",
            key: "9f5f0044-05b3-43e2-a74a-546deeac1477",
            key2: Date.now()
        }
        var code = btoa(JSON.stringify(obj));
        return code;


    } */


    //////////////// CHECK TEAMS CONNECTION ////////////////
    public checkTeamsConnection(connectionSetting) {
        if (this.ndvs.currentUserLicense.clientId == undefined || this.ndvs.currentUserLicense.clientId == null || this.ndvs.currentUserLicense.clientId == '') return;
        const url = environment.serviceUrl + 'api/teams/connection/' + this.ndvs.currentUserLicense.clientId;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + this.ndvs.GSAccessToken);
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, connectionSetting, { headers: headers }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection checking service request success");
                requestOut.emit({ status: "success", message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection checking service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection checking service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// GETTING ALL TEAMS CONNECTION LIST FOR USERS ////////////////
    public getTeamsConnections() {
        if (this.ndvs.currentUserLicense.clientId == undefined || this.ndvs.currentUserLicense.clientId == null || this.ndvs.currentUserLicense.clientId == '') return;
        const url = environment.serviceUrl + 'api/teams/connections/' + this.ndvs.currentUserLicense.clientId;

        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Teams connection service request failed");
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }
    //////////////// SET TEAMS CONNECTION DEFAULT ////////////////
    public setDefaultConnections(id) {
        const url = environment.serviceUrl + 'api/teams/connection/default/' + this.ndvs.currentUserLicense.clientId + '/' + id;

        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection default setting request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Teams connection default setting request failed");
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection default setting request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }





    //////////////// GETTING ALL TEAMS CONNECTION LIST FOR ADMIN////////////////
    public getTeamsConnectionSettings() {
        if (this.ndvs.currentUserLicense.clientId == undefined || this.ndvs.currentUserLicense.clientId == null || this.ndvs.currentUserLicense.clientId == '') return;
        const url = environment.serviceUrl + 'api/teams/connections/' + this.ndvs.currentUserLicense.clientId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Teams connection service request failed");
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    //////////////// SAVE TEAMS CONNECTION SETTING DATA ////////////////
    public saveTeamsConnectionSetting(requestData) {
        const url = environment.serviceUrl + 'api/teams/connection/' + this.ndvs.currentUserLicense.clientId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, requestData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection save service request success");
                requestOut.emit({ status: "success", message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection save service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection save service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    //////////////// DELETE TEAMS CONNECTION SETTING DATA ////////////////
    public deleteTeamsConnectionSetting(settingId) {
        const url = environment.serviceUrl + 'api/teams/connection/' + settingId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.delete(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection delete service request success");
                requestOut.emit({ status: "success", message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection delete service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection delete service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    //////////////// VALIDATE TEAMS CONNECTION ////////////////
    public validateTeamsConnection(connectionId) {
        const url = environment.serviceUrl + 'api/teams/validate/connection/' + connectionId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection validate service request success");
                requestOut.emit({ status: "success", message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection validate service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection validate service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// GET NETDOCSHARE TEAMS APP DETAILS ////////////////
    public checknetDocShareAppInstalled(requestData) {
        const url = environment.serviceUrl + 'api/teams/isappinstalled';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        requestData.teamAppId = environment.teamAppId;
        this._http.post(url, requestData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get teams groups service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Get teams groups service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get teams groups service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

 //////////////// CREATE TEAMS TEAM/GROUP ////////////////
 public createAdminTeam(requestData) {
    var rData = {
        "teamName": requestData.teamName,
        "teamDescription": requestData.teamDescription,
        "visibility": requestData.visibility,
        "key": this.netLocalStorage.licenseInfo.clientId,
        "connectionId": this.global.connectionData._id,
        "adminEmail": requestData.adminEmail,
        "syncDetails": requestData.syncDetails,
        "channels": requestData.channels,
        "requestId": requestData._id,
        "teamLevelSync": requestData.teamLevelSync,
        "isCreateSPSync": requestData.isCreateSPSync,
        "sharepointSiteTemplate": requestData.sharepointSiteTemplate,
        "members": requestData.members,
        "groups": requestData.groups
    }
    const url = environment.serviceUrl + 'api/teams/admin/createteam';
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this._http.post(url, rData).subscribe((data: any) => {
        if (data.result == "success") {
            this.logService.updateLogMessage("Create team service request success");
            requestOut.emit({ status: "success", data: data.id, message: data.message })
        } else {
            this.logService.updateLogMessage("Create team service request failed");
            requestOut.emit({ status: "failed", message: data.message })
        }
    }, (err) => {
        this.logService.updateLogMessage("Create team service request error => " + JSON.stringify(err));
        requestOut.emit({ status: "failed", message: err })
    })
    return requestOut;
}

    //////////////// CREATE TEAMS TEAM/GROUP ////////////////
    public createTeam(requestData) {
        var rData = {
            "teamName": requestData.teamName,
            "teamDescription": requestData.teamDescription,
            "visibility": requestData.visibility,
            "key": this.ndvs.currentUserLicense.clientId,
            "connectionId": this.global.connectionData._id,
            "channels": requestData.channels,
            "syncDetails": requestData.syncDetails
        }
        const url = environment.serviceUrl + 'api/teams/createteam';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create team service request success");
                requestOut.emit({ status: "success", data: data.id, message: data.message })
            } else {
                this.logService.updateLogMessage("Create team service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create team service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    public unsubscribeGetTeamsGroups() {
        if (this.groupCallRequest) this.groupCallRequest.unsubscribe();
    }

    //////////////// GET TEAMS GROUPS BY CONNECTION ID ////////////////
    public getTeamsGroups(connectionId, nxtPageReq = "", search = "", sort = "") {
        const url = environment.serviceUrl + 'api/teams/search/' + connectionId;
        if (this.groupCallRequest) this.groupCallRequest.unsubscribe();

        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.groupCallRequest = this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            skipToken: nxtPageReq,
            search: search,
            sort: sort
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get teams groups service request success");
                requestOut.emit({ status: "success", data: data.teams, addOn: data.addOn, skipToken: data.skipToken })
            } else {
                this.logService.updateLogMessage("Get teams groups service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get teams groups service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    //////////////// GET ALL TEAMS GROUPS BY CONNECTION ID ////////////////
    public getAllTeamsGroups(connectionId) {
        const url = environment.serviceUrl + 'api/teams/getallteams/' + connectionId;
        if (this.groupCallRequest) this.groupCallRequest.unsubscribe();

        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.groupCallRequest = this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get teams groups service request success");
                requestOut.emit({ status: "success", data: data.teams })
            } else {
                this.logService.updateLogMessage("Get teams groups service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get teams groups service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    public unsubscribeGetTeamsChannels() {
        if (this.channelsCallRequest) this.channelsCallRequest.unsubscribe();
    }
    private compare(a, b) {
        if (a.displayName < b.displayName) {
            return -1;
        }
        if (a.displayName > b.displayName) {
            return 1;
        }
        return 0;
    }


    //////////////// GET TEAM DETAIL BY GROUP ID ////////////////
    public getTeam(teamId) {
        const url = environment.serviceUrl + 'api/teams/getTeam';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, { key: this.ndvs.currentUserLicense.clientId, connectionId: this.global.connectionData._id, teamId: teamId }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get team data service request success");
                requestOut.emit({ status: "success", data: data })
            } else {
                this.logService.updateLogMessage("Get team data service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get team data service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    //////////////// UPDATE TEAM DETAIL BY GROUP ID ////////////////
    public updateTeam(payload) {
        const url = environment.serviceUrl + 'api/teams/updateTeam';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, { key: this.ndvs.currentUserLicense.clientId, connectionId: this.global.connectionData._id, ...payload }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Update team data service request success");
                requestOut.emit({ status: "success", data: data })
            } else {
                this.logService.updateLogMessage("Update team data service request failed");
                requestOut.emit({ status: "failed", message: data.message, error: data.error })
            }
        }, (err) => {
            this.logService.updateLogMessage("Update team data service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// UPDATE TEAM DETAIL BY GROUP ID ////////////////
    public deleteTeam(teamId) {
        const url = environment.serviceUrl + 'api/teams/deleteTeam';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, { key: this.ndvs.currentUserLicense.clientId, connectionId: this.global.connectionData._id, teamId: teamId }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Delete team service request success");
                requestOut.emit({ status: "success", data: data })
            } else {
                this.logService.updateLogMessage("Delete team service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Delete team service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }





    //////////////// GET TEAMS CHANNELS BY GROUP ID ////////////////
    public getTeamsChannels(teamId, nxtPageReq = "", search = "", sort = "") {
        const url = environment.serviceUrl + 'api/teams/channels';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.channelsCallRequest = this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
            teamId: teamId,
            skipToken: nxtPageReq,
            search: search,
            sort: sort
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get teams channels service request success");

                const filteredChannelList = data.channels.filter(i => i.displayName != "General");
                const generalChannel = data.channels.filter(i => i.displayName == "General");

                requestOut.emit({ status: "success", data: [...generalChannel, ...filteredChannelList.sort(this.compare)] })
            } else {
                this.logService.updateLogMessage("Get teams channels service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get teams channels service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// CREATE TEAMS CHANNEL ////////////////
    public createChannel(requestData) {
        let rData = {
            "channelName": requestData.channelName,
            "channelDescription": requestData.channelDescription,
            "membershipType": requestData.optionPrivacy
        }
        const url = environment.serviceUrl + 'api/teams/createchannel';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData, teamId: requestData.teamId,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create channel service request success");
                requestOut.emit({
                    status: "success", data: {
                        Description: requestData.channelDescription,
                        DisplayName: requestData.channelName,
                        Id: data.id,
                        displayName: requestData.channelName,
                        id: data.id,
                        message: data.message
                    }
                })
            } else {
                this.logService.updateLogMessage("Create channel service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create channel service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    //////////////// ADD PRIVATE CHANNEL MEMBERs ////////////////
    public addPrivateChannelMembers(requestData) {
        const url = environment.serviceUrl + 'api/teams/addprivatechannelmembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Add private channel member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Add private channel member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Add private channel member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    //////////////// ADD PRIVATE CHANNEL MEMBERs ////////////////
    public updatePrivateChannelMembers(requestData) {
        const url = environment.serviceUrl + 'api/teams/updateprivatechannelmembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Update private channel member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Update private channel member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Update private channel member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    //////////////// UPDATE TEAMS CHANNESL ////////////////
    public updateChannel(requestData) {
        let rData = {
            channelName: requestData.channelName,
            channelDescription: requestData.channelDescription,
        }

        const url = environment.serviceUrl + 'api/teams/updatechannel';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData, ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Update channel service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Update channel service request failed");
                requestOut.emit({ status: "failed", message: data.message, error: data.error })
            }
        }, (err) => {
            this.logService.updateLogMessage("Update channel service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// Delete TEAMS CHANNESL ////////////////
    public deleteChannel(requestData) {
        const url = environment.serviceUrl + 'api/teams/deletechannel';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Delete channel service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Delete channel service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Delete channel service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    public unsubscribeGetTeamsTabs() {
        if (this.tabssCallRequest) this.tabssCallRequest.unsubscribe();
    }

    //////////////// GET TEAMS CHANNELS BY CHANNEL ID ////////////////
    public getTeamsTabs(teamId, channelId) {
        const url = environment.serviceUrl + 'api/teams/channel/tabs';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.tabssCallRequest = this._http.post(url, {
            teamId: teamId, channelId: channelId,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get tabs service request success");
                requestOut.emit({ status: "success", data: data.tabs, sync: data.tabArray, tenantId: data.tenantId })
            } else {
                this.logService.updateLogMessage("Get tabs service request failed");
                requestOut.emit({ status: "failed", message: data.message, tenantId: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get tabs service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// Delete TEAMS TAB ////////////////
    public deleteTab(requestData) {
        const url = environment.serviceUrl + 'api/teams/deletetab';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Delete tab service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Delete tab service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Delete tab service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// Create new tab OLD in netDocShare DB ////////////////
    public createNewTabInDBOLD(requestData) {
        const url = environment.netDocShareServiceUrl + 'api/config/instance';
        let headers = (new HttpHeaders()).set("Content-Type", "application/json");
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, requestData, { headers: headers }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create new tab in DB service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Create new tab in DB service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create new tab in DB request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// Create new tab in netDocShare DB ////////////////
    public createNewTabInDB(requestData) {

        let tabentityId = "";
        if (requestData.tabDetail && requestData.tabDetail.configuration) {
            tabentityId = requestData.tabDetail.configuration.entityId
        } else if (requestData.tabDetail && requestData.tabDetail.config) {
            tabentityId = requestData.tabDetail.config.EntityId;
        } else {
            tabentityId = ""
        }
        var rData = {
            config: requestData.config, tabName: requestData.tabName, entityId:tabentityId ,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id, teamId: requestData.teamId, channelId: requestData.channelId
        }
        //const url = environment.netDocShareServiceUrl + 'api/config/instance';
        let url = "";
        if (requestData.tabDetail == null) {
            url = environment.serviceUrl + 'api/teams/createtab';
        }
        else {
            url = environment.serviceUrl + 'api/teams/updatetab';
        }
        /* let headers = (new HttpHeaders()).set("Content-Type", "application/json");
        headers = headers.set('x-auth-key', this.connectionToken()); */
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create new tab in DB service request success");
                requestOut.emit({ status: "success", data: data.netDocShareTab })
            } else {
                this.logService.updateLogMessage("Create new tab in DB service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create new tab in DB request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// LIST ALL MEMBERS ////////////////
    public fetchAllMembers(searchUserString) {
        if (this.memberCallRequest) this.memberCallRequest.unsubscribe();
        var rData = {
            searchUserString: searchUserString.trim(),
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }
        const url = environment.serviceUrl + 'api/teams/getmembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.memberCallRequest = this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("List Member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("List Member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("List Member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// LIST ALL MEMBERS OF A TEAM////////////////
    public fetchAllTeamMembers(teamId, searchUserString, getAllMembers = false) {
        if (this.teamMemberCallRequest) this.teamMemberCallRequest.unsubscribe();
        var rData = {
            searchUserString: searchUserString.trim(),
            getAllMembers: getAllMembers,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }
        const url = environment.serviceUrl + 'api/teams/' + teamId + '/getmembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.teamMemberCallRequest = this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("List Member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("List Member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("List Member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// LIST ALL MEMBERS OF A TEAM////////////////
    public fetchPrivateMembers(teamId, channelId) {
        var rData = {
            teamId: teamId,
            channelId: channelId,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }
        const url = environment.serviceUrl + 'api/teams/getprimembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this.memberCallRequest = this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("List private member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("List private member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("List private member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }





    //////////////// ADD A MEMBER ////////////////
    public addTeamMember(requestData) {
        const url = environment.serviceUrl + 'api/teams/addmembers';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...requestData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Add Member service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Add Member service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Add Member service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    //////////////// CLONE A TEAM ////////////////
    public duplicateTeam(requestData) {
        var rData = {
            teamDescription: requestData.teamDescription,
            teamName: requestData.teamName,
            visibility: requestData.visibility,
            teamSyncUI: requestData.teamSync,
            channelSync: requestData.channelSync,
            syncData: requestData.syncData,
            itemsToClone: requestData.itemsToClone
        }

        const url = environment.serviceUrl + "api/teams/" + requestData.Id + "/clone";
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Clone a team service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Clone a team service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Clone a team service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    //////////////// CREATE A TEMPLATE ////////////////
    public createTemplate(requestData) {

        var rData = {
            templateDescription: requestData.teamDescription,
            templateName: requestData.teamName,
            visibility: requestData.visibility,
            sync: requestData.sync,
            syncType: requestData.syncType
        }
        const url = environment.serviceUrl + "api/teams/" + requestData.Id + "/template";
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create a template service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else if (data.result == "fail") {
                var message = "Template name already exist. Please change template name."
                this.logService.updateLogMessage(message);
                requestOut.emit({ status: "fail", message: message })
            } else {
                this.logService.updateLogMessage("Create a template service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create a template service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    //////////////// LIST ALL TEMPLATES ////////////////
    public getTemplates() {
        const url = environment.serviceUrl + 'api/teams/listTemplates';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("List all templates service request success");
                requestOut.emit({ status: "success", data: data.templates })
            } else {
                this.logService.updateLogMessage("List all templates service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("List all templates service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// CREATE A TEAM FROM TEMPLATE ////////////////
    public createTeamFromTemplate(requestData) {
        var rData = {
            teamDescription: requestData.teamDescription,
            teamName: requestData.teamName,
            visibility: requestData.visibility,
            teamSyncUI: requestData.teamSync,
        }
        const url = environment.serviceUrl + "api/teams/template/" + requestData.id + "/clone";
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData,
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create a team from template service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Create a team from template service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create a team from template service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// DELETE A TEMPLATE ////////////////
    public deleteTemplate(id) {
        const url = environment.serviceUrl + 'api/teams/deleteTemplate/' + id;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create a team from template service request success");
                requestOut.emit({ status: "success", data: data.template })
            } else {
                this.logService.updateLogMessage("Create a team from template service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create a team from template service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    //////////////// UPDATE A TEMPLATE ////////////////
    public updateTemplate(requestData) {
        console.log("PT::updateTemplate::requestData::", requestData)
        var rData = {
            templateDescription: requestData.teamDescription,
            templateName: requestData.teamName,
            sync: requestData.sync,
            syncType: requestData.syncType,
            config: requestData.config
        }
        const url = environment.serviceUrl + 'api/teams/updateTemplate/' + requestData.id;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            ...rData, key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (data.result == "success") {//result == "success"//message
                this.logService.updateLogMessage("Update template service request success");
                requestOut.emit({ status: "success", data: data.template })
            } else if (data.result == "fail") {
                var message = "Template name already exist. Please change template name."
                this.logService.updateLogMessage(message);
                requestOut.emit({ status: "fail", message: message })
            } else {
                this.logService.updateLogMessage("Update template service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Update template service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }

    //////////////// Remove Channel Sync ////////////////
    public RemoveChannelSync(id) {
        const url = environment.syncServiceUrl + '/syncjob/' + id;
        let headers = (new HttpHeaders()).set("Content-Type", "application/json");
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.delete(url, { headers: headers }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Channel sync remove service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Channel sync remove service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Channel sync remove request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }



    public readSyncData(teamId) {

        const url = environment.serviceUrl + 'api/teams/' + teamId + '/syncjobs/';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id
        }).subscribe((data: any) => {
            if (!data.message) {//result == "success"
                this.logService.updateLogMessage("Get sync jobs service request success");
                requestOut.emit({ status: "success", data: data.jobs })
            } else {
                this.logService.updateLogMessage("Get sync jobs service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Get sync jobs service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }


    //////////////// UPLOAD EXCEL DATA ////////////////
    public uploadExcelData(requestData) {
        const url = environment.serviceUrl + 'api/teams/bulkupload';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
            ...requestData
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Bulk data upload service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Bulk data upload service request failed");
                requestOut.emit({ status: "failed", data: data.data })
            }
        }, (err) => {
            this.logService.updateLogMessage("Bulk data upload service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }



    //////////////// UPLOAD TEAM TEMPLATE DATA ////////////////
    public uploadTeamTemplateData(requestData) {
        const url = environment.serviceUrl + 'api/teams/bulkteamupload';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
            ...requestData
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Bulk team data upload service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Bulk team data upload service request failed");
                requestOut.emit({ status: "failed", data: data.data })
            }
        }, (err) => {
            this.logService.updateLogMessage("Bulk team data upload service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }


    //////////////// UPLOAD TEAM TEMPLATE DATA ////////////////
    public uploadTemplateConfig(requestData) {
        const url = environment.serviceUrl + 'api/teams/updatetemplateconfig';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
            ...requestData
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Bulk team data upload service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Bulk team data upload service request failed");
                requestOut.emit({ status: "failed", data: data.data })
            }
        }, (err) => {
            this.logService.updateLogMessage("Bulk team data upload service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }


    //////////////// BULK EXCEL DOWNLOAD ////////////////
    public saveBulkTeams() {
        const url = environment.serviceUrl + 'api/teams/saveteamsbulk';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Bulk excel download service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Bulk excel download service request failed");
                requestOut.emit({ status: "failed", data: data.data })
            }
        }, (err) => {
            this.logService.updateLogMessage("Bulk excel download service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }





    //////////////// CONFIG UPDATE EXCEL DOWNLOAD ////////////////
    public saveTemplateConfig(team) {
        const url = environment.serviceUrl + 'api/teams/savetemplateconfig';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.ndvs.currentUserLicense.clientId,
            connectionId: this.global.connectionData._id,
            teamId: team.teamId,
            teamName: team.teamName
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Config template excel download service request success");
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Config template excel download service request failed");
                requestOut.emit({ status: "failed", data: data.data })
            }
        }, (err) => {
            this.logService.updateLogMessage("Config template excel download service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    public editRequestForm(id, editForm) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/teams/updateRequest/' + id;
        this._http.post(url, editForm).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Edit Request Form update API request success");
                requestOut.emit({ status: "success", message: data.syncData });
            } else {
                this.logService.updateLogMessage("Edit Request Form update API request failed");
                requestOut.emit({ status: "failed", message: data.message });
            }
        });

        return requestOut;
    }
    public sendEmailAdminListTeamRequest(sendDataRequest) {
        const url = environment.serviceUrl + 'api/teams/admin';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.netLocalStorage.licenseInfo.clientId,
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection validate service request success");
                requestOut.emit({ status: "success", data: data.data, message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection validate service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection validate service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    public findRequest(id) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/request/' + id;
        this._http.get(url).subscribe((resData: any) => {
            if (resData.result === 'fail') {
                this.logService.updateLogMessage("Requested Team id API request failed");
                requestOut.emit({ status: "failed", data: null })
            } else {
                this.logService.updateLogMessage("Requested Team by id API request success");
                requestOut.emit({ status: "success", data: resData.data })
            }
        }, (err) => {
            requestOut.emit({ status: "failed", data: null, message: "" })
        });
        return requestOut;
    }
     //////////////// CREATE TEAMS REQUEST BY USER ////////////////
     public createTeamRequests(requestData) {
        var rData = {
            "cabinet": requestData.cabinet,
            "workspace": requestData.workspace,
            "teamsTemplate": requestData.teamsTemplate,
            "teamType": requestData.optionPrivacy,
            "teamName": requestData.teamName,
            "teamDescription": requestData.description,
            "Status": requestData.status,
            "key": this.netLocalStorage.licenseInfo.clientId,
            "teamId": requestData.teamId ? requestData.teamId : '',
            "channels": requestData.channels,
            "syncDetails": requestData?.syncDetails,
            "createdBy": requestData.createdBy,
            "syncEnabled":  requestData.teamLevelSync,
            "comments": "",
            "sharepointSiteTemplate": requestData.sharepointSiteTemplate,
            "isCreateSPSync":requestData.isCreateSPSync,
            "client": requestData.client,
            "matter": requestData.matter,
            "teamLevelSync": requestData.teamLevelSync,
            "members": requestData?.members,
            "groups": requestData?.groups
        }
        const url = environment.serviceUrl + 'api/teams/createteamrequest';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, rData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Create team service request success");
                requestOut.emit({ status: "success", data: data.id, message: data.message })
            } else {
                this.logService.updateLogMessage("Create team service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Create team service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
    public sendEmailRequest(subject, adminMail, selectedUser) {
        const url = environment.serviceUrl + 'api/sendEmail';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, {
            key: this.netLocalStorage.licenseInfo.clientId,
            subject: subject,
            adminMail: adminMail,
            bodyEmail: selectedUser.body
        }).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Teams connection validate service request success");
                requestOut.emit({ status: "success", data: data.data, message: data.message })
            } else {
                this.logService.updateLogMessage("Teams connection validate service request failed");
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Teams connection validate service request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", message: err })
        })
        return requestOut;
    }
  //////////////// CREATE bulk TEAMS  ////////////////
  public createBulkTeam(requestData) {
    var rData = {
        "teamName": requestData.teamName,
        "teamDescription": requestData.teamDescription,
        "visibility": requestData.visibility,
        "key": this.netLocalStorage.licenseInfo.clientId,
        "connectionId": this.global.connectionData._id,
        "adminEmail": requestData?.adminEmail,
        "syncDetails": requestData.syncDetails,
        "channels": requestData.channels,
        "requestId": requestData?._id,
        "teamLevelSync": requestData.teamLevelSync,
        "isCreateSPSync": requestData.isCreateSPSync,
        "sharepointSiteTemplate": requestData.sharepointSiteTemplate,
        "members": requestData.members,
        "groups": requestData.groups
    }   
    const url = environment.serviceUrl + 'api/teams/bulk/createteam';
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this._http.post(url, rData).subscribe((data: any) => {
        if (data.result == "success") {
            this.logService.updateLogMessage("Create team service request success");
            requestOut.emit({ status: "success", data: data.id, message: data.message })
        } else {
            this.logService.updateLogMessage("Create team service request failed");
            requestOut.emit({ status: "failed", message: data.message })
        }
    }, (err) => {
        this.logService.updateLogMessage("Create team service request error => " + JSON.stringify(err));
        requestOut.emit({ status: "failed", index: requestData?.index, message: err.message })
    })
    return requestOut;
}

}
