import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';


@Injectable({
  providedIn: 'root'
})
export class NetdocshareLocalstorageService {

  constructor(private global: GlobalService) { }


  private getFromLocalStore(key: string) {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  }
  private setToLocalStore(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error(error);
    }
  }
  private removeFromLocalStore(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }
  public clear() {
    localStorage.clear();
    sessionStorage.clear();
  }

  get netDocShareClient() {
    let clientData: any = this.getFromLocalStore('netDocShareClient');
    let decryptedData = btoa(clientData);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set netDocShareClient(value) {
    let encryptData = btoa(value);
    this.setToLocalStore('netDocShareClient', encryptData);
  }
  public removeNetDocShareClient() {
    this.removeFromLocalStore('netDocShareClient');
  }

  get createdJob() {
    let createdJob: any = this.getFromLocalStore('createdJob');
    let decryptedData = atob(createdJob);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set createdJob(value) {
    let encryptData = btoa(value);
    this.setToLocalStore('createdJob', encryptData);
  }
  public removecreatedJob() {
    this.removeFromLocalStore('createdJob');
  }



  get ndTokenInfo() {
    let ndTokenInfo: any = this.getFromLocalStore('ndTokenInfo');
    let decryptedData = btoa(ndTokenInfo);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set ndTokenInfo(info) {
    let encryptData = btoa(info);
    this.setToLocalStore('ndTokenInfo', encryptData);
  }
  public removeNdTokenInfo() {
    this.removeFromLocalStore('ndTokenInfo');
  }

  get ndInfo() {
    let CUAD: any = this.getFromLocalStore('CUAD');
    return CUAD ? JSON.parse(atob(CUAD)) : null;
  }
  set ndInfo(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('CUAD', encryptData);
  }
  public removeNdInfo() {
    this.removeFromLocalStore('CUAD');
  }

  get licenseInfo() {
    let clientId: any = this.getFromLocalStore('clientId');
    return clientId ? JSON.parse(atob(clientId)) : null;
  }
  set licenseInfo(user) {
    this.setToLocalStore('clientId', btoa(JSON.stringify(user)));
  }
  public removelicenseInfo() {
    this.removeFromLocalStore('clientId');
  }

  get last_page_url() {
    let lastpageurl: any = this.getFromLocalStore('last_page_url');
    let decryptedData = btoa(lastpageurl);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set last_page_url(user) {
    // let encryptData = atob(user);
    this.setToLocalStore('last_page_url', btoa(user));
  }
  public removelast_page_url() {
    this.removeFromLocalStore('last_page_url');
  }

  get accesstoken() {
    let accesstoken: any = this.getFromLocalStore('access_token');
    let decryptedData = btoa(accesstoken);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set accesstoken(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('access_token', encryptData);
  }
  public removeaccesstoken() {
    this.removeFromLocalStore('access_token');
  }

  get refreshtoken() {
    let refreshtoken: any = this.getFromLocalStore('refresh_token');
    let decryptedData = atob(refreshtoken);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set refreshtoken(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('refresh_token', encryptData);
  }
  public removerefreshtoken() {
    this.removeFromLocalStore('refresh_token');
  }

  get netdocBaseUrl() {
    let baseUrl: any = "";
    try {
      baseUrl = this.getFromLocalStore('netDocsBaseUrl');
    } catch (error) {
      console.log("Error getting base url", error);
    }
    return baseUrl;
  }
  set netdocBaseUrl(value) {
    this.setToLocalStore('netDocsBaseUrl', value);
  }
  public removeNetdocBaseUrl() {
    this.removeFromLocalStore('netDocsBaseUrl');
  }

  get showlog() {
    let showlog: any = this.getFromLocalStore('showlog');
    return showlog ? JSON.parse(btoa(showlog)) : null;
  }
  set showlog(user) {
    let encryptData = atob(user);
    this.setToLocalStore('showlog', encryptData);
  }
  public removeshowlog() {
    this.removeFromLocalStore('showlog');
  }

  get userDetails() {
    let userDetails: any = this.getFromLocalStore('userDetails');
    let decryptedData = atob(userDetails);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set userDetails(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('userDetails', encryptData);
  }
  public removeuserDetails() {
    this.removeFromLocalStore('userDetails');
  }
  get netdocshareAuth() {
    let iManageAuth: any = this.getFromLocalStore('netdocshareAuth');
    let decryptedData = iManageAuth;
    return decryptedData ? decryptedData : null;
  }
  set netdocshareAuth(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('netdocshareAuth', encryptData);
  }
  public removenetdocshareAuth() {
    this.removeFromLocalStore('netdocshareAuth');
  }
  get currentUser() {
    let curentUser: any = this.getFromLocalStore('currentUser');
    let decryptedData = curentUser ? atob(curentUser) : "";
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set currentUser(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('currentUser', encryptData);
  }
  public removecurrentUser() {
    this.removeFromLocalStore('currentUser');
  }
  get validater() {
    let validater: any = this.getFromLocalStore('validater');
    let decryptedData = atob(validater);
    return decryptedData ? JSON.parse(decryptedData) : null;
  }
  set validater(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('validater', encryptData);
  }
  public removevalidater() {
    this.removeFromLocalStore('validater');
  }

  get userSettingInfo() {
    let clientId: any = this.getFromLocalStore('userSetting');
    return clientId ? JSON.parse(atob(clientId)) : null;
  }
  set userSettingInfo(user) {
    this.setToLocalStore('userSetting', btoa(JSON.stringify(user)));
  }
  public removeuserSettingInfo() {
    this.removeFromLocalStore('userSetting');
  }
  get imCustID() {
    let imCustID: any = this.getFromLocalStore('imCustID');
    let decryptedData = atob(imCustID);
    return decryptedData ? decryptedData : null;
  }
  set imCustID(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('imCustID', encryptData);
  }
  public removeimCustID() {
    this.removeFromLocalStore('imCustID');
  }
  get iManageAuth() {
    let iManageAuth: any = this.getFromLocalStore('iManageAuth');
    let decryptedData = iManageAuth;
    return decryptedData ? decryptedData : null;
  }
  set iManageAuth(user) {
    let encryptData = btoa(user);
    this.setToLocalStore('iManageAuth', encryptData);
  }
  public removeiManageAuth() {
    this.removeFromLocalStore('iManageAuth');
  }

}
