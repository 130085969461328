import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-sync-net-doc-location',
  templateUrl: './sync-net-doc-location.component.html',
  styleUrls: ['./sync-net-doc-location.component.scss']
})
export class SyncNetDocLocationComponent implements OnInit {
  @Output() onSyncJobSettingChange: EventEmitter<any> = new EventEmitter<any>();
  @Input("syncEnv") set onSetSyncEnv(data) {
    this.syncEnv = data ? data : this.syncEnv;
    this.syncTypes.forEach(syncType => {
      syncType.isVisible = syncType.syncEnv === this.syncEnv;
    });

  }
  @Input("editDetails") set onChange(editDetails) {
    this.ndvs.setSyncSetting({ data: this.syncChannel });
    this.ndvsNew.syncJobSetting = this.ndvs.syncJobSetting;
    if (editDetails?.syncJobSetting?.syncType) {
      this.ndvsNew.syncJobSetting = editDetails?.syncJobSetting;
    }
    this.syncType = editDetails?.syncType || editDetails?.syncJobSetting?.syncType
    if (editDetails?.cabinet && editDetails?.workspace) {
      this.onSelectCabinet(editDetails?.cabinet, null, null, { workspaceId: editDetails?.workspace.Attributes.Id, workspaceName: editDetails?.workspace.Attributes.Name, workspaceEnvId: editDetails?.workspace.EnvId });
    }
    this.onSaveEnable();
  }


  @Input("syncToggle") set onChangeSyncCheck(sCheck) {
    this.syncCheck = sCheck.syncCheckToggle;
    this.syncChannel = sCheck.name;
    if (!this.syncCheck) {
      this.folderCreationName = "";
      this.selectedCabinet = { name: "" };
      this.syncType = "";
      this.onResetRoot();
      this.form.setValue({
        optionSync: this.syncType,
        selectedCabinetName: this.selectedCabinet.name,
        cabinetSearch: this.cabinetSearch,
        newFolderName: this.folderCreationName
      });
    }
    this.ndvsNew.syncJobSetting = sCheck.syncJobSetting;
    this.syncType = sCheck.syncJobSetting.syncType;
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropDownForm: FormGroup;

  private ndvsNew: any = {};

  public syncCheck: boolean = true;
  public syncChannel: string = "";
  public syncType: string = "";
  public disable: boolean = false;
  public form: FormGroup;
  public searchUserString = "";
  public folderCreationName = "";
  public folderCreationErrorMessage = "";
  public syncEnv: any = "teams";
  public isCabinetLoader: boolean = false;
  public cabinets: any = [];
  public selectedCabinet: any = { name: "" };
  public showLoader: boolean = true;
  public isFolerLoading: boolean = false;
  public isNDLoading: boolean = false;
  public workSpaceContents: any = [];
  public cabinetFolders: any = [];
  public workSpaces: any = [];
  public selectedWorkSpace: any = {};
  public selectedWorkSpacefromTemplate: any = {};
  public selectCabinetFolder: any = {};
  public isCabinetFolderLoader: boolean = false;
  public isWorkspaceLoader: boolean = false;
  public breadcurmbClicked: boolean = false;
  public cabinetSearch: string = "";
  public isDisabledSave: boolean = true;
  public isEditMode: boolean = false;
  public isOnpermRestriction: boolean = true;

  public ndNextRecord: string = null;
  public ndFilesNextRecord: string = null;
  public ndContentType: string = "folders";
  public isFloderScrollEnded: boolean = false;
  public isFileScrollEnded: boolean = false;
  public executedPagingTokens: any = [];
  public serviceResuest: any = null;
  public isTeamsLoading: boolean = false;
  public isODLoading: boolean = false;
  public allFolders: any = [];
  public ndCollumns: any = [];
  public spCollumns: any = [];
  public newColRecord: any = { SPCol: '', SPColTitle: "", spColDataType: '', NDCol: '', NDColId: null, NDColDataType: '', dataDirection: this.syncType }
  public newSPColData: any = {};
  public previousSyncJobSetting: any = {};
  public metaDataColPro: any = [];
  public isDoneDisabled: boolean = true;
  public editDetails: any;
  public configForm: FormGroup;
  public ndSiteUrl: string = this.ndvs.getUserWebUrl();
  public selectAll: boolean = false;
  public toolTip: any = {
    syncType: "Select any one Sync Type",
    ndCabinet: "Please choose the cabinet where you would like to sync your content",
    syncLocation: "Select Sync Location",
    tabCreation: "Create a netDocShare tab"
  }
  public syncTypes: any = [
    { value: "bidirectionalTeam", text: "Bidirectional (Teams-NetDocuments)", syncEnv: "teams", isVisible: true },
    { value: "netDocumentsUniTeam", text: "NetDocuments to Teams", syncEnv: "teams", isVisible: true },
    { value: "teamUni", text: "Teams to NetDocuments", syncEnv: "teams", isVisible: true },
    { value: "bidirectional", text: "Bidirectional (SharePoint-NetDocuments)", syncEnv: "sharepoint", isVisible: false },
    { value: "netDocumentsUni", text: "NetDocuments to SharePoint", syncEnv: "sharepoint", isVisible: false },
    { value: "sharePointUni", text: "SharePoint to NetDocuments", syncEnv: "sharepoint", isVisible: false },
  ]
  public fileIcons: any = {
    ndfld: "folder_nd.svg",
    pdf: "pdf_nd.svg",
    doc: "word_nd.svg",
    docx: "word_nd.svg",
    pptx: "powerPoint_nd.svg",
    ppt: "powerPoint_nd.svg",
    xlsx: "excel_nd.svg",
    xls: "excel_nd.svg",
    csv: "excel_nd.svg",
    jpg: "image_nd.svg",
    jpeg: "image_nd.svg",
    png: "image_nd.svg",
    svg: "image_nd.svg",
    txt: "blankDocument_nd.svg",
    url: "webLink_nd.svg",
    zip: "blankDocument_nd.svg",
    ndsq: "savedSearch_nd.svg",
    ndcs: "collaborationSpace_nd.svg",
    eml: "email_nd.svg",
    gen: "genericfile_icon.svg",
    file: "file_icon.svg",
    html: "webLink_nd.svg",
    htm: "webLink_nd.svg",
    css: "blankDocument_nd.svg",
    js: "blankDocument_nd.svg",
    one: "onenote_nd.svg",
    onetoc: "onenote_nd.svg",
    onetoc2: "onenote_nd.svg"
  }

  constructor(
    private ndvs: NetDocumentViewerService,
    private router: Router,
    private modalService: NgbModal,
    public global: GlobalService,
    private logService: LogService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      /* allowSearchFilter: true */
    };
    this.selectedItems = [
    ];
    this.dropDownForm = this.fb.group({
      myItems: [this.selectedItems]
    });

    this.form = new FormGroup(
      {
        syncType: new FormControl(this.syncType),
        selectedCabinetName: new FormControl(this.selectedCabinet.name),
        cabinetSearch: new FormControl(this.cabinetSearch),
        newFolderName: new FormControl(this.folderCreationName)

      }
    );

    // this.ndvs.setSyncSetting({ data: this.syncChannel });
    // this.ndvsNew.syncJobSetting = this.ndvs.syncJobSetting;
    this.ndvsNew.syncJobSetting.syncType = this.syncType;
    let JobId = '';//this.activatedRoute.snapshot.params.jobId
    if (JobId != undefined && JobId != null && JobId != '') {
      this.ndvs.pageTitle = "Edit Job"
      this.isEditMode = true;
      if (this.ndvs.GSAccessToken == "") {
        this.waitForToken();
        return;
      }
      this.ndvs.getJobDetails(JobId).subscribe((resData) => {
        if (resData.status == "success" && resData.data != undefined) {
          this.ndvs.syncJobId = JobId;
          this.ndvs.syncJobSetting = resData.data;
          /* this.isOnpermRestriction = (!this.electron.isElectronApp && this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType == 'onpremises'); */
          this.ndvs.syncJobSetting.applyPermissions = (this.ndvs.syncJobSetting.applyPermissions == undefined || this.ndvs.syncJobSetting.applyPermissions == null) ? false : this.ndvs.syncJobSetting.applyPermissions;
          this.syncType = this.ndvs.syncJobSetting.syncType;
          //this.onChangeFrequency('init');
          this.newColRecord.dataDirection = this.syncType;
          if (this.ndvs.syncJobSetting.netDocuments.sources == undefined) this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
          this.getCabinets(JSON.parse(JSON.stringify(this.ndvs.syncJobSetting.netDocuments)));
        } else {
          this.router.navigateByUrl("/syncjobs");
        }
      })
    } else {
      this.ndvs.pageTitle = "Create Jobs"
      this.isEditMode = false;
      this.isOnpermRestriction = false;
      let lKey = this.ndvs.getUserLicense();//localStorage.getItem('license_key');
      this.ndvsNew.syncJobSetting.key = (lKey != undefined && lKey != null) ? lKey : '';
      this.ndvsNew.syncJobSetting.licenseKey = (lKey != undefined && lKey != null) ? lKey : '';
      // this.isSyncTimeLoaded = true;
      this.onSaveEnable();
      this.getCabinets();
    }
    this.logService.updateLogMessage(this.ndvs.pageTitle);
  }

  private waitForToken() {
    let counter = setTimeout(() => {
      clearTimeout(counter);
      if (this.ndvs.GSAccessToken != "") {
        this.ngOnInit();
      } else {
        this.waitForToken();
      }
    }, 1000)
  }


  public responsiveHeight(filterSecHt, ndFormNavHt, colMetSecHt) {
    setTimeout(() => {
      let height = filterSecHt + ndFormNavHt + colMetSecHt + 276;
      return "calc(100vh - " + height + "px)";
    }, 500)
  }

  public onChangeSyncType($event) {
    this.ndvsNew.syncJobSetting.syncType = this.syncType;
    this.newColRecord.dataDirection = this.syncType;
    this.ndvsNew.syncJobSetting.columnOptions = [];
    this.ndvsNew.syncJobSetting.syncMetaData = false;
    this.ndvsNew.syncJobSetting.fileMove = false;
    this.ndvsNew.syncJobSetting.sharePoint.libraryType = "document";
    this.ndvsNew.syncJobSetting.sharePoint.transferType = "flatten";
    this.ndvsNew.syncJobSetting.netDocuments.siteUrl = this.ndvs.currentUserLicense.NDApiUrl;
    this.ndvsNew.syncJobSetting.netDocuments.userName = this.ndvs.currentUser.displayName;
    this.ndvsNew.syncJobSetting.createdBy = this.ndvs.currentUser.email;
    switch (this.ndvsNew.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        this.ndvsNew.syncJobSetting.selectAll = true;
        //this.ndvsNew.syncJobSetting.netDocuments.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "bidirectional":
        this.ndvsNew.syncJobSetting.selectAll = true;
        //this.ndvsNew.syncJobSetting.netDocuments.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "netDocumentsUniTeam":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "netDocumentsUni":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "sharePointUni":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "teamUni":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
    }
    //this.ndvs.onChangeSyncType.emit(this.syncType);
    this.onSaveEnable();
  }

  public onShowBrowser(url) {
    if (this.isNDLoading) return;
    this.ndvs.onShowBrowser(url);
  }

  public onSelectCabinetFolder(cabFolder) {
    if (this.isNDLoading) return;
    this.selectCabinetFolder = cabFolder;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
    this.executedPagingTokens = [];
    this.isFolerLoading = false;
    this.onSelectContent(cabFolder);
    this.onSaveEnable();
  }

  public onSelectContent(content) {
    if (this.ndvsNew.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvsNew.syncJobSetting.netDocuments.sources.files.length > 0 || this.isNDLoading) return
    if (content.Attributes.Ext == "ndfld" || content.Attributes.Ext == "ndsq") {
      this.cabinetSearch = "";
      this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.push({ DocId: content.DocId, Attributes: { Name: content.Attributes.Name, Ext: content.Attributes.Ext }, EnvId: content.EnvId });
      this.ndvsNew.syncJobSetting.netDocuments.folderId = content.DocId;
      this.ndvsNew.syncJobSetting.netDocuments.folderName = content.Attributes.Name;
      this.onResetScrollVars();
      this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, content.DocId);
    }
    this.onSaveEnable();
  }


  public onRefreshNetDocumentContants() {
    if ((!this.isNDLoading && this.ndvsNew.syncJobSetting.netDocuments.cabinetId == '') || (this.isNDLoading)) return;
    this.onResetScrollVars()
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    if (this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" || this.ndvsNew.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      if ((this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" && this.ndvsNew.syncJobSetting.netDocuments.folderId != "") || (this.ndvsNew.syncJobSetting.netDocuments.workspaceId == "" && this.ndvsNew.syncJobSetting.netDocuments.folderId != "")) {
        contentSource = this.ndvsNew.syncJobSetting.netDocuments.folderId;
      } else {
        contentSource = this.ndvsNew.syncJobSetting.netDocuments.workspaceId;
      }
      if (this.ndvsNew.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      if (this.ndvsNew.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
    }
    this.onSaveEnable();
  }

  public getWorkspace(cabinetId, netDocData = null, skipToken = null) {
    if (cabinetId == undefined) return;
    this.isNDLoading = true;
    if (skipToken == null) {
      this.selectedWorkSpace = {};
      this.workSpaces = [];
      this.ndvsNew.syncJobSetting.netDocuments.workspaceId = "";
    }
    this.isWorkspaceLoader = true;
    this.ndvs.getWorkspace(cabinetId, skipToken).subscribe((resData) => {
      this.isWorkspaceLoader = false;
      this.breadcurmbClicked = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.ndNextRecord = resData.skipToken;
        if (skipToken == null) {
          this.workSpaces = resData.data;
        } else {
          this.workSpaces = this.workSpaces.concat(resData.data);
        }
        if (this.workSpaces.length > 0) {
          if (netDocData != null) {
            if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
              let curSelWS = this.workSpaces.find((element => element.id == netDocData.workspaceId));
              this.onSelectWorkSpace(curSelWS, null, netDocData);
            } else {
              // this.workSpaces=[];
              this.showLoader = false;
              if (this.ndNextRecord == null) {
                this.getCabinetFolders(cabinetId, netDocData);
              } else {
                this.isNDLoading = false;
              }
            }
          } else {
            this.showLoader = false;
            if (this.ndNextRecord == null) {
              if (this.selectedWorkSpacefromTemplate != null && this.selectedWorkSpacefromTemplate != undefined) {
                this.selectedWorkSpace = this.selectedWorkSpacefromTemplate;
              }
              this.getCabinetFolders(cabinetId, netDocData);
            } else {
              this.isNDLoading = false;
            }
          }
        } else {
          this.showLoader = false;
          if (this.ndNextRecord == null) {
            this.getCabinetFolders(cabinetId, netDocData);
          } else {
            this.isNDLoading = false;
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
      }
    })
    this.onSaveEnable();
  }

  public getCabinets(netDocData = null) {
    this.isCabinetLoader = true;
    this.ndvs.getCabinets().subscribe((resData) => {
      this.isCabinetLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinets = resData.data;
        if (netDocData != null) {
          let curSelCabinet = this.cabinets.find((element => element.id == netDocData.cabinetId));
          this.onSelectCabinet(curSelCabinet, null, null, netDocData);
        } else {
          this.showLoader = false;
        }

      } else {
        this.cabinets = [];
        this.showLoader = false;
      }
    })
    this.onSaveEnable();
  }

  public onSelectCabinet(cabinet, dropdown = null, modalTemplate = null, netDocData = null) {
    if (dropdown != null) dropdown.close();
    if (this.selectedCabinet.id == undefined || this.ndvsNew.syncJobSetting?.columnOptions?.length == 0) {
      this.assignSelectdCabined(cabinet, netDocData, dropdown);
    } else {
      this.modalService.open(modalTemplate).result.then((resData) => {
        if (resData == "yes") {
          this.ndvsNew.syncJobSetting.columnOptions = [];
          this.assignSelectdCabined(cabinet, netDocData, dropdown);
        }
      }).catch((resError) => {
        this.logService.updateLogMessage("SharePoint column property confirmation popup error => " + JSON.stringify(resError))
      });

    }
    this.onSaveEnable();
  }

  public onResetRoot() {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.selectedWorkSpace = {};
    this.selectCabinetFolder = {};
    this.ndvsNew.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvsNew.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
    this.ndvsNew.syncJobSetting.netDocuments.folderId = "";
    this.ndvsNew.syncJobSetting.netDocuments.folderName = "";
    this.ndvsNew.syncJobSetting.netDocuments.cabinetId = "";
    this.ndvsNew.syncJobSetting.netDocuments.cabinetName = "";
    this.ndvsNew.syncJobSetting.netDocuments.workspaceId = "";
    this.ndvsNew.syncJobSetting.netDocuments.workspaceName = "";
    this.ndvsNew.syncJobSetting.syncType = "";
    /* this.selectedCabinet = { name: "" };
    this.syncType = ""; */
    this.onResetScrollVars();
    this.onSelectCabinet(this.selectedCabinet);
    this.folderCreationName = "";
    this.onSaveEnable();
  }


  public onResetWorkspace() {

    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.ndvsNew.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvsNew.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
    this.ndvsNew.syncJobSetting.netDocuments.folderId = "";
    this.ndvsNew.syncJobSetting.netDocuments.folderName = "";
    this.onResetScrollVars();
    this.onSelectWorkSpace(this.selectedWorkSpace);
    this.onSaveEnable();
  }

  public onLoadBreadCurmp(currentFolder, breadcurmbIndex) {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    if (breadcurmbIndex < (this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.length - 1)) {
      let removeItems = this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.length - breadcurmbIndex;
      this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.splice(breadcurmbIndex + 1, removeItems);
    }
    this.ndvsNew.syncJobSetting.netDocuments.folderId = currentFolder.DocId;
    this.ndvsNew.syncJobSetting.netDocuments.folderName = currentFolder.Attributes.Name;
    this.ndvsNew.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
    this.onResetScrollVars();
    this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, currentFolder.DocId);
    this.onSaveEnable();
  }

  public getExt(sourceType) {
    let types = { folder: "ndfld", workspace: "ndws" }
    return types[sourceType];
  }


  public assignSelectdCabined(cabinet, netDocData, dropdown) {
    this.selectedCabinet = cabinet;
    this.selectedWorkSpace = {}
    this.selectCabinetFolder = {};
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.cabinetSearch = "";
    this.onResetScrollVars();
    if (netDocData == null) this.ndvsNew.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvsNew.syncJobSetting.netDocuments.cabinetId = this.selectedCabinet.id;
    this.ndvsNew.syncJobSetting.netDocuments.cabinetName = this.selectedCabinet.name
    this.ndvsNew.syncJobSetting.netDocuments.repositoryId = this.selectedCabinet.repositoryId;
    if (netDocData != null) {
      if (netDocData.folderId != undefined && netDocData.folderId != null && netDocData.folderId != "") {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {
          this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, netDocData.folderId);
          // this.onSelectWorkSpace({id:netDocData.workspaceId,name:netDocData.workspaceName},null,netDocData);
        }
      } else {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {
          if (this.ndvsNew.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, netDocData)
          // this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId,netDocData); 
        }
      }
    } else {
      if (this.ndvsNew.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvsNew.syncJobSetting.netDocuments.cabinetId)
      // this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId);
    }
    this.onSaveEnable();
  }

  public getWorkspaceContents(cabinetId, workspaceId, skipToken = null) {
    this.selectAll = false;
    this.isNDLoading = true;
    this.isFolerLoading = true;
    if (skipToken != null) {
      if (this.executedPagingTokens.indexOf(skipToken) < 0) {
        this.executedPagingTokens.push(skipToken);
      } else {
        return;
      }
    } else {
      this.executedPagingTokens = [];
    }
    this.workSpaces = [];
    this.cabinetFolders = [];
    if (skipToken == null && !this.isFloderScrollEnded) this.workSpaceContents = [];
    if (this.ndContentType == "folders" && this.ndvsNew.syncJobSetting.netDocuments.folderId == "") {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      this.workSpaceContents = [];
      this.serviceResuest = this.ndvs.getNetDocumentsFolders(cabinetId, workspaceId, skipToken).subscribe((resData) => {
        this.showLoader = false;
        this.breadcurmbClicked = false;
        if (resData.status == "success" && resData.data != undefined) {
          if (resData.data.length > 0) {
            resData.data.forEach(element => {
              if (this.ndvsNew.syncJobSetting.netDocuments.selectAll) {
                element.isChecked = true;
              } else {
                if (this.ndvsNew.syncJobSetting.netDocuments.sources.folders.length > 0) {
                  let curContent = this.ndvsNew.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement?.id == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
                if (this.ndvsNew.syncJobSetting.netDocuments.sources.files.length > 0) {
                  let curContent = this.ndvsNew.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement?.id == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
              }
              this.workSpaceContents.push(element);
            });
            if (resData.skipToken != undefined && resData.skipToken != null && resData.skipToken != "") {
              this.ndFilesNextRecord = resData.skipToken;
              this.isFolerLoading = false;
            } else {
              this.isFolerLoading = false;
              this.isFloderScrollEnded = true;
              this.isNDLoading = false;
              // this.ndContentType = "files";
              // this.getWorkspaceContents(cabinetId, workspaceId)
            }
          } else {
            this.isFolerLoading = false;
            this.isFloderScrollEnded = true;
            this.isNDLoading = false;
            // this.ndContentType = "files";
            // this.getWorkspaceContents(cabinetId, workspaceId)
          }
        } else {
          this.isFolerLoading = false;
          this.isFloderScrollEnded = true;
          this.isNDLoading = false;
          // this.ndContentType = "files";
          // this.getWorkspaceContents(cabinetId, workspaceId)
        }
      });
    } else {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      this.serviceResuest = this.ndvs.getFilesAdnFolders(cabinetId, workspaceId, this.cabinetSearch, this.ndContentType, skipToken).subscribe((resData) => {
        this.isFolerLoading = false;
        this.showLoader = false;
        this.breadcurmbClicked = false;
        if (resData.status == "success" && resData.data != undefined) {
          if (resData.data.Results != undefined && resData.data.Results.length > 0) {
            this.ndFilesNextRecord = resData.skipToken;
            this.workSpaceContents = this.workSpaceContents.concat(resData.data.Results);
            this.onRestrictFile();
            this.workSpaceContents.forEach(element => {
              if (this.ndvsNew.syncJobSetting.netDocuments.selectAll) {
                element.isChecked = true;
              } else {
                if (this.ndvsNew.syncJobSetting.netDocuments.sources.folders.length > 0) {
                  let curContent = this.ndvsNew.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement?.id == element?.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
                if (this.ndvsNew.syncJobSetting.netDocuments.sources.files.length > 0) {
                  let curContent = this.ndvsNew.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement?.id == element?.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
              }
            });
            this.isNDLoading = false;
            this.updateNDSelectAllStatus(this.ndvsNew.syncJobSetting.netDocuments.selectAll);
          }
          if (this.ndFilesNextRecord == null && !this.isFloderScrollEnded) {
            this.isFloderScrollEnded = true;
            //this.ndContentType = "files"
            //this.getWorkspaceContents(cabinetId, workspaceId, this.ndFilesNextRecord);
          } else if (this.ndFilesNextRecord == null && this.isFloderScrollEnded && !this.isFileScrollEnded) {
            this.isFileScrollEnded = true;
            this.isNDLoading = false;
          }
        }
      })
    }
    this.onSaveEnable();
  }

  public onSelectWorkSpace(workspace, dropdown = null, netDocData = null) {
    this.selectedWorkSpace = workspace;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.ndvsNew.syncJobSetting.netDocuments.workspaceId = this.selectedWorkSpace.id;
    this.ndvsNew.syncJobSetting.netDocuments.workspaceName = this.selectedWorkSpace.name;
    this.ndvsNew.syncJobSetting.netDocuments.workspaceEnvId = this.selectedWorkSpace.envId;
    if (dropdown != null) dropdown.close();
    this.onResetScrollVars();
    if (netDocData == null || this.ndvsNew.syncJobSetting.netDocuments.breadCurmb == undefined || this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.length == 0) {
      this.ndvsNew.syncJobSetting.netDocuments.breadCurmb = [];
      if (netDocData == null) this.ndvsNew.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
      this.ndvsNew.syncJobSetting.netDocuments.folderId = "";
      this.ndvsNew.syncJobSetting.netDocuments.folderName = "";
      this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, this.ndvsNew.syncJobSetting.netDocuments.workspaceId);
    } else {
      this.ndvsNew.syncJobSetting.netDocuments.folderId = this.ndvsNew.syncJobSetting.netDocuments.breadCurmb[this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.length - 1].DocId;
      this.ndvsNew.syncJobSetting.netDocuments.folderName = this.ndvsNew.syncJobSetting.netDocuments.breadCurmb[this.ndvsNew.syncJobSetting.netDocuments.breadCurmb.length - 1].Attributes.Name;
      this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, this.ndvsNew.syncJobSetting.netDocuments.folderId)
      // }    
    }
    this.onSaveEnable();
  }

  public onChangeCabinetSearch() {

    //console.log("resData===", this.cabinetSearch)
    this.ndNextRecord = null;
    if (this.cabinetSearch != "") return;
    this.onResetScrollVars();
    if (this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" || this.ndvsNew.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvsNew.syncJobSetting.netDocuments.folderId != "") ? this.ndvsNew.syncJobSetting.netDocuments.folderId : this.ndvsNew.syncJobSetting.netDocuments.workspaceId;
      this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.workSpaces = [];
      this.cabinetFolders = [];
      if (this.selectedCabinet.id != undefined) {
        this.ndvs.getWorkspace(this.selectedCabinet.id, this.ndNextRecord).subscribe((resData) => {
          this.isWorkspaceLoader = false;
          if (resData.status == "success" && resData.data != undefined) {
            this.workSpaces = resData.data;
          }
          this.getCabinetFolders(this.ndvsNew.syncJobSetting.netDocuments.cabinetId);
        });
      }
    }
    this.onSaveEnable();
  }



  public onNDScroll(event) {
    if (event.target.scrollHeight - event.target.clientHeight <= event.target.scrollTop) {
      if (this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" || this.ndvsNew.syncJobSetting.netDocuments.folderId != "") {
        if (this.isFileScrollEnded) return;
        let contentSource: string = "";
        if ((this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" && this.ndvsNew.syncJobSetting.netDocuments.folderId != "") || (this.ndvsNew.syncJobSetting.netDocuments.workspaceId == "" && this.ndvsNew.syncJobSetting.netDocuments.folderId != "")) {
          contentSource = this.ndvsNew.syncJobSetting.netDocuments.folderId;
        } else {
          contentSource = this.ndvsNew.syncJobSetting.netDocuments.workspaceId;
        }
        this.isNDLoading = true;
        this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
      } else {
        if (this.ndNextRecord != null) this.getWorkspace(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
      }
    }
    this.onSaveEnable();
  }

  public onSearchKeyPress(event) {
    if (event.keyCode == 13) this.onSearchWorkspace();
  }

  public onSearchWorkspace(skipToken = null) {
    if (this.cabinetSearch.trim() == '' || this.cabinetSearch.length < 3 || this.isNDLoading) return;
    if (skipToken == null) this.onResetScrollVars();
    if (this.ndvsNew.syncJobSetting.netDocuments.workspaceId != "" || this.ndvsNew.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvsNew.syncJobSetting.netDocuments.folderId != "") ? this.ndvsNew.syncJobSetting.netDocuments.folderId : this.ndvsNew.syncJobSetting.netDocuments.workspaceId;
      this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.ndvs.getSearchWorkspace(this.selectedCabinet.id, this.cabinetSearch, skipToken).subscribe((resData) => {
        this.isWorkspaceLoader = false;
        if (resData.status == "success" && resData.data != undefined) {
          this.ndNextRecord = resData.skipToken;
          if (skipToken == null) {
            this.workSpaces = resData.data;
          } else {
            this.workSpaces = this.workSpaces.concat(resData.data);
          }
        } else {
          this.showLoader = false;
        }
        // if(this.ndNextRecord!=null) this.onSearchWorkspace(this.ndNextRecord);      
      });
    }
    this.onSaveEnable();
  }

  public getCabinetFolders(cabinetId, netDocData = null) {
    this.isNDLoading = true;
    this.selectCabinetFolder = {};
    this.ndvsNew.syncJobSetting.netDocuments.folderId = "";
    this.ndvsNew.syncJobSetting.netDocuments.folderName = "";
    let recordFrom = null;
    this.isCabinetFolderLoader = true;
    this.ndvs.getCabinetFolder(cabinetId, recordFrom).subscribe((resData) => {
      this.isCabinetFolderLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinetFolders = resData.data;
        if (this.cabinetFolders.length > 0) {
          if (netDocData != null) {
            let curSelCabFolder = this.cabinetFolders.find((element => element.DocId == netDocData.folderId));
            if (curSelCabFolder != undefined && curSelCabFolder != null) {
              this.selectCabinetFolder = curSelCabFolder
              this.ndvsNew.syncJobSetting.netDocuments.folderId = this.selectCabinetFolder.DocId;
              this.ndvsNew.syncJobSetting.netDocuments.folderName = this.selectCabinetFolder.Attributes.Name;
              this.getWorkspaceContents(this.ndvsNew.syncJobSetting.netDocuments.cabinetId, this.selectCabinetFolder.DocId);
            } else {
              if (netDocData.contents != undefined && netDocData.contents != null && netDocData.contents.length > 0) {
                this.cabinetFolders.forEach(foldFindEle => {
                  foldFindEle.isChecked = (netDocData.contents.indexOf(foldFindEle.DocId) >= 0) ? true : false;
                })
              }
              this.showLoader = false;
              this.isNDLoading = false;
            }
          } else {
            this.showLoader = false;
            this.isNDLoading = false;
            // this.onSelectWorkSpace(this.workSpaces[0]);   
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
        this.workSpaces = [];
      }
    })
    this.onSaveEnable();
  }

  public onRestrictFile() {
    this.workSpaceContents.forEach(fileElement => {
      if (this.ndvsNew.syncJobSetting.sharePoint.connectionType != undefined && this.ndvsNew.syncJobSetting.sharePoint.connectionType != null && this.ndvsNew.syncJobSetting.sharePoint.connectionType == 'online') {
        if (fileElement.Attributes.Size >= 100000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else if (this.ndvsNew.syncJobSetting.sharePoint.connectionType != undefined && this.ndvsNew.syncJobSetting.sharePoint.connectionType != null && this.ndvsNew.syncJobSetting.sharePoint.connectionType == 'onpremises') {
        if (fileElement.Attributes.Size >= 15000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else {
        fileElement.isRestricted = false;
      }
    });
  }

  private isfileCharValidated(fileName) {
    let validateChars = ['"', '*', ':', '<', '>', '?', '/', '\\', '|'];
    let isAvailable: boolean = true;
    validateChars.forEach((char) => {
      if (fileName.indexOf(char) >= 0) isAvailable = false;
    })
    return isAvailable;
  }


  public updateNDSelectAllStatus(selectAllStstus = null) {
    //selectAllStstus = true;
    // if (selectAllStstus != null) {
    //   this.ndvsNew.syncJobSetting.netDocuments.selectAll = selectAllStstus;
    // } else {
    let restricetedFiles = this.workSpaceContents.filter(resFile => (resFile.isRestricted == true));
    let filesCount = (restricetedFiles == undefined || restricetedFiles == null || restricetedFiles.length == 0) ? this.workSpaceContents.length : (this.workSpaceContents.length - restricetedFiles.length)
    if ((this.ndvsNew.syncJobSetting.netDocuments.sources.folders.length + this.ndvsNew.syncJobSetting.netDocuments.sources.files.length) == filesCount) {
      this.ndvsNew.syncJobSetting.netDocuments.selectAll = true;
      this.selectAll = true;
    } else {
      this.selectAll = false;
      this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
    }
    // }
    this.onSaveEnable();
  }

  public onSaveEnable() {
    this.isDisabledSave = true;
    if (this.isNDLoading || this.isFolerLoading || this.isTeamsLoading) return;
    switch (this.ndvsNew.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        this.isDisabledSave = this.syncType && this.ndvsNew.syncJobSetting.netDocuments.cabinetId ? false : true;
        break;
      case "teamUni":
        this.isDisabledSave = this.syncType && this.ndvsNew.syncJobSetting.netDocuments.cabinetId ? false : true;
        break;
      case "netDocumentsUniTeam":
        this.isDisabledSave = this.syncType && this.ndvsNew.syncJobSetting.netDocuments.folderId ? false : true;
        break;
    }
    //console.log("this.isDisabledSave====", this.isDisabledSave)
    // const previousValue = JSON.stringify(this.previousSyncJobSetting);
    // const currentValue = JSON.stringify(this.ndvsNew.syncJobSetting);
    // if (currentValue !== previousValue) {
    this.onSyncJobSettingChange.emit({ syncJobSetting: this.ndvsNew.syncJobSetting });
    //   this.previousSyncJobSetting = JSON.parse(JSON.stringify(this.ndvsNew.syncJobSetting));
    // }

  }

  public onSaveSyncJob(teamId, resFolderData, channel = null) {
    this.ndvsNew.syncJobSetting.netDocuments.siteUrl = this.ndvsNew.currentUserLicense.NDApiUrl;
    this.ndvsNew.syncJobSetting.netDocuments.userName = this.ndvsNew.currentUser.displayName;
    this.ndvsNew.syncJobSetting.createdBy = this.ndvsNew.currentUser.email;
    this.ndvsNew.syncJobSetting.netDocuments.isPathBroken = false;
    this.ndvsNew.syncJobSetting.sharePoint = {
      isConnectionBroken: false,
      isPathBroken: false
    };
    this.ndvsNew.syncJobSetting.oneDrive = {
      isPathBroken: false
    }
    this.ndvsNew.syncJobSetting.selectAll = false;
    this.ndvsNew.syncJobSetting.teams = {
      connectionId: this.global.connectionData._id,
      connectionType: "online",
      teamId: teamId,
      teamName: this.form.value.teamName,
      channelId: channel ? channel.id : "",
      channelName: channel ? channel.name : "",
      channeWebUrl: "",
      folderId: "",
      folderName: "",
      folderWebUrl: "",
      selectAll: true,
      breadCrumb: [],
      sources: {
        folders: [],
        files: [],
        channels: []
      },
      channelWebUrl: ""
    }
    switch (this.ndvsNew.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.netDocuments.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "bidirectional":
        this.ndvsNew.syncJobSetting.selectAll = true;
        this.ndvsNew.syncJobSetting.netDocuments.selectAll = true;
        this.ndvsNew.syncJobSetting.teams.selectAll = true;
        break;
      case "teamUni":
        if (this.ndvsNew.syncJobSetting.teams.selectAll) {
          this.ndvsNew.syncJobSetting.selectAll = true;
          this.ndvsNew.syncJobSetting.netDocuments.selectAll = false;
          this.ndvsNew.syncJobSetting.teams.selectAll = true;
        }
        break;
    }

    this.ndvsNew.syncJobSetting.netDocuments.folderId = resFolderData.standardAttributes.id
    this.ndvsNew.syncJobSetting.netDocuments.folderName = resFolderData.standardAttributes.name

    var breadCrumb = [...this.ndvsNew.syncJobSetting.netDocuments.breadCurmb, {
      DocId: resFolderData.standardAttributes.id,
      EnvId: resFolderData.standardAttributes.envId,
      Attributes: {
        Name: resFolderData.standardAttributes.name,
        Ext: resFolderData.standardAttributes.extension
      }
    }]

    this.ndvsNew.syncJobSetting.netDocuments.breadCurmb = breadCrumb;
    return this.ndvsNew.syncJobSetting;
  }


  private onResetScrollVars() {
    this.ndNextRecord = null;
    this.ndFilesNextRecord = null;
    this.ndContentType = "folders";
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
  }
  onnetDocumentSelectAll() {
    if (this.workSpaceContents.length > 0) {
      this.ndvsNew.syncJobSetting.netDocuments.sources.folders = [];
      this.ndvsNew.syncJobSetting.netDocuments.sources.files = [];
      this.workSpaceContents.forEach(foldFindEle => {
        foldFindEle.isChecked = this.selectAll;
        if (foldFindEle.isChecked) {
          if (foldFindEle?.Attributes.Ext == "ndfld" || foldFindEle?.Attributes.Ext == "ndsq") {
            this.ndvsNew.syncJobSetting.netDocuments.sources.folders.push({ name: foldFindEle.Attributes.Name, id: foldFindEle.DocId });
          } else {
            this.ndvsNew.syncJobSetting.netDocuments.sources.files.push({ name: foldFindEle.Attributes.Name, id: foldFindEle.DocId, extension: foldFindEle.Attributes.Ext });

          }
        }
      });
    } else {
      this.ndvsNew.syncJobSetting.netDocuments.selectAll = this.selectAll;
    }
    this.onSaveEnable();
  }
  onChooseNDContent(event, content) {
    content.isChecked = (event != null) ? event.target.checked : (content.isChecked ? false : true);
    if (content.isChecked) {
      if (content?.Attributes.Ext == "ndfld" || content?.Attributes.Ext == "ndsq") {
        this.ndvsNew.syncJobSetting.netDocuments.sources.folders.push({ name: content.Attributes.Name, id: content.DocId });
      } else {
        this.ndvsNew.syncJobSetting.netDocuments.sources.files.push({ name: content.Attributes.Name, id: content.DocId, extension: content.Attributes.Ext });

      }
    } else {
      if (content?.Attributes.Ext == "ndfld" || content?.Attributes.Ext == "ndsq") {
        let urlIndex = this.ndvsNew.syncJobSetting.netDocuments.sources.folders.findIndex((element => element.id == content.DocId));
        if (urlIndex != null && urlIndex >= 0) this.ndvsNew.syncJobSetting.netDocuments.sources.folders.splice(urlIndex, 1);
      } else {
        let urlIndex = this.ndvsNew.syncJobSetting.netDocuments.sources.files.findIndex((element => element.id == content.DocId));
        if (urlIndex != null && urlIndex >= 0) this.ndvsNew.syncJobSetting.netDocuments.sources.files.splice(urlIndex, 1);
      }
    }
    this.updateNDSelectAllStatus(this.ndvsNew.syncJobSetting.netDocuments.selectAll);
    this.onSaveEnable();
  }

}
