<div class="sync-freq {{(!isSyncTimeValidate) ? 'border-danger' : ''}}" [formGroup]="syncForm">
    <input formControlName="hour" maxlength="8" placeholder="HH" (keypress)="onKeyPress($event)" class="form-control"   type="text"  (change)="changeFrequency()" (input)="onFrequencyInput()" (focus)="onFrequencyFocus($event)">
    <span class="time-spliter">:</span>
    <input formControlName="minute" maxlength="2" placeholder="MM" (keyup)="onMinuteKeyup($event)" (keypress)="onKeyPress($event)" class="form-control two"  type="text"   (change)="changeFrequency()" (input)="onFrequencyInput()" (focus)="onFrequencyFocus($event)">   
    <span class="time-spliter">:</span>
    <input formControlName="second" maxlength="2" placeholder="SS" (keyup)="onSecondKeyup($event)" (keypress)="onKeyPress($event)" class="form-control three mr-3"  type="text"   (change)="changeFrequency()" (input)="onFrequencyInput()" (focus)="onFrequencyFocus($event)">   
    <button class="icn-btn" *ngIf="isShowResetIcon" (click)="onResetFrequency()">
            <i class="material-icons-sharp md-close" ></i>
    </button>
</div>
