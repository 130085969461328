import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/services/helper';
import { NetDocumentViewerService } from '../../services/netdocument-viewer';


@Component({
  selector: 'app-container-top',
  templateUrl: './container-top.component.html',
  styleUrls: ['./container-top.component.scss']
})
export class ContainerTopComponent implements OnInit {

  public isAdmin: boolean = HelperService.IS_ADMIN();
  public companyName: string = "";
  public showLoader: boolean = false;
  public licenseType: string = "";


  constructor(
    public netDocService: NetDocumentViewerService,
    public toastr: ToastrService,
    private router: Router,
    public modalService: NgbModal,
  ) {
    // //console.log(this.netDocService.currentUser)
  }

  ngOnInit(): void {

  }

  logout() {
    this.showLoader = true;
    this.netDocService.logOut(false).subscribe((data) => {
      localStorage.clear();
      this.netDocService.isDULogin = false;
      this.router.navigateByUrl("/");
      window.location.reload();
    });
  }

  public onCloseWarrning() {
    this.netDocService.isLicWarnShow = false;
  }

  public onShowTeamsSetting() {
    this.router.navigateByUrl('/teams-connections')
  }

  public logoClick() {
    this.router.navigateByUrl('/provisioning')
  }

}
