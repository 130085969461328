<!-- Members Start -->
<div class="container">
  <div>
    <div class="form-group row mr-0 ml-0 mb-4">
      <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
        <label class="form-label">Members</label>
      </div>
    </div>
    <div class="form-group row mr-0 ml-0 mb-4">
      <div class="vh50 col-sm-12" overflow-hidden>

        <p class="p-0">Search and add members to your private channel.</p>

        <div class="col-sm-12 position-relative d-flex justify-content-between align-items-center  nopadding  p-0">
          <label class="form-label">Search Members:</label>
        </div>
        <div class="form-group row mr-0 ml-0 mb-0">
          <div class="col-sm-12 nopadding p-0 inp-search-holder">
            <input type="text" [(ngModel)]="searchUserString" class="form-control inp-search"
              (ngModelChange)="setSearchUserString($event)">
            <button *ngIf="searchUserString!=''" class="icn-btn inp-search-close" (click)="clearSearch($event)">
              <i class="material-icons-sharp md-close"></i>
            </button>
          </div>
        </div>
        <div class="form-group row mr-0 ml-0 height500" [hidden]="!searchUserString && !dropDownForm.value.myItems.length">
          <div class="search-member col-sm-12 nopadding p-0">
            <form [formGroup]="dropDownForm" class="w-100">
              <div>
                <div class="dd-loader-holder" *ngIf="loadingMember">
                  <img class="loader-image" src="assets/images/Spinner.gif">
                </div>
                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="searchmembers"
                  [disabled]="loadingMember||loadingAddMember||searchmembers.length==0" formControlName="myItems"
                  class="ms-drop">
                </ng-multiselect-dropdown>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row mb-4 p-4" *ngIf="isChannelEdit">
      <table class="table table-bordered bg-white col-sm-12" *ngIf="members.length>0">
        <thead class="thead-light">
          <tr>
            <th class="col-sm-4">Name</th>
            <th class="col-sm-6">Email</th>
            <th class="col-sm-2">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let member of members">
            <td class="col-sm-4">{{member.displayName}}</td>
            <td class="col-sm-6">{{member.email}}</td>
            <td class="col-sm-2">
              <div (click)="removeMember(member)" style="display: inline-block;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash"
                  viewBox="0 0 16 16" style="cursor: pointer;">
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="members.length==0 && !loadingPrivateMembers" class="col-sm-12">
        No active members are assigned to this private channel.
      </div>
      <div *ngIf="loadingPrivateMembers" class="col-sm-12">
        Loading members.
        <img class="loader-image" src="assets/images/Spinner.gif">
      </div>
    </div>
  </div>
</div>
<!-- Members End -->