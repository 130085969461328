import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { CATCH_ERROR_VAR } from '@angular/compiler/src/output/output_ast';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {map,catchError} from "rxjs/operators";
import { GlobalService } from './global.service';
import { LogService } from './log.service';
import { NetDocumentViewerService } from './netdocument-viewer';

@Injectable({
  providedIn: 'root'
})
export class HttpInterseptorService {

  constructor(private logService:LogService, private ndvs:NetDocumentViewerService, private global:GlobalService) { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.logService.updateLogMessage("API service requested =>"+ req.url);
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    let headers:any=req.headers;
    if(req.url.indexOf("netdocuments.com")<0 && req.url.indexOf("netvoyage.com")<0 ){
      if(this.ndvs.currentUserLicense.clientId!=undefined && this.ndvs.currentUserLicense.clientId!= null && this.ndvs.currentUserLicense.clientId!=''){
        if((this.ndvs.currentUser.access_token!=undefined && this.ndvs.currentUser.access_token!=null && this.ndvs.currentUser.access_token!='') && (this.ndvs.currentUser.email!=undefined && this.ndvs.currentUser.email!=null && this.ndvs.currentUser.email!='')){
          headers=headers.set('x-auth-client-key', btoa(this.ndvs.currentUserLicense.clientId+"|"+this.ndvs.currentUser.email+"|"+Date.now()));
          headers=headers.set('Authorization', 'Bearer '+btoa(this.ndvs.currentUser.access_token+"|"+this.ndvs.currentUserLicense.NDApiUrl+"|"+Date.now()));
        }else{
          headers=headers.set('x-auth-client-key',btoa(this.ndvs.currentUserLicense.clientId+"|"+Date.now()));
        }
      }
    }
    const authReq = req.clone({
      headers: headers, 
    });
    return next.handle(authReq)
    .pipe(
      map((event:HttpEvent<any>)=>{
      return event;
      }),
      catchError((error:HttpErrorResponse)=>{
      if(req.url.indexOf("netdocuments.com")<0 && req.url.indexOf("netvoyage.com")<0 ){
        if(error.status==401){
          this.ndvs.logOut();
          return throwError(error);
        }else{
          return this.handleError(error);
        }
      }else{
        return  this.handleError(error);  
      }       
    }))
  }

  handleError(error: any ) {
    this.logService.updateLogMessage("API service request error \n"+JSON.stringify(error));
    return throwError(error);
  }
}
