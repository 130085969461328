import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(private logService:LogService) { 

  }

  handleError(error) {  
    if(typeof(error)=="string"){
      this.logService.updateLogMessage(error) 
    }else{
      const {stack} = error;
      if(stack){
        this.logService.updateLogMessage(stack)
      }else{
        this.logService.updateLogMessage(JSON.stringify(error))
      }
    }
  }  
}
