<div class="teamsOptions">
  <input
    type="radio"
    name="teams"
    id="teams-scratch"
    class="teamsOption-input"
    (change)="onSelectCreateOption('new')">
  <label
    for="teams-scratch"
    class="teamsOption-label">
    <div class="teams-img">
      <img
        src="../../../assets/images/icons/new-teams-icon.svg"
        alt="team from scratch">
    </div>
    <b>New team</b>
    <p>Create a team from scratch</p>
  </label>

  <!-- <input
    type="radio"
    name="teams"
    id="teams-existing"
    class="teamsOption-input"
    (change)="onSelectCreateOption('existing')">
  <label
    for="teams-existing"
    class="teamsOption-label">
    <div class="teams-img">
      <img
        src="../../../assets/images/icons/existing-teams-icon.svg"
        alt="team from existing team">
    </div>
    <b>From existing team</b>
    <p>Use one of your existing team as a starting point</p>
  </label> -->

  <input
    type="radio"
    name="teams"
    id="teams-template"
    class="teamsOption-input"
    (change)="onSelectCreateOption('fromtemplate')">
  <label
    for="teams-template"
    class="teamsOption-label">
    <div class="teams-img">
      <img
        src="../../../assets/images/icons/existing-teams-icon.svg"
        alt="team from template">
    </div>
    <b>From a template</b>
    <p>Use one of your template as a starting point</p>
  </label>

</div>
