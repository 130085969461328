import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { NetDocumentViewerService } from '../../services/netdocument-viewer';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ElectronService } from 'ngx-electron';
import { HelperService } from 'src/app/services/helper';
import { LogService } from 'src/app/services/log.service';
import { NetDocAuthComponent } from '../net-doc-auth/net-doc-auth.component';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('eleUserName') userNameElement: ElementRef;
  public loginMethod: string = "email";
  public loginMethodPlaceHolder: any = { email: "sample@sample.com", firmname: "Enter and select the Firm Name", clientid: "XXXX-XXXX-XXXX-XXXX" }
  public isValidEmail: boolean = false;
  public isShowCaptcha: boolean = false;
  public selectedClientId: string = "";
  public userMessage: string = "";
  // public isLicenseBasedLogin: boolean = false;

  public isLicenseLogin: boolean = false;
  public currentHost: string;
  public thesiteKey: string;
  public userName: string;
  public isFirmNameLoading: boolean = false;
  public isValidated: boolean = false;;
  public validating: boolean = false;;
  public fail: boolean;
  public isEmpty: boolean;
  public isRobot: boolean = true;
  public invalid: boolean = false;
  public unauthorized: boolean = true;
  public obtainedUsername: boolean;
  public expired: boolean;
  public isCaptchaProcessed: boolean = false;
  public isDisabledUserName: boolean = false;
  public isaddOnsRestrict: boolean = false;
  public isaddOnsRestrictAPI: boolean = false;

  public isLoginNDTriggred: boolean = false;
  public isSignInDiffUserTriggred: boolean = false;

  public isAdmin: boolean = HelperService.IS_ADMIN();

  public suggestedFirmNames: any = [];
  public responseMessage: string = "";

  constructor(
    private toastr: ToastrService,
    private zone: NgZone,
    public ndvs: NetDocumentViewerService,
    private router: Router,
    public electron: ElectronService,
    public logService: LogService,
    public netDocLocalStorage: NetdocshareLocalstorageService
  ) {
    this.thesiteKey = environment.siteKey;
    this.userName = electron.isElectronApp ? HelperService.STORED_KEY() : "";
  }

  ngOnInit() {
    this.ndvs.NDAuthComponent = NetDocAuthComponent;
    if (this.ndvs.currentUserLicense.clientId != undefined && this.ndvs.currentUserLicense.clientId != null && this.ndvs.currentUserLicense.clientId != "") {
      if (this.ndvs.currentUser.access_token != undefined && this.ndvs.currentUser.access_token != null && !this.ndvs.tokenExpired()) {
        //console.log("netdocuments===reached===");
        this.router.navigateByUrl('/netdocuments');
      } else if (this.ndvs.currentUser.access_obtained != undefined && this.ndvs.currentUser.access_obtained != null && this.ndvs.tokenExpired()) {
        //console.log("netdocuments=else==reached===");
        // this.ndvs.getSystemInfo().subscribe((r) => {
          const email = this.isValidEmail && this.userName ? this.userName : null;
          this.ndvs.getSystemInfo(email).subscribe((r) => {
          if (r === 'fail' || r === 'error') {
            if (this.electron.isElectronApp) {
              this.loginMethod = 'clientid';
              // this.isLicenseLogin = true;
              this.bypassRecaptcha();
            } else {
              this.isValidated = false;
              this.fail = true;
              //console.log("this.fail===1=====", this.fail)
            }
          } else {
            this.getUser();
          }
        })
      } else {
        //console.log("netdocuments=isElectronApp==reached===");
        if (this.electron.isElectronApp) {
          this.loginMethod = 'clientid';
          this.bypassRecaptcha();
        } else {
          this.onSelectLoginType("email")
        }
      }
    }
  }

  public onSelectLoginType(loginType) {
    if (this.validating || loginType == null) return;
    if (this.loginMethod.toLowerCase() != loginType.toLowerCase()) {
      this.isShowCaptcha = false;
      this.loginMethod = loginType
      this.userName = "";
      this.userMessage = "";
      this.responseMessage = "";
      this.selectedClientId = "";
      this.isValidEmail = false;
      // this.selectedClient = null;
      this.isDisabledUserName = false;
      this.isValidated = false;
      this.suggestedFirmNames = [];
    }
    setTimeout(() => {
      this.userNameElement.nativeElement.focus();
    }, 0)
  }

  public onKeyPressUser(event) {
    if (this.loginMethod.toLowerCase() == 'email' || this.loginMethod.toLowerCase() == 'clientid') {
      if (event.keyCode == 32) return false;
      if (event.keyCode == 13) this.getUserFirmnames();
    }
  }

  onUserNameChange(input) {
    this.userName = input.trim();
    this.invalid = false;
    this.expired = false;
    // this.selectedClient = null;
    this.selectedClientId = "";
    this.isShowCaptcha = false;
    this.responseMessage = "";
    this.userMessage = "";
    if (this.loginMethod.toLowerCase() == "firmname") {
      this.isShowCaptcha = ((this.userName != '' && !this.isValidated && !this.isValidEmail) && (this.isAdmin && !this.electron.isElectronApp))
      this.responseMessage = "";
      this.isShowCaptcha = true;
      this.ndvs.getFirmNames({ searchString: this.userName.toLowerCase() }).subscribe((resData: any) => {
        if (resData.status == 'success') {
          let clientExist = resData.data.find(element => element.client.toLowerCase() == this.userName.toLowerCase());
          if (clientExist == undefined) {
            this.suggestedFirmNames = resData.data;
            //this.bypassRecaptcha();
          } else {
            this.suggestedFirmNames = [];;
          }
        } else {
          this.isShowCaptcha = true;
          this.suggestedFirmNames = [];
        }
      })
    } else if (this.loginMethod.toLowerCase() == "email") {
      this.isValidEmail = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userName)) ? true : false;
      if (this.isValidEmail) this.getUserFirmnames();
      else this.suggestedFirmNames = [];
    } else {
      this.isShowCaptcha = ((this.userName != '' && !this.isValidated && !this.isValidEmail) && (this.isAdmin && !this.electron.isElectronApp))
      this.suggestedFirmNames = [];
    }
  }

  public getUserFirmnames() {
    this.isShowCaptcha = ((this.userName != '' && !this.isValidated && !this.isValidEmail) && (this.isAdmin && !this.electron.isElectronApp))
    if (this.userName != "") {
      this.isValidEmail = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.userName)) ? true : false;
      if (this.isValidEmail) {
        this.userMessage = "";
        this.isFirmNameLoading = true;
        this.ndvs.getUserFirmNames(btoa(this.userName)).subscribe((resData: any) => {
          this.isFirmNameLoading = false;
          console.log(resData);
          if (resData.status == 'success') {
            this.suggestedFirmNames = resData.data;
            if (this.suggestedFirmNames.length == 1) {
              this.isShowCaptcha = true;
              this.selectedClientId = this.suggestedFirmNames[0].id;
            }
          } else {
            this.suggestedFirmNames = [];
          }
          this.userMessage = resData.message
          console.log(this.responseMessage);
        })
      } else {
        this.userMessage = "Enter the valid email."
      }
    } else {
      this.userMessage = "Enter the email."
    }
  }

  public onSelectClient() {
    console.log(this.selectedClientId);
    this.isShowCaptcha = true;
  }

  public onLicenseValidate() {
    if (this.userName == '' || this.validating) return;
    this.isRobot = false;
    this.licenseCheck(this.userName);
  }

  licenseCheck(key) {
    if (this.validating || (key == undefined && key == null && key == "")) return;
    this.validating = true;
    this.invalid = false;
    this.isValidated = false;
    this.expired = false;
    if (this.userName !== "" && this.userName !== null) {
      this.isEmpty = false;
      this.isaddOnsRestrict = false;
      this.isaddOnsRestrictAPI = false;
      setTimeout(() => {
        this.responseMessage = "";
        if (this.loginMethod.toLowerCase() == 'clientid') {
          this.ndvs.validateLicense(key).then((resData: any) => {
            this.validating = false;
            try {
              this.responseMessage = resData.message;
              if (resData.result === "success") {
                this.logService.updateLogMessage("License key validate success");
                this.isValidated = true;
                this.fail = false;
              } else {
                this.logService.updateLogMessage("License key validate failed");
                this.isDisabledUserName = false;
                this.invalid = true;
                grecaptcha.reset();
              }
            }
            catch (err) {
              this.logService.updateLogMessage("License key validate failed => " + JSON.stringify(err));
              this.isDisabledUserName = false;
              this.invalid = true;
              grecaptcha.reset();
            }
          }).catch((error) => {
            this.isDisabledUserName = false;
            this.validating = false;
            this.invalid = true;
            this.logService.updateLogMessage("License key validate error => " + JSON.stringify(error));
            grecaptcha.reset();
          })
        } else {
          this.ndvs.validateFirmName({ firmName: this.userName }).subscribe((resData: any) => {
            this.zone.run(() => {
              this.validating = false;
              this.responseMessage = resData.message;
              if (resData.status == 'success') {
                this.logService.updateLogMessage(resData.message);
                this.isValidated = true;
                this.fail = false;
              } else {
                this.logService.updateLogMessage("Firmname validate failed");
                this.invalid = true;
                this.isDisabledUserName = false;
                grecaptcha.reset();
              }
            });
          }, (error) => {
            this.logService.updateLogMessage("Firmname validate  => " + JSON.stringify(error));
          })
        }
      }, 3000);
    }
  }

  public resetCaptcha() {
    this.isDisabledUserName = false;
    this.validating = false;
    this.invalid = true;
    this.responseMessage = "";
    if (this.userName != "") grecaptcha.reset();
  }

  public bypassRecaptcha() {
    this.isRobot = false;
    this.licenseCheck(this.userName);
  }

  resolved(token: string) {
    const tokenObj = {
      "captchaResponse": token
    }
    this.isCaptchaProcessed = true;
    this.isDisabledUserName = true;
    this.ndvs.verifyToken(tokenObj).subscribe((res) => {
      if (res.data && res.status == "success") {
        this.isRobot = false;
        if (this.loginMethod == 'email') {
          this.getNetDocumentsLoginInfo();
        } else {
          this.licenseCheck(this.userName);
        }
      } else {
        this.ndvs.showLoginProcessLoader = false;
        this.expired = true;
        this.isValidated = false;
        this.isDisabledUserName = false;
      }
    })
  }

  public getNetDocumentsLoginInfo() {
    if (this.validating || (this.userName == undefined || this.userName == null || this.userName == "" || this.selectedClientId == null)) return;
    this.validating = true;
    this.responseMessage = "";
    this.userMessage = "";
    this.ndvs.getNetDocumentsLoginInfo(btoa(this.userName), this.selectedClientId).subscribe((resData) => {
      this.validating = false;
      if (resData.status == "success") {
        this.logService.updateLogMessage("License key validate success");
        this.isValidated = true;
        this.fail = false;
        this.isShowCaptcha = false;
      } else {
        this.logService.updateLogMessage("License key validate failed");
        this.isDisabledUserName = false;
        this.invalid = true;
        grecaptcha.reset();
      }
      this.responseMessage = resData.message;
    }, (error) => {
      this.validating = false;
      this.logService.updateLogMessage("License key validate failed");
      this.isDisabledUserName = false;
      this.invalid = true;
      grecaptcha.reset();
      this.responseMessage = "Can not able to fetch data. Try again later.";
    })
  }

  public onClickSignInDU() {
    if (this.isSignInDiffUserTriggred) return;
    this.isSignInDiffUserTriggred = true;
    this.ndvs.isDULogin = true;
    this.ndvs.theAuthModal = null;
    this.ndvs.logOut().subscribe(() => {
      this.zone.run(() => {
        this.doLogin();
      })
    })
  }

  doLogin() {
    // if(!this.isValidated || this.isRobot || this.isLoginNDTriggred || (this.unauthorized && this.fail) || this.userName=='') return;
    if (this.ndvs.currentUser.access_token == undefined || this.ndvs.tokenExpired()) {
      this.isLoginNDTriggred = true;
      this.netDocLocalStorage.last_page_url = window.location.href;
      this.ndvs.theAuthModal = null;
      this.ndvs.showLoginProcessLoader = true;
      //console.log("PT::getSystemInfo::IN")
      const email = this.isValidEmail && this.userName ? this.userName : null;
      this.ndvs.getSystemInfo(email).subscribe((res: any) => {
        //console.log("PT::getSystemInfo = res====", res)
        if (!res.data && res.status == 'fail') {
          this.unauthorized = true;
          this.fail = true;
          this.userName = res.user.email;
          this.isLoginNDTriggred = false;
          this.isSignInDiffUserTriggred = false;
          this.ndvs.showLoginProcessLoader = false;
          this.ndvs.resetExpValues();
        } else if (res == 'error') {
          this.isLoginNDTriggred = false;
          this.isSignInDiffUserTriggred = false;
          this.ndvs.logOut().subscribe(() => {
            this.ndvs.resetExpValues();
            this.doLogin();
          })
        } else if (res == 'deny') {
          this.isLoginNDTriggred = false;
          this.isSignInDiffUserTriggred = false;
          this.ndvs.logOut().subscribe(() => {
            this.unauthorized = true;
            this.fail = false;
            this.isValidated = false;
            this.expired = false;
            this.userName = '';
            this.resetCaptcha();
            this.userName = res.user;
            this.isLoginNDTriggred = false;
            this.isSignInDiffUserTriggred = false;
            this.ndvs.isDULogin = false;
            this.ndvs.resetExpValues();
            localStorage.clear();
            window.location.reload();
            this.ndvs.showLoginProcessLoader = false;
          })
        } else {
          this.ndvs.showLoginProcessLoader = false;
          this.router.navigateByUrl('/provisioning');
        }
      }, (error) => {
        //console.log(error)
      })
    } else {
      this.accessTokenFound();
    }
  }

  accessTokenFound() {
    this.isValidated = true;
    this.fail = false;
  }

  getUser() {
    this.ndvs.getNetDocUserInfo().subscribe((r: any) => {
      this.isValidated = true;
      this.fail = false;
      this.userName = r.displayName;
      this.obtainedUsername = true;
      this.router.navigateByUrl('/netdocuments');
    })
  }

  public onCancel() {
    this.unauthorized = true;
    this.fail = false;
    this.isValidated = false;
    this.expired = false;
    this.userName = '';
    this.resetCaptcha();
    this.userName = "";
    this.isLoginNDTriggred = false;
    this.isSignInDiffUserTriggred = false;
    this.ndvs.isDULogin = false;
    // this.ndvs.resetExpValues();
    localStorage.clear();
    window.location.reload();
  }
}
