import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'create-teams-options',
  templateUrl: './create-teams-options.component.html',
  styleUrls: ['./create-teams-options.component.scss']
})
export class CreateTeamsOptionsComponent implements OnInit {

  @Output() onTypeSelection: EventEmitter<any> = new EventEmitter<any>();
  public selectedType: string = ""

  constructor(
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  public onSelectCreateOption(type: string) {
    this.selectedType = type;
    if (this.selectedType == 'new') {
      this.onTypeSelection.emit(this.selectedType)
      //this.router.navigateByUrl('createteam');
    } else if (this.selectedType == 'existing') {
      this.onTypeSelection.emit(this.selectedType)
      //this.router.navigateByUrl('createteam');
    } else if (this.selectedType == 'fromtemplate') {
      this.onTypeSelection.emit(this.selectedType)
      //this.router.navigateByUrl('createteam');
    } else {
      this.router.navigateByUrl('createfromtemplate');
      this.modalService.dismissAll();
    }
  }
}
