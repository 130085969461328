import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'team-tabs',
  templateUrl: './team-tabs.component.html',
  styleUrls: ['./team-tabs.component.scss']
})
export class TeamTabsComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  public connectionData: any = {};
  @Input("connection") set onChangeConnection(data) {
    this.connectionData = data;
  };

  public groupData: any = {};
  @Input("group") set onChangeGroup(data) {
    this.groupData = data;
  };

  public channelData: any = {};
  @Input("channel") set onChangeChannel(data) {
    this.channelData = data;
    this.getTabs();
  };

  @Input("disconnection") set onDisconnection(data) {
    if (data) {

    }
  };

  public tabsLoader: boolean = false;
  public teamsTabsRequest: any = null;
  public isTeamsRequestFailed: boolean = false;
  public isTabDeleteLoader: boolean = false;
  public tabs: any = [];
  public tabSync: any = [];
  public selectedTab: any = null;

  constructor(
    public ndvs: NetDocumentViewerService,
    public global: GlobalService,
    private teamsService: TeamsService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    public sanitizer: DomSanitizer
  ) {
    //console.log("global====", global)

    this.global.syncCreated.subscribe((data) => {
      console.log("this.global.syncCreated====tab===data====this.global====", data, JSON.parse(JSON.stringify(this.global)))
      if (data.status == "deleted" && this.global.syncFrom == "tab") {
        //console.log("this.global.syncCreated====tab===", data, this.global, this.tabs)

        let selectedTab = this.global.selectedTab;
        let index = this.tabs.findIndex(i => i.configuration.entityId == selectedTab.configuration.entityId)

        if (index != -1) {
          let tabSelected = this.tabs[index];
          tabSelected = { ...tabSelected, isSync: false }
          this.tabs[index] = tabSelected;
        }


        /* var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
        //var index = teamsSyncData.findIndex(i => i.teams.channelId == this.global.selectedChannel.Id)
        var newTeamsSyncData = teamsSyncData.filter(i => i.teams.channelId != this.global.selectedChannel.Id) */
        //var newTeamsSyncData = teamsSyncData;
        /* if (index != -1) {
          var teams = teamsSyncData;
          teams.splice(index, 1);
          newTeamsSyncData = teams;
        } */

        /* var newSelectedGroup = this.global.selectedGroup;
        if (newTeamsSyncData.length == 0) {
          newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
        } else {
          newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
        }

        this.global.selectedGroup = newSelectedGroup;
        this.syncChange.emit({ "syncChange": true }); */

      }
      if (data.status == "updated" && this.global.syncFrom == "tab") {
        //console.log("data.data====", data)
        //this.getTabs();
      }
      /* if (data.syncId != undefined) {
        let currentChannel = this.channels.find(channel => channel.Id == this.global.selectedChannel.Id)
        if (currentChannel != undefined && this.global.syncFrom == "channel") {
          currentChannel.isSyncScheduled = true;
          currentChannel.syncId = data.syncId;

          let teamsSyncData = this.global.selectedGroup.teamsSyncData;
          if (teamsSyncData == undefined || teamsSyncData == null || teamsSyncData.trim() == "") {
            this.global.selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify([{ _id: data.syncId, teams: { teamId: this.global.selectedGroup.Id, channelId: this.global.selectedChannel.Id } }]) };
          } else {
            var teamsSyncDataJson = JSON.parse(teamsSyncData);
            teamsSyncDataJson.push({ _id: data.syncId, teams: { teamId: this.global.selectedGroup.Id, channelId: this.global.selectedChannel.Id } })
            this.global.selectedGroup = { ...this.global.selectedGroup, syncData: data.syncId, teamsSyncData: JSON.stringify(teamsSyncDataJson) };
          }

          this.syncChange.emit({ "syncChange": true });
        }
      } */
    })
  }

  ngOnInit(): void {
  }


  public onAddTab(data) {
    this.tabs.push({ ...data, Id: data.data.id, DisplayName: data.data.displayName })
    this.getTabs();
  }

  private getTabs() {
    this.tabs = [];
    if (this.channelData.Id == undefined) {
      if (this.teamsTabsRequest != null) this.teamsTabsRequest.unsubscribe();
      this.teamsService.unsubscribeGetTeamsTabs();
      this.tabsLoader = false;
      this.teamsTabsRequest = null;
      return;
    }
    this.isTeamsRequestFailed = false;
    this.tabsLoader = true;
    if (this.teamsTabsRequest != null) this.teamsTabsRequest.unsubscribe();
    this.teamsTabsRequest = this.teamsService.getTeamsTabs(this.groupData.Id, this.channelData.Id).subscribe((resData) => {
      this.tabsLoader = false;
      if (resData.status == "success") {
        var data = resData.data.map(d => {
          let tabSync = resData.sync.filter(item => item.id == d.configuration.entityId);
          return { ...d, DisplayName: d.displayName, Id: d.id, isSync: tabSync.length > 0 ? tabSync[0].isSync : false, isChannelSync: tabSync.length > 0 ? tabSync[0].isChannelSync : false, isTeamSync: tabSync.length > 0 ? tabSync[0].isTeamSync : false, data: tabSync.length > 0 ? tabSync[0].data : "" }
        })
        //var data = resData.data.map(d => { return { ...d, DisplayName: d.displayName, Id: d.id } })
        var wikiData = data.filter(item => item.DisplayName.toLowerCase() == "wiki");
        var nonWikiData = data.filter(item => item.DisplayName.toLowerCase() != "wiki");
        data = [...wikiData, ...nonWikiData];
        this.tabs = data;
        this.tabSync = resData.sync;
        //this.global.tenantId = this.global.connectionData.tenantId;
      } else {
        this.isTeamsRequestFailed = true;
      }
    }, (error) => {
      this.isTeamsRequestFailed = true;
      this.tabsLoader = false;
    })

  }
  public onConfigureSync(e, tab, erroPop) {
    //console.log("onConfigureSync====tab====", tab);
    if (this.global.maxSyncJobCreated && e.target.parentElement.id != "btn") return;
    //console.log("onConfigureSync=====this.tabSync====", this.tabSync)
    var job = this.tabSync.find(i => i.id == tab.configuration.entityId)
    var cabinetId = job && job.jobData && job.jobData.netDocuments?.cabinetId;
    this.global.syncFrom = "tab";
    var found = false;
    if (this.global.selectedGroup.teamsSyncData) {
      found = JSON.parse(this.global.selectedGroup.teamsSyncData).find(e => e.teams.tabId != '');
    } else {
      found = true;
      //return;
    }
    //console.log("onConfigureSync====found====", found);
    if (!found) {
      this.modalService.open(erroPop, { backdrop: true }).result.then((res) => {
      }, (result) => {
      }).catch((error) => {

      })
      return;
    } else {
      //return;
    }
    //console.log("onConfigureSync=====this.global.selectedGroup.teamsSyncData====", this.global.selectedGroup)
    cabinetId = this.global.selectedGroup.teamsSyncData && JSON.parse(this.global.selectedGroup.teamsSyncData).find(e => e.teams.tabId != '').netDocuments?.cabinetId;
    //console.log("onConfigureSync=====job,cabinetId====", job, cabinetId)
    let tabData = {
      ...tab, syncId: job.data, isSyncScheduled: job.isSync, syncData: JSON.stringify(job.jobData), cabinetId: cabinetId
    }
    //console.log("onConfigureSync=====tabData====", tabData)
    //let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? "https://syncbeta.netdocshare.com/#/" : environment.syncUrl + "/#/";
    let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? "http://localhost:4300/#/" : environment.syncUrl + "/#/";
    this.ndvs.syncDataId = tabData.syncId;
    this.ndvs.syncUrl = (tabData.syncId != undefined && tabData.syncId != null && tabData.syncId != "") ? syncUrl + "editjob/" + tabData.syncId : syncUrl + "createjobs";
    this.ndvs.syncUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ndvs.syncUrl);
    this.ndvs.showSyncPopup = true;
    //console.log("onConfigureSync=====this.ndvs.syncUrl====", this.ndvs.syncUrl)
    this.onSelectTab(tabData);

    // setTimeout(()=>{
    //   let container:any=document.getElementById('SyncContainerFrame');
    //   let targetWindow = container.contentWindow;    
    //   let messageData={ndsApp:localStorage.getItem("ndsApp"),access_token:localStorage.getItem("access_token"), license_key:localStorage.getItem("access_token")}
    //   targetWindow.postMessage(messageData)
    // },200)
  }

  public getNetDocumentsCabinetId(tab) {
    //console.log("getNetDocumentsCabinetId====this.global.selectedGroup=====", this.global.selectedGroup)
    var hasCabinet = false;
    if (!this.global.selectedGroup.teamsSyncData) return hasCabinet;
    var tabId = tab.configuration.entityId;
    var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
    var isTabSync = teamsSyncData.filter(item => item.teams.tabId == tabId);

    if (isTabSync[0].netDocuments?.cabinetId) {
      hasCabinet = true;
    }
    if (!isTabSync[0].netDocuments) {
      // tab sync created at that time netDocuments details not stored. But during tab created from template this is stored. Hence set to true even on no iManage
      hasCabinet = true;
    }
    return hasCabinet;
  }

  public isChannelSync(tab) {
    //console.log("isChannelSync===tab====", tab)
    var channelSync = tab.isChannelSync;
    return channelSync;
  }

  public isTeamSync(tab) {
    //console.log("isChannelSync===tab====", tab)
    var teamSync = tab.isTeamSync;
    return teamSync;
  }


  /* public isTabSync(tab) {
    var tabFound = this.tabSync.filter(item => item.id == tab.id);
    return tabFound[0].isSync;
  } */
  public isTabSync(tab) {
    //console.log("tab=====", tab, this.tabSync, this.tabs, teamsSyncData)
    var tabFound = tab.isSync;//this.tabSync?.filter(item => item.id == tab.id);
    return tabFound;// && tabFound[0]?.isSync;
  }

  public onSelectTab(tab) {
    this.change.emit({ data: JSON.parse(JSON.stringify(tab)), isProcessed: false });
  }

  public onEditTab(modal, tabData, tabIndex) {
    this.global.isTabEdit = true;
    this.selectedTab = tabData;
    this.change.emit({ data: JSON.parse(JSON.stringify(tabData)), isProcessed: true });
    setTimeout(() => {
      this.onShowCreateTab(modal);
    }, 500)
  }

  public onShowCreateTab(modal) {
    /* if (this.connectionData.isConnected == undefined || !this.connectionData.isConnected || this.channelData.Id == undefined || !this.global.isNDSAppInstalled) return; */
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {
    }, (resData) => {
      if (resData.Id != undefined) {
        let tabExist = this.tabs.find(element => (element.Id == resData.Id));
        if (tabExist == undefined || tabExist == null) {
          this.tabs.push(resData);
        } else {
          tabExist.DisplayName = resData.DisplayName;
        }
      }
    }).catch((error) => {
    })
  }

  public onDeleteTab(modalTab, tabData, tabIndex) {
    this.change.emit({ data: JSON.parse(JSON.stringify(tabData)), isProcessed: true });
    this.modalService.open(modalTab, { backdrop: false }).result.then((res) => {
    }, (result) => {
    }).catch((error) => {

    })
  }

  public onDeleteConfirm(modal) {
    this.isTabDeleteLoader = true;
    let sendData: any = {}
    sendData.connectionId = this.global.connectionData._id;
    sendData.teamId = this.global.selectedGroup.Id;
    sendData.channelId = this.global.selectedChannel.Id;
    sendData.tabId = this.global.selectedTab.Id;
    this.teamsService.deleteTab(sendData).subscribe((resData) => {
      if (resData.status == "success") {
        this.toaster.success("Tab successfully removed", "Success", { extendedTimeOut: 10 });
        let deleteIndex = this.tabs.findIndex(tabData => tabData.Id == this.global.selectedTab.Id);
        if (deleteIndex >= 0) {
          this.tabs.splice(deleteIndex, 1);
          this.global.selectedTab = {};
        }

      } else {
        this.toaster.warning(resData.message, "Failed", { extendedTimeOut: 10 });
      }
      this.isTabDeleteLoader = false;
      modal.close();
    }, (error) => {
      this.isTabDeleteLoader = false;
      modal.close();
    })
  }

}
