import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-bulk-team-creation',
  templateUrl: './bulk-team-creation.component.html',
  styleUrls: ['./bulk-team-creation.component.scss']
})
export class BulkTeamCreationComponent implements OnInit {


  public selectedFile: any = {};
  public fileError: string = "";
  public isUploadProcess: boolean = false;
  public excelProcess: boolean = false;
  public excelData: any = [];
  //public statusColor: any = { Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber' }
  public syncChange: any = null;
  public teams = "";
  public jsonData = null;
  public validJsonData = [];
  public bulkUploadProcess = false;

  public processIndex = 0;
  public showStatusProgress = false;
  public processComplete = false;

  public downloadProgress = false;
  public uniqueTable = [];


  constructor(
    public excelService: ExcelService,
    public router: Router,
    public ndvs: NetDocumentViewerService,
    public teamsService: TeamsService,
    private modalService: NgbModal,
    public global: GlobalService,
  ) { }

  ngOnInit(): void {
    if (!this.global.connectionData._id) this.router.navigateByUrl('/provisioning');
  }

  public goHome() {
    this.router.navigateByUrl("/provisioning");
  }



  public onChooseFile() {
    this.excelData = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
  }

  public reUpload() {
    if (this.bulkUploadProcess) return;
    this.excelData = [];
    this.uniqueTable = [];
    this.fileError = "";
    this.isUploadProcess = false;
    this.selectedFile = {};
    this.validJsonData = [];
    this.showStatusProgress = false;
    this.processComplete = false;
    this.setProcessComplete();
  }

  public onFileDragOver(event) {
    event.preventDefault();
  }

  public onFileDropSuccess(event) {
    event.preventDefault();
    this.uploadSettings(event.dataTransfer.files);
  }

  public onBrowseFile(element) {
    element.click()
  }

  public onFileChange(event) {
    this.uploadSettings(event.target.files);
  }

  private uploadSettings(fileObject) {
    this.excelData = [];
    this.validJsonData = [];
    this.jsonData = null;
    if (fileObject.length > 1) {
      this.fileError = "Not supported for multiple files";
      return;
    } else {
      let fileExt = fileObject[0].name.split(".").pop();
      let allowedFiles: any = ["xls", "xlsx", "csv"];
      if (allowedFiles.indexOf(fileExt.toLowerCase()) == -1) {
        this.fileError = "Invalid file";
        return;
      } else {
        this.fileError = "";
      }

    }
    this.selectedFile = fileObject[0];
  }


  public showBulkUpdateStatus(popUp) {
    this.modalService.open(popUp, { scrollable: true, size: "lg" }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onValidateSettings() {
    if (((this.selectedFile.name == undefined || this.selectedFile.name == null || this.selectedFile.name == "") && !this.isUploadProcess)) return;

    this.excelProcess = true;
    this.isUploadProcess = true;
    let workBook = null;
    // let jsonData = null;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      this.jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      if (this.jsonData.Sheet1?.length > 0) {
        this.jsonData.Sheet1.forEach((element, index) => {
          element.row = index + 1;
          this.excelData.push(element);
          let tmp = this.uniqueTable.filter(i => i.IDRef == element.IDRef);
          if (tmp.length != 0) {
            element.TeamName = tmp[0].TeamName;
            element.TeamDescription = tmp[0].TeamDescription;
            element.TeamPrivacy = tmp[0].TeamPrivacy;
          } else {
            this.uniqueTable.push(element)
          }

          let teamPrivacy = element.TeamPrivacy?.toLowerCase();
          element.TeamPrivacy = teamPrivacy;

          let channelPrivacy = element.ChannelPrivacy?.toLowerCase();
          element.ChannelPrivacy = channelPrivacy;

          element.index = index;

          this.validateSPBulkData(element);
        });
        this.validJsonData = this.jsonData.Sheet1.filter(d => !d.isDataInvalid);
        this.processComplete = false;
        this.showStatusProgress = true;
      } else {
        this.fileError = "File have no records";
      }
      this.isUploadProcess = false;
      this.excelProcess = false;
    }
    reader.readAsBinaryString(this.selectedFile);
    this.selectedFile = {}
  }

  public startBulkUpload(e) {
    if (e != null) {
      if (this.bulkUploadProcess || this.processComplete) return;
    }
    this.bulkUploadProcess = true;
    //var index = this.excelData.findIndex(e => e.TeamID == this.validJsonData[this.processIndex].TeamID && e.ChannelID == this.validJsonData[this.processIndex].ChannelID && e.TabName == this.validJsonData[this.processIndex].TabName);
    var index = this.validJsonData[this.processIndex].index;
    this.excelData[index] = { ...this.excelData[index], teamStatus: "Processing...", channelStatus: "Processing...", tabStatus: "Processing...", syncStatus: "Processing...", gStatus: "progress", cStatus: "progress", tStatus: "progress", sStatus: "progress" }
    let team = this.uniqueTable.find(i => i.IDRef == this.validJsonData[this.processIndex].IDRef);
    this.teamsService.uploadTeamTemplateData({ "bulkTeamData": [this.validJsonData[this.processIndex]], teamId: team.teamId || "" }).subscribe((resData) => {

      if (resData.data == undefined || resData.data == null) {
        this.excelData[index] = { ...this.excelData[index], tabStatus: "Team or Channel not found", syncStatus: "Team or Channel not found", tStatus: "fail", sStatus: "fail" }
      } else if (resData.data.status == undefined || resData.data.status == null || resData.data.status.trim() == "") {
        this.excelData[index] = { ...this.excelData[index], tabStatus: "Team or Channel not found", syncStatus: "Team or Channel not found", tStatus: "fail", sStatus: "fail" }
      } else {
        team.teamId = resData.data.data[0].teamId;
        let newTeam = this.uniqueTable.filter(i => i.IDRef != this.validJsonData[this.processIndex].IDRef)
        this.uniqueTable = [...newTeam, team];
        this.excelData[index] = { ...this.excelData[index], teamStatus: resData.data.data[0].teamStatus, channelStatus: resData.data.data[0].channelStatus, tabStatus: resData.data.data[0].tabStatus, syncStatus: resData.data.data[0].syncStatus, gStatus: resData.data.data[0].teamStatus.toLowerCase() == "created" ? "pass" : "fail", cStatus: resData.data.data[0].channelStatus.toLowerCase() == "created" ? "pass" : "fail", tStatus: resData.data.data[0].tabStatus == "created" ? "pass" : "fail", sStatus: resData.data.data[0].syncStatus == "created" ? "pass" : "fail" }

      }
      if (this.processIndex == this.validJsonData.length - 1) {
        this.setProcessComplete();
      } else {
        this.processIndex++;
        this.startBulkUpload(null);
      }
    }, (error) => {
      this.bulkUploadProcess = false;
    })
  }

  public statusColor(status) {
    //Processing: '', Failed: 'text-danger', Success: 'text-success', Already: 'text-amber'
    return status == "pass" ? "text-success" : status == "progress" ? "text-amber" : "text-danger";
  }

  public setProcessComplete() {
    this.processIndex = 0;
    this.bulkUploadProcess = false;
    this.processComplete = true;
    this.uniqueTable = [];
  }

  private validateSPBulkData(jobData) {
    jobData.reason = "";
    jobData.isDataInvalid = true;
    jobData.dataProcessStatus = "Not Processed";
    jobData.IDRef = jobData.IDRef?.toString();
    jobData.TeamName = jobData.TeamName?.toString();
    jobData.TeamDescription = jobData.TeamDescription?.toString();
    jobData.TeamPrivacy = jobData.TeamPrivacy?.toString();
    jobData.ChannelName = jobData.ChannelName?.toString();
    jobData.ChannelDescription = jobData.ChannelDescription?.toString();
    jobData.ChannelPrivacy = jobData.ChannelPrivacy?.toString();
    jobData.TabName = jobData.TabName?.toString();
    jobData.TabConfig = jobData.TabConfig?.toString();
    jobData.SyncLocation = jobData.SyncLocation?.toString();

    //VALIDATE TEAM Name
    if (jobData.IDRef == undefined || jobData.IDRef == null || jobData.IDRef == "" || jobData.IDRef.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "IDRef field missing";
    }

    //VALIDATE TEAM Name
    if (jobData.TeamName == undefined || jobData.TeamName == null || jobData.TeamName == "" || jobData.TeamName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Name field missing";
    }

    //VALIDATE TEAM Description
    if (jobData.TeamDescription == undefined || jobData.TeamDescription == null || jobData.TeamDescription == "" || jobData.TeamDescription.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Description field missing";
    }

    //VALIDATE TEAM Privacy
    if (jobData.TeamPrivacy == undefined || jobData.TeamPrivacy == null || jobData.TeamPrivacy == "" || jobData.TeamPrivacy.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Privacy field missing";
    }

    /*
      private
      public
      org-wide
    */

    //VALIDATE TEAM Privacy
    if (jobData.TeamPrivacy != "private" && jobData.TeamPrivacy != "public" && jobData.TeamPrivacy != "org-wide") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Team Privacy is not valid privacy";
    }

    //VALIDATE CHANNEL Name
    if (jobData.ChannelName == undefined || jobData.ChannelName == null || jobData.ChannelName == "" || jobData.ChannelName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Name field missing";
    }

    //VALIDATE Channel Description
    if (jobData.ChannelDescription == undefined || jobData.ChannelDescription == null || jobData.ChannelDescription == "" || jobData.ChannelDescription.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Description field missing";
    }

    //VALIDATE Channel Privacy
    if (jobData.ChannelPrivacy == undefined || jobData.ChannelPrivacy == null || jobData.ChannelPrivacy == "" || jobData.ChannelPrivacy.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Privacy field missing";
    }

    /*
      standard
      private
    */
    //VALIDATE TEAM Privacy
    if (jobData.ChannelPrivacy != "standard" && jobData.ChannelPrivacy != "private") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Channel Privacy is not valid privacy";
    }

    //VALIDATE Tab Name
    /* if (jobData.TabName == undefined || jobData.TabName == null || jobData.TabName == "" || jobData.TabName.trim() == "") {
      jobData.reason += (jobData.reason == '') ? "" : ", ";
      jobData.reason += "Tab Name field missing";
    } */

    if (!(jobData.TabName == undefined || jobData.TabName == null || jobData.TabName == "" || jobData.TabName.trim() == "") && (jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "")) {
      //&& (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "")
      //VALIDATE Tab Config
      if ((jobData.TabConfig == undefined || jobData.TabConfig == null || jobData.TabConfig == "" || jobData.TabConfig.trim() == "")) {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "TabConfig field missing";
      }
      //VALIDATE Sync Location
      /* if (jobData.SyncLocation == undefined || jobData.SyncLocation == null || jobData.SyncLocation == "" || jobData.SyncLocation.trim() == "") {
        jobData.reason += (jobData.reason == '') ? "" : ", ";
        jobData.reason += "SyncLocation field missing";
      } */
    }

    if (jobData.reason == "") {
      jobData.isDataInvalid = false;
      jobData.reason = "-";
    }

  }


  public openTabConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public openSyncConfig(popUp) {
    this.modalService.open(popUp, { scrollable: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public copyTabConfig() {
    var copyText = document.getElementById("tab-config");
    navigator.clipboard.writeText(copyText.textContent);
  }

  public copySyncConfig() {
    var copyText = document.getElementById("sync-config");
    navigator.clipboard.writeText(copyText.textContent);
  }


  public templateExcelExport(e) {
    if (this.downloadProgress) return;
    this.downloadProgress = true;
    let template = [{
      "IDRef": "", "TeamName": "", "TeamDescription": "", "TeamPrivacy": "", "ChannelName": "", "ChannelDescription": "", "ChannelPrivacy": "", "TabName": "", "TabConfig": "", "SyncLocation": "", "TeamMembers": "", "PrivateChannelMembers": ""
    }];
    this.excelService.exportAsExcelFile(template, 'Teams_Template', "template");
    this.downloadProgress = false;
  }

  public toFirstLetterCase(str) {
    if (str == null || str == undefined || str.trim() == "") return "";
    //return str.replace(/\w\S*/g, function (txt) {
    return str.charAt(0).toUpperCase() + str.substr(1);
    //});
  }

}
