import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {


  @Output() updateGroup: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('name') nameElement: ElementRef;

  public isNDLoading: boolean = true;
  public isDataSave: boolean = false;
  public form: FormGroup;
  public membersWithRoles = {};
  public members = [];
  public selectedItem = [];
  public searchUserString = "";
  public searchmembers = [];
  public loadingMember: boolean = false;
  dropdownSettings: IDropdownSettings = {};
  public loadingAddMember: boolean = false;
  dropDownForm: FormGroup;
  public myItems: any;
  removedMembers = [];

  constructor(
    private modalService: NgbModal,
    private teamsService: TeamsService,
    public global: GlobalService,
    private toaster: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      /* allowSearchFilter: true */
    };
    this.myItems = [];

    this.removedMembers = [];
    // this.dropDownForm = this.fb.group({
    //   myItems: [this.selectedItems]
    // });


    this.form = new FormGroup(
      {
        teamName: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        optionPrivacy: new FormControl("private")
      }
    );
    this.form.valueChanges.subscribe((val) => { /* //console.log(val) */ })

    // get all members of a team with roles
    this.teamsService.fetchAllTeamMembers(this.global.selectedGroup.Id, this.searchUserString, true).subscribe((res) => {
      if (res.status == 'success') {
        res.data.forEach(member => {
          this.membersWithRoles[member.userId] = member;
        });
      } else if (res.status == 'failed') {
        this.toaster.error("Error in receving team members data.", "Failure", { timeOut: 3000 });
      }
    }, (error) => {
      this.toaster.error("Error in receving team members data.", "Failure", { timeOut: 3000 });
    })

    this.getTeams();

    //this.fetchAllMembers();
    this.nameElement.nativeElement.focus();

  }

  public getTeams() {
    this.teamsService.getTeam(this.global.selectedGroup.Id).subscribe((response) => {
      if (response.status == "success") {
        let team = response.data.team;
        let members = response.data.members || team.members;
        let owners = response.data.owners || team.owners;
        members?.map((i: any) => {
          const userType = i.userType.toLowerCase() == "guest" ? " (Guest)" : "";
          this.members.push({
            ...i,
            displayName: i.displayName + userType,
            role: "Member"
          });
        });
        owners?.map((i: any) => {
          const memberIndex = this.members.findIndex(item => item.role == "Member" && item.id == i.id);
          if (memberIndex > -1) this.members.splice(memberIndex, 1);
          this.members.push({
            ...i,
            displayName: i.displayName,
            role: "Owner"
          });
        });
        this.form.setValue({
          teamName: team.displayName,
          description: team.description,
          optionPrivacy: team.visibility.toLowerCase(),
        });
      } else {
        this.toaster.error("Error in receving team data.", "Failure", { timeOut: 3000 });
      }
      this.isNDLoading = false;

      //this.toaster.success("Team data received.", "Success", { timeOut: 3000 });
    }, (error) => {
      this.isNDLoading = false;
      this.toaster.error("Error in receving team data.", "Failure", { timeOut: 3000 });
    })
  }

  public capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.split(' ').map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  public clearSearch(e) {
    this.searchUserString = ""
  }

  public onClosePopup() {
    this.modalService.dismissAll()
  }
  public goBack() {
    this.onClosePopup();
  }
  public onUpdate(selected = null) {
    if (this.isNDLoading) return;
    this.isNDLoading = true;
    this.isDataSave = true;

    let membersIdList = [];
    let ownersIdList = [];
    this.members.map(member => {
      if (member.isNeedToSave) {
        if (member.role?.toLowerCase() === "owner") {
          if (ownersIdList.indexOf(member.id) == -1) ownersIdList.push(member.id);
        }
        else {
          if (membersIdList.indexOf(member.id) == -1) membersIdList.push(member.id);
        }
      }
    });
    // this.dropDownForm?.value?.myItems.map(m => membersIdList.push(m.id))
    let membersRemoveList = [];
    let ownersRemoveList = [];
    this.removedMembers.map(m => {
      if (m.role?.toLowerCase() === "owner") ownersRemoveList.push(m.id);
      else membersRemoveList.push(m.id);
    })
    this.teamsService.updateTeam({
      teamId: this.global.selectedGroup.Id, teamDescription: this.form.value.description, visibility: this.form.value.optionPrivacy, teamName: this.form.value.teamName,
      newMembers: membersIdList,
      newOwners: ownersIdList,
      removeMembers: membersRemoveList,
      removeOwners: ownersRemoveList
    }).subscribe((response) => {
      if (response.status == "success") {
        let data = {
          teamId: this.global.selectedGroup.Id,
          teamName: response.data.team?.displayName,
          description: response.data.team?.description,
          optionPrivacy: response.data.team?.visibility.toLowerCase(),
        }
        this.updateGroup.emit(data);
        this.toaster.success("Team data updated.", "Success", { timeOut: 3000 });
      } else {
        if (response.error)
          this.toaster.error(response.message, "Failure", { timeOut: 3000 });
        else
          this.toaster.error("Error in updating Team data.", "Failure", { timeOut: 3000 });
      }
      this.isDataSave = false;
      this.isNDLoading = false;
      if (!response.error)
        this.onClosePopup();
    }, (error) => {
      this.isNDLoading = false;
      this.toaster.error("Error in updating Team data.", "Failure", { timeOut: 3000 });
    })

  }
  public setSearchUserString(val) {
    this.fetchAllMembers();
  }
  public fetchAllMembers = () => {
    this.loadingMember = true;
    this.teamsService.fetchAllMembers(this.searchUserString).subscribe((res) => {
      if (res.status == 'success') {
        this.searchmembers = res.data.length > 0 ? res.data.map((i) => { return { id: i.id, name: i.displayName, userType: i.userType } }) : [];
        let result = this.searchmembers.filter(o1 => !this.members.some(o2 => o1.id === o2.id));
        //console.log(result)
        let result2 = this.searchmembers;//result.filter(d => d.userType != "Guest");
        //console.log(result2)
        this.searchmembers = result2;
        //this.openDropdown();
      } else if (res.status == 'failed') {
        this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
      }
      this.loadingMember = false;
    }, (error) => {
      this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
      this.loadingMember = false;
    })
  }




  public addMembers = (selected) => {
    selected.isNeedToSave = true;
    selected.displayName = selected.name;
    this.members.push(selected);
    this.searchUserString = "";
    this.searchmembers = [];
    // if (this.dropDownForm.value.myItems.length == 0 || this.loadingAddMember) return;
    // this.loadingAddMember = true;
    // let membersIdList = [];
    // this.dropDownForm.value.myItems.map(m => membersIdList.push(m.id))
    // this.teamsService.addTeamMember({ teamId: this.global.selectedGroup.Id, members: membersIdList }).subscribe((res) => {
    //   if (res.status == 'success') {
    //     this.toaster.success("Members are added to team.", "Success", { extendedTimeOut: 10 });
    //     this.loadingAddMember = false;
    //     this.modalService.dismissAll(res.data);
    //   } else if (res.status == 'failed') {
    //     this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
    //   }
    //   this.loadingAddMember = false;
    // }, (error) => {
    //   this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
    //   this.loadingAddMember = false;
    // })
  }


  openDropdown() {
    let el = document.getElementsByClassName("dropdown-list")[0] as HTMLElement;
    let el2 = document.getElementsByClassName("dropdown-btn")[0] as HTMLElement;
    el.style.position = "relative";
    if (this.members.length == 0) {
      if (!el.hasAttribute("hidden")) {
        el2.click();
      }
    } else {
      if (el.hasAttribute("hidden")) {
        el2.click();
      }
    }
  }

  public removeMember(member) {
    let filteredMembers = this.members.filter(i => i.id == member.id);
    let retainedMembers = this.members.filter(i => i.id != member.id);
    this.members = retainedMembers;
    this.removedMembers = [...this.removedMembers, ...filteredMembers];
  }

}
