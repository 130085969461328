import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services/global.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { environment } from 'src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'team-groups',
  templateUrl: './team-groups.component.html',
  styleUrls: ['./team-groups.component.scss']
})
export class TeamGroupsComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeLoadTeam: EventEmitter<any> = new EventEmitter<any>();

  public refreshTeam = false;
  @Input("refreshTeamsConnection") set onRefreshTeam(data) {
    this.ndvs.getUserJobsCount();
    if (data) {
      this.nextPageRequest = "";
      this.refreshTeam = data;
      this.teamsSearch = "";
      this.dateSort = 1;
      this.teamsGroups = [];
      this.getTeamsGroups();
    }
  };

  @Input("disconnection") set onDisconnection(data) {
    if (data) {

    }
  };

  public connectionData: any = {};
  @Input("connection") set onChangeConnection(data) {
    this.connectionData = data;
    this.nextPageRequest = "";
    this.getTeamsGroups();
    if (!this.connectionData.isConnected) this.teamsSearch = "";
  };
  @Input("syncChange") set onChangeGroup(data) {
    //console.log("PT::syncChange::data::", data, this.global.selectedGroup)
    if (data && data.syncChange) {
      var index = this.teamsGroups.findIndex(item => item.id == this.global.selectedGroup.id || item.Id == this.global.selectedGroup.id);
      if (index != -1) {
        this.teamsGroups[index] = this.global.selectedGroup;
      }
    }
  };

  public startDeleting: boolean = false;
  public isDeleteAgreed: boolean = false;
  public nextPageRequest: any = "";
  public groupsLoader: boolean = false;
  public teamsGroupRequest: any = null;
  public isTeamsRequestFailed: boolean = false;
  public teamsGroups: any = [];
  public teamsSearch: any = "";
  public selectedKind: string = ""
  public modalPop;
  public seletedGroup;
  public dateSort = 1;
  public loadTeam = false;
  public sort = "";

  //results$: Observable;
  subject = new Subject();
  obs: Subscription;

  constructor(
    private modalService: NgbModal,
    private teamsService: TeamsService,
    public ndvs: NetDocumentViewerService,
    public sanitizer: DomSanitizer, public global: GlobalService,
    private toaster: ToastrService,
  ) {

    this.global.syncCreated.subscribe((data) => {
      //console.log("PT::this.global.syncCreated.subscribe::group::data::", data)
      if (this.teamsGroups.length <= 0) return;
      if (data.from == "channel" && this.global.syncFrom == "tab" && data.status != "updated") {
        var selectedGroup = this.global.selectedGroup
        if (this.global.selectedGroup.teamsSyncData) {
          teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
          teamsSyncData.push({
            _id: data.syncId,
            teams: {
              teamName: this.global.selectedGroup.displayName,
              teamId: this.global.selectedGroup.id,
              channelName: this.global.selectedChannel.displayName,
              channelId: this.global.selectedChannel.id,
              tabName: this.global.selectedTab.DisplayName,
              tabId: this.global.selectedTab.configuration.entityId
            }
          })
          if (data.status == "deleted") {

          } else {

            selectedGroup = { ...this.global.selectedGroup, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify(teamsSyncData) }
          }
        } else {
          teamsSyncData = {
            _id: data.syncId,
            teams: {
              teamName: this.global.selectedGroup.displayName,
              teamId: this.global.selectedGroup.id,
              channelName: this.global.selectedChannel.displayName,
              channelId: this.global.selectedChannel.id,
              tabName: this.global.selectedTab.DisplayName,
              tabId: this.global.selectedTab.configuration.entityId
            }
          }
          selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify([teamsSyncData]) }
        }
        this.global.selectedGroup = selectedGroup;
        let currentTeamIndex = this.teamsGroups.findIndex(team => team.Id == this.global.selectedGroup.Id);
        if (currentTeamIndex !== -1) this.teamsGroups[currentTeamIndex] = this.global.selectedGroup;
        return;
      }
      if (data.from == "tab" && this.global.syncFrom == "tab" && data.status == "deleted") {
        //console.log("this.global.syncCreated====group===data====this.global.syncFrom====this.global.selectedGroup====", data, this.global.syncFrom, this.global.selectedGroup, this.global.selectedTab.configuration.entityId)

        var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
        //console.log("teamsSyncData=====", JSON.parse(JSON.stringify(teamsSyncData)))
        //var index = teamsSyncData.findIndex(i => i.teams.channelId == this.global.selectedChannel.Id)
        var newTeamsSyncData = teamsSyncData.filter(i => i.teams.tabId != this.global.selectedTab.configuration.entityId)
        //var newTeamsSyncData = teamsSyncData;
        /* if (index != -1) {
          var teams = teamsSyncData;
          teams.splice(index, 1);
          newTeamsSyncData = teams;
        } */
        //console.log("newTeamsSyncData=====", newTeamsSyncData)
        var newSelectedGroup = this.global.selectedGroup;
        if (newTeamsSyncData.length == 0) {
          //console.log("newTeamsSyncData====zero")
          newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
        } else {
          newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
        }

        this.global.selectedGroup = newSelectedGroup;

        var index = this.teamsGroups.findIndex(item => item.id == this.global.selectedGroup.id || item.Id == this.global.selectedGroup.id);
        if (index != -1) {
          //console.log("this.global.selectedGroup;=====", this.teamsGroups, this.global.selectedGroup)
          this.teamsGroups[index] = this.global.selectedGroup;
        }


        return;
      }
      if (data.status == "deleted" && this.global.syncFrom == "group") {
        //console.log("deleted=====group")
        let currentTeam = this.teamsGroups.find(team => team.Id == this.global.selectedGroup.Id)
        let currentTeamIndex = this.teamsGroups.findIndex(team => team.Id == this.global.selectedGroup.Id);

        if (currentTeam != undefined) {
          currentTeam.isSyncScheduled = false;
          currentTeam.syncId = "";
          currentTeam.syncData = "";
          currentTeam.teamsSyncData = "";//JSON.stringify([]);
          //currentTeam = { ...currentTeam, teamsSyncData: JSON.stringify([teamsSyncData]) }
        }
        this.global.selectedGroup = currentTeam;
        if (currentTeamIndex !== -1) this.teamsGroups[currentTeamIndex] = currentTeam;

        //this.global.syncFrom = "";
        return;
      }

      if (data.status == undefined && this.global.syncFrom == "channel") {
        return;
      }
      if (data.status == "deleted" && this.global.syncFrom == "channel") {
        //console.log("teams-group-component")
      }
      var teamsSyncData;

      if (data.from == "channel" && this.global.syncFrom == "channel" && data.status != "updated") {
        console.log("deleted=====channel,this.global.selectedGroup, data=====", this.global.selectedGroup, data)
        var selectedGroup = this.global.selectedGroup
        if (this.global.selectedGroup.teamsSyncData) {
          if (data.syncId) {
            teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
            teamsSyncData.push({
              _id: data.syncId,
              teams: {
                teamName: this.global.selectedGroup.displayName,
                teamId: this.global.selectedGroup.id,
                channelName: this.global.selectedChannel.displayName,
                channelId: this.global.selectedChannel.id
              }
            })
          }
          if (data.status == "deleted") {


            var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
            var newTeamsSyncData = teamsSyncData.filter(i => i.teams.channelId != this.global.selectedChannel.Id)
            var newSelectedGroup = this.global.selectedGroup;
            if (newTeamsSyncData.length == 0) {
              //console.log("newTeamsSyncData====zero")
              newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
            } else {
              newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
            }

            this.global.selectedGroup = newSelectedGroup;
            //selectedGroup = newSelectedGroup;
            var index = this.teamsGroups.findIndex(item => item.id == this.global.selectedGroup.id || item.Id == this.global.selectedGroup.id);
            if (index != -1) {
              //console.log("this.global.selectedGroup;=====", this.teamsGroups, this.global.selectedGroup)
              this.teamsGroups[index] = this.global.selectedGroup;
            }
            return;
          } else {
            selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify(teamsSyncData) }
          }
        } else {
          teamsSyncData = {
            _id: data.syncId,
            teams: {
              teamName: this.global.selectedGroup.displayName,
              teamId: this.global.selectedGroup.id,
              channelName: this.global.selectedChannel.displayName,
              channelId: this.global.selectedChannel.id
            }
          }
          selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify([teamsSyncData]) }
        }
        this.global.selectedGroup = selectedGroup;
        let currentTeamIndex = this.teamsGroups.findIndex(team => team.Id == this.global.selectedGroup.Id);
        //console.log("currentTeamIndex,this.global.selectedGroup;=====", this.teamsGroups, currentTeamIndex, this.global.selectedGroup)
        if (currentTeamIndex !== -1) this.teamsGroups[currentTeamIndex] = this.global.selectedGroup;
      }
      if (data.syncId != undefined && this.global.syncFrom == "group") {
        let currentTeam = this.teamsGroups.find(team => team.Id == this.global.selectedGroup.Id)
        let currentTeamIndex = this.teamsGroups.findIndex(team => team.Id == this.global.selectedGroup.Id)
        if (currentTeam != undefined) {
          currentTeam.isSyncScheduled = true;
          currentTeam.syncId = data.syncId;
          currentTeam.syncData = data.syncId;
          teamsSyncData = {
            _id: data.syncId,
            teams: {
              teamName: this.global.selectedGroup.displayName,
              teamId: this.global.selectedGroup.id,
              channelName: "",
              channelId: ""
            }
          }
          currentTeam = { ...currentTeam, teamsSyncData: JSON.stringify([teamsSyncData]) }
        }
        this.global.selectedGroup = currentTeam;
        if (currentTeamIndex !== -1) this.teamsGroups[currentTeamIndex] = currentTeam;
      }
      //this.global.syncFrom = "";
    })
  }
  disableKeyPress(event: KeyboardEvent) {
    // Check if the key pressed is Enter (keyCode 13) or Space (keyCode 32)
    //if (event.keyCode === 13 || event.keyCode === 32) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior (key press) for Enter or Space
    }
  }
  ngOnInit(): void {


    this.obs = this.subject.pipe(
      debounceTime(1000)
    ).subscribe(data => {
      this.teamsSearch = data;
      this.nextPageRequest = "";
      this.teamsGroups = [];
      this.getTeamsGroups("fromSearch")

    });
    //console.log("this.ndvs===", this.ndvs)
  }

  ngOnDestroy() {
    this.obs.unsubscribe()
  }

  public getNetDocsCabinet(group) {
    if (group.isSyncScheduled)
      var hasCabinet = false;
    if (group.teamsSyncData != undefined)
      if (JSON.parse(group.teamsSyncData)[0].netDocuments?.cabinetId) {
        hasCabinet = true;
      } else if (!JSON.parse(group.teamsSyncData)[0].netDocuments) {
        hasCabinet = true;
      }
    return hasCabinet;
  }


  public onShowCreateOption(modal) {
    //console.log("PT::onShowCreateOption::")
    if (this.connectionData.isConnected == undefined && !this.connectionData.isConnected) return;
    this.modalService.open(modal, { backdrop: true }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public onChangeSearch(e) {
    this.subject.next(e)/*  */
  }

  public onFetchMore() {
    if (this.nextPageRequest) this.getTeamsGroups("loadMore")
  }

  public sortAcending() {
    this.sort = "asc";
    this.teamsGroups = [];
    this.nextPageRequest = "";
    this.getTeamsGroups();
    //this.teamsGroups = this.teamsGroups.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
  }
  public sortDecending() {
    this.sort = "desc";
    this.teamsGroups = [];
    this.nextPageRequest = "";
    this.getTeamsGroups();
    //this.teamsGroups = this.teamsGroups.sort((a, b) => b.DisplayName.localeCompare(a.DisplayName));
  }
  public clearSorting() {
    this.sort = "";
    this.teamsGroups = [];
    this.nextPageRequest = "";
    this.getTeamsGroups();
  }
  public sortByDate() {
    this.dateSort = this.dateSort == 1 ? 0 : 1;
    if (this.dateSort == 0)
      this.teamsGroups.sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
    else this.teamsGroups.sort((b, a) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
  }

  public setModalPop(modal) {
    this.modalPop = modal;
  }

  private getTeamsGroups(initiateFrom = "") {
    this.loadTeam = true;
    if (initiateFrom != "fromSearch" && initiateFrom != "loadMore") this.changeLoadTeam.emit(this.loadTeam);

    if (this.connectionData._id == undefined) {
      this.teamsGroupRequest = null;
      this.teamsService.unsubscribeGetTeamsGroups();
      this.groupsLoader = false;
      this.teamsGroups = [];
      return;
    }
    this.isTeamsRequestFailed = false;
    this.groupsLoader = true;
    this.teamsGroupRequest = this.teamsService.getTeamsGroups(this.connectionData._id, this.nextPageRequest, this.teamsSearch, this.sort).subscribe((resData) => {
      //console.log("PT::getTeamsGroups::resData::", resData)
      this.groupsLoader = false;
      this.nextPageRequest = resData.skipToken;
      if (resData.status == "success") {
        if (initiateFrom != "" && initiateFrom == "fromSearch") this.teamsGroups = [];
        var data = resData.data.map(d => { return { ...d, DisplayName: d.displayName, Id: d.id, displayName: d.displayName, id: d.id } })
        if (resData.addOn) {
          this.global.haveSyncAddOn = resData.addOn.syncAddOn || false;
          this.global.haveTeamsAppAddOn = resData.addOn.teamsAppAddOn || false;
          //console.log(this.global.haveSyncAddOn, this.global.haveTeamsAppAddOn)
        }
        this.teamsGroups = [...this.teamsGroups, ...data];
        this.dateSort = 1;
        this.loadTeam = false;
        this.refreshTeam = false;
        this.changeLoadTeam.emit(this.loadTeam);
      } else {
        this.teamsGroups = [];
        this.isTeamsRequestFailed = true;
        this.loadTeam = false; this.refreshTeam = false;
        this.changeLoadTeam.emit(this.loadTeam);
      }
      if (initiateFrom != "loadMore") this.onSelectTeam(this.teamsGroups[0]);
    }, (error) => {
      this.teamsGroups = [];
      this.isTeamsRequestFailed = true;
      this.groupsLoader = false;
      this.loadTeam = false; this.refreshTeam = false;
      this.changeLoadTeam.emit(this.loadTeam);
    })
  }

  public checkSelection(group) {
    return (group.id == this.global.selectedGroup.id) ? true : false
  }

  public onSelectTeam(group) {
    if (group)
      this.change.emit(JSON.parse(JSON.stringify(group)));
    else
      this.change.emit({});
    window.scrollTo(0, 0);
    //console.log("END")
  }

  public selectedType(type: string, modal) {
    this.modalPop = modal;
    this.selectedKind = type;
    if (type == "new" || type == "existing" || type == "fromtemplate")
      this.modalService.dismissAll()
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }
  public createGroup(teamId) {
    this.teamsGroups.unshift(teamId);
    this.change.emit(JSON.parse(JSON.stringify(teamId)));
    this.ndvs.getUserJobsCount();
    //console.log(this.teamsGroups)
  }

  public onTeamUpdate(data) {
    let index = this.teamsGroups.findIndex(i => i.Id == data.teamId);
    let updatedTeam = { ...this.teamsGroups[index], DisplayName: data.teamName, displayName: data.teamName }
    this.teamsGroups[index] = updatedTeam;
    this.global.selectedGroup = updatedTeam;
  }

  public syncDisable(group) {
    var found = false;
    //if (this.getNetDocsCabinet(group)) return found;
    if (group.channelSync) return true;
    if (group.syncData && group.teamsSyncData) {
      found = JSON.parse(group.teamsSyncData).find(e => e.teams.channelId != '');
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }
  public closeModal() {

    this.startDeleting = false;
    this.isDeleteAgreed = false;
    this.modalService.dismissAll()
  }

  public onConfigureSync(e, team, errorPop) {
    //console.log("this.ndvs.syncUrl===e====", e.target.parentElement.id);
    //console.log("channelCreationSync===this.global.===", team, JSON.stringify(this.global.selectedGroup))
    if (this.global.maxSyncJobCreated && e.target.parentElement.id != "btn") return;
    this.global.syncFrom = "group";
    if (errorPop) {
      this.modalService.open(errorPop, { backdrop: true }).result.then((res) => {

      }, (reason) => {

      }).catch((error) => {

      })
      return;
    }
    let teamData = { ...team, syncId: team.syncData }

    //let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? "https://syncbeta.netdocshare.com/#/" : environment.syncUrl + "/#/";
    let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? environment.syncUrl + "/#/" : environment.syncUrl + "/#/";
    this.ndvs.syncDataId = teamData.syncId;
    this.ndvs.syncUrl = (teamData.syncId != undefined && teamData.syncId != null && teamData.syncId != "") ? syncUrl + "editjob/" + teamData.syncId : syncUrl + "createjobs";
    this.ndvs.syncUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ndvs.syncUrl);
    this.ndvs.showSyncPopup = true;
    this.onSelectTeam(teamData);
  }


  public onDuplicate(group, modal) {
    this.modalPop = modal;
    this.seletedGroup = group;
    this.selectedType("existing", this.modalPop)
  }
  public toTemplate(group, modal) {
    this.modalPop = modal;
    this.seletedGroup = group;
    this.selectedType("template", this.modalPop)
  }

  public onEditTeam(group, modal) {
    this.seletedGroup = group;
    this.global.selectedGroup = this.seletedGroup;
    this.modalPop = modal;
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }


  public onDeleteTeam(group, modal) {
    this.seletedGroup = group;
    this.global.selectedGroup = this.seletedGroup;
    this.modalPop = modal;
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {

    }, (reason) => {

    }).catch((error) => {

    })
  }

  public deleteTeam() {
    if (this.deleteDisable()) return;
    this.startDeleting = true;
    this.teamsService.deleteTeam(this.global.selectedGroup.Id).subscribe((response) => {
      if (response.status == "success") {
        this.teamsGroups = this.teamsGroups.filter(i => i.Id != this.global.selectedGroup.Id);
        this.seletedGroup = this.teamsGroups[0];
        if (this.seletedGroup) {
          this.global.selectedGroup = this.seletedGroup;
          this.change.emit(JSON.parse(JSON.stringify(this.seletedGroup)));
        } else {
          this.change.emit(JSON.parse(JSON.stringify({})));
        }
        this.toaster.success("Team deleted.", "Success", { timeOut: 3000 });
      } else {
        this.toaster.error("Error in deleting team.", "Failure", { timeOut: 3000 });
      }
      this.closeModal();
      this.startDeleting = false;
      this.isDeleteAgreed = false;
    }, (error) => {
      this.toaster.error("Error in deleting team.", "Failure", { timeOut: 3000 });
      this.closeModal();
      this.startDeleting = false;
      this.isDeleteAgreed = false;
    })
  }

  public deleteDisable() {
    return !this.isDeleteAgreed || this.startDeleting;
  }

  public agreeDelete(e) {
    this.isDeleteAgreed = e.target.checked;
  }

}
