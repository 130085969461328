import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { TeamsService } from 'src/app/services/teams.service';
import { environment } from 'src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { HelperService } from 'src/app/services/helper';

@Component({
  selector: 'team-channels',
  templateUrl: './team-channels.component.html',
  styleUrls: ['./team-channels.component.scss']
})
export class TeamChannelsComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() syncChange: EventEmitter<any> = new EventEmitter<any>();

  @Input("onSyncChannel") set onSyncChannelChange(data) {

  };
  @Input("refreshTeamsConnection") set onRefreshTeam(data) {
    if (data) {
      this.dateSort = 1;
      this.channelSearch = "";
      this.channels = [];
      this.nextPageRequest = "";
      this.sort = "";
    }
  };

  @Input("disconnection") set onDisconnection(data) {
    if (data) {

    }
  };

  public connectionData: any = {};
  @Input("connection") set onChangeConnection(data) {
    this.connectionData = data;
    if (!this.connectionData.isConnected) this.channelSearch = "";
  };

  public selectedGroup: any = {};
  @Input("group") set onChangeGroup(data) {
    this.selectedGroup = data;
    if (this.selectedGroup.Id != undefined) {
      this.nextPageRequest = "";
      this.channelSearch = "";
      this.sort = "";
      this.getChannels();
    } else {
      if (this.teamsChannelsRequest != null) this.teamsChannelsRequest.unsubscribe();
      this.teamsChannelsRequest = null;
      this.teamsService.unsubscribeGetTeamsChannels();
      this.channelsLoader = false;
      this.channels = [];
    }
  };

  public isChannelsRequestFailed: boolean = false;
  public channelsLoader: boolean = false;
  public teamsChannelsRequest: any = null;
  public channels: any = [];
  public channelSearch: any = "";
  public isChannelDeleteLoader: boolean = false;
  public isChannelRemoveLoader: boolean = false;
  public syncToRemove: any = null;
  public nextPageRequest = "";
  public sort = "";

  //results$: Observable;
  subject = new Subject();
  obs: Subscription;

  private requestSyncCreateStatus: any = null;
  public dateSort = 1;

  constructor(
    private teamsService: TeamsService,
    private modalService: NgbModal,
    public global: GlobalService,
    private toaster: ToastrService,
    private ndvs: NetDocumentViewerService,
    public sanitizer: DomSanitizer
  ) {
    //if (this.requestSyncCreateStatus != null) this.global.syncCreated.unsubscribe();
    //console.log("this.global.syncCreated====constructor channels====")
    this.global.syncCreated.subscribe((data) => {

      console.log("this.global.syncCreated====channel===data====this.global====", data, JSON.parse(JSON.stringify(this.global)))

      if (data.status == "deleted" && (this.global.syncFrom == "channel")) {//|| this.global.syncFrom == "tab"
        var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);
        //var index = teamsSyncData.findIndex(i => i.teams.channelId == this.global.selectedChannel.Id)
        var newTeamsSyncData = teamsSyncData.filter(i => i.teams.channelId != this.global.selectedChannel.Id)
        //var newTeamsSyncData = teamsSyncData;
        /* if (index != -1) {
          var teams = teamsSyncData;
          teams.splice(index, 1);
          newTeamsSyncData = teams;
        } */

        var newSelectedGroup = this.global.selectedGroup;
        if (newTeamsSyncData.length == 0) {
          newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
        } else {
          newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
        }

        this.global.selectedGroup = newSelectedGroup;
        this.syncChange.emit({ "syncChange": true });

      }
      if (data.syncId != undefined) {
        let currentChannel = this.channels.find(channel => channel.Id == this.global.selectedChannel.Id)
        if (currentChannel != undefined && this.global.syncFrom == "channel") {
          currentChannel.isSyncScheduled = true;
          currentChannel.syncId = data.syncId;

          let teamsSyncData = this.global.selectedGroup.teamsSyncData;
          if (teamsSyncData == undefined || teamsSyncData == null || teamsSyncData.trim() == "") {
            this.global.selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncId, teamsSyncData: JSON.stringify([{ _id: data.syncId, teams: { teamId: this.global.selectedGroup.Id, channelId: this.global.selectedChannel.Id } }]) };
          } else {
            var teamsSyncDataJson = JSON.parse(teamsSyncData);
            teamsSyncDataJson.push({ _id: data.syncId, teams: { teamId: this.global.selectedGroup.Id, channelId: this.global.selectedChannel.Id } })
            this.global.selectedGroup = { ...this.global.selectedGroup, syncData: data.syncId, teamsSyncData: JSON.stringify(teamsSyncDataJson) };
          }

          this.syncChange.emit({ "syncChange": true });
        }
      }
    })

    //On creating sync in channel updating the selected group
    this.global.channelCreationSync.subscribe((data) => {
      //console.log("PT::this.global.channelCreationSync::data::", data)
      let teamsSyncData = this.global.selectedGroup.teamsSyncData;
      if (teamsSyncData == undefined || teamsSyncData == null || teamsSyncData.trim() == "") {
        this.global.selectedGroup = { ...this.global.selectedGroup, channelSync: true, isSyncScheduled: true, syncData: data.syncData, teamsSyncData: data.teamsSyncData };
      } else {
        var teamsSyncDataJson = JSON.parse(teamsSyncData);
        teamsSyncDataJson.push(JSON.parse(data.teamsSyncData)[0])
        this.global.selectedGroup = { ...this.global.selectedGroup, syncData: data.syncId, teamsSyncData: JSON.stringify(teamsSyncDataJson) };
      }
      //this.global.selectedGroup = { ...this.global.selectedGroup, ...data };
      this.syncChange.emit({ "syncChange": true });
    })

  }

  ngOnInit(): void {

    this.obs = this.subject.pipe(
      debounceTime(1000)
    ).subscribe(data => {
      this.channels = [];
      this.channelSearch = data;
      this.nextPageRequest = "";
      this.getChannels("fromSearch")

    });
    //console.log("this.ndvs===", this.ndvs)
  }

  ngOnDestroy() {
    this.obs.unsubscribe()
  }

  public onChangeSearch(e) {
    this.subject.next(e)/*  */
  }

  public onFetchMore() {
    if (this.nextPageRequest) this.getChannels("loadMore")
  }

  public sortAcending() {
    this.sort = "asc";
    /* this.channels = [];
    this.nextPageRequest = "";
    this.getChannels(); */
    this.channels = this.channels.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
  }
  public sortDecending() {
    this.sort = "desc";
    /* this.channels = [];
    this.nextPageRequest = "";
    this.getChannels(); */
    this.channels = this.channels.sort((a, b) => b.DisplayName.localeCompare(a.DisplayName));
  }

  public clearSorting() {
    this.sort = "";
    this.channels = [];
    this.nextPageRequest = "";
    this.getChannels();
  }
  public sortByDate() {
    this.dateSort = this.dateSort == 1 ? 0 : 1;
    if (this.dateSort == 0)
      this.channels.sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
    else this.channels.sort((b, a) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime());
  }


  private getChannels(initiateFrom = "") {
    if (initiateFrom !== "loadMore") this.channels = [];
    if (this.selectedGroup.Id == undefined) return
    this.isChannelsRequestFailed = false;
    this.channelsLoader = true;
    if (this.teamsChannelsRequest != null) this.teamsChannelsRequest.unsubscribe();
    this.teamsChannelsRequest = this.teamsService.getTeamsChannels(this.selectedGroup.Id, this.nextPageRequest, this.channelSearch, "").subscribe((resData) => {
      this.channelsLoader = false;
      this.nextPageRequest = resData.skipToken;
      if (resData.status == "success") {
        var data = resData.data.map(d => {
          return {
            ...d, DisplayName: d.displayName, Id: d.id, Description: d.description
          }
        })
        this.channels = data;
        //if (data.length == 1) {
        //this.onSelectChannel(data.filter(i => i.DisplayName == "General")[0])
        this.onSelectChannel(data[0])
        //}
        this.dateSort = 1;
      } else {
        this.isChannelsRequestFailed = true;
      }
    }, (error) => {
      this.isChannelsRequestFailed = true;
      this.channelsLoader = false;
    })

    this.ndvs.getUserJobsCount();
  }

  /* public isTeamSync() {
    return (this.global.selectedGroup.isSyncScheduled && !this.global.selectedGroup.channelSync) ? true : false;
  } */

  public isTeamSync() {
    if (this.global.selectedGroup.isSyncScheduled && this.global.selectedGroup.channelSync == false) return true;
    return (this.global.selectedGroup.isSyncScheduled && this.global.selectedGroup.syncId) ? true : false;
  }

  public onSelectChannel(channel) {
    this.change.emit(JSON.parse(JSON.stringify(channel)));
    window.scrollTo(0, 0);
  }

  public onEditChannel(modal, channelData, channelIndex) {
    this.global.isChannelEdit = true;
    this.change.emit(JSON.parse(JSON.stringify(channelData)));
    setTimeout(() => {
      this.onShowCreateChannel(modal, true);
    }, 500)
  }

  public onShowCreateChannel(modal, isChannelEdit = false) {
    if (this.connectionData.isConnected == undefined || !this.connectionData.isConnected || this.selectedGroup.Id == undefined) return;
    this.global.isChannelEdit = isChannelEdit;
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {
      this.global.isChannelEdit = false;
    }, (channelData) => {
      if (channelData != undefined) {
        this.global.isChannelEdit = false;
        let editedData = this.channels.find(chData => chData.Id == channelData.Id);
        if (editedData == undefined || editedData == null) {
          this.channels.push({ ...channelData, createdDateTime: HelperService.getISOTimeNow() });
          this.onSelectChannel({ ...channelData, createdDateTime: HelperService.getISOTimeNow() });
        } else {
          editedData.DisplayName = channelData.DisplayName;
          editedData.Description = channelData.Description;
        }

      }
    }).catch((error) => {
      this.global.isChannelEdit = false;
    })
  }

  public onDeleteChannel(modal, channelData, channelIndex) {
    this.change.emit(JSON.parse(JSON.stringify(channelData)));
    this.modalService.open(modal, { backdrop: false }).result.then((res) => {
    }, (result) => {
    }).catch((error) => {

    })
  }

  public onDeleteConfirm(modal) {
    if (this.isChannelDeleteLoader) return;
    this.isChannelDeleteLoader = true;
    let sendData: any = {}
    sendData.connectionId = this.global.connectionData._id;
    sendData.teamId = this.global.selectedGroup.Id;
    sendData.channelId = this.global.selectedChannel.Id;
    this.teamsService.deleteChannel(sendData).subscribe((resData) => {
      if (resData.status == "success") {
        this.toaster.success("Channel successfully removed", "Success", { extendedTimeOut: 10 });

        if (this.global.selectedGroup.teamsSyncData != undefined && this.global.selectedGroup.teamsSyncData.trim() != "") {
          var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);


          //var index = teamsSyncData.findIndex(i => i.teams.channelId == this.global.selectedChannel.Id)
          //var newTeamsSyncData = teamsSyncData;

          var newTeamsSyncData = teamsSyncData.filter(i => i.teams.channelId != this.global.selectedChannel.Id)
          /* if (index != -1) {
            var teams = teamsSyncData;
            teams.splice(index, 1);
            newTeamsSyncData = teams;
          } */
          var newSelectedGroup = this.global.selectedGroup;
          if (newTeamsSyncData.length == 0) {
            newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
          } else {
            newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
          }

          this.global.selectedGroup = newSelectedGroup;
          this.syncChange.emit({ "syncChange": true });
          this.syncToRemove = null;
        }

        let deleteIndex = this.channels.findIndex(chData => chData.Id == this.global.selectedChannel.Id);
        if (deleteIndex >= 0) {
          this.channels.splice(deleteIndex, 1);
          this.global.selectedChannel = {};
          this.global.selectedTab = {};
        }


        this.onSelectChannel(this.channels.filter(i => i.DisplayName == "General")[0])

      } else {
        this.toaster.warning(resData.message, "Failed", { extendedTimeOut: 10 });
      }
      this.isChannelDeleteLoader = false;
      modal.close();
    }, (error) => {
      this.isChannelDeleteLoader = false;
      modal.close();
    })

  }

  public closeModal() {
    this.modalService.dismissAll()
  }
  public onRemoveChannelSync(modal, channelData) {
    this.syncToRemove = channelData;
    this.modalService.open(modal, { backdrop: true }).result.then((res) => {
    }, (result) => {
    }).catch((error) => {

    })
  }
  public onRemoveConfirm(modal) {
    this.isChannelRemoveLoader = true;

    this.teamsService.RemoveChannelSync(this.syncToRemove.syncData).subscribe((resData) => {
      this.isChannelRemoveLoader = false;
      if (resData.status == "success") {
        var teamsSyncData = JSON.parse(this.global.selectedGroup.teamsSyncData);

        //var index = teamsSyncData.findIndex(i => i.teams.channelId == this.syncToRemove.id)
        //var newTeamsSyncData = teamsSyncData;
        var newTeamsSyncData = teamsSyncData.filter(i => i.teams.channelId != this.syncToRemove.id)
        /* if (index != -1) {
          var teams = teamsSyncData;
          teams.splice(index, 1);
          newTeamsSyncData = teams;
        } */
        var newSelectedGroup = this.global.selectedGroup;
        if (newTeamsSyncData.length == 0) {
          newSelectedGroup = { ...this.global.selectedGroup, channelSync: false, teamsSyncData: "", syncData: "", isSyncScheduled: false }
        } else {
          newSelectedGroup = { ...this.global.selectedGroup, teamsSyncData: JSON.stringify(newTeamsSyncData) }
        }

        this.global.selectedGroup = newSelectedGroup;
        this.syncChange.emit({ "syncChange": true });
        this.syncToRemove = null;
        this.toaster.success("Channel sync job removed.", "Success", { extendedTimeOut: 10 });
      } else {
        this.toaster.warning(resData.message, "Failed to remove Channel sync job.", { extendedTimeOut: 10 });
      }
    }, (error) => {
      this.toaster.warning(error.message, "Failed to remove Channel sync job.", { extendedTimeOut: 10 });
    })
    this.isChannelRemoveLoader = false;
    modal.close();
  }

  public getNetDocsCabinet(channel) {
    var hasCabinet = false;
    if (JSON.parse(channel.teamsSyncData).netDocuments?.cabinetId) {
      hasCabinet = true;
    }
    return hasCabinet;
  }

  public tabSync(channel) {
    return (channel.tabId != null && channel.tabId != undefined && channel.tabId != "") ? true : false;
  }

  public onTabSyncError(e, channel, erroPop) {
    this.modalService.open(erroPop, { backdrop: true }).result.then((res) => {
    }, (result) => {
    }).catch((error) => {

    })
  }

  public onConfigureSync(e, channel, erroPop) {

    if (this.global.maxSyncJobCreated && e.target.parentElement.id != "btn") return;
    var cabinetId = channel.teamsSyncData && JSON.parse(channel.teamsSyncData).netDocuments?.cabinetId;
    this.global.syncFrom = "channel";
    var found = false;
    console.log("this.global.selectedGroup.teamsSyncData====", this.global.selectedGroup.teamsSyncData)
    if (this.global.selectedGroup.teamsSyncData) {
      found = JSON.parse(this.global.selectedGroup.teamsSyncData).find(e => e.teams.channelId != '');
      console.log("this.global.selectedGroup.teamsSyncData==found==", found)
      if (found == undefined) found = true;
    } else {
      found = true;
      console.log("this.global.selectedGroup.teamsSyncData==found==", found)
      //return;
    }
    console.log("found==found==", found)
    if (!found) {
      this.modalService.open(erroPop, { backdrop: true }).result.then((res) => {
      }, (result) => {
      }).catch((error) => {

      })
      return;
    } else {
      //return;
    }
    let channelData = {
      ...channel, syncId: channel.syncData, isSyncScheduled: channel.isSyncScheduled, syncData: channel.syncData, cabinetId: cabinetId
    }
    //let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? "https://syncbeta.netdocshare.com/#/" : environment.syncUrl + "/#/";
    let syncUrl = (window.location.origin.indexOf("localhost") >= 0) ? environment.syncUrl + "/#/" : environment.syncUrl + "/#/";
    this.ndvs.syncDataId = channelData.syncId;
    this.ndvs.syncUrl = (channelData.syncId != undefined && channelData.syncId != null && channelData.syncId != "") ? syncUrl + "editjob/" + channelData.syncId : syncUrl + "createjobs";
    this.ndvs.syncUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ndvs.syncUrl);
    this.ndvs.showSyncPopup = true;
    this.onSelectChannel(channelData);

    // setTimeout(()=>{
    //   let container:any=document.getElementById('SyncContainerFrame');
    //   let targetWindow = container.contentWindow;    
    //   let messageData={ndsApp:localStorage.getItem("ndsApp"),access_token:localStorage.getItem("access_token"), license_key:localStorage.getItem("access_token")}
    //   targetWindow.postMessage(messageData)
    // },200)
  }


}
