<footer>
    <div class="align-items-center d-flex nds-footer-bottom justify-content-center">
        <ul class="nds-footer-bottom-ul fs-14">
            <li class="fw-bold">
                <span class="text-black-50">Copyright © 2021 KLoBot Inc., All Rights Reserved </span>
            </li>
            <li>
                <span class="link_text" (click)="ndvs.onShowBrowser('https://netdocshare.com/privacy-policy/')">
                Privacy Policy
                </span>
            </li>
            <li>
                <span class="link_text" (click)="ndvs.onShowBrowser('https://netdocshare.com/terms-of-use/')" >
                Terms of Use
                </span>
            </li>
        </ul>
    </div>
</footer>