<app-container-top></app-container-top>
<div class="bulk-upload">
  <div class="main-container p-2">
    <div class="inner-container grey-bg middle-section-height">
      <section class="col-12 z-depth-1 rounded-2 bg-white main-section">
        <div class="ip-container-header border-bottom p-2 d-flex justify-content-between align-items-center">
          <b class="ip-container-header-title">Bulk Team Creation</b>
          <div class="d-flex">

            <button
              type="button"
              class="btn btn-blue"
              placement="right"
              [disabled]="false"
              (click)="openTabConfig(modalTabConfig)">
              Tab Config
            </button>

            <button *ngIf="global.haveSyncAddOn"
              type="button"
              class="btn btn-blue"
              placement="right"
              [disabled]="false"
              (click)="openSyncConfig(modalSyncConfig)">
              Sync Config
            </button>
            <button
              type="button"
              class="btn btn-blue"
              placement="right"
              (click)="templateExcelExport($event)"
              [disabled]="downloadProgress">
              <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
                <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z"/>
              </svg>
              {{!downloadProgress?"Export Template":"Exporting Template..."}}
            </button>
            <button
              type="button"
              class="btn btn-blue"
              placement="left"
              (click)="goHome()">
              <span class="material-icons-sharp md-home"></span>
            </button>
          </div>
        </div>

        <div class="mt-3">
          <div
            class="drop-area text-center"
            *ngIf="excelData.length<=0 && !excelProcess"
            (dragover)="onFileDragOver($event)"
            (drop)="onFileDropSuccess($event)">
            <div>
              <div class="text-center">Drag & Drop file here</div>
              <div class="text-center">or</div>
              <div class="text-center">
                <button
                  type="button"
                  class="browse-files text-center"
                  (click)="onBrowseFile(elenentFileUpload)"
                  placement="center">
                  Browse Files
                </button>
              </div>

            </div>
            <input
              #elenentFileUpload
              id="elenentFileUpload"
              (change)="onFileChange($event)"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden>
          </div>
          <div
            *ngIf="(excelProcess)"
            class="text-center process-loader">
            Importing Excel
            <img
              src="./assets/images/Spinner.gif"
              alt="Loader"
              class="button-loader">
          </div>
          <div
            *ngIf="(selectedFile.name!=undefined)"
            class="d-flex">
            <div
              class="alert alert-info px-3 py-1 my-2 w-100 d-flex align-items-center"
              role="alert">
              <div class="w-100">{{selectedFile.name}}</div>
              <button
                type="button"
                class="btn btn-blue btn-upload{{(isUploadProcess) ? 'show-loader':''}}"
                (click)="onValidateSettings()"
                [disabled]="(isUploadProcess)">
                Upload
                <img
                  *ngIf="(isUploadProcess)"
                  src="./assets/images/Spinner.gif"
                  alt="Loader"
                  class="button-loader">
              </button>
            </div>
          </div>
          <div
            *ngIf="(fileError!='')"
            class="alert alert-danger px-3 py-1 my-2"
            role="alert">
            {{fileError}}
          </div>
          <div class="my-2">
            <div
              *ngIf="(excelData.length>0)"
              class="text-center mb-2 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  Valid Data: {{validJsonData.length}}/{{jsonData.Sheet1.length}}
                </div>
                <button
                  *ngIf="validJsonData.length>0 && !processComplete"
                  class="btn btn-blue {{!processComplete?'':'btn-green'}}"
                  [disabled]="bulkUploadProcess||processComplete"
                  (click)="startBulkUpload($event)">
                  {{!processComplete?"Process Data":"Process Complete"}}
                </button>
                <div
                  *ngIf="processComplete"
                  class="btn-green p-2">
                  {{"Process Complete"}}
                </div>
              </div>

              <div *ngIf="bulkUploadProcess">
                Processed Data: {{this.processIndex}}/{{this.validJsonData.length}}
              </div>

              <button
                type="button"
                [disabled]="bulkUploadProcess"
                class="btn btn-blue btn-upload{{(isUploadProcess) ? 'show-loader':''}}"
                (click)="reUpload()">
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
                  <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z"/>
                </svg>
                Upload Excel
              </button>
            </div>

            <div
              class="btn-red"
              *ngIf="bulkUploadProcess">
              Note: Navigating away from this page will stop the bulk creation process.
            </div>
            <div class="table-outter wrapper fixTableHead">
              <table
                *ngIf="(excelData.length>0) && showStatusProgress"
                class="table table-bordered bg-white"
                id="header-fixed">
                <thead class="thead-light">
                  <tr>
                    <th class="width-1">Sl.No</th>
                    <th class="width-2">ID Ref</th>
                    <th class="width-3">Team Name</th>
                    <th class="width-4">Team Description</th>
                    <th class="width-5">Team Privacy</th>
                    <th class="width-6">Channel Name</th>
                    <th class="width-7">Channel Description</th>
                    <th class="width-8">Channel Privacy</th>
                    <th class="width-9">Tab Name</th>
                    <th class="width-12">Data</th>
                    <th class="width-13">Reason</th>
                    <th class="width-10">Team Status</th>
                    <th class="width-11">Channel Status</th>
                    <th class="width-10">Tab Status</th>
                    <th class="width-11">Sync Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let excelRow of excelData">
                    <td class="width-1">{{excelRow.row}}</td>
                    <td class="width-2">{{excelRow.IDRef}}</td>
                    <td class="width-3">{{excelRow.TeamName}}</td>
                    <td class="width-4">{{excelRow.TeamDescription}}</td>
                    <td class="width-5">{{excelRow.TeamPrivacy}}</td>
                    <td class="width-6">{{excelRow.ChannelName}}</td>
                    <td class="width-7">{{excelRow.ChannelDescription}}</td>
                    <td class="width-8">{{excelRow.ChannelPrivacy}}</td>
                    <td class="width-9">{{excelRow.TabName}}</td>
                    <td class="width-12">
                      {{(excelRow.isDataInvalid) ? 'Invalid' : 'Valid' }}
                    </td>
                    <td class="width-13">{{excelRow.reason }}</td>
                    <td class="width-10 {{statusColor(excelRow.gStatus)}}">
                      {{toFirstLetterCase(excelRow.teamStatus)}}
                    </td>
                    <td class="width-11 {{statusColor(excelRow.cStatus)}}">
                      {{toFirstLetterCase(excelRow.channelStatus)}}
                    </td>
                    <td class="width-10 {{statusColor(excelRow.tStatus)}}">
                      {{toFirstLetterCase(excelRow.tabStatus)}}
                    </td>
                    <td class="width-11 {{statusColor(excelRow.sStatus)}}">
                      {{toFirstLetterCase(excelRow.syncStatus)}}
                    </td>
                    <!-- green color:text-success, red color:text-danger, orange color:text-amber -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- UPLOAD BULK EXCEL -->

<!-- Tab Config Modal -->
<ng-template
  #modalTabConfig
  let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">Tab Config JSON Structure</h4>
  </div>
  <div class="modal-body">
    <div class="my-2">
      <textarea
        class="w-100 h-200"
        id="tab-config">
        {
            "viewType": "RecentDoc",
            "showLogo": true,
            "showLogout": false,
            "showSearchBox": true,
            "columnProperties": [],
            "contextMenu": [],
            "filters": [],
            "CDNBaseUrl": "",
            "assetPath": "",
            "AuthSuffix": "",
            "netDocsBaseUrl": "",
            "authPage": "",
            "menuSize": "s",
            "clickBehavior": "openND",
            "searchBehavior": "searchAll",
            "mandatoryEditProfile": false,
            "searchIn": "everything",
            "showWorkspaceName": true,
            "teamsPost": false,
            "teamsPostComments": false
        }
      </textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.close('cancel')">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-blue"
      (click)="copyTabConfig()">
      Copy to Clipboard
    </button>
  </div>
</ng-template>

<!-- Sync Config Modal -->

<ng-template
  #modalSyncConfig
  let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">Sync Config JSON Structure</h4>
  </div>
  <div class="modal-body">
    <div class="my-2">
      <textarea
        class="w-100 h-200"
        id="sync-config">
        {
            "syncType": "teamUni",
            "netDocuments": {
                "userName": "",
                "cabinetId": "",
                "cabinetName": "",
                "repositoryId": "",
                "workspaceId": "",
                "workspaceName": "",
                "workspaceEnvId": "",
                "folderId": "",
                "folderName": "",
                "breadCurmb": [
                    {
                        "DocId": "",
                        "Attributes": {
                            "Name": "",
                            "Ext": ""
                        },
                        "EnvId": ""
                    },
                    {
                        "DocId": "",
                        "EnvId": "",
                        "Attributes": {
                            "Name": "",
                            "Ext": ""
                        }
                    }
                ]
            }
        }
      </textarea>

    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.close('cancel')">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-blue"
      (click)="copySyncConfig()">
      Copy to Clipboard
    </button>
  </div>
</ng-template>
