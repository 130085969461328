<form [formGroup]="team">

  <div class="form-group row mr-0 ml-0">
    <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
      <label class="form-label">Team Name *</label>
    </div>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="name" placeholder="Give your teamplate a name">
    </div>
  </div>

  <div class="form-group row mr-0 ml-0">
    <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
      <label class="form-label">Team Description</label>
    </div>
    <div class="col-sm-8">
      <textarea type="text" rows="5" class="form-control-custom" formControlName="description"
        placeholder="Let people know what this team is all about"></textarea>

    </div>
  </div>

  <div class="form-group row mr-0 ml-0">
    <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
      <label class="form-label">Privacy</label>
    </div>
    <div class="col-sm-8">
      <div>
        <select class="form-control" id="visibility" formControlName="visibility">
          <option value="public">
            Public
            <!-- - Anyone in your organization can join -->
          </option>
          <option value="private">
            Private
            <!-- - Only team owners can add members -->
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-group row mr-0 ml-0" *ngIf="global.haveSyncAddOn">
    <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">

      <label class="form-label" for="syncCheck">
        Team sync
      </label>
    </div>
    <div class="col-sm-8">
      <input type="checkbox" id="teamSync" formControlName="sync" style="display:none"
        (change)="onChangeTeamSync($event)">
      <label for="teamSync" class="check-toggle ml-0 mt-2">
        <span></span>
      </label>
    </div>
    <!-- <div
      class="col-sm-8"
      *ngIf="syncType=='channel'">
      <input
        type="checkbox"
        style="display:none"
        disabled="{{true}}">
      <label
        for="teamSync"
        class="check-toggle ml-0 mt-2">
        <span></span>
      </label>
    </div> -->
  </div>

  <div formArrayName="channels" *ngFor="let channel of team.controls.channels['controls']; let i=index"
    class="channel-holder">
    <div [formGroupName]="i">
      <div class="channel-head">
        <div>{{channel.controls.name.value}}</div>
        <div>
          <button class="btn btn-remove" title="Remove" (click)="removeChannel(i)">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div class="form-group row mr-0 ml-0">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
          <label class="form-label">Channel Name *</label>
        </div>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="name" placeholder>
        </div>
      </div>

      <div class="form-group row mr-0 ml-0">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
          <label class="form-label">Description</label>
        </div>
        <div class="col-sm-8">
          <textarea type="text" rows="5" class="form-control-custom" formControlName="description"
            placeholder="Let people know what this channel is all about"></textarea>
        </div>
      </div>

      <div class="form-group row mr-0 ml-0">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
          <label class="form-label">Privacy</label>
        </div>
        <div class="col-sm-8">
          <div>
            <select class="form-control" id="membershipType" formControlName="membershipType">
              <option value="0">
                Standard
                <!-- - Only team owners can add members -->
              </option>
              <option value="1">
                Private
                <!-- - Anyone in your organization can join -->
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group row mr-0 ml-0" *ngIf="global.haveSyncAddOn">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
          <label class="form-label" for="syncCheck">
            Sync
          </label>
        </div>
        <div class="col-sm-8">
          <input type="checkbox" id="channelSync_{{i}}" formControlName="sync" style="display:none"
            (click)="onChangeChannelSync($event)">
          <label for="channelSync_{{i}}" class="check-toggle ml-0 mt-2">
            <span></span>
          </label>
        </div>
        <!-- <div
          class="col-sm-8"
          *ngIf="syncType=='team'">
          <input
            type="checkbox"
            style="display:none"
            disabled="{{true}}">
          <label
            for="channelSync_{{i}}"
            class="check-toggle ml-0 mt-2">
            <span></span>
          </label>
        </div> -->
      </div>
      <div formArrayName="tabs" *ngFor="let tab of channel.controls.tabs.controls; let j=index">
        <div [formGroupName]="j">
          <div class="form-group row mr-0 ml-0">
            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
              <label class="form-label">Tab Name *</label>
            </div>
            <div class="col-sm-8 d-flex">
              <input type="text" class="form-control mr-2" formControlName="name" placeholder>
              <div style="display: flex;">
                <button class="btn btn-remove btn-edit-remove" type="button"><img width="15" height="15"
                    (click)="onEditTab(modalTab,tab,tabIndex)" src="assets/images/edit_black_24dp.svg">
                </button>
              
                <button class="btn btn-remove btn-edit-remove" title="Remove" (click)="removeTab(i,j)">
                  <span aria-hidden="true" style="font-size: 15px;">×</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row mr-0 ml-0">
        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center"></div>
        <div class="col-sm-8">
          <div>
            <button class="btn btn-remove" (click)="addTab(i)">
              <span aria-hidden="true">Add Tab</span>
            </button>
          </div>
        </div>
      </div> -->

    </div>
  </div>
  <div class="form-group row mr-0 ml-0 d-flex justify-content-center">
    <div>
      <button class="btn btn-remove" (click)="addChannel()">
        <span aria-hidden="true">Add Channel</span>
      </button>
    </div>
  </div>
  <ng-template #modalTab let-createTabModal>
    <create-tab [tabsList]="selectedTab" [modal]="createTabModal" [isTemplate]="isTemplate" [tabDetail]="selectedTab"
      (change)="addTab($event)"></create-tab>
  </ng-template>
</form>


