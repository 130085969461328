import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-teams-template-from-existing',
  templateUrl: './create-teams-template-from-existing.component.html',
  styleUrls: ['./create-teams-template-from-existing.component.scss']
})
export class CreateTeamsFTComponent implements OnInit {

  constructor(
    private router:Router,
  ) { }

  ngOnInit(): void {
  }

  public onCancel(){
    this.router.navigateByUrl('provisioning');
  }
}
