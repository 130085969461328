import { Injectable, ElementRef, EventEmitter, NgZone } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { NetDocAuthComponent } from '../pages/net-doc-auth/net-doc-auth.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
//import { HyperService } from './hyper-service.service';
import * as $ from 'jquery';
import { Router } from "@angular/router";
import { ElectronService } from "ngx-electron";
import { HelperService } from "./helper";
import * as moment from "moment";
import { LogService } from "./log.service";
import { GlobalService } from "./global.service";
import { ServerRequestsService } from "./server-requests.service"
import { NetdocshareLocalstorageService } from './netdocshare-localstorage.service';

@Injectable()
export class NetDocumentViewerService {
    public pageTitle: string = "";
    public syncJobId: string = "";
    public currentUser: any = {};
    public currentUserLicense: any = {};
    public onConfigChange: EventEmitter<any> = new EventEmitter<any>();
    public onChangeSyncType: EventEmitter<any> = new EventEmitter<any>();
    public onCreateSPCol: EventEmitter<any> = new EventEmitter<any>();
    public getSPColPro: EventEmitter<any> = new EventEmitter<any>();
    public theAuthModal = null;
    public adminUsers: any = [];
    public cdnBaseUrl: string;
    private windowMessageDone = false;

    private baseUrl: string;
    private AuthSuffix: string;
    private clientId: string;
    private clientSecret: string;
    private licenseKey: string;
    private isAuthorized: boolean;

    public syncJobLimit: number = 0;
    public userSyncJobs: number = 0;
    public syncJobsSP: number = 0;
    public syncJobsOD: number = 0;
    public syncJobSetting: any;


    public isDULogin: boolean = false;
    public tempData: any = null;
    public showLoginProcessLoader: boolean = false;

    private electronPopUpData: string = null;
    private isWinMsgResponsed: boolean = false;
    public GSAccessToken: string = "";

    public isLicenseExpired: boolean = false;
    public licenseExpRemaining: number = null;
    public licenseExpAfter: number = null;
    public licenseExpOn: string = null;
    public licenseExpMedssage: string = null;
    public isLicWarnShow: boolean = true;

    public showSyncPopup: boolean = false;
    public syncUrl: any = null;
    public syncDataId: any = "";
    public NDAuthComponent: any = null;

    constructor(private _http: HttpClient, private modalService: NgbModal, public router: Router,
        public electron: ElectronService, public logService: LogService, public global: GlobalService,
        private zone: NgZone, public netDocLocalStorage: NetdocshareLocalstorageService, public serverRequestSrvc: ServerRequestsService) {
        this.setSyncSetting();
    }

    public setSyncSetting(syncData = null) {
        this.syncJobSetting = {
            key: "",
            syncFrequency: "00:00:30",
            syncFrequencyInSecond: 30,
            syncType: "teamUni",
            syncMetaData: false,
            applyPermissions: false,
            syncSource: "ProvisioningTool",
            createdFrom: "PRO",
            fileMove: false,
            fileMoveOn: "immediate",
            fileMoveProcessFrom: "",
            fileMoveProcessTo: "",
            defaultproperty: false,
            syncAllSPList: false,
            copyNDDocLink: false,
            moveAllVersions: false,
            columnOptions: [],
            netDocuments: {
                userName: "",
                siteUrl: "",
                cabinetId: "",
                cabinetName: "",
                repositoryId: "",
                workspaceId: "",
                workspaceName: "",
                workspaceEnvId: "",
                folderId: "",
                folderName: "",
                sources: {
                    workspaces: [],
                    folders: [],
                    files: [],
                },
                breadCurmb: [],
                selectAll: false,
            },
            teams: {
                connectionId: "",
                connectionType: "online",
                teamId: "",
                teamName: "",
                channelId: "",
                channelName: "",
                folderId: "",
                folderName: "",
                channeWebUrl: "",
                folderWebUrl: "",
                selectAll: false,
                breadCrumb: [],
                sources: {
                    folders: [],
                    files: [],
                    channels: []
                },
                channelWebUrl: "",
                isPathBroken: false
            },
            sharePoint: {
                connectionType: null,
                siteAddress: "",
                connectionId: "",
                clientId: "",
                clientSecret: "",
                username: "",
                password: "",
                libraryType: "document",
                transferType: "flatten",
                tenantUrl: "",
                breadCurmb: [],
                multiFactorAuthu: false,
                appPassword: "",
                listUrl: "",
                listName: "",
                listId: "",
                cabinetId: "",
                workspaceId: "",
                folderId: "",
                sources: {
                    folders: [],
                    files: [],
                },
                selectAll: false
            },
            oneDrive: {
                connectionId: "",
                tenantName: "",
                tenantId: "",
                clientId: "",
                clientSecret: "",
                userEmail: "",
                userName: "",
                folderId: "",
                folderName: "",
                breadCurmb: [],
                sources: {
                    folders: [],
                    files: []
                },
                selectAll: false
            },
            selectAll: false,
            createdBy: this.currentUser.email
        }
    }

    public validateLicense(key) {
        const defer = new Promise((resolve, reject) => {
            const url = environment.serviceUrl + 'api/user/validate/' + key;
            this._http.get(url).subscribe((resData: any) => {
                if (resData.result === 'success') {
                    this.netDocLocalStorage.licenseInfo = resData.data;
                    this.currentUserLicense = resData.data;
                    this.logService.updateLogMessage("License validate service request success");
                } else {
                    this.logService.updateLogMessage("License validate service request failed");
                }
                resolve(resData);
            }, (error) => {
                this.logService.updateLogMessage("License validate service request error => " + JSON.stringify(error));
                reject(error);
            }
            );
        });
        return defer;
    };

    public validateFirmName(userData) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/validate/firmname';
        this._http.post(url, userData).subscribe((resData: any) => {
            if (resData.result == "success") {
                this.logService.updateLogMessage("Firmname validation service request success");
                this.netDocLocalStorage.licenseInfo = resData.data;
                this.currentUserLicense = resData.data;
                requestOut.emit({ status: "success", message: resData.message })
            } else {
                this.logService.updateLogMessage("Firmname validation service request failed");
                requestOut.emit({ status: "failed", data: null, message: resData.message })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", data: null })
            this.logService.updateLogMessage("API service request error \n" + JSON.stringify(error));
        })
        return requestOut;
    }

    // Getting all Firmnames based on the search string
    private resuestFirmnameSearch: any = null;
    public getFirmNames(userData) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/firmnames';
        if (this.resuestFirmnameSearch != null) this.resuestFirmnameSearch.unsubscribe();
        this.resuestFirmnameSearch = this._http.post(url, userData).subscribe((resData: any) => {
            if (resData.result == "success") {
                this.logService.updateLogMessage("Firmnames list service request success");
                requestOut.emit({ status: "success", data: resData.data })
            } else {
                this.logService.updateLogMessage("Firmnames list service request failed");
                requestOut.emit({ status: "failed", data: [] })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", data: [] })
            this.logService.updateLogMessage("API service request error \n" + JSON.stringify(error));
        })
        return requestOut;
    }

    public getUserFirmNames(user) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/user/firmnames/' + user;
        if (this.resuestFirmnameSearch != null) this.resuestFirmnameSearch.unsubscribe();
        this.resuestFirmnameSearch = this._http.get(url).subscribe((resData: any) => {
            if (resData.result == "success") {
                this.logService.updateLogMessage("User's firmnames list service request success");
                requestOut.emit({ status: "success", data: resData.data, message: resData.message ? resData.message : "" })
            } else {
                this.logService.updateLogMessage("User's firmnames list service request failed");
                requestOut.emit({ status: "failed", message: resData.message })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", message: "Sorry can not able to get data. Please try again later." })
            this.logService.updateLogMessage("API service request error \n" + JSON.stringify(error));
        })
        return requestOut;
    }


    public setBaseUrls(baseURL, suffix) {
        this.baseUrl = baseURL;
        this.AuthSuffix = suffix;
        localStorage.setItem("netDocsBaseUrl", this.baseUrl);
        localStorage.setItem("client_id", this.clientId);
        localStorage.setItem("client_secret", this.clientSecret);
        localStorage.setItem("auth_suffix", this.AuthSuffix);
    }

    //Validate License
    public getNetDocumentsLoginInfo(user, clientId) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        const url = environment.serviceUrl + 'api/user/validate/' + user + "/" + clientId;
        this._http.get(url).subscribe((resData: any) => {
            if (resData.result == "success") {
                this.logService.updateLogMessage("User firm validation service request success");
                this.netDocLocalStorage.licenseInfo = resData.data;
                this.currentUserLicense = resData.data;
                localStorage.removeItem("_grecaptcha");
                requestOut.emit({ status: "success", message: resData.message })
            } else {
                this.logService.updateLogMessage("User firm validation service request failed");
                requestOut.emit({ status: "failed", data: null, message: resData.message })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", data: null })
            this.logService.updateLogMessage("API service request error \n" + JSON.stringify(error));
        })
        return requestOut;
    };

    public getSystemInfo(email = null) {
        const eventEmitter: EventEmitter<string> = new EventEmitter<string>();
        // const defer = new Promise((resolve, reject) => {
        const accessToken = this.getNDAuthToken();
        const refreshToken = this.getNDRefreshToken();
        if (accessToken == undefined || accessToken == null) {
            if (refreshToken != undefined && refreshToken != null && refreshToken != "") {
                this.getAccessTokenByRefreshToken().subscribe(() => {
                    let status: any = true;
                    eventEmitter.emit(status);
                }, (error) => {
                    //console.log("PT::NetyDocuments referesh token error => " + JSON.stringify(error));
                    this.logService.updateLogMessage("NetyDocuments referesh token error => " + JSON.stringify(error));
                });
            }
            //get authorization code to exchange to access/refresh tokens
            else {
                this.CaptureAuthCode().subscribe((res) => {
                    this.zone.run(() => {
                        //console.log(res);
                        if (res === 'fail' || res === undefined) {
                            eventEmitter.emit('fail');
                        }
                        if (res === 'error' || res === 'deny') {
                            this.logService.updateLogMessage("NetyDocuments login " + res);
                            eventEmitter.emit(res);
                        }
                        //localStorage.setItem('auth_code', res);
                        //sessionStorage.setItem('license', res);
                        localStorage.setItem('auth_code', btoa(res));
                        sessionStorage.setItem('license', btoa(res));
                        this.GetRefreshToken().then((res: any) => {
                            this.zone.run(() => {
                                if (res) {
                                    this.authorizeUser(email).subscribe((res) => {
                                        this.zone.run(() => {
                                            if (!res.data && res.status === "fail") {
                                                this.isAuthorized = false;
                                                this.tempData = {};
                                                //this.tempData.licenseInfo = localStorage.getItem("licenseInfo");
                                                this.tempData.licenseInfo = this.netDocLocalStorage.licenseInfo;
                                                this.clearStorage();
                                            } else {
                                                this.isAuthorized = true;
                                            }
                                            //console.log("I ma in")
                                            // //console.log(resolve);
                                            eventEmitter.emit(res);
                                        })
                                    })
                                } else {
                                    eventEmitter.emit(res);
                                }
                            })
                        }).catch((res) => {
                            eventEmitter.emit('deny')
                        })
                    })

                })
            }
        }
        //access token exists
        else {
            if (this.tokenExpired()) {
                //if access token is expired, get another access token using refresh token
                //console.log("PT::eventEmitter::status::IN");
                this.getAccessTokenByRefreshToken().subscribe(() => {
                    let status: any = true;
                    //console.log("PT::eventEmitter::status::", status);
                    eventEmitter.emit(status);
                }, (err) => {
                    this.clearStorage();
                })
            }
            else {
                //const url = localStorage.getItem("netDocsBaseUrl");
                const url = this.getUserApiUrl();
                const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getNDAuthToken());
                headers.set('Accept', 'application/json')
                const options = { headers: headers };
                this._http.get(url, options).subscribe((res: any) => {
                    eventEmitter.emit(res);
                })
            }
        }
        // });
        // return defer;
        return eventEmitter;
    }

    private windowMessage(data: any, eventEmitter = null, jsFunc = null) {
        if (this.windowMessageDone) return;
        //console.log(data);
        if (data.origin == environment.serviceRootUrl) {
            //console.log("I am here 11")
            try {
                //console.log("I am here 111")
                let authuData = JSON.parse(data.data)
                if (authuData.code) {
                    //console.log("authuData===code=", authuData)
                    this.isWinMsgResponsed = true;
                    window.removeEventListener("message", jsFunc);
                    this.windowMessageDone = true;
                    eventEmitter.emit(authuData.code);
                    this.theAuthModal.close();
                } else if (authuData.error) {
                    //console.log("authuData===error=", authuData)
                    window.removeEventListener("message", jsFunc);
                    this.isWinMsgResponsed = true;
                    this.windowMessageDone = true;
                    this.theAuthModal.close();
                    this.logOut().subscribe((data) => {
                        //localStorage.clear();
                        this.netDocLocalStorage.clear();
                        this.isDULogin = false;
                        this.router.navigateByUrl("/");
                        window.location.reload();
                    });
                } else {
                    //console.log("authuData===else==", authuData)
                }
            } catch {

            }
        }
    }

    private watchWindowActivity() {
        let timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            if (this.theAuthModal == null) return;
            let responseUrl = this.theAuthModal.getURL();
            let urlParams = responseUrl.split("code=");
            if (urlParams[1] != undefined) {
                let codeParams = urlParams[1].split("&");
                this.electronPopUpData = decodeURIComponent(codeParams[0])
                this.theAuthModal.close();
            } else {
                if (responseUrl.indexOf('access_denied') >= 0) {
                    this.electronPopUpData = "deny";
                    this.theAuthModal.close();
                } else {
                    this.watchWindowActivity();
                }
            }
        }, 1000)
    }

    public CaptureAuthCode() {
        const eventEmitter: EventEmitter<string> = new EventEmitter<string>();
        const url = environment.serviceUrl + 'api/netdocuments/info/' + this.getUserLicense();
        //console.log("CaptureAuthCode  => " + url)
        this._http.get(url).subscribe((resData: any) => {
            if (resData.result == 'success') {
                //console.log("PT::NetDocuments login information service request success::resData::", resData);
                let licenseInfo = JSON.parse(atob(resData.data));
                this.currentUserLicense.NDApiUrl = licenseInfo.NDApiUrl;
                this.currentUserLicense.NDWebUrl = licenseInfo.NDWebUrl;
                this.currentUserLicense.isSSOEnabled = licenseInfo.isSSOEnabled;
                this.currentUserLicense.repository = licenseInfo.repository;
                this.currentUserLicense.authToken = licenseInfo.authToken;
                this.netDocLocalStorage.licenseInfo = this.currentUserLicense;
                let fullURL = licenseInfo.NDLoginUrl + environment.authSuffix;
                if (this.currentUserLicense.isSSOEnabled && (this.currentUserLicense.repository != undefined && this.currentUserLicense.repository != null && this.currentUserLicense.repository != "") && !this.isDULogin) fullURL += "&whr=" + this.currentUserLicense.repository;
                //console.log(fullURL);
                if (this.theAuthModal === null || this.theAuthModal === undefined) {

                    setTimeout(() => {
                        try {
                            this.windowMessageDone = false;
                            this.isDULogin = false;
                            this.logService.updateLogMessage("NetyDocuments login popup initated");
                            this.theAuthModal = window.open(fullURL, "AuthuWin", 'height=650,width=500');
                            let self = this;
                            window.addEventListener("message", function (data: any) {
                                self.windowMessage(data, eventEmitter, this);
                            })
                            this.popupLoginWindowCloseStatus(eventEmitter);
                            if (window.focus) { this.theAuthModal.focus() }

                        } catch (err) {
                            this.logService.updateLogMessage("NetyDocuments authcode capture error => " + JSON.stringify(err));
                        }
                    }, 100);
                } else {

                }
            } else {
                this.logService.updateLogMessage("NetDocuments login information service request failed");
                eventEmitter.emit('fail');
            }
        }, (error) => {
            this.logService.updateLogMessage("NetDocuments login information service request error => " + JSON.stringify(error));
            eventEmitter.emit('fail');
        });
        return eventEmitter;
    }

    private popupLoginWindowCloseStatus(eventEmitter) {
        let timeInterval = setTimeout(() => {
            clearTimeout(timeInterval);
            if (this.theAuthModal.closed) {
                //console.log(this.isWinMsgResponsed);
                if (!this.isWinMsgResponsed) {
                    eventEmitter.emit('error');
                };
            } else {
                this.popupLoginWindowCloseStatus(eventEmitter);
            }
        }, 1000)
    }

    /* private doElectronLogin(fullURL, eventEmitter) {
        let BrowserWindow = this.electron.remote.BrowserWindow;
        this.theAuthModal = new BrowserWindow({ width: 500, height: 650 });
        this.theAuthModal.removeMenu();
        this.theAuthModal.loadURL(fullURL)
        this.theAuthModal.center();
        this.theAuthModal.focus();
        this.theAuthModal.on('close', (data) => {
            if (this.electronPopUpData == null) this.electronPopUpData = 'fail'
            if (this.electronPopUpData == 'deny' || this.electronPopUpData == 'fail') {
                this.logOut().subscribe((data) => {
                    localStorage.clear();
                    this.isDULogin = false;
                    this.theAuthModal = null;
                    this.router.navigateByUrl("/");
                    window.location.reload();
                });
            } else {
                eventEmitter.emit(this.electronPopUpData);
            }
            this.electronPopUpData = null;
            this.theAuthModal = null;
        })
        this.theAuthModal.on('hide', (data) => {
            eventEmitter.emit(this.electronPopUpData);
        });
        // this.theAuthModal.webContents.openDevTools();
        this.watchWindowActivity();
    } */

    public GetRefreshToken(authRequired = true) {
        //const code = localStorage.getItem('auth_code');
        const code = atob(localStorage.getItem('auth_code'));
        const defer = new Promise((resolve, reject) => {
            //const url = this.currentUserLicense.NDApiUrl.slice(0, -2) + 'v1/Oauth';
            let apiUrl = this.getUserApiUrl();
            const url = apiUrl.slice(0, -2) + 'v1/Oauth';
            this.logService.updateLogMessage("NetyDocuments refresh token service requested => " + url);
            //console.log(url)
            let body = {
                'grant_type': 'authorization_code',
                'code': code,
                'redirect_uri': environment.authSuffix
            };
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                contentType: 'application/x-www-form-urlencoded',
                headers: {
                    'Authorization': 'Basic ' + btoa(`${this.currentUserLicense.authToken}`),
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: body,
                complete: () => {
                    // called when complete
                },
                success: (r) => {
                    //console.log(r)
                    let Obtained = Date.now().toString();

                    this.currentUser = { refresh_token: r.refresh_token, access_token: r.access_token, access_expiry: r.expires_in, access_obtained: Obtained };
                    localStorage.setItem("CUAD", btoa(JSON.stringify(this.currentUser)))
                    //this.netDocLocalStorage.ndInfo = JSON.stringify({ refresh_token: r.refresh_token, access_token: r.access_token, access_expiry: r.expires_in, access_obtained: Obtained });
                    localStorage.removeItem('auth_code');
                    resolve(true);
                },
                error: (err) => {
                    //console.log(err)
                    // called when there is an error
                    this.logService.updateLogMessage("NetyDocuments refresh token service request error => " + JSON.stringify(err));
                    reject(false);
                },
            });
        });
        return defer;
    }

    public authorizeUser(email = null) {
        //this.isWinMsgResponsed = false;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        //console.log(this.currentUserLicense);
        //if (this.currentUserLicense.NDApiUrl == undefined || this.currentUserLicense.NDApiUrl == null || this.currentUserLicense.NDApiUrl == '') {
        let apiUrl = this.getUserApiUrl();
        if (apiUrl == undefined || apiUrl == null || apiUrl == '') {
            this.router.navigateByUrl('/');
            window.location.reload();
        }
        const getURL = this.currentUserLicense.NDApiUrl.slice(0, -2) + 'v1/User/info';
        this.logService.updateLogMessage("NetyDocuments user info service requested => " + getURL);
        const defer = new Promise((resolve, reject) => {
            $.ajax({
                url: getURL,
                type: 'GET',
                contentType: 'application/json',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.getNDAuthToken()
                },
                success: (resUser) => {
                    //add admin list here and check if emailaddress exists in admin list 
                    this.logService.updateLogMessage("NetyDocuments user info service request success");
                    this.currentUser = this.netDocLocalStorage.ndInfo;
                    this.currentUser.email = resUser.email
                    this.currentUser.displayName = resUser.displayName
                    this.currentUser.id = resUser.id
                    this.currentUser.id = resUser.primaryCabinet
                    this.netDocLocalStorage.ndInfo = JSON.stringify(this.currentUser);
                    let requestUrl = environment.serviceUrl + 'api/netdocuments/user/' + this.getUserLicense();
                    this._http.post(requestUrl, { email: this.currentUser.email }).subscribe((resData: any) => {
                        if (resData.result == "success") {
                            let userRoleData = resData.data;
                            this.currentUser.role = userRoleData.role;
                            this.currentUser.isKLSTAdmin = userRoleData.isKLSTAdmin;
                            this.currentUser.isTeamsApp = userRoleData.isTeamsApp;
                            this.currentUser.isNDSync = userRoleData.isNDSync;
                            this.global.haveSyncAddOn = this.currentUser.isNDSync;
                            this.global.haveTeamsAppAddOn = this.currentUser.isTeamsApp;
                            this.netDocLocalStorage.ndInfo = JSON.stringify(this.currentUser);
                            this.isAuthorized = (this.currentUser.role == "globalAdmin" || this.currentUser.role == "syncAdmin" || this.currentUser.role == "user") ? true : false;
                            this.global.isGlobalAdmin = (this.global.currentUser.role == "globalAdmin" || this.global.currentUser.role == "syncAdmin") ? true : false;
                            requestOut.emit({ status: (this.isAuthorized) ? "success" : "fail", data: this.isAuthorized, user: this.currentUser, message: resData.message })

                        } else {
                            requestOut.emit({ status: "fail", data: this.isAuthorized, message: resData.message })
                        }
                    }, (error) => {
                        requestOut.emit({ status: "fail", data: this.isAuthorized, message: "Sorry can not able to get data. Please try again later." })
                    })
                },
                error: (err) => {
                    this.logService.updateLogMessage("NetyDocuments user info service request error => " + JSON.stringify(err));
                    if (err.status == 401) {
                        //localStorage.clear();
                        this.netDocLocalStorage.clear();
                        this.router.navigateByUrl('/');
                        window.location.reload();
                    }
                    reject(err);
                },
            });
        });
        return requestOut;

    }

    public validateNetDocumentsUser() {
        let requestOut: EventEmitter<any> = new EventEmitter<any>()
        if (this.currentUserLicense.clientId == undefined || this.currentUserLicense.clientId == null || this.currentUserLicense.clientId == "") return;
        let requestUrl = environment.serviceUrl + 'api/netdocuments/user/' + this.getUserLicense();
        this._http.post(requestUrl, { email: this.currentUser.email }).subscribe((resData: any) => {
            if (resData.result == "success") {
                this.global.currentUser.role = resData.data.role;
                this.currentUser.role = resData.data.role;

                this.currentUser.isKLSTAdmin = resData.data.isKLSTAdmin;
                this.netDocLocalStorage.ndInfo = JSON.stringify(this.currentUser);
                requestOut.emit({ status: "success", data: resData.data, message: resData.message })
            } else {
                requestOut.emit({ status: "failed", message: resData.message })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", message: "Sorry can not able to get data. Please try again later." })
        })
        return requestOut;
    }

    public validateCurentUserLicense() {
        if (this.currentUserLicense.clientId == undefined && this.currentUserLicense.clientId == null && this.currentUserLicense.clientId == "") return;
        const url = environment.serviceUrl + 'api/validate/license/' + this.currentUserLicense.clientId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((resLicenseData: any) => {
            if (resLicenseData.result == "success") {
                requestOut.emit({ status: "success", data: resLicenseData.data })
            } else {
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    public isValidAdminUser(adminUsersList, currentUser) {///This funtion is not called
        // VALIDATE ADMIN USER////// 
        let adminUsers = (adminUsersList != undefined && adminUsersList != null && adminUsersList != "") ? adminUsersList : [];
        let newAdminsList: any = []
        if (adminUsers.length > 0) {
            adminUsers.forEach(element => {
                if (typeof (element) == 'string') {
                    newAdminsList.push({ email: element, role: "globalAdmin" });
                } else {
                    newAdminsList.push(element);
                }
            });
        }
        let userExist = newAdminsList.find(element => (element.email.toLowerCase() == currentUser.email.toLowerCase() || element.email.toLowerCase() == currentUser.id.toLowerCase()));
        if (userExist != undefined && userExist != null) {
            //console.log("userExist====global=====", userExist)
            if (userExist.role == 'globalAdmin' || userExist.role == 'syncAdmin') {
                this.currentUser = currentUser;
                this.currentUser.role = userExist.role;
                this.global.isGlobalAdmin = (userExist.role == 'globalAdmin');
                this.currentUser.companyName = this.currentUserLicense.client;
                this.currentUser.licenseType = this.currentUserLicense.licenseType;
                localStorage.setItem('curentUser', btoa(JSON.stringify(this.currentUser)));
                this.logService.updateLogMessage("User role validation success");
                return true;
            } else {
                this.global.isGlobalAdmin = false;
                this.logService.updateLogMessage("User role validation failed");
                return false;
            }
        } else {
            this.global.isGlobalAdmin = false;
            this.logService.updateLogMessage("User role validation failed");
            return false;
        }
    }

    public getAccessTokenByRefreshToken() {
        let requestOut: EventEmitter<any> = new EventEmitter<any>()
        let requestUrl = environment.serviceUrl + 'api/netdocuments/token';
        //console.log("PT::getAccessTokenByRefreshToken::resData::IN")
        this._http.post(requestUrl, { region: this.currentUserLicense.region, token: this.currentUser.refresh_token }).subscribe((resData: any) => {
            if (resData.result == "success") {
                try {
                    let tokenInfo = typeof resData.data === 'string' ? JSON.parse(resData.data) : resData.data;
                    let Obtained = Date.now().toString();
                    this.currentUser.access_token = tokenInfo.access_token;
                    this.currentUser.refresh_token = tokenInfo.refresh_token;
                    this.currentUser.access_expiry = tokenInfo.expires_in;
                    this.currentUser.access_obtained = Obtained;
                    this.netDocLocalStorage.ndInfo = JSON.stringify(this.currentUser);
                    //console.log("PT::getAccessTokenByRefreshToken::resData::", resData)
                    requestOut.emit({ status: "success", data: resData.data, message: resData.message })
                } catch (e) {
                    requestOut.emit({ status: "failed", message: e.message })
                    //console.log("PT::getAccessTokenByRefreshToken::ERROR,resData::", e, resData)
                }
            } else {
                requestOut.emit({ status: "failed", message: resData.message })
            }
        }, (error) => {
            requestOut.emit({ status: "failed", message: "Sorry can not able to get data. Please try again later." })
        })
        return requestOut;
    }

    public tokenExpired() {
        const accessob = this.currentUser.access_obtained;
        const accessexp = this.currentUser.access_expiry;
        if (!accessob || !accessexp) {
            //console.log("1")
            return true;
        }
        const expiryTime = parseInt(accessob) + (parseInt(accessexp) * 1000);
        if (Date.now() < expiryTime) {
            //console.log("2")
            return false;
        } else {
            //console.log("3")
            return true;
        }
    }

    public logOut(isReload = true) {
        if (!this.isDULogin) this.tempData = null;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        if (this.currentUserLicense.clientId != undefined) {
            let urlString = this.currentUserLicense.NDWebUrl;
            if (urlString == undefined || urlString == null || urlString == '') return;
            this.revokeNDToken().subscribe(res => {
                let url = urlString + "/logout.aspx";
                this.NDlogout(url, isReload).subscribe((resData) => {
                    this.currentUser = {};
                    this.currentUserLicense = {};
                    //console.log("logOut - nds viewer, isDULogin", this.isDULogin)
                    if (this.isDULogin) requestOut.emit({})
                });
                setTimeout(() => {
                    if (this.currentUserLicense != null && this.currentUserLicense.sso && this.tempData != null) requestOut.emit({});
                }, 2100)
            });
        } else {
            this.router.navigateByUrl("/");
        }
        return requestOut;
    }

    public revokeNDToken() {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        let body = {
            token: this.currentUser.access_token,
            token_type_hint: "Bearer"
        }
        if (requestURL != null) {
            requestURL = requestURL + "/oauth/revoke";
            this._http.post(requestURL, body, requestOptions).subscribe((res: any) => {
                requestOut.emit({ status: res.Revoked ? "success" : "failed" });
            }, (error) => {
                requestOut.emit({ status: "failed" });
            })
        } else {
            requestOut.emit({ status: "failed" });
        }
        return requestOut;
    }

    public clearStorage() {
        try {

            //let remote = this.electron.remote;
            //remote.getCurrentWindow().webContents.session.clearStorageData();

        } catch (e) { }
        //localStorage.clear();
        this.netDocLocalStorage.clear();
    }

    private logOutWindowListiner(logoutWindow) {
        setTimeout(() => {
            let responseUrl = logoutWindow.getURL();
            if (responseUrl != "https://vault.netvoyage.com/neWeb2/logout.aspx") {
                logoutWindow.close();
            } else {
                this.logOutWindowListiner(logoutWindow);
            }

        }, 1000)
    }

    public NDlogout(url, isReload = true) {
        let emit: EventEmitter<any> = new EventEmitter<any>();
        let logoutWin = document.createElement("iframe");
        logoutWin.setAttribute('src', url);
        logoutWin.setAttribute('id', 'logout');
        document.body.appendChild(logoutWin);
        setTimeout(() => {
            document.body.removeChild(logoutWin);
            this.clearStorage()
            if (this.currentUserLicense != null && this.currentUserLicense.isSSOEnabled && this.tempData != null) {
                this.netDocLocalStorage.licenseInfo = this.tempData.licenseInfo;
                emit.emit({})
            } else {
                this.logService.updateLogMessage("User logout success");
                this.currentUser = {};
                this.currentUserLicense = {};
                this.router.navigateByUrl('/');
                if (isReload) setTimeout(window.location.reload, 1000);
            }
        }, 2000);
        return emit;
    }

    public getNetDocUserInfo() {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        //let urlFromStorage = this.currentUserLicense.NDApiUrl || this.currentUserLicense.netDocBaseUrl;
        //console.log("PT::this.currentUserLicense.NDApiUrl::", this.currentUserLicense)
        //if (urlFromStorage == undefined || urlFromStorage == null || urlFromStorage == '') {
        let apiUrl = this.getUserApiUrl();
        if (apiUrl == undefined || apiUrl == null || apiUrl == '') {
            this.router.navigateByUrl('/');
            // window.location.reload();
        } else {
            const getURL = apiUrl.slice(0, -2) + 'v1/User/info';
            this.logService.updateLogMessage("NetDocuments user information service requested => " + getURL);
            // return new Promise((resolve, reject) => {
            if (getURL!)
                $.ajax({
                    url: getURL,
                    type: 'GET',
                    contentType: 'application/json',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.getNDAuthToken()
                    },
                    success: (resUser) => {
                        //console.log("PT::NetDocuments user information service request success::resUser::", resUser);
                        this.currentUser = this.netDocLocalStorage.ndInfo;
                        this.currentUser.email = resUser.email
                        this.currentUser.displayName = resUser.displayName
                        this.currentUser.id = resUser.id
                        this.currentUser.id = resUser.primaryCabinet
                        this.netDocLocalStorage.ndInfo = JSON.stringify(this.currentUser);
                        requestOut.emit(resUser);
                    },
                    error: (err) => {
                        this.logService.updateLogMessage("NetDocuments user information service request failed => " + JSON.stringify(err));
                        if (err.status == 400 || err.status == 401 || err.status == 0) {
                            //localStorage.clear();
                            this.netDocLocalStorage.clear();
                            this.router.navigateByUrl('/');
                            window.location.reload();
                        }
                        requestOut.emit(err);
                    },
                });
            // });
        }

        return requestOut;
    }

    public getLicenceData() { //This function not in use
        const url = environment.serviceUrl + 'api/checklicense/' + localStorage.getItem('license_key');
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success" && data.data != undefined) {
                this.logService.updateLogMessage("License data request success");
                if (data.data != undefined) {
                    let validDomains = (data.data.validDomains != undefined && data.data.validDomains != null && data.data.validDomains.length > 0) ? data.data.validDomains : [];
                    let adminUsers = (data.data.validAdmins != undefined && data.data.validAdmins != null && data.data.validAdmins.length > 0) ? data.data.validAdmins : [];
                    let expiredOn = (data.data != undefined && data.data.expiredOn != undefined && data.data.expiredOn != null && data.data.expiredOn != "") ? data.data.expiredOn : null;
                    let validater: any = { validDomains: validDomains, adminUsers: adminUsers, expiredOn: expiredOn }
                    localStorage.setItem("validater", btoa(JSON.stringify(validater)));
                    this.global.haveSyncAddOn = false;
                    this.global.haveTeamsAppAddOn = false;

                    for (let i in data.data.addOns) {
                        if (data.data.addOns[i] == "netDocShare Sync") {
                            this.global.haveSyncAddOn = true;
                            break
                        }
                    }

                    for (let i in data.data.addOns) {
                        if (data.data.addOns[i] == "netDocShare Teams app") {
                            this.global.haveTeamsAppAddOn = true;
                            break
                        }
                    }

                    //this.global.haveSyncAddOn = false;
                    //this.global.haveTeamsAppAddOn = false;
                    //console.log(this.global.haveSyncAddOn, this.global.haveTeamsAppAddOn)
                }
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("License data request failed");
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("License data request error => " + JSON.stringify(err));
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    public saveSyncJob(jobData) {
        let url = environment.serviceUrl + 'api/sync/create';
        if (jobData._id != undefined) url = environment.serviceUrl + 'api/sync/update/' + jobData._id;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        //console.log("jobData=====", jobData)
        this._http.post(url, jobData).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Sync Job save service request success")
                requestOut.emit({ status: "success", data: data })
            } else {
                this.logService.updateLogMessage("Sync Job save service request failed")
                requestOut.emit({ status: "failed", message: data.message })
            }
        }, (err) => {
            this.logService.updateLogMessage("Sync Job save service request error => " + JSON.stringify(err))
            requestOut.emit({ status: "failed", message: '' })
        })
        return requestOut;
    }

    public getJobDetails(jobId) {
        const url = environment.serviceUrl + 'api/syncjob/' + jobId;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Sync Job detail service request success")
                requestOut.emit({ status: "success", data: data.data })
            } else {
                this.logService.updateLogMessage("Sync Job detail service request failed")
                requestOut.emit({ status: "failed", data: null })
            }
        }, (err) => {
            this.logService.updateLogMessage("Sync Job detail service request error => " + JSON.stringify(err))
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    public verifyToken(token) {
        const url = environment.serviceUrl + 'api/validate/captcha';
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        this._http.post(url, token).subscribe((data: any) => {
            let successData = data.success;
            if (successData && token.captchaResponse === data.captchaResponse) {
                this.logService.updateLogMessage("Token validate service request success")
                requestOut.emit({ status: "success", data: successData })
            } else {
                this.logService.updateLogMessage("Token validate service request failed")
                requestOut.emit({ status: "failed", data: successData })
            }
        }, (err) => {
            this.logService.updateLogMessage("Token validate service request error => " + JSON.stringify(err))
            requestOut.emit({ status: "failed", data: null })
        })
        return requestOut;
    }

    public validateWebSite(siteUrl) {
        return /^((https|http|):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(siteUrl);
    }

    public getCabinets() {
        if (this.currentUser.access_token == undefined || this.currentUser.access_token == null || this.currentUser.access_token == "") return;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "/v1/user/cabinets";
            this._http.get(requestURL, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("NetDocuments cabinets service request success")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments cabinets service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null })
        }
        return requestOut;
    }


    public createFolder(subFolder, folderId, CabinetId, ignoreFolderCreation = false) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        if (ignoreFolderCreation) {
            setTimeout(() => {
                requestOut.emit({ status: "success", data: null })
            }, 500)
            return requestOut;
        }
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        //headers.append('Accept', 'application/json');
        headers.append('Accept', 'application/x-www-form-urlencoded');

        const formData = new FormData();
        formData.append("name", subFolder);
        formData.append("parent", folderId);
        formData.append("sanitizeName", "true");
        formData.append("cabinet", CabinetId);

        const requestOptions: object = { headers: headers };
        const payload = folderId ? { name: subFolder, parent: folderId, sanitizeName: true, cabinet: CabinetId } : { name: subFolder, sanitizeName: true, cabinet: CabinetId };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1/Folder";
            this._http.post(requestURL, formData, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("NetDocuments folder service request success")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments folder service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, error: error })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, error: "requestURL" })
        }
        return requestOut;
    }

    public getWorkspace(cabinetId, nextRecords = null, NDSSearchString = "") {
        let recordLimit = 50;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1";
            if (!NDSSearchString)
                requestURL += `/search/?q==10([${cabinetId}])=11(ndws)&$orderby=name asc&$select=standardAttributes&$top=${recordLimit}`;
            else
                requestURL += `/search/?q==3(${NDSSearchString}*)=10([${cabinetId}])=11(ndws)&$orderby=name asc&$select=standardAttributes&$top=${recordLimit}`;
            if (nextRecords != null) requestURL += "&$skiptoken=" + nextRecords;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                this.logService.updateLogMessage("NetDocuments workspace service request success")
                let skipToken: string = null
                if (res.next != undefined) {
                    let nextParams = res.next.split("skiptoken=");
                    if (nextParams[1] != undefined) skipToken = decodeURIComponent(nextParams[1]);
                }
                requestOut.emit({ status: "success", data: res.standardList, skipToken: skipToken })
            }, (error) => {
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getSearchWorkspace(cabinetId, searchStering, nextRecords) {
        let recordLimit = 50;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1";
            requestURL += `/Search/${cabinetId}/?q=${searchStering}*=11(ndws)&$select=standardAttributes&orderby=3|asc&$top=${recordLimit}`;
            if (nextRecords != null) requestURL += "&$skiptoken=" + nextRecords;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                this.logService.updateLogMessage("NetDocuments search workspace service request success")
                let skipToken: string = null
                if (res.next != undefined) {
                    let nextParams = res.next.split("skiptoken=");
                    if (nextParams[1] != undefined) skipToken = decodeURIComponent(nextParams[1]);
                }
                requestOut.emit({ status: "success", data: res.standardList, skipToken: skipToken })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments search workspace service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getNetDocumentsWorkspaceContents(cabinetId, selectedWorkSpace, ndContentType, searchString = "", nextRecords = null, syncType = null, componentType = null) {
        let recordLimit = 20;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            let FilesFilter: string = "(ndfld or ndflt or ndsq or ndcs or ss)";
            if (ndContentType == "folders") {
                if (searchString == "") {
                    requestURL += "/container/" + selectedWorkSpace.id + "/summary/containers?top=" + recordLimit;
                } else {
                    requestURL += "/search/" + cabinetId + "?q==3(" + searchString + "*) NOT =63({*}) NOT =20({*})=11" + FilesFilter + "&container=" + selectedWorkSpace.id + "&select=standardAttributes&orderby=3|asc&top=" + recordLimit;
                }
            } else {
                let fileMapFilter: String = "";
                if (selectedWorkSpace.filters != undefined && selectedWorkSpace.filters.length > 0) {
                    selectedWorkSpace.filters.forEach((filterData) => {
                        if (filterData.value != "") {
                            fileMapFilter += "=" + filterData.key + "({" + filterData.value + "})";
                        }
                    })
                }
                if (searchString == "") {
                    requestURL += "/search/" + cabinetId + "?q=" + fileMapFilter + " NOT =63({*}) NOT =20({*}) NOT =11" + FilesFilter + "&container=" + selectedWorkSpace.id + "&select=standardAttributes&orderby=3|asc&top=" + recordLimit;

                } else {
                    requestURL += "/search/" + cabinetId + "?q==3(" + searchString + "*)" + fileMapFilter + " NOT =63({*}) NOT =20({*}) NOT =11" + FilesFilter + "&container=" + selectedWorkSpace.id + "&select=standardAttributes&orderby=3|asc&top=" + recordLimit;
                }
            }


            if (nextRecords != null) requestURL += "&Skiptoken=" + nextRecords;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                let skipToken: string = null
                requestOut.emit({ status: "success", data: res, skipToken: (res.SkipToken != undefined && res.SkipToken != null && res.SkipToken != '' && res.Results.length == recordLimit) ? res.SkipToken : null })
            }, (error) => {
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null, error: error })
                }
            })
        } else {
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getCabinetFolder(cabinetId, nextRecords) {
        let recordLimit = 50;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            // requestURL = requestURL.slice(0, -2) + "v1";
            requestURL += `/cabinet/${cabinetId}/folders?select=standardAttributes,Locations,CustomAttributes,StatusAttributes&orderby=3|asc`;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                this.logService.updateLogMessage("NetDocuments cabinet content service request success")
                requestOut.emit({ status: "success", data: res.Results })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments cabinet content service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null })
        }
        return requestOut;
    }

    public createNewFolder(formData) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        //let requestUrl = `${this.currentUserLicense.NDApiUrl.slice(0, -2)}v1/Folder`;
        let requestUrl = `${this.getUserApiUrl().slice(0, -2)}v1/Folder`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        this._http.post(requestUrl, formData, requestOptions).subscribe((response: any) => {
            requestOut.emit({ status: "success", data: response });
        }, err => {
            let msg = ""
            if (err.error && (err.error.error.indexOf("The parent workspace already contains a folder with this name.") > -1 || err.error.error.indexOf("The parent folder already contains a subfolder with this name.") > -1))
                msg = "already exist"
            else if (err.error && err.error.error) msg = err.error.error;
            requestOut.emit({ status: "failed", data: null, message: msg });
        });
        return requestOut;
    }

    public getNetDocumentsFolders(cabinetId, workspaceId, nextRecords = null) {
        let recordLimit = 50;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            let filterQuery: string = "";
            //filterType=0&filter=extension eq ndfld&orderby=3|asc&
            requestURL += "/container/" + workspaceId + "/summary/containers?top=" + recordLimit;
            if (nextRecords != null) requestURL += "&skiptoken=" + nextRecords;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                let skipToken: string = null
                /*if (res.next != undefined) {
                    let nextParams = res.next.split("skiptoken=");
                    if (nextParams[1] != undefined) skipToken = decodeURIComponent(nextParams[1]);
                }*/

                if (res.SkipToken) skipToken = res.SkipToken;
                this.logService.updateLogMessage("NetDocuments folder content service request success")
                requestOut.emit({ status: "success", data: res.Results, skipToken: skipToken })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments folder content service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getNeDocDocInfo(docId) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            let filterQuery: string = "";
            requestURL += "/container/" + docId + "/info";
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                this.logService.updateLogMessage("NetDocuments document info service request success")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments document info service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getSearchedFilesAndFolders(cabinetId, containerId, searchString = "", sourceType = null, nextRecords = null, containerType = null, syncType = "") {
        let recordLimit = 20;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            let filterQuery: string = "";
            requestURL += "/search";
            let sendData: any = {
                MetaData: { listFlagsLayout: 0, allCabs: false, crossRepository: false, listScroll: true },
                Count: recordLimit,
                AttributesToReturn: ["StandardAttributes", "CheckedOutBy", "Versions", "EmailAttributes", "StatusAttributes", "DeletedStatus", "Favorite", "AllowCheckedOutState", "CustomAttributes", "Locations", "DisplayNames", "1002", "11002", "1014", "1001", "11001", "1010", "1003", "Descriptions", "1006", "1008", "1009"
                ],
                SortBy: [],
                //OrderBy:["DisplayNames"],
                FieldQueries: [{ Field: 3, Query: searchString + "*" }],
                //Filter:["extension eq ndfld", "extension eq ndsq", "extension eq ndflt", "extension eq ndcs","extension eq ss"],
                SubContainers: [],
                DefaultFASTSort: false,
                NewScope: false,
                NewSearch: false,
                Cabinets: [cabinetId],
                ContainerId: containerId,
                SearchFlags: "None",
                Facets: [],
                Refinements: [],
                AddToRecents: false
            }

            if (nextRecords != null) sendData.skiptoken = nextRecords;
            this._http.post(requestURL, sendData, requestOptions).subscribe((res: any) => {
                let skipToken: string = (res.SkipToken != undefined) ? res.SkipToken : null;
                this.logService.updateLogMessage("NetDocuments serach folder content service request success")
                requestOut.emit({ status: "success", data: res, skipToken: skipToken })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments serach folder content service request error => " + JSON.stringify(error));
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {

                    requestOut.emit({ status: "failed", data: null, skipToken: null, error: error })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getFilesAdnFolders(cabinetId, containerId, searchString = "", sourceType = null, nextRecords = null) {
        let recordLimit = 50;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL += "/container/" + containerId + "?select=standardAttributes,Locations,CustomAttributes,StatusAttributes,Versions,CheckedOutBy,Containers&orderby=3|asc&top=" + recordLimit;

            if (nextRecords != null) requestURL += "&skiptoken=" + nextRecords;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                let skipToken: string = (res.SkipToken != undefined) ? res.SkipToken : null;
                requestOut.emit({
                    status: "success", data: (res.Results != undefined) ? res : { Results: [] }
                    , skipToken: skipToken
                })
            }, (error) => {
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {

                    requestOut.emit({ status: "failed", data: null, skipToken: null, error: error })
                }
            })
        } else {
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getFilesAdnFoldersByName(cabinetId, workspaceId, searchStering = "", sourceType = null) {
        let recordLimit = 100;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            let filterQuery: string = "";
            filterQuery = (sourceType == "files") ? "?q==3(" + searchStering + ")&filterType=1&filter=extension eq ndfld extension eq ndsq extension eq ndflt extension eq ss" : "?q=" + searchStering + "=11(ndfld)";
            requestURL += "/search/" + cabinetId + filterQuery + "&container=" + workspaceId + "&select=standardAttributes,Locations,CustomAttributes,StatusAttributes,Versions,CheckedOutBy,Containers&orderby=3|asc&top=" + recordLimit;
            this._http.get(requestURL, requestOptions).subscribe((res: any) => {
                let skipToken: string = (res.SkipToken != undefined) ? res.SkipToken : null;
                if (res.Results != undefined && res.Results != null && res.Results.length > 0) {
                    this.logService.updateLogMessage("NetDocuments Foleders and Files search by name service request success")
                    requestOut.emit({ status: "success", data: res.Results, skipToken: skipToken })
                } else {
                    this.logService.updateLogMessage("NetDocuments Foleders and Files search by name service request failed")
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }

            }, (error) => {
                this.logService.updateLogMessage("NetDocuments Foleders and Files search by name service request error => " + JSON.stringify(error))
                if (error.status == 401 || error.status == 0) {
                    //localStorage.clear();
                    this.netDocLocalStorage.clear();
                    this.router.navigateByUrl('/');
                    window.location.reload();
                } else {
                    requestOut.emit({ status: "failed", data: null, skipToken: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null, skipToken: null })
        }
        return requestOut;
    }

    public getNDSColProperty(cabinetId) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1" + "/Cabinet/" + cabinetId + "/customAttributes";
            this.logService.updateLogMessage("NetDocuments column propery service requested => " + requestURL)
            this._http.get(requestURL, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("NetDocuments column propery service request success")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments column propery service request error => " + JSON.stringify(error))
                if (error.status == 0) {
                    this.logOut().subscribe((data) => {
                        //localStorage.clear();
                        this.netDocLocalStorage.clear();
                        this.isDULogin = false;
                        this.router.navigateByUrl("/");
                        window.location.reload();
                    });
                } else {
                    requestOut.emit({ status: "failed", data: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments service base URL missing")
            requestOut.emit({ status: "failed", data: null })
        }
        return requestOut;
    }

    public getFolderByName(sendData) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1" + "/Cabinet/" + sendData + "/customAttributes";
            this._http.get(requestURL, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("NetDocuments files get by name service request success")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments files get by name service request error => " + JSON.stringify(error))
                if (error.status == 0) {
                    this.logOut().subscribe((data) => {
                        //localStorage.clear();
                        this.netDocLocalStorage.clear();
                        this.isDULogin = false;
                        this.router.navigateByUrl("/");
                        window.location.reload();
                    });
                } else {
                    requestOut.emit({ status: "failed", data: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments folder get by name service base URL missing")
            requestOut.emit({ status: "failed", data: null })
        }
        return requestOut;
    }

    public getFilesByName(sendData) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1" + "/Cabinet/" + sendData + "/customAttributes";
            this._http.get(requestURL, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("NetDocuments files get by name service request sucess")
                requestOut.emit({ status: "success", data: res })
            }, (error) => {
                this.logService.updateLogMessage("NetDocuments files get by name service request error => " + JSON.stringify(error))
                if (error.status == 0) {
                    this.logOut().subscribe((data) => {
                        //localStorage.clear();
                        this.netDocLocalStorage.clear();
                        this.isDULogin = false;
                        this.router.navigateByUrl("/");
                        window.location.reload();
                    });
                } else {
                    requestOut.emit({ status: "failed", data: null })
                }
            })
        } else {
            this.logService.updateLogMessage("NetDocuments files get by name service base URL missing")
            requestOut.emit({ status: "failed", data: null })
        }
        return requestOut;
    }


    public validateRepository(repoId) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        headers.append('Accept', 'application/json');
        const requestOptions: object = { headers: headers };
        //let requestURL = this.currentUserLicense.NDApiUrl;
        let requestURL = this.getUserApiUrl();
        requestURL = (requestURL != undefined && requestURL != null && requestURL != '') ? requestURL : null;
        if (requestURL != null) {
            requestURL = requestURL.slice(0, -2) + "v1" + "/Repository/" + repoId + "/info";
            this._http.get(requestURL, requestOptions).subscribe((res) => {
                this.logService.updateLogMessage("Validate repository service request success")
                requestOut.emit({ status: "success", message: "Repository Id validated." })
            }, (error) => {
                this.logService.updateLogMessage("Validate repository service request error => " + JSON.stringify(error))
                if (error.status == 404) {
                    requestOut.emit({ status: "failed", message: "Invalid Repository Id or You don't have permission for this repository." })
                } else {
                    requestOut.emit({ status: "failed", message: "Server to  busy, Try again later." })
                }
            })
        } else {
            this.logService.updateLogMessage("Validate repository service URL missing")
            requestOut.emit({ status: "failed", message: "Server to  busy, Try again later." })
        }
        return requestOut;
    }

    public getUserJobsCount() {
        const licenseKey = this.getUserLicense();
        if (licenseKey == null) return;
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let url = environment.serviceUrl + 'api/sync/userjobs/' + licenseKey;//localStorage.getItem('license_key');
        if (this.currentUser.role != undefined && this.currentUser.role == 'syncAdmin') {
            url += "/" + btoa(this.currentUser.email);
        }
        this._http.get(url).subscribe((data: any) => {
            if (data.result == "success") {
                this.logService.updateLogMessage("Get user jobs count service request success")
                this.userSyncJobs = data.totalJobs;
                this.syncJobLimit = data.jobsLimit;
                this.syncJobsSP = data.totalSPJobs;
                this.syncJobsOD = data.totalODJobs;
                //console.log("this.userSyncJobs, this.syncJobLimit====", this.userSyncJobs, this.syncJobLimit)
                if (this.userSyncJobs >= this.syncJobLimit) {//this.syncJobLimit - 50 - 220
                    this.global.maxSyncJobCreated = true;
                } else {
                    this.global.maxSyncJobCreated = false;
                }
                requestOut.emit({ status: "success" });
            } else {
                this.logService.updateLogMessage("Get user jobs count service request failed")
                this.userSyncJobs = 0;
                this.syncJobLimit = 0;
                this.syncJobsSP = 0;
                this.syncJobsOD = 0;
                requestOut.emit({ status: "failed" });
            }
        });
        return requestOut;
    }

    public onShowBrowser(url) {
        if (this.electron.isElectronApp) {
            this.electron.shell.openExternal(url);
        } else {
            window.open(url, "_blank");
        }
    }

    public secondToTime(seconds) {
        let time = "";
        if (seconds < 60) {
            let sec = (seconds < 10) ? "0" + seconds : seconds;
            time = "00:00:" + sec;
        } else {
            time = "00:00:" + seconds;
        }
        return time;
    }

    public resetExpValues() {
        this.isLicenseExpired = false;
        this.licenseExpRemaining = null;
        this.licenseExpAfter = null;
        this.licenseExpOn = null;
        this.licenseExpMedssage = null;
        //console.log("I am here1")
    }

    public getUserLicense() {
        if (this.currentUserLicense != null && this.currentUserLicense.clientId != undefined && this.currentUserLicense.clientId != null && this.currentUserLicense.clientId != '') {
            return this.currentUserLicense.clientId;
        } else {
            let licenseData: any = this.getLicenseInfo();
            return licenseData.clientId;
        }/*  else if (this.netDocLocalStorage.licenseInfo && this.netDocLocalStorage.licenseInfo.clientId) {
            return this.netDocLocalStorage.licenseInfo.clientId;
        }  */
    }

    public getUserApiUrl() {
        //return (this.currentUserLicense != null && this.currentUserLicense.NDWebUrl != undefined && this.currentUserLicense.NDWebUrl != null && this.currentUserLicense.NDWebUrl != '') ? this.currentUserLicense.NDWebUrl : "";
        return (this.currentUserLicense != null && this.currentUserLicense.NDApiUrl != undefined && this.currentUserLicense.NDApiUrl != null && this.currentUserLicense.NDApiUrl != '') ? this.currentUserLicense.NDApiUrl : "";
    }
    public getUserWebUrl() {
        return (this.currentUserLicense != null && this.currentUserLicense.NDWebUrl != undefined && this.currentUserLicense.NDWebUrl != null && this.currentUserLicense.NDWebUrl != '') ? this.currentUserLicense.NDWebUrl : "";
    }

    public getLicenseInfo() {
        //let storedLicenseInfo = localStorage.getItem("licenseInfo");
        let storedLicenseInfo = this.netDocLocalStorage.licenseInfo;
        if (storedLicenseInfo != undefined && storedLicenseInfo != null && storedLicenseInfo != "") {
            this.currentUserLicense = (typeof (storedLicenseInfo) == 'string') ? JSON.parse(atob(storedLicenseInfo)) : storedLicenseInfo;
            return this.currentUserLicense;
        } else {
            return {};
        }
    }

    public getNDAuthToken() {
        if (this.currentUser.access_token != undefined && this.currentUser.access_token != null && this.currentUser.access_token != "") {
            return this.currentUser.access_token;
        } else {
            let storedNDInfo = this.netDocLocalStorage.ndInfo;
            if (storedNDInfo != undefined && storedNDInfo != null && storedNDInfo != "") {
                this.currentUser = storedNDInfo;
                return this.currentUser.access_token;
            } else {
                return null;
            }
        }
    }
    public getNDRefreshToken() {
        if (this.currentUser.refresh_token != undefined && this.currentUser.refresh_token != null && this.currentUser.refresh_token != "") {
            return this.currentUser.refresh_token;
        } else {
            let storedNDInfo = this.netDocLocalStorage.ndInfo;
            if (storedNDInfo != undefined && storedNDInfo != null && storedNDInfo != "") {
                this.currentUser = storedNDInfo;
                return this.currentUser.refresh_token;
            } else {
                return null;
            }
        }
    }
    //kalai
    getAccessibleClientMatter(data: any) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let requestUrl = this.getUserApiUrl();
        let body = {
            token: this.netDocLocalStorage.ndTokenInfo?.access_token,
            baseUrl: requestUrl?.replace("v2", ""),
            data: data
        }
        let requestURL = `api/validate/workspacesbyclientmatter`;
        this.netDocSharePost(requestURL, body, { "x-auth-ndbase-url": requestUrl }).subscribe((res: any) => {
            requestOut.emit({ status: res.status, data: res?.data?.data });
        });
        return requestOut;
    }
    getProfileAttributes(cabinetId: string) {
        const requestOut = new EventEmitter<any>();
        let requestEndPoint = `cabinet/${cabinetId}/customAttributes`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        this.NetDocumentsGet(requestEndPoint, "v1", headers).subscribe(res => {
            requestOut.emit(res);
        });
        return requestOut;
    }


    /** All NetDocuments get calls should call this function for get data from ND */
    requestHeaders(header: any = {}, resType: any = '', returnResHeaders = false, isReportProgress = false) {
        let headers = new HttpHeaders().set('Accept', 'application/json');
        for (let key in header) {
            headers = headers.set(key, header[key]);
        }
        let finalHeaders: any = { headers };
        if (resType) finalHeaders.responseType = resType;
        if (returnResHeaders) finalHeaders.observe = 'response';
        if (isReportProgress) {
            finalHeaders.reportProgress = true;
            finalHeaders.observe = 'events';
        }
        return finalHeaders;
    }

    NetDocumentsGet(endPoint: string, version = "v2", header = {}, resType = "", returnResHeaders = false) {

        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let baseUrl = `${version === "v1" ? this.getUserApiUrl().replace("v2", version) : this.getUserApiUrl()}`
        let requestUrl = `${baseUrl}/${endPoint}`;
        this._http.get(requestUrl, { headers: header }).subscribe((resData: any) => {
            requestOut.emit({
                status: "success", data: returnResHeaders ? resData.body : resData,
                headers: returnResHeaders ? resData.headers : null
            });
        }, (error) => {
            requestOut.emit({ status: "failed", data: null, error: error });
        });
        return requestOut;
    }
    netDocSharePost(endPoint: string, payload: any = {}, header = {}) {
        let requestOut: EventEmitter<any> = new EventEmitter<any>();
        let requestUrl = `${environment.serviceUrl}${endPoint}`;
        this._http.post(requestUrl, payload, this.requestHeaders(header)).subscribe((resData: any) => {
            requestOut.emit({ status: "success", data: resData });
        }, (error) => {
            requestOut.emit({ status: "failed", data: null, error: error });
        });
        return requestOut;
    }

    getDocumentInfo(id: string) {
        const requestOut = new EventEmitter<any>();
        let requestEndPoint = `document/${id}/info?select=standardAttributes,CustomAttributes,StatusAttributes,Locations,Versions,CheckedOutBy,Descriptions`;
        this.NetDocumentsGet(requestEndPoint).subscribe(res => {
            requestOut.emit(res);
        });
        return requestOut;
    }

    getWorkspaceInfoByClientMatter(cabinetId: string, client: string, matter = null) {
        const requestOut = new EventEmitter<any>();
        let id = `${cabinetId}/${client}`;
        if (matter) id += `/${matter}`;
        let requestEndPoint = `workspace/${id}/info`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        this.NetDocumentsGet(requestEndPoint, "v1", headers).subscribe(res => {
            if (res?.data) res.data = this.convertV1ToV2Object(res?.data);
            requestOut.emit(res);
        });
        return requestOut;
    }
    convertV1ToV2Object(data: any) {
        data = data?.standardAttributes ? data?.standardAttributes : data;
        let created = data?.created ? new Date(parseInt(data?.created.replace("/Date(", "").replace(")/", ""))) : '';
        let modified = data?.created ? new Date(parseInt(data?.modified.replace("/Date(", "").replace(")/", ""))) : '';
        return {
            Attributes: {
                Name: data?.name,
                Id: data?.id,
                Ext: data?.extension,
                Created: created,
                CreatedBy: data?.createdBy,
                CreatedByGuid: data?.createdByGuid,
                Modified: modified,
                ModifiedBy: data?.modifiedBy,
                ModifiedByGuid: data?.modifiedByGuid,
                Size: data?.size,
            },
            CustomAttributes: [],
            DocId: data?.id,
            EnvId: data?.envId,
            Versions: {
                Count: data?.latestVersionNumber
            },
            Status: {
                Access: {
                    Edit: true
                }
            },
            Locations: data?.Locations ? data.Locations : null
        }

    }


    getLookUpData(repoId: string, attrNum: string, parentAttrNum = null, searchQuery = null, skip = 0, top = 100) {
        const requestOut = new EventEmitter<any>();
        let requestEndPoint = `attributes/${repoId}/${attrNum}`;
        if (parentAttrNum) requestEndPoint += `/${parentAttrNum}`;
        requestEndPoint += `?$select=key,description,parent`;
        if (searchQuery) requestEndPoint += `&$filter=substringof(${searchQuery},keyfirst)`;
        requestEndPoint += `&$top=${top}&useLongName=true&$skip=${skip}`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        this.NetDocumentsGet(requestEndPoint, "v1", headers).subscribe(res => {
            if (res.status === 'success' && res.data && res.data.rows) res.data.overflow = res.data.rows.length >= top;
            requestOut.emit(res);
        });
        return requestOut;
    }

    getAllUsersInRepository(repositoryId: string) {
        const requestOut = new EventEmitter<any>();
        let requestEndPoint = `repository/${repositoryId}/users`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        this.NetDocumentsGet(requestEndPoint, "v1", headers).subscribe(res => {
            requestOut.emit(res);
        });
        return requestOut;
    }
    getWorkspaceAcl(docId: string) {
        let requestEndPoint = `container/${docId}/info?select=IncludeAcls,IncludeIsExternalToACL,DisplayNames,UseLongName,Locations`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        return this.NetDocumentsGet(requestEndPoint, "v2", headers);
    }

    getGroupMembers(repositoryId: string, groupId: string) {
        let requestEndPoint = `repository/${repositoryId}/group/${groupId}/members`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        return this.NetDocumentsGet(requestEndPoint, "v2", headers);
    }
    getDefaultGroups(groupId: string) {
        let requestEndPoint = `cabinet/${groupId}/defaultgroupspaged?Count=100`;
        let headers = new HttpHeaders().append('Authorization', 'Bearer ' + this.currentUser.access_token);
        return this.NetDocumentsGet(requestEndPoint, "v2", headers);
    }

}

