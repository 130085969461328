<div style="max-width:1400px;margin:auto">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="getLoaderTeam || createLoaderTeam">
        <div class="loader-container loader-container-absolute">
            <div class="loader"></div>
        </div>
    </div>

    <div class="button-container" style="text-align:right ;margin-bottom:20px;margin-right: 10px;">
        <button title="Create Team" *ngIf="global.isGlobalAdmin" 
            class="btn btn-sm btn-blue h-100" type="button"
            (click)="openTeam(modalTeam)"><img src="assets/images/plus_white.svg">
        Team</button>
        <button type="button" placement="right" class="btn btn-sm btn-blue h-100"
            (click)="onChooseFilterDate(modalFilter)" title="Filter">
            <img src="./assets/images/filter-applied.svg" alt="filter" class="button-loader" *ngIf="filterEnabled">
            <img src="./assets/images/filter-not-applied.svg" alt="filter" class="button-loader" *ngIf="!filterEnabled">
        </button>
        <button type="button" placement="right" class="btn btn-sm btn-blue h-100" (click)="getTeamSearchRequest(role)"
            title="Refresh">
            <img src="./assets/images/refresh.svg" alt="Refresh" class="button-loader">
        </button>
    </div>
    
    <div class="list">
        <div class="item" *ngFor="let reqData of teamRequestData">
            <div class="info">
                <div class="flexBox">
                    <div class="left">
                        <div class="teamName">
                            Team Name: <span>{{reqData.teamName}}</span>
                        </div>
                        <div class="description">
                            Description: <span>{{reqData.teamDescription}}</span>
                        </div>
                        <div class="name">
                            <div class="title">Created By: <span>{{reqData.createdBy}}</span></div>
                        </div>
                        <div class="name" *ngIf="reqData.Status=='Rejected' && role=='client'">
                            <div class="title">Rejected Reason: <span>{{reqData.comments}}</span></div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="date">
                            <div class="title">Created On: </div>
                            <span class="data">{{reqData.createdOn | date:'MMM d, yyyy HH:mm:ss'}} {{timezone}}</span>
                        </div>
                        <button *ngIf="reqData.Status=='Pending' && role=='admin'" type="button"
                            class="btn btn-link btn-sm" (click)="editRequest(reqData._id)" title="View"><span
                                class="material-icons-sharp md-visibility"></span> View</button>
                        <div class="status amber" *ngIf="reqData.Status=='Pending' && role=='client'">
                            <span style="color:#EF6C00">
                                Waiting for approval</span>
                            <button type="button" class="btn btn-link btn-sm" (click)="editRequest(reqData._id)"
                                title="Edit"><span class="material-icons-sharp md-edit"></span>Edit</button>
                        </div>
                        <div class="status amber" *ngIf="reqData.Status=='InProgress' && role=='client'">
                            <span style="color:#EF6C00" title="Creating channels, tabs and site collection">
                                In Progress
                            </span>
                        </div>
                        <div class="status amber" *ngIf="reqData.Status=='Approved' && role=='client'">
                            <span style="color:green; text-align: center;">
                                Approved</span>
                        </div>
                        <div class="status amber" *ngIf="reqData.Status=='Rejected' && role=='client'">
                            <span style="color:red; text-align: center;">
                                Rejected</span>
                        </div>


                        <div class="action" *ngIf="role=='admin'">
                            <button *ngIf="reqData.Status=='Pending' || reqData.Status=='Approved'"
                                class=" btn actionBtn" [disabled]="reqData.Status=='Approved'" style="color:green"
                                (click)="approveRequest(reqData)" title="Approved">{{reqData.Status=='Pending' ?
                                "Approve" : "Approved"}}</button>
                            <button *ngIf="reqData.Status=='Pending' || reqData.Status=='Rejected'" type="button"
                                class="btn actionBtn" [disabled]="reqData.Status=='Rejected'" style="color:red"
                                (click)="rejectRequest(rejectDesc,reqData._id)"
                                title="Rejected">{{reqData.Status=='Pending' ?
                                "Reject" : "Rejected"}}</button>
                            <div class="status amber" *ngIf="reqData.Status=='InProgress'">
                                <span style="color:#EF6C00" title="Creating channels, tabs and site collection">
                                    In Progress
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 border text-center" *ngIf="teamRequestData.length==0">
        <div class="alert alert-warning my-3" role="alert">
            <span>No data found</span>
        </div>
    </div>
</div>
<div [formGroup]="formReq">
    <ng-template #rejectDesc let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Comments </h4>
        </div>
        <div class="modal-body">
            <textarea class="form-control" rows="3" formControlName="rejectComments"
                placeholder="Let people know"></textarea>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Close</button>
            <button type="button" class="btn btn-secondary" (click)="editRequestComments(requestId)">Submit</button>
        </div>
    </ng-template>
</div>

<!--Filter Popup Modal-->
<ng-template #modalFilter let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Filter </h4>
    </div>
    <div class="modal-body">

        <div class="dateFilterBox">
            <div class="dateFilterRow">
                <div class="dateLabel">
                    <label for="">Created</label>
                </div>
                <div class="dateInput">
                    <div class="input">
                        <input type="text" placeholder="Start Date" name="dp"
                            [(ngModel)]="currentFilterSetting.CreatedStartDate" (ngModelChange)="onChangeFilterSelect()"
                            ngbDatepicker [maxDate]="maxDate" #fDate="ngbDatepicker" type="text"
                            (click)="fDate.toggle()">

                        <svg xmlns="http://www.w3.org/2000/svg" class="materialicon-view" (click)="fDate.toggle()"
                            height="24" width="24">
                            <path
                                d="M11 14v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2Zm-4 4v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2ZM3 22V4h3V2h2v2h8V2h2v2h3v18Zm2-2h14V10H5ZM5 8h14V6H5Zm0 0V6v2Z"
                                fill="currentColor" />
                        </svg>

                    </div>
                </div>
                <div class="dateInput">
                    <div class="input">
                        <input type="text" placeholder="End Date" name="dp"
                            [(ngModel)]="currentFilterSetting.CreatedEndDate" (ngModelChange)="onChangeFilterSelect()"
                            ngbDatepicker
                            [minDate]="currentFilterSetting.CreatedStartDate ? currentFilterSetting.CreatedStartDate : ''"
                            [maxDate]="maxDate" #tDate="ngbDatepicker" type="text" (click)="tDate.toggle()">

                        <svg xmlns="http://www.w3.org/2000/svg" class="materialicon-view" (click)="tDate.toggle()"
                            height="24" width="24">
                            <path
                                d="M11 14v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2Zm-4 4v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2ZM3 22V4h3V2h2v2h8V2h2v2h3v18Zm2-2h14V10H5ZM5 8h14V6H5Zm0 0V6v2Z"
                                fill="currentColor" />
                        </svg>

                    </div>
                </div>
            </div>
            <div class="dateFilterRow">
                <div class="dateLabel">
                    <label for="">Modified</label>
                </div>
                <div class="dateInput">
                    <div class="input">
                        <input type="text" placeholder="Start Date" name="dp"
                            [(ngModel)]="currentFilterSetting.ModifyStartDate"
                            (ngModelChange)="onChangeFilterModifySelect()" ngbDatepicker [maxDate]="maxDate"
                            #MDate="ngbDatepicker" type="text" (click)="MDate.toggle()">

                        <svg xmlns="http://www.w3.org/2000/svg" class="materialicon-view" (click)="MDate.toggle()"
                            height="24" width="24">
                            <path
                                d="M11 14v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2Zm-4 4v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2ZM3 22V4h3V2h2v2h8V2h2v2h3v18Zm2-2h14V10H5ZM5 8h14V6H5Zm0 0V6v2Z"
                                fill="currentColor" />
                        </svg>

                    </div>
                </div>
                <div class="dateInput">
                    <div class="input">
                        <input type="text" placeholder="End Date" name="dp"
                            [(ngModel)]="currentFilterSetting.ModifyEndDate"
                            (ngModelChange)="onChangeFilterModifySelect()" ngbDatepicker
                            [minDate]="currentFilterSetting.ModifyStartDate ? currentFilterSetting.ModifyStartDate : ''"
                            [maxDate]="maxDate" #mtDate="ngbDatepicker" type="text" (click)="mtDate.toggle()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="materialicon-view" (click)="mtDate.toggle()"
                            height="24" width="24">
                            <path
                                d="M11 14v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2Zm-4 4v-2h2v2Zm-4 0v-2h2v2Zm8 0v-2h2v2ZM3 22V4h3V2h2v2h8V2h2v2h3v18Zm2-2h14V10H5ZM5 8h14V6H5Zm0 0V6v2Z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
            <!---Status Process-->
            <div class="form-group row mb-3 align-items-center mt-2">
                <div class="col-md-12 col-xl-4">
                    <div class="clearfix d-flex justify-content-between align-items-center">
                        <label class="col-form-label col-label-info">Status</label>
                    </div>
                </div>
                <div class="col-md-12 col-xl-8">
                    <select class="form-control" id="elementStatus" [(ngModel)]="selectedStatus.status"
                        (ngModelChange)="onChangeFilterStatus()">
                        <option value="" selected disabled>Select</option>
                        <option *ngFor="let status of teamStatus" value="{{status.value}}">
                            {{status.text}}</option>
                    </select>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Close</button>
        <button type="button" class="btn btn-secondary" (click)="resetFilter();modal.close();">Reset</button>
        <button type="button" class="btn btn-blue" (click)="getTeamSearchRequest(this.role);modal.close();"
            data-dismiss="modal" [disabled]="!isFilterSearch">Search
        </button>
    </div>
</ng-template>
<ng-template #modalTeam let-modal style="max-width: 700px !important;">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Team</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: scroll;height: 450px;">
        <app-create-request [isFromRequestPage]="true" (onRequestCreated)="onRequestCreated()"></app-create-request>
    </div>
</ng-template>