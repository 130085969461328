import { Component, OnInit,EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'nd-combo-box',
  templateUrl: './nd-combo-box.component.html',
  styleUrls: ['./nd-combo-box.component.scss']
})
export class NdComboBoxComponent implements OnInit {
  @Input("appConfig") appConfig: any;
  @Input("placeholder") placeholder: string = "";
  @Input("sourceType") sourceType: string = "";
  @Input("isFetching") isFetching: boolean = false;
  @Input("selectedItem") set onSetSelectedItem(data: any) {
    if(data!=undefined){ this.selectedItem = data;
      this.searchString = data?.name || data?.key||data?.Title ||"";}
   
  }
  @Input("lookupData") set onSetLookupData(data: any) {
    if(data!=undefined){
    this.lookupData = data?.data;
    this.message = data?.message;
    this.skipToken = data?.skipToken || "";
  }
}
 
  @Output("onChange") public onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output("onFetch") public onFetch: EventEmitter<any> = new EventEmitter<any>();
  @Output("onReset") public onReset: EventEmitter<any> = new EventEmitter<any>();

  lookupData: any = null;
  message: string = "";
  skipToken: string = "";
  
  searchDelay: any = null;
  searchString: string = "";
  isSearching: boolean = false;
  selectedItem: any = null;
  constructor() { }

  ngOnInit(): void {
  }
  onScroll(event: any) {
    const dropdownMenuContainer: any = event?.target?.children[0];
    if ((event.target.scrollTop + event.target.offsetHeight + 100 > dropdownMenuContainer.offsetHeight) && !this.isFetching && this.skipToken) {
      this.onFetch.emit({ searchstring: this.searchString, skipToken: this.skipToken, fetchBy: "scroll" });
    }
  }

  onSearch(dropdown: any, comboBoxInput: any) {
    dropdown?.open();
    comboBoxInput?.focus();
    this.isSearching = true;
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.onFetch.emit({ searchstring: this.searchString.trim(), skipToken: "", fetchBy: "search" });
    }, 600);
  }

  onSelect(data: any, comboBoxDropdown: any) {
    this.selectedItem = { ...data, value: data?.name || data?.key ||data?.Title};
    comboBoxDropdown?.close();
    this.searchString = data?.name || data?.key + "-" + data?.description||data?.Title || "";
    this.onChange.emit(this.selectedItem);
    if(this.isSearching){
      this.isSearching = false;
      this.onFetch.emit({ searchstring: "", skipToken: "", fetchBy: "toggle" });
    }
    
  }

  onClear() {
    this.selectedItem = {};
    this.searchString = "";
    this.isSearching = false;
    this.onReset.emit();
    this.onFetch.emit({ searchstring: "", skipToken: "", fetchBy: "clear" });
  }

  getSources() {
    if (!this.lookupData || this.lookupData?.data?.length == 0) this.onFetch.emit({ searchstring: "", skipToken: "", fetchBy: "toggle" })
  }

  onClickInput(comboBoxDropdown: any, comboBoxInput: any){
    comboBoxDropdown?.open();
    comboBoxInput?.focus();
    this.getSources();
  }

}
