<div class="loader-container" *ngIf="ndvs.showLoginProcessLoader && !(unauthorized && fail)">
    <div class="loader"></div>
</div>
<div class="d-flex justify-content-center align-items-center nds-with-footer overflow-auto">
    <div class="nds-login-box">
        <div class="nds-login-logo bb-thick">
            <img src="./assets/images/netDocShare-logo.svg" alt="logo" class="nds-logo" />
            <!-- <img src="https://services.klobot.ai/apps/beta/netDocShareSync/assets/images/netDocShare-logo.svg" alt="logo" class="nds-logo" /> -->
        </div>

        <div *ngIf="unauthorized && !fail">
            <h6 class="nds-login-title text-center">Thank you for installing <b>netDocShare Provisioning</b> app</h6>
            <form><!--  or Firm Name
                or License Key -->
                <p class="px-30 fs-14 text-black-50">Please enter your Primary/Secondary/Admin email to continue. If you
                    do
                    not have a license key please contact <a
                        href="mailto:licensing@netdocshare.com">licensing@netdocshare.com</a>.</p>
                <div class="d-flex px-30 justify-content-center" *ngIf="(!electron.isElectronApp && isAdmin)">
                    <div class="checkbox-container" *ngIf="isAdmin">
                        <!-- <div class="form-check form-check-inline mt-2">
                            <input type="radio" name="SL" id="eleFirmName" name="eleLoginType" value="disable"
                                [checked]="(loginMethod=='firmname')" (click)="onSelectLoginType('firmname')"
                                [disabled]="(validating || isValidated)" />
                            <label for="eleFirmName"> Firm Name</label>
                        </div> -->
                        <!-- <div class="form-check form-check-inline mt-2">
                            <input type="radio" name="SL" id="eleEmail" name="eleLoginType" value="disable"
                                [checked]="(loginMethod=='email')" (click)="onSelectLoginType('email')"
                                [disabled]="(validating || isValidated)" />
                            <label for="eleEmail"> Email</label>
                        </div> -->
                        <!-- <div class="form-check form-check-inline mt-2">
                            <input type="radio" name="SL" id="eleClientId" name="eleLoginType" value="enable"
                                [checked]="(loginMethod=='clientid')" (click)="onSelectLoginType('clientid')"
                                [disabled]="(validating || isValidated)" />
                            <label for="eleClientId"> License Key</label>
                        </div> -->
                    </div>
                </div>
                <div class="px-30 pb-2 row">

                    <div class="col-sm-12">
                        <div class="col-sm-12 {{(isFirmNameLoading) ? 'show-loader' : ''}}"
                            *ngIf="(!electron.isElectronApp && isAdmin)">
                            <div *ngIf="(loginMethod=='firmname' || loginMethod=='clientid')">
                                <input #eleUserName name="eleUserName" type="text" autocomplete="off" list="firmNames"
                                    [(ngModel)]="userName" class="fs-14 form-control"
                                    placeholder="{{loginMethodPlaceHolder[loginMethod]}}"
                                    (ngModelChange)="onUserNameChange($event)" [disabled]="(isDisabledUserName)"
                                    (keypress)="onKeyPressUser($event)">
                                <img *ngIf="(isFirmNameLoading)" src="./assets/images/Spinner.gif" alt="Loader"
                                    class="loader-img firm-name-loading">
                                <datalist id="firmNames" class="fs-14">
                                    <option *ngFor="let sfName of suggestedFirmNames" value="{{sfName.client
                                    }}">
                                </datalist>
                            </div>
                            <div *ngIf="(loginMethod=='email')">
                                <input #eleUserName name="eleUserName" type="text" autocomplete="off" list="firmNames"
                                    [(ngModel)]="userName" class="fs-14 form-control"
                                    placeholder="{{loginMethodPlaceHolder[loginMethod]}}"
                                    (ngModelChange)="onUserNameChange($event)" [disabled]="(isDisabledUserName)"
                                    (keypress)="onKeyPressUser($event)">
                                <img *ngIf="(isFirmNameLoading)" src="./assets/images/Spinner.gif" alt="Loader"
                                    class="loader-img firm-name-loading">

                                <select *ngIf="(suggestedFirmNames.length>1)" class="form-control mt-3"
                                    id="elementUserTenant" name="elementUserTenant" [(ngModel)]="selectedClientId"
                                    (change)="onSelectClient()" [disabled]="(isDisabledUserName )">
                                    <option value="" [disabled]="true">Select your Tenant</option>
                                    <option *ngFor="let firm of suggestedFirmNames" value="{{firm.id}}"
                                        title="{{firm.client}}">{{firm.client}}</option>
                                </select>
                                <div *ngIf="(userMessage!='' && suggestedFirmNames.length!=1)"
                                    class="text-center alert px-3 py-1 mb-0 {{(suggestedFirmNames.length>1) ? 'alert-success' : 'alert-danger mt-3'}}"
                                    role="alert" [innerHTML]="userMessage"></div>
                                <!--    
                                <div *ngIf="(selectedClient==null)" class="suggested-firmname-container">
                                    <div class="suggested-firmname-list">
                                        <div *ngFor="let sfName of suggestedFirmNames" class="suggested-firmname-item"
                                            (click)="onSelectFirmName(sfName)">{{sfName.client
                                            }}</div>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div *ngIf="isShowCaptcha" class="mt-3 d-flex align-items-center flex-wrap flex-md-nowrap"
                            [ngClass]="{'hide' : (userName==null || userName =='') && !invalid}">
                            <re-captcha (resolved)="resolved($event)" (reset)="grecaptcha.reset()"
                                siteKey="{{thesiteKey}}">
                            </re-captcha>

                        </div>
                        <div class="row justify-content-center">
                            <div class="col-sm-8 my-2">
                                <div *ngIf="(loginMethod=='email' &&  !isFirmNameLoading && responseMessage!='' && isValidated)"
                                    class="text-center alert alert-success px-3 py-1 mb-0" role="alert"
                                    [innerHTML]="responseMessage"></div>
                                <div *ngIf="(loginMethod=='email' &&  !isFirmNameLoading && responseMessage!='' && !isValidated)"
                                    class="text-center alert alert-danger px-3 py-1 mb-0" role="alert"
                                    [innerHTML]="responseMessage"></div>

                                <div *ngIf="validating" class="alert alert-light px-3 text-center py-1 mb-0"
                                    role="alert">
                                    Validating License Key… <img *ngIf="(validating)" src="./assets/images/Spinner.gif"
                                        alt="Loader" class="loader-img license-validating-loader">
                                </div>

                                <div *ngIf="(loginMethod!='email' && userName!='' && isValidated && responseMessage!='')"
                                    class="text-center alert alert-success px-3 py-1 mb-0" role="alert"
                                    [innerHTML]="responseMessage"></div>

                                <div *ngIf="(loginMethod!='email' && userName!='' && invalid && responseMessage!='')"
                                    class="text-center alert alert-danger px-3 py-1 mb-0" role="alert"
                                    [innerHTML]="responseMessage"></div>


                                <div *ngIf="isRobot && fail" class="text-center alert alert-danger px-3 py-1 mb-0"
                                    role="alert">
                                    Invalid Token. Please try again.
                                </div>
                                <div *ngIf="(expired && !isValidated && responseMessage=='')"
                                    class="text-center alert alert-danger px-3 py-1 mb-0" role="alert">
                                    Your re-captcha token has expired. Please try again.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="unauthorized && fail">
            <!-- <h2 class="nds-login-title my-3 text-center">401: Unauthorized</h2> -->
            <div class="px-30 row justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="alert alert-danger py-1 mt-3" role="alert">
                        Whoops! Sorry, your account <b>{{userName}}</b> is unauthorized to access this page. To enable
                        access, please contact <a href="mailto:support@netdocshare.com">support@netdocshare.com</a>.
                    </div>
                    <button type="button" (click)="onClickSignInDU()" class="btn d-block mb-3 mx-auto text-blue"
                        [disabled]="isSignInDiffUserTriggred">Sign in different user</button>
                </div>
            </div>
        </div>

        <div *ngIf="(ndvs.licenseExpOn!=null && ndvs.licenseExpOn!='')" class="px-30 pb-3 row  justify-content-center">
            <div class="col-sm-7 text-center px-0">
                <div *ngIf="(ndvs.isLicenseExpired)" class="alert alert-danger px-3 py-1 mb-0" role="alert">
                    Your license has expired on {{ndvs.licenseExpOn}}. Please contact <a
                        href="mailto:licensing@netdocshare.com">licensing@netdocshare.com </a>
                </div>
                <!-- <div *ngIf="(!ndvs.isLicenseExpired)" class="alert alert-danger px-3 py-1 mb-0" role="alert">
                    Your license will expire on {{ndvs.licenseExpOn}}. Please contact <a href="mailto:licensing@netdocshare.com">licensing@netdocshare.com </a>
                </div>                     -->
            </div>
        </div>

        <div *ngIf="(((unauthorized && !fail) && !electron.isElectronApp) || isValidated) ">
            <div class="bb-thick"></div>
            <div *ngIf="(loginMethod=='firmname' || loginMethod=='clientid')" class="px-30 py-3 text-right">
                <button *ngIf="!ndvs.isLicenseExpired  && (unauthorized && !fail)" type="button"
                    class="btn btn-secondary"
                    [disabled]="(!isValidated || isRobot || isLoginNDTriggred || (unauthorized && fail) || userName=='')"
                    [ngClass]="{'im-primary-btn' : isValidated && !isRobot}" (click)="doLogin()"><i
                        class="material-icons-sharp md-login"></i>&nbsp;&nbsp;Login to
                    NetDocuments</button>
                <button *ngIf="isAdmin" type="button" class="btn btn-secondary" [hidden]="(!isValidated)"
                    [ngClass]="{'im-primary-btn' : isValidated && !isRobot}" (click)="onCancel()">
                    <i *ngIf="(ndvs.isLicenseExpired)" class="material-icons-sharp md-home"></i>
                    <i *ngIf="(!ndvs.isLicenseExpired)" class="material-icons-sharp md-cancel"></i>
                    &nbsp;&nbsp;{{(ndvs.isLicenseExpired) ? 'Home' : 'Cancel'}}</button>
            </div>
            <div *ngIf="(loginMethod=='email')" class="px-30 py-3 text-right">
                <button *ngIf="!ndvs.isLicenseExpired  && (unauthorized && !fail)" type="button"
                    class="btn btn-secondary" [disabled]="(!isValidated)"
                    [ngClass]="{'btn-blue' : isValidated && !isRobot}" (click)="doLogin()"><i
                        class="material-icons-sharp md-login"></i>&nbsp;&nbsp;Login to
                    NetDocuments</button>
                <button *ngIf="isAdmin" type="button" class="btn btn-secondary" [hidden]="(!isValidated)"
                    [ngClass]="{'btn-blue' : isValidated && !isRobot}" (click)="onCancel()">
                    <i *ngIf="(ndvs.isLicenseExpired)" class="material-icons-sharp md-home"></i>
                    <i *ngIf="(!ndvs.isLicenseExpired)" class="material-icons-sharp md-cancel"></i>
                    &nbsp;&nbsp;{{(ndvs.isLicenseExpired) ? 'Home' : 'Cancel'}}</button>
            </div>
            <!-- <div class="text-center text-danger pb-2 pl-1 pr-1">Please note that starting January 1, 2024, login using
                Firm Name and License key will no longer be supported. Please use your Email to access this application.
            </div> -->
        </div>
        <div *ngIf="(electron.isElectronApp && isAdmin && !isValidated)">
            <div class="px-30 py-3 text-right">
                <button type="button" class="btn btn-secondary" (click)="onLicenseValidate()"
                    [disabled]="(userName=='' || validating)" [ngClass]="{'im-primary-btn' : userName!=''}"><i
                        class="material-icons-sharp md-login"></i>&nbsp;&nbsp;Validate License</button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>