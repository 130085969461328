import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ElectronService } from 'ngx-electron';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private remote = null;
  private fs = null;
  private path = null;
  private fileName = moment().format("YYYYMMDD") + ".txt";

  constructor(public electron: ElectronService) {
    if (this.electron.isElectronApp) {
      this.remote = this.electron.remote;
      this.fs = this.remote.require('fs');
      this.path = this.remote.require('path');

    }
  }

  public logInitiate() {
    if (this.remote == null || this.fs == null) return;
    this.fs.exists(this.path.join(__dirname, '/../../../logs'), (e) => {
      if (e) {
        this.fs.exists(this.path.join(__dirname, '/../../../logs') + '/' + this.fileName, (e) => {
          if (!e) {
            this.createLogFile().subscribe((res) => {
              this.updateLogMessage("File created")
            })
          }
        });
      } else {
        this.fs.mkdir(this.path.join(__dirname, '/../../../logs'), "0777", () => {
          this.createLogFile().subscribe((res) => {
            this.updateLogMessage("File created")
          })
        });
      }
    });
  }

  private createLogFile() {
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this.fs.writeFile(this.path.join(__dirname, '/../../../logs') + "/" + this.fileName, '', (res) => {
      requestOut.emit("success");
    }, (err) => {
      requestOut.emit("failed");
    })
    return requestOut;
  }

  public updateLogMessage(message = "") {
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    let logStatus: any = localStorage.getItem('logStatus');
    logStatus = (logStatus == undefined || logStatus == null || logStatus == '' || !logStatus || logStatus.toLocaleLowerCase() == 'false') ? false : true;
    if (this.electron.isElectronApp) {
      message = moment().format("DD-MMM-YYYY HH:mm:ss") + " => " + message + "\n";
      this.fs.appendFile(this.path.join(__dirname, '/../../../logs') + "/" + this.fileName, message, () => {
        requestOut.emit("success");
      }, (err) => {
        requestOut.emit("failed");
      })
    } else {
      if (!environment.production || logStatus)
        requestOut.emit("success");
    }
    return requestOut;
  }
}
