import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  public members = [];
  public searchUserString = "";
  public searchmembers = [];
  public loadingMember: boolean = false;
  public loadingAddMember: boolean = false;
  public loadingPrivateMembers: boolean = false;
  removedMembers = [];
  dropdownSettings: IDropdownSettings = {};
  selectedItems = [];
  dropDownForm: FormGroup;


  @Input() public teamId: string = "";
  @Input() public channelId: string = "";
  @Input() public isChannelEdit: boolean = false;

  @Output() channelMembers: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private teamsService: TeamsService,
    public global: GlobalService,
    private toaster: ToastrService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'userId',
      textField: 'name',
      /* allowSearchFilter: true */
    };
    this.selectedItems = [
    ];
    this.dropDownForm = this.fb.group({
      myItems: [this.selectedItems]
    });
    this.dropDownForm.valueChanges.subscribe((e) => {
      this.channelMembers.emit({ members: e.myItems.map(item => item.userId), removedMembers: this.removedMembers.map(item => item.id) })
    })
    if (this.isChannelEdit) {
      this.loadingPrivateMembers = true;
      this.teamsService.fetchPrivateMembers(this.teamId, this.channelId).subscribe((res) => {
        if (res.status == 'success') {
          this.members = res.data;
        } else if (res.status == 'failed') {
          this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
        }
        this.loadingPrivateMembers = false;
      }, (error) => {
        this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
        this.loadingPrivateMembers = false;
      })
    }
    this.fetchAllMembers();
  }
  public clearSearch(e) {
    this.searchUserString = ""
  }
  public setSearchUserString(val) {
    this.fetchAllMembers();
  }
  public fetchAllMembers = () => {
    this.loadingMember = true;
    this.teamsService.fetchAllTeamMembers(this.teamId, this.searchUserString).subscribe((res) => {
      if (res.status == 'success') {
        this.searchmembers = res.data.length > 0 ? res.data.map((i) => { return { id: i.id, name: i.displayName, userType: i.userType, userId: i.userId } }) : [];
        if (this.isChannelEdit) {
          let result = this.searchmembers.filter(o1 => !this.members.some(o2 => o1.userId === o2.userId));
          //console.log(result)
          let result2 = result.filter(d => d.userType != "Guest");
          this.searchmembers = result2;
        }
        //console.log(this.searchmembers, this.members);
        this.openDropdown();
      } else if (res.status == 'failed') {
        this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
      }
      this.loadingMember = false;
    }, (error) => {
      this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
      this.loadingMember = false;
    })
  }



  public addMembers = () => {
    if (this.dropDownForm.value.myItems.length == 0 || this.loadingAddMember) return;
    this.loadingAddMember = true;
    let membersIdList = [];
    this.dropDownForm.value.myItems.map(m => {
      membersIdList.push(m.id)
    })
    this.teamsService.addTeamMember({ teamId: this.global.selectedGroup.Id, members: membersIdList }).subscribe((res) => {
      if (res.status == 'success') {
        this.toaster.success("Members are added to team.", "Success", { extendedTimeOut: 10 });
        this.loadingAddMember = false;
        this.modalService.dismissAll(res.data);
      } else if (res.status == 'failed') {
        this.toaster.error(res.message, "Failure", { extendedTimeOut: 10 });
      }
      this.loadingAddMember = false;
    }, (error) => {
      this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
      this.loadingAddMember = false;
    })
  }


  openDropdown() {

    let el = document.getElementsByClassName("dropdown-list")[0] as HTMLElement;
    let el2 = document.getElementsByClassName("dropdown-btn")[0] as HTMLElement;
    el.style.position = "relative";
    if (this.searchmembers.length == 0) {
      if (!el.hasAttribute("hidden")) {
        el2.click();
      }
    } else {
      if (el.hasAttribute("hidden")) {
        el2.click();
      }
    }
  }

  public removeMember(member) {
    let filteredMembers = this.members.filter(i => i.id == member.id);
    let retainedMembers = this.members.filter(i => i.id != member.id);
    this.members = retainedMembers;
    this.removedMembers = [...this.removedMembers, ...filteredMembers];
    this.channelMembers.emit({ members: this.dropDownForm.value.myItems.map(item => item.userId), removedMembers: this.removedMembers.map(item => item.id) })
  }

}
