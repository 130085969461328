import { Component, OnInit } from '@angular/core';
import { ToasterMessageService } from 'src/app/services/toaster-message.service';

@Component({
  selector: 'toaster-message',
  templateUrl: './toaster-message.component.html',
  styleUrls: ['./toaster-message.component.scss']
})
export class ToasterMessageComponent implements OnInit {

  constructor(public tms:ToasterMessageService) { }

  ngOnInit(): void {

  }

}
